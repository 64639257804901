import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import {
  Typography,
  TextField,
  Box,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import {
  accountDeletionRequest,
  userLoginForDelete,
} from '../../controllers/accounts/accountDeleteControllers';

const deleteOptions = {
  ACCOUNT_DELETION: 'accountDeletion',
  DATA_DELETION: 'dataDeletion',
  ACCOUNT_AND_DATA_DELETION: 'accountAndDataDeletion',
  ACCOUNT_DEACTIVATION: 'accountDeactivation',
};

function DataDeletionRequest() {
  const [formData, setData] = useState<any>({});
  const [error, setErrors] = useState<any>('');
  const [authUser, setAuthUser] = useState<any>({});
  const [deleteRequest, setDeleteRequest] = useState<any>('');
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));
  };

  const userLogin = useMutation(userLoginForDelete);
  const deletionRequest = useMutation(accountDeletionRequest);

  const onLoginSubmit = async () => {
    try {
      const response = await userLogin.mutateAsync(formData);
      setAuthUser(response);
    } catch (err) {
      // log error
      // console.error('err:', err);
      setErrors('Login Error');
    }
  };

  const onDeleteRequestSubmit = async () => {
    try {
      const payload = { token: authUser.accessToken, deleteRequest };
      await deletionRequest.mutateAsync(payload);
      setRequestSubmitted(true);
    } catch (err) {
      // console.error('err:', err);
    }
  };

  // console.log('formData:', formData);
  // console.log('deleteRequest:', deleteRequest);

  const btnIsDisabled =
    formData.phoneNumber && formData.password ? false : true;

  if (requestSubmitted) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Stack spacing={4} paddingY={24}>
          <Typography variant="h5" fontWeight={'bold'}>
            Your Request has been submitted !!
          </Typography>
          <Typography variant="h5">Thank You !</Typography>
        </Stack>
      </Box>
    );
  }

  if (authUser.accessToken) {
    return (
      <Box display="flex" justifyContent="center" mt={8}>
        <Stack spacing={4} paddingY={24}>
          <Typography variant="h5">
            Hello, {authUser.user?.firstName}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            Please select one of the following options to proceed with your
            account deletion request:
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="data-delete-radio-buttons"
              name="data-delete-buttons-group">
              <FormControlLabel
                value={deleteOptions.ACCOUNT_DELETION}
                control={
                  <Radio
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                    onChange={(e: any) => setDeleteRequest(e.target.value)}
                  />
                }
                label="Account Deletion"
              />
              <FormControlLabel
                value={deleteOptions.DATA_DELETION}
                control={
                  <Radio
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                    onChange={(e: any) => setDeleteRequest(e.target.value)}
                  />
                }
                label="Data Deletion"
              />
              <FormControlLabel
                value={deleteOptions.ACCOUNT_AND_DATA_DELETION}
                control={
                  <Radio
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                    onChange={(e: any) => setDeleteRequest(e.target.value)}
                  />
                }
                label="Account & Data Deletion"
              />
              <FormControlLabel
                value={deleteOptions.ACCOUNT_DEACTIVATION}
                control={
                  <Radio
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                    onChange={(e: any) => setDeleteRequest(e.target.value)}
                  />
                }
                label="Account Deactivation"
              />
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            size="large"
            disabled={deleteRequest ? false : true}
            onClick={onDeleteRequestSubmit}>
            Submit Request
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" mt={8}>
      <Stack
        spacing={4}
        paddingY={24}
        // alignSelf={'center'}
      >
        <Typography variant="h5">
          Login to request for account & data deletion
        </Typography>

        <TextField
          id="phoneNumber"
          label="Phone Number"
          variant="outlined"
          inputProps={{ maxLength: 10 }}
          onChange={(e: any) => onFormChange('phoneNumber', e.target.value)}
        />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          type="password"
          onChange={(e: any) => onFormChange('password', e.target.value)}
        />

        {error && (
          <Typography color={'red'} textAlign={'center'} fontSize={18}>
            {error}
          </Typography>
        )}
        <Button
          variant="contained"
          size="large"
          disabled={btnIsDisabled}
          onClick={onLoginSubmit}>
          Login
        </Button>
      </Stack>
    </Box>
  );
}

export default DataDeletionRequest;
