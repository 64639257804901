import { store } from '../../store/store';
import {
  partnerUrl,
  vendorUrl,
  customerUrl,
  updatePartnerUrl,
  partnerCodeUrl,
  loanPartnerUrl,
  updateLoanPartnerUrl,
  loanPartnerCodeUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getPartnerCode = async (data: any) => {
  const path = partnerCodeUrl();

  const response = await httpClient().get<any>(path, data);
  return response.data;
};

export const getLoanPartnerCode = async () => {
  const path = loanPartnerCodeUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getPartners = async (query: any) => {
  const path = partnerUrl();

  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const getVendors = async () => {
  const path = vendorUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getCustomers = async () => {
  const path = customerUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getLoanPartners = async (data: any) => {
  const path = loanPartnerUrl();

  const response = await httpClient().get<any>(path, data);
  return response.data;
};

export const createPartner = async (data: any) => {
  const path = partnerUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const createLoanPartner = async (data: any) => {
  const path = loanPartnerUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updatePartner = async (payload: any) => {
  const path = updatePartnerUrl(payload.partnerId);

  const response = await httpClient().patch<any>(path, payload.data);
  return response.data;
};

export const updateLoanPartner = async (payload: any) => {
  const path = updateLoanPartnerUrl(payload.partnerId);

  const response = await httpClient().patch<any>(path, payload.data);
  return response.data;
};

export const deletePartner = async (partnerId: number) => {
  const path = v1(`/settings/partners/${partnerId}`);

  const response = await httpClient().delete<any>(path);
  return response.data;
};

export const addPartnerCategory = async (data: number) => {
  const path = v1('/settings/partner-category');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getPartyCategories = async () => {
  const path = v1('/settings/partner-category');

  const response = await httpClient().get<any>(path);
  return response.data;
};
