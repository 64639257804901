import { getPartnerCode } from '../../controllers/settings/partnerControllers';
import { emailIsValid, phoneNoIsValid } from '../common/commonValidations';

export const validatePartnerCode = async (data: any, errors: any) => {
  const dx = { isCustomer: data.isCustomer, isVendor: data.isVendor };
  const newPartnerCode = await getPartnerCode(dx);
  let isCodeValid = true;

  if (data.code !== newPartnerCode) {
    errors = { ...errors, code: 'Partner code updated, submit again!' };
    isCodeValid = false;
  } else {
    delete errors.code;
  }

  return { isCodeValid, errss: errors };
};

export const validatePartner = (data, errors) => {
  let isValid = true;

  if (!data.code) {
    errors = { ...errors, code: 'Code is required' };
    isValid = false;
  } else if (data.code.length > 16) {
    errors = { ...errors, code: 'Code should be less than 17 characters' };
    isValid = false;
  } else {
    delete errors.code;
  }

  if (!data.name) {
    errors = { ...errors, name: 'Name is required' };
    isValid = false;
  } else if (data.name.length > 128) {
    errors = { ...errors, name: 'Name should be less than 129 characters' };
    isValid = false;
  } else {
    delete errors.name;
  }

  if (data.phoneNumber && !phoneNoIsValid(data.phoneNumber)) {
    errors = { ...errors, phoneNumber: 'Invalid phone number' };
    isValid = false;
  } else {
    delete errors.phoneNumber;
  }

  if (data.panNumber && data.panNumber.length > 32) {
    errors = {
      ...errors,
      panNumber: 'PanNumber should be less than 33 characters',
    };
    isValid = false;
  } else {
    delete errors.panNumber;
  }

  if (data.address && data.address.length > 64) {
    errors = {
      ...errors,
      address: 'Address should be less than 65 characters',
    };
    isValid = false;
  } else {
    delete errors.address;
  }

  if (data.email && !emailIsValid(data.email)) {
    errors = {
      ...errors,
      email: 'Invalid email address',
    };
    isValid = false;
  } else {
    delete errors.email;
  }

  if (data.customerReceivable && !data.cusOpeningDate) {
    errors = { ...errors, cusOpeningDate: 'Opening Date is required' };
    isValid = false;
  } else {
    delete errors.cusOpeningDate;
  }

  if (data.vendorPayable && !data.venOpeningDate) {
    errors = { ...errors, venOpeningDate: 'Opening Date is required' };
    isValid = false;
  } else {
    delete errors.venOpeningDate;
  }

  // if (data.isVendor && data.accountPayable === undefined) {
  //   errors = {
  //     ...errors,
  //     accountPayable: 'Account payable is required for vendor',
  //   };
  //   isValid = false;
  // } else {
  //   delete errors.accountPayable;
  // }

  // if (data.isCustomer && data.accountReceivable === undefined) {
  //   errors = {
  //     ...errors,
  //     accountReceivable: 'Account receivable is required for customer',
  //   };
  //   isValid = false;
  // } else {
  //   delete errors.accountReceivable;
  // }

  // if (!data.isVendor && data.accountPayable) {
  //   delete data.accountPayable;
  // }

  // if (!data.isCustomer && data.accountReceivable) {
  //   delete data.accountReceivable;
  // }

  return { isValid, errors };
};

export const validatePartnerSwitchFields = (name: string, data) => {
  if (name === 'isCustomer' && data.isCustomer) {
    data = { ...data, isCustomer: false, isVendor: true };
  } else if (name === 'isCustomer' && !data.isCustomer) {
    data = { ...data, isCustomer: true, isVendor: false };
  } else if (name === 'isVendor' && data.isVendor) {
    data = { ...data, isCustomer: true, isVendor: false };
  } else if (name === 'isVendor' && !data.isVendor) {
    data = { ...data, isVendor: true, isCustomer: false };
  }

  return data;
};

export const validatePartnerCategory = (data, errors) => {
  let isValid = true;
  if (!data.name) {
    errors = { ...errors, name: 'Category Name is required' };
    isValid = false;
  } else if (data.name.length > 64) {
    errors = {
      ...errors,
      name: 'Category Name cannot be greater than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  return { isValid, errors };
};
