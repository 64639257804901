import {
  bankAndCashBookUrl,
  incomeStatementUrl,
  generalLedgerUrl,
  partnerLedgerUrl,
  dashboardReportUrl,
  partnersAccountUrl,
  bankAndCashBalanceCheckUrl,
  productStockUrl,
  productStockDetailsUrl,
  trialBalanceUrl,
  balanceSheetUrl,
  productStatementUrl,
  expenseStatementUrl,
  employeeStatementUrl,
  bankloanStatementUrl,
  partnerStatementUrl,
  partnerProductStatementUrl,
} from '../endPoints';
import { store } from '../../store/store';
import { dayBookUrl } from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};
const v1 = (path: string) => `/v1${path}`;

export const getDashboardReport = async () => {
  const path = v1('/reports/dashboard');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const generalLedger = async (filterFields: any) => {
  const path = generalLedgerUrl();

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const partnerLedger = async (filterFields: any) => {
  const path = partnerLedgerUrl();

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const partnersAccountLedger = async (filterFields: any) => {
  const path = partnersAccountUrl();

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const partnerDashboardLedger = async (filters: any) => {
  const path = v1('/reports/partner-ledger-dashboard');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const dayBook = async (filterFields: any) => {
  const path = dayBookUrl();

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const bankAndCashBook = async (filterFields: any) => {
  const path = bankAndCashBookUrl();

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const incomeStatement = async (filterFields: any) => {
  const path = incomeStatementUrl();

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const trialBalance = async (filters: any) => {
  const path = trialBalanceUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const balanceSheet = async (filters: any) => {
  const path = balanceSheetUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const productStatement = async (filters: any) => {
  const path = productStatementUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const expenseStatement = async (filters: any) => {
  const path = expenseStatementUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const employeeStatement = async (filters: any) => {
  const path = employeeStatementUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const bankloanStatement = async (filters: any) => {
  const path = bankloanStatementUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const partnerStatement = async (filters: any) => {
  const path = partnerStatementUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const partnerProductStatement = async (filters: any) => {
  const path = partnerProductStatementUrl();

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const productStockMovement = async (filters: any) => {
  const path = v1('/inventory/product-stock-movement');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const bankAndCashBalanceCheck = async (
  accountId: any,
  amount: number,
) => {
  const path = bankAndCashBalanceCheckUrl(accountId, amount);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const productStockAPI = async () => {
  const path = v1('/inventory/product-stocks');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const productStockDetails = async (productId: number) => {
  const path = productStockDetailsUrl(productId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const stockAdjustmentAPI = async (data: any) => {
  const path = v1('/transactions/stock-adjustments');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const partnerProductTxn = async (filters: any) => {
  const path = v1('/reports/partner-product-txn');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const salesReportAPI = async (filters: any) => {
  const path = v1('/reports/sales-report');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const stockPurchaseReport = async (filters: any) => {
  const path = v1('/reports/stock-purchase-report');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const partnersProdTxnStatement = async (filterFields: any) => {
  const path = v1('/reports/partner-product-txn-statements');

  const response = await httpClient().get<any>(path, filterFields);
  return response.data;
};

export const getCashBalance = async (accountId: number) => {
  const path = v1(`/reports/cash-balance/${accountId}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};
