import axios from 'axios';
import configs from '../../config/configs';

type LoginData = {
  phoneNumber: string;
  password: string;
};

export const userLoginForDelete = async (data: LoginData) => {
  const DOMAIN = configs.baseURL;
  const PATH = 'v1/auth/login';

  const response = await axios({
    method: 'POST',
    url: `${DOMAIN}/${PATH}`,
    data: {
      phoneNumber: data.phoneNumber,
      password: data.password,
    },
  });
  return response.data;
};

export const accountDeletionRequest = async (data: any) => {
  const DOMAIN = configs.baseURL;
  const PATH = 'v1/accounts/user-account-deletion-request';

  const response = await axios({
    method: 'POST',
    url: `${DOMAIN}/${PATH}`,
    headers: { Authorization: `Bearer ${data.token}` },
    data: { deleteRequest: data.deleteRequest },
  });
  return response.data;
};
