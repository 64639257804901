import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import auth from '@react-native-firebase/auth';

import {
  firebaseUserLoginAPI,
  refreshAccessTokenAPI,
  refreshUserDetailsAPI,
  sendSMSOTP,
  userLoginAPI,
  userPasswordResetAPI,
  verifySMSOTP,
} from '../controllers/accounts/userAccountControllers';

import { setClient } from './httpSlice';
// import { saveSecureStore } from '../controllers/accounts/asyncStorage';

// export const signInWithPhoneNumber = createAsyncThunk(
//   'auth/phoneLogin',
//   async (payload: { phoneNumber: string }, { rejectWithValue }) => {
//     const { phoneNumber } = payload;
//     try {
//       const otpSent = await auth().signInWithPhoneNumber(
//         phoneNumber,
//       );
//       return otpSent;
//     } catch (err) {
//       console.log('err2 @login', err);
//       return rejectWithValue('Error authenticating');
//     }
//   },
// );

// export const confirmVerificationCode = createAsyncThunk(
//   'auth/codeConfirm',
//   async (
//     payload: { code: string; otpSent: any },
//     { rejectWithValue },
//   ) => {
//     try {
//       const { code, otpSent } = payload;
//       const response = await otpSent?.confirm(code);
//       return response;
//     } catch (err) {
//       console.log('err @refresing token', err);
//       return rejectWithValue('Invalid code');
//     }
//   },
// );

export const signInWithPhoneNumber = createAsyncThunk(
  'auth/phoneLogin',
  async (
    payload: { phoneNumber: string; countryCode: string; otpUsage: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await sendSMSOTP(payload);
      return response;
    } catch (err: any) {
      // console.log('err sending otp:', err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const confirmVerificationCode = createAsyncThunk(
  'auth/codeConfirm',
  async (
    payload: { otp: string; phoneNumber: string; countryCode: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await verifySMSOTP(payload);
      return response;
    } catch (err) {
      // console.log('err @confirming otp:', err);
      return rejectWithValue('Invalid code');
    }
  },
);

// export const verifyFirebaseUserWithServer = createAsyncThunk(
//   'auth/verifyFirebaseUser',
//   async (payload: { token: string }, { dispatch, rejectWithValue }) => {
//     try {
//       const authUser = await firebaseUserLoginAPI(payload.token);
//       const { user, companyAccount } = authUser;
//       await saveSecureStore('userSession', { user, companyAccount });

//       // console.log('user @asynthunk', authUser);
//       dispatch(setClient(authUser));
//       return authUser;
//     } catch (err: any) {
//       // console.log('err @login', err);
//       // console.log('err @login', err.response.data);
//       return rejectWithValue(err.response.data);
//     }
//   },
// );

export const userLogin = createAsyncThunk(
  'auth/userLogin',
  async (
    payload: { phoneNumber: string; password: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const authUser = await userLoginAPI(payload);
      localStorage.setItem('userSession', JSON.stringify(authUser));

      // await saveSecureStore('userSession', authUser);
      // console.log('user @asynthunk', authUser.accessToken);
      dispatch(setClient(authUser));
      return authUser;
    } catch (err) {
      // console.log('err @login', err);
      return rejectWithValue(err.response?.data);
    }
  },
);

export const refreshUserDetails = createAsyncThunk(
  'auth/refreshUserDetails',
  async (payload, { dispatch, rejectWithValue }: any) => {
    try {
      const authUser = await refreshUserDetailsAPI();
      // await saveSecureStore('userSession', authUser);

      dispatch(setClient(authUser));
      return authUser;
    } catch (err) {
      // console.log('err @refresing token ud', err);
      return rejectWithValue('Invalid auth token');
    }
  },
);

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async (refreshToken: string, { dispatch, rejectWithValue }: any) => {
    try {
      const authUser = await refreshAccessTokenAPI(refreshToken);
      localStorage.setItem('userSession', JSON.stringify(authUser));

      // await saveSecureStore('userSession', authUser);
      dispatch(setClient(authUser));
      return authUser;
    } catch (err) {
      // console.log('err @refresing token rt', err);
      return rejectWithValue('Invalid auth token');
    }
  },
);

export const userPasswordReset = createAsyncThunk(
  'auth/passwordReset',
  async (
    payload: { phoneNumber: string; password: string },
    { dispatch, rejectWithValue }: any,
  ) => {
    try {
      const authUser = await userPasswordResetAPI(payload);
      // await saveSecureStore('userSession', authUser);
      // console.log('user on pass reset:', authUser.user);
      dispatch(setClient(authUser));
      return authUser;
    } catch (err: any) {
      // console.log('err @reseting password1', err);
      return rejectWithValue('Err on password reset');
    }
  },
);

const initialState: any = {
  requestingCode: false,
  codeRequestError: false,
  otpSent: undefined,

  confirmingCode: false,
  codeConfirmError: false,
  firebaseUser: undefined,
  codeVerified: false,
  invalidCode: false,

  authUser: {},
  isAuthenticated: false,
  isAuthenticating: false,
  onAuthStateChangeUnsubscribe: undefined,
  authenticationError: false,
  userRegistrationInProcess: false,
  loginCodeUnlocked: false,
};

export const authenticationSlice = createSlice({
  name: 'auth',
  initialState,

  reducers: {
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
      state.isAuthenticated = true;
      state.isAuthenticating = false;
    },
    startUserRegistration: state => {
      state.userRegistrationInProcess = true;
    },
    endUserRegistration: state => {
      state.userRegistrationInProcess = false;
    },
    logout: () => initialState,

    loginCodeUnlocked: state => {
      state.loginCodeUnlocked = true;
    },
  },

  extraReducers: builder => {
    // code request
    builder.addCase(signInWithPhoneNumber.pending, state => {
      state.requestingCode = true;
    });
    builder.addCase(signInWithPhoneNumber.fulfilled, (state, action) => {
      state.requestingCode = false;
      state.otpSent = action.payload.otpSent;
    });
    builder.addCase(signInWithPhoneNumber.rejected, state => {
      state.requestingCode = false;
      state.codeRequestError = true;
    });

    // code confirmation
    builder.addCase(confirmVerificationCode.pending, state => {
      state.confirmingCode = true;
    });
    builder.addCase(confirmVerificationCode.fulfilled, (state, action) => {
      state.confirmingCode = false;
      state.codeVerified = action.payload.verified;
      state.invalidCode = action.payload.invalidCode;
    });
    builder.addCase(confirmVerificationCode.rejected, state => {
      state.confirmingCode = false;
      state.codeConfirmError = true;
    });

    // builder.addCase(verifyFirebaseUserWithServer.pending, state => {
    //   state.isAuthenticating = true;
    // });
    // builder.addCase(verifyFirebaseUserWithServer.fulfilled, (state, action) => {
    //   state.isAuthenticated = true;
    //   state.isAuthenticating = false;
    //   state.authUser = action.payload;
    // });
    // builder.addCase(verifyFirebaseUserWithServer.rejected, state => {
    //   state.authenticationError = true;
    //   state.isAuthenticating = false;
    // });

    // initially implemented for email and pass login
    builder.addCase(userLogin.pending, state => {
      state.isAuthenticating = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.authUser = action.payload;
    });
    builder.addCase(userLogin.rejected, state => {
      state.isError = true;
      state.isAuthenticating = false;
    });

    /** REFRESH USER DETAILS */
    builder.addCase(refreshUserDetails.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.authUser = action.payload;
    });
    builder.addCase(refreshUserDetails.rejected, state => {
      state.isError = true;
      state.isAuthenticating = false;
    });

    /** REFRESH ACCESS TOKEN */
    builder.addCase(refreshAccessToken.pending, state => {
      state.isAuthenticating = true;
    });
    builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.authUser = action.payload;
    });
    builder.addCase(refreshAccessToken.rejected, state => {
      state.isError = true;
      state.isAuthenticated = false;
      state.isAuthenticating = false;
    });

    /** PASSWORD RESET */
    builder.addCase(userPasswordReset.pending, state => {
      state.isAuthenticating = true;
    });
    builder.addCase(userPasswordReset.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.isAuthenticating = false;
      state.authUser = action.payload;
    });
    builder.addCase(userPasswordReset.rejected, state => {
      state.isError = true;
      state.isAuthenticating = false;
    });
  },
});

export const {
  setAuthUser,
  logout,
  startUserRegistration,
  endUserRegistration,
  loginCodeUnlocked,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
