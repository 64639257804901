export const businessCreateFields = [
  { fieldName: 'name', required: true, numeric: false },
  { fieldName: 'address', required: false, numeric: false },
  { fieldName: 'phoneNumber', required: false, numeric: true },
  { fieldName: 'panNumber', required: false, numeric: true },
  { fieldName: 'description', required: false, numeric: false },
];

export const businessUpdateFields = [...businessCreateFields];

export const businessSwitchFields = [
  { fieldName: 'onVat', required: false },
  // { fieldName: 'enableInvoiceInterest', required: false },
  // { fieldName: 'enableBillInterest', required: false },
  { fieldName: 'showPanVatNoOnInvoice', required: false },
  { fieldName: 'isActive', required: false },
];

export const businessUpdateFields1 = [{ fieldName: 'name', required: true }];

export const businessUpdateFields2 = [
  { fieldName: 'address', required: false, numberic: false },
  { fieldName: 'phoneNumber', required: false, numberic: true },
  { fieldName: 'panNumber', required: false, numberic: true },
  // { fieldName: 'description', required: false },
];
