import i18next from 'i18next';
import { DashboardCardType } from '../../../constants/defaultCodes/transactionCodes';

// TO DO:
// CREATE CONST WITH CODES USED BELOW, AND USE THEM ACCROSS USED PLACES

export const generateDashboardData = (data: any) => {
  const dashboardReports = [
    {
      id: 1,
      rows: [
        {
          id: 31,
          code: DashboardCardType.receivable,
          title: i18next.t('dashboard.title.receivable'),
          balance: data.receivableBalances,
          iconName: 'compress-arrows-alt',
          closing: true,
        },
        {
          id: 32,
          code: DashboardCardType.payable,
          title: i18next.t('dashboard.title.payable'),
          balance: data.payableBalances,
          iconName: 'expand-arrows-alt',
          closing: true,
        },
        {
          id: 21,
          code: 'revenue',
          title: i18next.t('dashboard.title.revenue'),
          balance: data.incomeBalances,
          iconName: 'hand-holding-usd',
          nonClosing: true,
        },
        {
          id: 22,
          code: 'expense',
          title: i18next.t('dashboard.title.expense'),
          balance: data.expenseBalances,
          iconName: 'location-arrow',
          nonClosing: true,
        },
      ],
    },
    {
      id: 3,
      rows: [
        {
          id: 11,
          code: 'bankAndCash',
          title: i18next.t('dashboard.title.bankAndCash'),
          balance: data.bankAndCashBalances,
          iconName: 'money-bill-alt',
          closingAssets: true,
        },

        {
          id: 41,
          code: 'otherAsset',
          title: i18next.t('dashboard.title.otherAsset'),
          balance: data.assetsBalances,
          iconName: 'building',
          closingAssets: true,
        },
        {
          id: 42,
          code: 'stockValue',
          title: i18next.t('dashboard.title.stockValue'),
          balance: data.stockBalances,
          iconName: 'funnel-dollar',
          inventory: true,
        },
        {
          id: 12,
          code: 'netProfit',
          title: i18next.t('dashboard.title.netProfit'),
          balance: data.netProfitBalances,
          iconName: 'chart-line',
          nonClosing: true,
        },
      ],
    },
  ];

  return dashboardReports;
};
