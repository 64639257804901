export const formatAmount = (amount: any) => {
  const numericAmount = Number(amount);
  // const numericAmount = 1138800;

  let localeString = numericAmount.toLocaleString('hi-IN', {
    style: 'currency',
    currency: 'INR',
  });
  // console.log('localeString', localeString);

  const splittedValues = localeString.split('');

  let filteredValues;
  if (splittedValues[0] === '-') {
    filteredValues = splittedValues.splice(2, splittedValues.length);
    filteredValues.unshift('-');
  } else {
    filteredValues = splittedValues.splice(1, splittedValues.length);
  }

  const formattedAmount = filteredValues.join('');
  // console.log('formattedAmount', formattedAmount);

  // if (formattedAmount === '0.00') {
  //   return '-';
  // }

  return formattedAmount;
};

export const amountToLocaleString = (amount: any) => {
  const numericAmount = Number(amount);
  return numericAmount.toLocaleString('hi-IN');
};

export const formattedAmountDashboard = (amount: any) => {
  const numericAmount = Number(amount);
  return numericAmount.toLocaleString('hi-IN', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
