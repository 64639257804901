import { AxiosRequestConfig } from 'axios';
import HttpClientWithRetry from '../../config/httpClient';

import { store } from '../../store/store';
import {
  makeAdminUrl,
  removeAdminUrl,
  userDateFormatUrl,
  userLoginCodeUrl,
  loginCodeStatusUrl,
  helpTextStatusUrl,
  invoiceInterestStatusUrl,
  billInterestStatusUrl,
  panNumberStatusUrl,
  updateActiveBusinessUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;
// const v2 = (path: string) => `v2/${path}`;

export const userLoginAPI = async (data: any) => {
  const path = v1('/auth/login');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const userLogoutAPI = async () => {
  const path = v1('/auth/logout');

  const response = await httpClient().post<any>(path);
  return response.data;
};

export const refreshAccessTokenAPI = async (refreshToken: string) => {
  const path = v1('/auth/refresh-token');
  const client: HttpClientWithRetry = new HttpClientWithRetry();

  client.interceptors?.request.use((config: any): AxiosRequestConfig => {
    config.headers = {
      refreshtoken: refreshToken,
    };
    return config;
  });

  const response = await client.post<any>(path);
  return response.data;
};

export const firebaseUserLoginAPI = async (firebaseToken: string) => {
  const path = v1('/auth/firebase-login');
  const client: HttpClientWithRetry = new HttpClientWithRetry();

  client.interceptors?.request.use((config: any): AxiosRequestConfig => {
    config.headers = {
      Authorization: `Bearer ${firebaseToken}`,
    };
    return config;
  });

  const response = await client.get<any>(path);
  return response.data;
};

export const refreshUserDetailsAPI = async () => {
  const path = v1('/auth/refresh-user');
  const response = await httpClient().get<any>(path);
  return response.data;
};

export const checkUserExistance = async (data: any) => {
  const path = v1('/accounts/check-user/');

  const response = await httpClient().get<any>(path, data);
  return response.data;
};

export const sendSMSOTP = async (data: any) => {
  const path = v1('/auth/send-sms-otp');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const verifySMSOTP = async (data: any) => {
  const path = v1('/auth/verify-sms-otp');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const userRegistrationAPI = async (data: any) => {
  const path = v1('/accounts/register');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const userPasswordResetAPI = async (data: any) => {
  const path = v1('/auth/password-reset');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const userPasswordChangeAPI = async (data: any) => {
  const path = v1('/auth/password-change');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const giveEmployeeAdminAccess = async (employeeId: number) => {
  const path = makeAdminUrl(employeeId);

  const response = await httpClient().patch<any>(path);
  return response.data;
};

export const removeEmployeeAdminAccess = async (employeeId: number) => {
  const path = removeAdminUrl(employeeId);

  const response = await httpClient().patch<any>(path);
  return response.data;
};

export const userDateFormat = async (data: any) => {
  const path = userDateFormatUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const userLoginCode = async (data: any) => {
  const path = userLoginCodeUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const loginCodeStatus = async (data: any) => {
  const path = loginCodeStatusUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const helpTextStatus = async (data: any) => {
  const path = helpTextStatusUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const userLocaleAPI = async (data: any) => {
  const path = v1('/accounts/user/locale');

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const invoiceInterestStatus = async (data: any) => {
  const path = invoiceInterestStatusUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const billInterestStatus = async (data: any) => {
  const path = billInterestStatusUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const panNumberStatus = async (data: any) => {
  const path = panNumberStatusUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const updateActiveBusiness = async (data: any) => {
  const path = updateActiveBusinessUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const checkAllowedSettingCount = async () => {
  const path = v1('/accounts/check-allowed-setting-count');

  const response = await httpClient().get<any>(path);
  return response.data;
};
