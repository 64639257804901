import React from 'react';
import { colors } from '../../../assets/styles/colors';
import { Button, ButtonGroup } from '@mui/material';

const CashOrCreditSwitch = ({
  isFullPayment,
  onToggle,
  isPdfScreen = false,
  disable = false,
}: any) => {
  const getBgColor = (isCash: boolean) => {
    if (isCash) {
      return isFullPayment ? colors.theme1 : colors.gray200;
    } else {
      return !isFullPayment ? colors.theme1 : colors.gray200;
    }
  };

  const getDisableStatus = (isCash: boolean) => {
    if (isPdfScreen) {
      return true;
    }

    if (isCash) {
      return isFullPayment ? true : false;
    } else {
      return !isFullPayment ? true : false;
    }
  };

  return (
    <ButtonGroup aria-label="outlined primary button group">
      <Button
        sx={{
          width: 120,
          // fontSize: 15,
          fontWeight: getDisableStatus(true) ? 'bold' : '400',
          backgroundColor: getBgColor(true),
          borderColor: getBgColor(true),
          color: !isFullPayment && colors.blueGrey500,
        }}
        variant={isFullPayment ? 'contained' : 'outlined'}
        onClick={() => onToggle(true)}>
        CASH
      </Button>
      <Button
        sx={{
          width: 120,
          fontWeight: getDisableStatus(false) ? 'bold' : '400',
          backgroundColor: getBgColor(false),
          borderColor: getBgColor(false),
          color: isFullPayment && colors.blueGrey500,
        }}
        variant={!isFullPayment ? 'contained' : 'outlined'}
        onClick={() => onToggle(false)}>
        CREDIT
      </Button>
    </ButtonGroup>
  );
};

export default CashOrCreditSwitch;
