import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import { CardContent, Divider, Stack, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { getPartyCategories } from '../../../controllers/settings/partnerControllers';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';

const PartnerCategoriesListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // const [filteredItems, setFilteredItems] = useState<any>([]);
  // const [searchText, setSearchText] = useState<string>('');

  const catsQuery = useQuery(['partyCategories'], getPartyCategories);
  const { data = [], isLoading, isError } = catsQuery;

  return (
    <Card sx={{ minWidth: 275, paddingX: 4 }}>
      <CardContent>
        <Stack pb={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom>
                  {t('settingStack.partnerCategories')} ({data.length})
                </Typography>
              </Grid>
              <Grid xs={4}>
                {/* <InputForm
                  size="small"
                  label={'search account by name, code or type...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                /> */}
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              title="Add Partner Category"
              onClick={() =>
                navigate('/settings/partner-categories/create', {
                  replace: true,
                })
              }
            />
          </Box>
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ width: 64 }}>SN</TbCellHeader>

                  <TbCellHeader>Partner Category</TbCellHeader>
                  {/* <TbCellHeader align="right">Created At</TbCellHeader> */}
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody>{index + 1}</TbCellBody>

                    <TbCellBody
                      component="th"
                      scope="row"
                      style={{ fontSize: 16 }}>
                      {row.name}
                    </TbCellBody>
                    {/* <TableCell align="right">{row.createdAt}</TableCell> */}
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
};

export default PartnerCategoriesListPage;
