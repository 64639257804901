import i18next from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';
import { itemsAmountSum } from '../../shared/utils/amountSum';
import { getTaxAmount } from '../../shared/utils/vatAndDiscount';

const { SALES_RETURN } = voucherTypes;

export const validateVoucherNo = async (data: any, errors: any) => {
  const newVoucherNumber = await getVoucherNumber(SALES_RETURN);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateSalesReturnFilters = (data: any, errors: any) => {
  let isValid = true;

  if (data.fromDate === undefined) {
    errors = { ...errors, fromDate: 'From date is required' };
    isValid = false;
  } else {
    delete errors.fromDate;
  }

  if (data.toDate === undefined) {
    errors = { ...errors, toDate: 'To Date is required' };
    isValid = false;
  } else {
    delete errors.toDate;
  }

  return { isValid, errs: errors };
};

export const validateSalesReturn = (data: any, errors: any) => {
  let isValid = true;

  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('voucher.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.voucherDate === undefined) {
    errors = {
      ...errors,
      voucherDate: i18next.t('salesReturn.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.isFullPayment && !data.customer) {
    errors = {
      ...errors,
      customer: i18next.t('voucher.validation.customerRequired'),
    };
    isValid = false;
  } else {
    delete errors.customer;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('voucher.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  if (!data.voucherItems.length) {
    errors = {
      ...errors,
      salesAmount: 'Return Item is required',
    };
    isValid = false;
  } else {
    delete errors.salesAmount;
  }

  if (data.paidAmount) {
    const { isTaxable } = data;
    const discountAmount = Number(data.discountAmount || 0);
    const amountSum = itemsAmountSum(data.voucherItems);
    const taxAmount = getTaxAmount(isTaxable, discountAmount, amountSum, 13);

    const finalRawAmount = amountSum - discountAmount + (+taxAmount || 0);
    const finalAmount = +finalRawAmount.toFixed(2);

    if (data.paidAmount > finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('invoice.validation.invalidPaidAmount'),
      };
      isValid = false;
    }

    if (!data.isFullPayment && data.paidAmount === finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('invoice.validation.isCashPayment'),
      };
      isValid = false;
    }
  } else {
    delete errors.paidAmount;
  }

  if ((data.paidAmount || data.isFullPayment) && !data.cashOutAccount) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('salesReturn.validation.cashOutAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  data.voucherItems.forEach((voucherItem: any) => {
    const accountField = `${voucherItem.lineNo}-account`;
    const amountField = `${voucherItem.lineNo}-amount`;

    if (!voucherItem.account) {
      errors = {
        ...errors,
        [accountField]: i18next.t(
          'salesReturn.validation.salesAccountRequired',
        ),
      };
      isValid = false;
    } else {
      delete errors[accountField];
    }

    if (!voucherItem.amount || voucherItem.amount <= 0) {
      errors = {
        ...errors,
        [amountField]: i18next.t('salesReturn.validation.returnAmountRequired'),
      };
      isValid = false;
    } else {
      delete errors[amountField];
    }
  });

  if (
    data.discountAmount &&
    data.discountAmount > itemsAmountSum(data.voucherItems)
  ) {
    errors = {
      ...errors,
      discountAmount: i18next.t('bilinvoice.validation.invalidDiscount'),
    };
    isValid = false;
  } else {
    delete errors.discountAmount;
  }

  return { isValid, errs: errors };
};
