import NepaliDate from 'nepali-date-converter';

import { dateFormat } from '../../constants/defaultCodes/otherEnums';
import { formatToTextDate } from './dateTime';
import { getNepaliDate } from './nepaliDate';
import { monthsList } from '../../components/calendar/NepaliCalendarData';

export const getSettingBasedDate = (date: any, user: any) => {
  const jsDate = new Date(date);
  const userDateFormat = user.business?.dateFormat;

  if (userDateFormat === dateFormat.BS) {
    return getNepaliDate(jsDate);
  } else {
    return formatToTextDate(jsDate);
  }
};

export const dateFileName = (user: any) => {
  const jsDate = new Date();
  const timeNow = `${jsDate.getHours()}${jsDate.getMinutes()}`;

  const userDateFormat = user.business?.dateFormat;

  if (userDateFormat === dateFormat.BS) {
    const bsDate = new NepaliDate(jsDate);
    const textMonth = monthsList[bsDate.getMonth()].name;
    return `${textMonth}_${bsDate.getDate()}_${bsDate.getYear()}${timeNow}`;
  } else {
    let dateString = jsDate.toDateString();
    let splitted = dateString.split(' ');
    return `${splitted[1]}_${splitted[2]}_${splitted[3]}${timeNow}`;
  }
};

export const monthDayFileName = (user: any) => {
  const jsDate = new Date();

  const userDateFormat = user.business?.dateFormat;

  if (userDateFormat === dateFormat.BS) {
    const bsDate = new NepaliDate(jsDate);
    const textMonth = monthsList[bsDate.getMonth()].name;
    return `${textMonth}_${bsDate.getDate()}`;
  } else {
    let dateString = jsDate.toDateString();
    let splitted = dateString.split(' ');
    return `${splitted[1]}_${splitted[2]}`;
  }
};

export const testFn = () => {};
