import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../../store/reduxHooks';
import { balanceSheet } from '../../../controllers/reports/reportControllers';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import DatePicker from '../../../components/datePickers/DatePicker';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';

function BalanceSheet() {
  const { t } = useTranslation();

  const { user } = useSelector(s => s.auth.authUser);

  const [toDate, setToDate] = useState<any>(new Date());
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const balFn = () => balanceSheet({ toDate });
  const balanceSheets = useQuery(['balanceSheet'], balFn, { enabled: false });
  // console.log('balanceSheets ==', balanceSheets.data);
  // console.log('toDate:', toDate);

  useEffect(() => {
    if (toDate) {
      balanceSheets.refetch();
    }
  }, [toDate]);

  const onCreatePDF = async () => {
    const now = new Date();
    const createdDate = getSettingBasedDate(now, user);
    const createdTime = now.toLocaleTimeString();

    const data = {
      user,
      balanceSheetData: balanceSheets.data,
      // fromDate: getSettingBasedDate(filters.fromDate, user),
      toDate: getSettingBasedDate(toDate, user),
      createdAt: `${createdDate} ${createdTime}`,
    };

    // const template = balanceSheetTemplate(data);

    // const fileName = `balance_sheet_${dateFileName(user)}`.toLowerCase();
    // const source = await createPDF(template, fileName);
    // setPdfSource(source);
  };

  const sheet = balanceSheets.data || {};
  // console.log('sheet:', sheet);

  const balanceSheetSum: any = {
    currentAssets: formatAmount(sheet.currentAssetsSum),
    nonCurrentAssets: formatAmount(sheet.nonCurrentAssetsSum),
    currentLiabilities: formatAmount(sheet.currentLiabilitiesSum),
    nonCurrentLiabilities: formatAmount(sheet.nonCurrentLiabilitiesSum),
    equities: formatAmount(sheet.equitySum),
    totalAssets: formatAmount(sheet.assetsSum),
    totalLiabilities: formatAmount(sheet.liabilitiesSum),
    equitiesAndLiabilitiesSum: formatAmount(sheet.equitiesAndLiabilitiesSum),
  };

  const sheetItems = [
    { name: 'Current Assets', key: 'currentAssets' },
    { name: 'Non Current Assets', key: 'nonCurrentAssets' },
    { name: 'Assets', key: 'totalAssets', summary: true, primary: true },
    { name: 'Current Liabilities', key: 'currentLiabilities' },
    { name: 'Non Current Liabilities', key: 'nonCurrentLiabilities' },
    { name: 'Liabilities', key: 'totalLiabilities', summary: true },
    { name: 'Equities', key: 'equities' },
    {
      name: 'Equities & Liabilities',
      key: 'equitiesAndLiabilitiesSum',
      summary: true,
      primary: true,
    },
  ];

  const renderBalanceSheet = (row, index) => {
    if (!row.summary) {
      if (!balanceSheets.data?.balanceSheet[row.key].length) {
        return null;
      }
    }

    return (
      <>
        {!row.summary && (
          <TableRow
            sx={{
              backgroundColor: colors.gray100,
              borderTopWidth: index == 0 ? 0 : 8,
              borderColor: colors.white,
            }}>
            <TbCellBody
              colSpan={2}
              sx={{
                fontWeight: 'bold',
                fontSize: 16,
                color: colors.darker2,
              }}>
              {row.name.toUpperCase()}
            </TbCellBody>
          </TableRow>
        )}

        {balanceSheets.data?.balanceSheet[row.key]?.length > 0 &&
          balanceSheets.data?.balanceSheet[row.key].map((row2, index2) => (
            <TableRowStyled key={index2}>
              <TbCellBody sx={{ paddingLeft: 8 }}>{row2.account}</TbCellBody>
              <TbCellAmount>{formatAmount(row2.balance)}</TbCellAmount>
            </TableRowStyled>
          ))}

        <TableRow
          sx={{
            backgroundColor: row.primary ? colors.gray200 : colors.gray100,
          }}>
          <TbCellAmount
            sx={{
              color: row.primary ? colors.darker : colors.darker3,
              fontSize: row.primary ? 17 : 16,
              fontStyle: 'italic',
            }}>
            Total {row.name}
          </TbCellAmount>

          <TbCellSummary
            sx={{
              fontSize: row.primary ? 20 : 17,
              color: row.primary ? colors.theme1 : colors.darker,
            }}>
            {balanceSheetSum[row.key]}
          </TbCellSummary>
        </TableRow>
      </>
    );
  };

  return (
    <ScreenWrapper
      isLoading={balanceSheets.isLoading}
      isError={balanceSheets.isError}>
      <>
        <Card sx={{ paddingX: 4 }}>
          <CardContent sx={{ paddingY: 1 }}>
            <Stack direction={'row'} alignItems={'center'} pb={0.4} gap={3}>
              <Typography sx={{ fontSize: 20 }}>
                {t('reportStack.balanceSheet')} as of
              </Typography>
              <Stack pb={1}>
                <DatePicker
                  size="small"
                  onDateChange={(v: any) => setToDate(v)}
                  value={toDate}
                />
              </Stack>
            </Stack>

            <Divider />

            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ paddingLeft: 8 }}>
                      Account Name
                    </TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {sheetItems.map((row, index) =>
                    renderBalanceSheet(row, index),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </>
    </ScreenWrapper>
  );
}

export default BalanceSheet;
