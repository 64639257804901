import React from 'react';
import { Typography } from '@mui/material';

import { colors } from '../../../../assets/styles/colors';

function ProdctSectionTitle({ title }) {
  return (
    <Typography
      bgcolor={colors.info100}
      mt={2}
      p={1}
      py={1.4}
      px={2}
      fontWeight={'bold'}
      color={colors.theme1}
      borderRadius={1}>
      {title}
    </Typography>
  );
}

export default ProdctSectionTitle;
