import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Stack } from '@mui/material';
import SelectPicker from '../../../../components/forms/SelectPicker';
import InputForm from '../../../../components/forms/InputForm';

function GeneralVoucherItem({
  voucherItem,
  accounts,
  formData,
  errors,
  onFormChange,
  onFormItemChange,
  getHelpText,
}: any) {
  const { debit, credit, account, lineNo } = voucherItem;

  return (
    <Stack>
      <Grid
        container
        rowSpacing={{ xs: 1, sm: 2, md: 3 }}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid xs={6}>
          <SelectPicker
            mode={'modal'}
            isRequired={true}
            selectedValue={account}
            selectItems={accounts}
            placeHolder="Account"
            isInvalid={`${lineNo}-account` in errors}
            helpText={getHelpText(`${lineNo}-account`)}
            onValueChange={(v: string) =>
              onFormItemChange('account', v, lineNo)
            }
          />
        </Grid>
        <Grid xs={3}>
          <InputForm
            inLine={true}
            isNumberic={true}
            editable={credit ? false : true}
            defaultValue={String(debit || '')}
            placeHolder="0.00"
            isInvalid={`${lineNo}-debit` in errors}
            helpText={getHelpText(`${lineNo}-debit`)}
            onChangeText={(v: string) => {
              onFormItemChange('debit', +v, lineNo);
              onFormItemChange('credit', +v, lineNo === 1 ? 2 : 1);
            }}
          />
        </Grid>
        <Grid xs={3}>
          <InputForm
            inLine={true}
            isNumberic={true}
            editable={debit ? false : true}
            defaultValue={String(credit || '')}
            placeHolder="0.00"
            isInvalid={`${lineNo}-credit` in errors}
            helpText={getHelpText(`${lineNo}-credit`)}
            onChangeText={(v: string) => {
              onFormItemChange('credit', +v, lineNo);
              onFormItemChange('debit', +v, lineNo === 1 ? 2 : 1);
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}

export default GeneralVoucherItem;
