import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Divider, Typography, Container } from '@mui/material';

import Card from '@mui/material/Card';
import { CardContent, Chip, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import {
  getPartnerCode,
  getPartyCategories,
  updatePartner,
} from '../../../controllers/settings/partnerControllers';
import { deleteEmptyFields } from '../../../shared/utils/objectUtils';
import { validatePartner } from '../../../validations/settings/validatePartner';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import DatePicker from '../../../components/datePickers/DatePicker';
import Button from '../../../components/buttons/Button';
import {
  partnerInputFields,
  partnerUpdateSwitchFields,
} from '../../../constants/formFields/settings/partnerFormFields';
import SwitchForm from '../../../components/forms/SwitchForm';

function UpdatePartnerPage() {
  const navigate = useNavigate();
  const { state: partner } = useLocation();
  if (!partner) {
    navigate('/settings/partners');
    return null;
  }
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const isOBEditable = partner.isOBEditable;

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const partyCatFn = () => getPartyCategories();
  const partyCategories = useQuery(['partyCategories'], partyCatFn);

  useEffect(() => {
    const { cusOpeningDate, venOpeningDate } = partner;

    const partnerData: any = {
      code: partner.code,
      email: partner.email,
      isActive: partner.isActive,
      isCustomer: partner.isCustomer,
      isLoanPartner: partner.isLoanPartner,
      isVendor: partner.isVendor,
      name: partner.name,
      panNumber: partner.panNumber,
      phoneNumber: partner.phoneNumber,
      address: partner.address,
      customerReceivable: partner.customerReceivable,
      vendorPayable: partner.vendorPayable,
      category: partner.categoryId,
      // cusOpeningDate: cusOpeningDate ? new Date(cusOpeningDate) : '',
      // venOpeningDate: venOpeningDate ? new Date(venOpeningDate) : '',
    };
    cusOpeningDate && (partnerData.cusOpeningDate = new Date(cusOpeningDate));
    venOpeningDate && (partnerData.venOpeningDate = new Date(venOpeningDate));
    // console.log('partnerData', partnerData);
    deleteEmptyFields(partnerData);

    setData(prev => ({ ...prev, ...partnerData }));
  }, [partner]);

  const { isCustomer, isVendor } = formData;
  const partnerCodeQuery = { isCustomer, isVendor };
  const codeFn = () => getPartnerCode(partnerCodeQuery);
  const partnerCode = useQuery(['getPartnerCode', partnerCodeQuery], codeFn, {
    enabled: false,
  });

  const codeShouldUpdate =
    partner.isVendor !== isVendor || partner.isCustomer !== isCustomer;
  // console.log('codeShouldUpdate..', codeShouldUpdate);

  useEffect(() => {
    if (
      isCustomer !== undefined &&
      isVendor !== undefined &&
      codeShouldUpdate
    ) {
      partnerCode.refetch();
    }
  }, [isCustomer, isVendor]);

  useEffect(() => {
    if (partnerCode.data) {
      const updatedData = { ...formData, code: partnerCode.data };
      setData(updatedData);
    }

    if (partner.isVendor === isVendor && partner.isCustomer === isCustomer) {
      const updatedData = { ...formData, code: partner.code };
      setData(updatedData);
    }
  }, [partnerCode.data, isVendor, isCustomer]);

  const { isLoading, mutateAsync } = useMutation(updatePartner);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validatePartner(formData, errors);

    if (isValid) {
      try {
        const payload = { partnerId: partner.id, data: formData };
        await mutateAsync(payload);
        queryClient.invalidateQueries(['getPartners']);
        queryClient.invalidateQueries(['getDashboardReport']);

        navigate('/settings/partners', { replace: true });
        enqueueSnackbar(t('partner.updateMsg.success'), successToast);
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('partner.updateMsg.error'), errorToast);
      }
    } else {
      setErrors(errs);
      // console.log('Validation Failed');
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validatePartner(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const onToggleIsActive = (name: string) => {
    const updatedData = { ...formData, [name]: !formData[name] };
    setData(updatedData);
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName === 'code' && codeShouldUpdate) {
      return t('partner.helpText.codeUpdated');
    }
    if (fieldName in errors) {
      return errors[fieldName];
    } else {
      if (fieldName === 'isActive') {
        return t(`partner.helpText.${fieldName}-${formData[fieldName]}`);
      } else {
        // return t(`partner.helpText.${fieldName}`);
      }
    }
  };

  // console.log('formData Partner:', formData);
  // console.log(' isOBEditable:', isOBEditable);
  // console.log('errors Partner:', errors);
  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.updateParty')}
          </Typography>
          <Divider />
          <Stack py={2} gap={3}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <InputForm
                  isRequired={true}
                  editable={false}
                  isDisabled={true}
                  defaultValue={formData.code}
                  label={t('partner.label.code')}
                  placeHolder={t('partner.placeholder.code')}
                  isInvalid={'code' in errors}
                  onChangeText={(v: string) => onFormChange('code', v)}
                  helpText={getHelpText('code')}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  label={t('partner.label.category')}
                  selectedValue={formData.category}
                  selectItems={partyCategories.data || []}
                  isInvalid={'category' in errors}
                  helpText={'category' in errors && errors.category}
                  onValueChange={(v: any) => onFormChange('category', v)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {partnerInputFields.map(({ fieldName, required, numberic }) => (
                <Grid xs={6} key={fieldName}>
                  <InputForm
                    isRequired={required}
                    isNumberic={numberic}
                    isFloatNo={false}
                    maxLength={fieldName === 'phoneNumber' && 10}
                    defaultValue={formData[fieldName]}
                    label={t(`partner.label.${fieldName}`)}
                    placeHolder={t(`partner.placeholder.${fieldName}`)}
                    isInvalid={fieldName in errors}
                    onChangeText={(v: string) => onFormChange(fieldName, v)}
                    helpText={getHelpText(fieldName)}
                  />
                </Grid>
              ))}
            </Grid>

            {partnerUpdateSwitchFields.map(({ fieldName, disabled }) => (
              <SwitchForm
                key={fieldName}
                disabled={disabled}
                isChecked={formData[fieldName]}
                label={t(`partner.label.${fieldName}`)}
                onToggle={() => onToggleIsActive(fieldName)}
                helpText={getHelpText(fieldName)}
              />
            ))}

            {formData.isCustomer && isOBEditable && (
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={6}>
                  <InputForm
                    isNumberic={true}
                    maxLength={12}
                    label={t('partner.label.customerReceivable')}
                    defaultValue={String(formData.customerReceivable || '')}
                    isInvalid={'customerReceivable' in errors}
                    onChangeText={(v: string) =>
                      onFormChange('customerReceivable', v)
                    }
                    helpText={
                      'customerReceivable' in errors &&
                      errors.customerReceivable
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    entryMinDate={true}
                    isRequired={formData.customerReceivable ? true : false}
                    label={t('partner.label.cusOpeningDate')}
                    value={formData.cusOpeningDate}
                    isInvalid={'cusOpeningDate' in errors}
                    helpText={
                      'cusOpeningDate' in errors && errors.cusOpeningDate
                    }
                    onDateChange={(v: any) => onFormChange('cusOpeningDate', v)}
                  />
                </Grid>
              </Grid>
            )}

            {formData.isVendor && isOBEditable && (
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={6}>
                  <InputForm
                    isNumberic={true}
                    maxLength={12}
                    label={t('partner.label.vendorPayable')}
                    defaultValue={String(formData.vendorPayable || '')}
                    isInvalid={'vendorPayable' in errors}
                    onChangeText={(v: string) =>
                      onFormChange('vendorPayable', v)
                    }
                    helpText={'vendorPayable' in errors && errors.vendorPayable}
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    entryMinDate={true}
                    isRequired={formData.vendorPayable ? true : false}
                    label={t('partner.label.venOpeningDate')}
                    value={formData.venOpeningDate}
                    isInvalid={'venOpeningDate' in errors}
                    helpText={
                      'venOpeningDate' in errors && errors.venOpeningDate
                    }
                    onDateChange={(v: any) => onFormChange('venOpeningDate', v)}
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            title="Update Partner"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default UpdatePartnerPage;
