import React from 'react';
import { Typography } from '@mui/material';

import { colors } from '../../assets/styles/colors';

export const FormError = ({ error, align }: any) => {
  return (
    <Typography
      textAlign={align ? align : 'left'}
      sx={{ fontSize: 14, color: colors.red500 }}>
      {error}
    </Typography>
  );
};
