import React from 'react';

import AuthRequired from './AuthRequired';
import JournalEntries from '../pages/journals/JournalEntries';
import PartnerTotalCredits from '../pages/partyReports/PartnerCredits';
import PartnerTransactions from '../pages/partyReports/PartnerTransactions';

const otherRoutes = [
  {
    path: '/journals',
    element: (
      <AuthRequired>
        <JournalEntries />
      </AuthRequired>
    ),
  },
  {
    path: '/receivables',
    element: (
      <AuthRequired>
        <PartnerTotalCredits />
      </AuthRequired>
    ),
  },
  {
    path: 'receivables/partner-receivables',
    element: (
      <AuthRequired>
        <PartnerTransactions />
      </AuthRequired>
    ),
  },
  {
    path: '/payables',
    element: (
      <AuthRequired>
        <PartnerTotalCredits />
      </AuthRequired>
    ),
  },
  {
    path: 'payables/partner-payables',
    element: (
      <AuthRequired>
        <PartnerTransactions />
      </AuthRequired>
    ),
  },
];

export default otherRoutes;
