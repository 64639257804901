import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { itemsAmountSum } from './amountSum';
import { getSettingBasedDate } from './getSettingBasedDate';
import { formatAmount } from './locale';
import { getFinalProductPrice, getTaxAmount } from './vatAndDiscount';

export const createPDF23 = async (template: any, fileName: string) => {
  let iframe = document.createElement('iframe');
  iframe.setAttribute('type', 'hidden');
  iframe.style.visibility = 'hidden';
  iframe.style.width = '792px';
  // iframe.style.height = '100%';

  document.body.appendChild(iframe);
  iframe.srcdoc = template;

  // TO FIX: The issues of text shifting down
  const style = document.createElement('style');
  document.head.appendChild(style);
  style.sheet?.insertRule(
    'body > div:last-child img { display: inline-block; }',
  );

  iframe.addEventListener('load', () => {
    html2canvas(iframe.contentWindow.document.body, {
      scale: 2,
    }).then(function (canvas) {
      // Removes the added HTML elem and styles
      iframe.remove();
      style.remove();
      const image = { type: 'jpeg', quality: 0.98 };

      const margin = [0.5, 0.5];
      var imgWidth = 594;
      var pageHeight = 840;

      var innerPageWidth = imgWidth - margin[0] * 2;
      var innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      var pxFullHeight = canvas.height;
      var pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
      var nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      var pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      var pageCanvas = document.createElement('canvas');
      var pageCtx = pageCanvas.getContext('2d');
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;

      // Initialize the PDF.
      var pdf = new jsPDF('p', 'pt', [594, 840]);

      for (var page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        var w = pageCanvas.width;
        var h = pageCanvas.height;
        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        debugger;
        var imgData = pageCanvas.toDataURL(
          'image/' + image.type,
          image.quality,
        );
        pdf.addImage(
          imgData,
          image.type,
          margin[1],
          margin[0],
          innerPageWidth,
          pageHeight,
        );
      }

      // pdf.addImage(imgData, 0, 0, 594, 0);
      pdf.save(`${fileName}.pdf`);
    });
  });
};

export const createPDF = async (template: any, fileName: string) => {
  let iframe = document.createElement('iframe');
  iframe.setAttribute('type', 'hidden');
  iframe.style.visibility = 'hidden';
  iframe.style.width = '792px';
  // iframe.style.height = '100%';

  document.body.appendChild(iframe);
  iframe.srcdoc = template;

  // TO FIX: The issues of text shifting down
  const style = document.createElement('style');
  document.head.appendChild(style);
  style.sheet?.insertRule(
    'body > div:last-child img { display: inline-block; }',
  );

  iframe.addEventListener('load', () => {
    html2canvas(iframe.contentWindow.document.body, {
      scale: 2,
    }).then(function (canvas) {
      // Removes the added HTML elem and styles
      iframe.remove();
      style.remove();

      const imgData = canvas.toDataURL('image/png', 1);
      const pdf = new jsPDF('p', 'pt', 'a4', true);

      var imgWidth = 594;
      var pageHeight = 840;

      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var position = 0;

      // console.log('imgHeight', imgHeight);
      // console.log('heightLeft', heightLeft);

      pdf.addImage(imgData, 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      // pdf.addImage(imgData, 0, 0, 594, 0);
      pdf.save(`${fileName}.pdf`);
    });
  });
};

export const getPDFData = (formData, data) => {
  const templateData = JSON.parse(JSON.stringify(formData));
  const { customers, vendors, products, expenseItems, accounts } = data;

  const customer = customers && customers.find(x => x.id === formData.customer);
  const vendor = vendors && vendors.find(x => x.id === formData.vendor);
  // const cashInAcc = data.bncAccounts.find(x => x.id === formData.cashInAccount);

  // console.log('address', data.user.business);

  const panEnabled = data.user.business.showPanVatNoOnInvoice;
  templateData.voucherType = data.voucherType;
  templateData.businessName = data.user.business?.name;
  templateData.phoneNumber =
    data.user.business?.phoneNumber || data.user.phoneNumber;
  panEnabled && (templateData.businessPanNumber = data.user.business.panNumber);
  templateData.businessAddress = data.user.business.address;
  templateData.customerName = customer ? customer.name : 'Cash Invoice';
  templateData.vendorName = vendor ? vendor.name : 'Cash Bill';
  // templateData.cashInAccountName = cashInAcc ? cashInAcc.name : '';

  const getVoucherItem = (item: any, index: number) => {
    const { amount, discountPrice, taxable } = item;
    const account = accounts && accounts.find(x => x.id === item.account);
    const product = products && products.find(x => x.id === item.product);
    const expenseItem =
      expenseItems && expenseItems.find(x => x.id === item.expenseItem);

    item.sn = index + 1;
    item.accountName = account ? account.name : '';
    item.particularItemName = product?.name || expenseItem?.name || '';
    const prodAmount = getFinalProductPrice(amount, discountPrice, taxable);
    item.amountFormatted = formatAmount(prodAmount);

    if (taxable) {
      const itemTaxAmount = getTaxAmount(taxable, discountPrice, amount, 13);
      item.itemTaxAmount = formatAmount(itemTaxAmount);
      templateData.hasTaxableItem = true;
    }
    return item;
  };

  const amountSum = itemsAmountSum(formData.voucherItems);
  const itmData = templateData.voucherItems.map((i, x) => getVoucherItem(i, x));

  // for testing - start
  const voucherItemsX = [];

  // for (let i = 0; i < 35; i++) {
  //   const one = { ...templateData.voucherItems[0] };
  //   voucherItemsX.push(getVoucherItem(one, i));
  // }
  // templateData.voucherItems = voucherItemsX;

  // for testing - ends

  templateData.voucherItems = itmData;

  const { discountAmount, paidAmount, isTaxable } = formData;

  const taxAmount = +getTaxAmount(isTaxable, discountAmount, amountSum, 13);
  const totalAmount = amountSum - (+discountAmount || 0) + +(taxAmount || 0);
  const remainingAmount = totalAmount - paidAmount;

  templateData.discountAmount = formatAmount(discountAmount || 0);
  templateData.amountSum = formatAmount(amountSum);
  templateData.paidAmount = paidAmount ? formatAmount(paidAmount) : null;
  templateData.taxAmount = formatAmount(taxAmount);
  templateData.totalAmount = formatAmount(totalAmount);
  templateData.remainingAmount = formatAmount(remainingAmount);
  templateData.isTaxable = formData.isTaxable;
  templateData.isFullPayment = formData.isFullPayment;
  // templateData.createdAt = formData.createdAt;

  const nowTime = new Date();
  const createdDate = getSettingBasedDate(nowTime, data.user);
  const createdTime = nowTime.toLocaleTimeString();

  templateData.createdAt = `${createdDate} ${createdTime}`;
  templateData.transactionDate = getSettingBasedDate(
    formData.voucherDate,
    data.user,
  );

  return templateData;
};
