import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { useSelector } from '../../../store/reduxHooks';
import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
  LAST_30_DAYS,
} from '../../../components/forms/PeriodSelect';
import {
  getLoanPartners,
  getPartners,
  getPartyCategories,
} from '../../../controllers/settings/partnerControllers';
import { partnerLedger } from '../../../controllers/reports/reportControllers';
import { validatePartnerLedgerFilters } from '../../../validations/reports/validatePartnerLedgerFilters';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { formatAmount } from '../../../shared/utils/locale';
import { colors } from '../../../assets/styles/colors';
import SwitchForm from '../../../components/forms/SwitchForm';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';
import { partyAmountColor } from '../../../shared/utils/partnerUtils';
import { getStaticPeriodByDate } from '../../../constants/defaultCodes/accountingPeriods';

export const partnerLedgerTypes = {
  VENDOR_CUSTOMER: 'VENDOR_CUSTOMER',
  LOAN_PARTNER: 'LOAN_PARTNER',
};

const initial = { initialData: [] };

function PartnerLedger() {
  const { user } = useSelector(s => s.auth.authUser);
  // const { enablePartnerCategory } = useSelector(s => s.config.remoteConfigs);

  // const { code: partnerLedgerType } = route.params;
  const partnerLedgerType = partnerLedgerTypes.VENDOR_CUSTOMER;
  // console.log('partnerLedgerType', partnerLedgerType);

  const { t } = useTranslation();

  const toDate = new Date();
  const allInit = { allVendors: false, allCustomers: false };
  const initSt: any = { ...initialDate, ...allInit, toDate };
  const [filters, setFilters] = useState<any>({ ...initSt, partnerLedgerType });
  const [errors, setErrors] = useState<any>({});
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [showFilters, setShowFilter] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  const partners = useQuery(['getPartners'], getPartners, initial);
  const loanPartners = useQuery(['getLoanPartners'], getLoanPartners, initial);
  const ledgers = useQuery(['ledgers', filters], () => partnerLedger(filters), {
    enabled: false,
  });

  const partyCatFn = () => getPartyCategories();
  const partyCategories = useQuery(['partyCategories'], partyCatFn);

  useEffect(() => {
    let title = t('reportStack.partyLedger');
    if (partnerLedgerType === partnerLedgerTypes.LOAN_PARTNER) {
      title = t('reportStack.lnPartyLedger');
    }
    // navigation.setOptions({ title });
  }, [partnerLedgerType]);

  const onSubmit = async () => {
    const { isValid, errs } = validatePartnerLedgerFilters(filters, errors);

    if (isValid) {
      ledgers.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onFilterChange = (name: string, value: any) => {
    let allVendors = filters.allVendors;
    let allCustomers = filters.allCustomers;
    let partner = filters.partner;
    let fromDate = filters.fromDate;
    if (name === 'allVendors' && value) {
      allCustomers = false;
      partner = undefined;
    }
    if (name === 'allCustomers' && value) {
      allVendors = false;
      partner = undefined;
    }

    if (selectedPeriod === 'all' && !partner) {
      setSelectedPeriod(LAST_30_DAYS);
      fromDate = initialDate.fromDate;
    }
    if (name === 'partner' && partner !== value) {
      setSelectedPeriod(LAST_30_DAYS);
      fromDate = initialDate.fromDate;
    }
    const xData = { allVendors, allCustomers, partner, fromDate };
    setFilters(prev => ({ ...prev, ...xData, [name]: value }));

    const updatedData = { ...filters, ...xData, [name]: value };
    const { errs } = validatePartnerLedgerFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const getSelectItems = () => {
    const getNameId = (x: any) => ({
      id: x.id,
      name: `${x.code} ${x.name} ${x.phoneNumber ? `(${x.phoneNumber})` : ''} ${
        x.address ? x.address : ''
      }`,
    });

    if (partnerLedgerType === partnerLedgerTypes.VENDOR_CUSTOMER) {
      const partnerItems = partners.data.map((x: any) => getNameId(x));
      return partnerItems;
    } else if (partnerLedgerType === partnerLedgerTypes.LOAN_PARTNER) {
      const loanPartnerItems = loanPartners.data.map((x: any) => getNameId(x));
      return loanPartnerItems;
    }
  };

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const tbCell = { fontWeight: 'bold', textAlign: 'right' };

  const selPartner = partners.data?.find(x => x.id === filters.partner);
  const periodOnCreated = getStaticPeriodByDate(selPartner?.createdAt);

  if (ledgers.isSuccess && !showFilters) {
    const category = partyCategories.data?.find(x => x.id === filters.category);

    return (
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.partyLedger')}{' '}
                {category ? `- ${category.name}` : ''}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={ledgers.isLoading}
            isError={ledgers.isError}
            isEmpty={!ledgers.data?.partnerLedgers?.length}>
            <TableContainer>
              <Table aria-label="partnerledger">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Partner</TbCellHeader>
                    <TbCellHeader>Account</TbCellHeader>
                    <TbCellHeader align="right">Opening Balance</TbCellHeader>
                    <TbCellHeader align="right">
                      Transaction Amount
                    </TbCellHeader>
                    <TbCellHeader align="right">Closing Balance</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {ledgers.data?.partnerLedgers?.map((row, index) =>
                    row.accountLedgers.map((row2, index2) => (
                      <TableRowStyled key={index2}>
                        <TbCellBody sx={{ maxWidth: 20 }}>
                          {index + 1}
                        </TbCellBody>
                        <TbCellBody>
                          {row.partner.code} {row.partner.name}
                        </TbCellBody>
                        <TbCellBody>{row2.account.name}</TbCellBody>
                        <TbCellAmount sx={{ color: colors.darker3 }}>
                          {formatAmount(row2.openingBalance)}
                        </TbCellAmount>
                        <TbCellAmount sx={{ color: colors.darker3 }}>
                          {formatAmount(row2.normalAmount)}
                        </TbCellAmount>
                        <TbCellAmount>
                          {formatAmount(row2.closingBalance)}
                        </TbCellAmount>
                      </TableRowStyled>
                    )),
                  )}
                </TableBody>
                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TableCell />
                    <TbCellAmount
                      colSpan={2}
                      sx={{ fontStyle: 'italic', color: colors.darker2 }}>
                      Total Balance
                    </TbCellAmount>
                    <TbCellSummary>
                      {formatAmount(ledgers.data?.openingBalanceSum)}
                    </TbCellSummary>
                    <TbCellSummary>
                      {formatAmount(ledgers.data?.normalAmountSum)}
                    </TbCellSummary>
                    <TbCellSummary>
                      {formatAmount(ledgers.data?.closingBalanceSum)}
                    </TbCellSummary>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={partners.isError}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.partyLedger')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid
            py={2}
            pb={3}
            container
            rowSpacing={{ xs: 4 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
                enableAllSelect={filters.partner ? true : false}
                startFromDate={
                  filters.partner ? periodOnCreated?.startDate : new Date()
                }
              />
            </Grid>

            <Grid xs={6}>
              <SelectPicker
                isDisabled={
                  filters.allVendors ||
                  filters.allCustomers ||
                  filters.allLoanPartners
                    ? true
                    : false
                }
                label={t('partnerLedger.label.partner')}
                selectedValue={filters.partner}
                selectItems={getSelectItems()}
                isInvalid={'partner' in errors}
                helpText={'partner' in errors && errors.partner}
                onValueChange={(v: any) => onFilterChange('partner', v)}
              />
            </Grid>
            <Grid xs={6}>
              <SelectPicker
                label={t('partnerLedger.label.category')}
                selectedValue={filters.category}
                selectItems={partyCategories.data || []}
                isInvalid={'category' in errors}
                helpText={'category' in errors && errors.category}
                onValueChange={(v: any) => onFilterChange('category', v)}
              />
            </Grid>
            <Grid xs={12}>
              <SwitchForm
                isChecked={filters.allVendors}
                label={t('partnerLedger.label.allVendors')}
                onToggle={(e, v: boolean) => onFilterChange('allVendors', v)}
              />
            </Grid>
            <Grid xs={12}>
              <SwitchForm
                isChecked={filters.allCustomers}
                label={t('partnerLedger.label.allCustomers')}
                onToggle={(e, v: boolean) => onFilterChange('allCustomers', v)}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Partner Ledger'}
              onClick={onSubmit}
              loading={ledgers.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default PartnerLedger;
