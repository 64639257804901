export const validateGeneralLedgerFilters = (data: any, errors: any) => {
  let isValid = true;

  if (data.fromDate === undefined) {
    errors = { ...errors, fromDate: 'From date is required' };
    isValid = false;
  } else {
    delete errors.fromDate;
  }

  if (data.toDate === undefined) {
    errors = { ...errors, toDate: 'To Date is required' };
    isValid = false;
  } else {
    delete errors.toDate;
  }

  if (!data.allAccounts && data.account === undefined) {
    errors = { ...errors, account: 'At least one account should be selected' };
    isValid = false;
  } else {
    delete errors.account;
  }

  return { isValid, errs: errors };
};
