import React from 'react';

import AuthRequired from './AuthRequired';
import SubscriptionPlans from '../pages/system/subscription/SubscriptionPlans';
import LearnToUsePage from '../pages/system/support/LearnToUse';
import SystemAdmin from '../pages/admin/SystemAdmin';
import SubscriptionOrderCreate from '../pages/admin/SubscriptionOrder';
import BusinessSwitchPage from '../pages/admin/BusinessSwitch';
// import BillingInformation from '../pages/system/billing/BillingInfo';

const systemAdminRoutes = [
  {
    path: '/system-admin',
    element: (
      <AuthRequired>
        <SystemAdmin />
      </AuthRequired>
    ),
  },
  {
    path: '/system-admin/subscription-order',
    element: (
      <AuthRequired>
        <SubscriptionOrderCreate />
      </AuthRequired>
    ),
  },
  {
    path: '/system-admin/business-switch',
    element: (
      <AuthRequired>
        <BusinessSwitchPage />
      </AuthRequired>
    ),
  },
];

export default systemAdminRoutes;
