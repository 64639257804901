import React, { useEffect } from 'react';
import { itemsAmountSum } from '../../../shared/utils/amountSum';
import { getFinalAmount } from '../../../shared/utils/vatAndDiscount';
import TransactionError from '../sales/components/TransactionError';
import { Stack, Typography } from '@mui/material';
import InputForm from '../../../components/forms/InputForm';
// import { Text, View } from 'react-native';

// import InputForm from '../../../../components/forms/InputForm';
// import { itemsAmountSum } from '../../../../shared/utils/amountSum';
// import { getFinalAmount } from '../../../../shared/utils/vatAndDiscount';
// import { FormError } from '../../../../components/utility/FormError';
// import { colors } from '../../../../assets/styles/colors';

const PaymentAmount = ({
  formData,
  onFormChange,
  errors,
  type,
  t,
  originalFullPayment,
}: any) => {
  const { isFullPayment, paidAmount, isTaxable, ...rest } = formData;
  const { discountAmount, voucherItems } = rest;

  const amountSum = itemsAmountSum(voucherItems);
  const finalAmount = getFinalAmount(
    isTaxable,
    discountAmount,
    isFullPayment,
    amountSum,
  );

  // useEffect(() => {
  //   const finalAmount = getFinalAmount(
  //     isTaxable,
  //     discountAmount,
  //     isFullPayment,
  //     amountSum,
  //   );
  //   console.log('paidAmount', finalAmount);

  //   onFormChange('paidAmount', Number(finalAmount));
  // }, [isFullPayment, isTaxable, discountAmount, amountSum]);

  const onValueChange = (value: string) => {
    onFormChange('paidAmount', +value || 0);
    !value && onFormChange('cashOutAccount', null);
    !value && onFormChange('cashInAccount', null);
  };

  const getCashPaymentText = () => {
    if (type === 'SALES') {
      return t('entriesCommonText.cashReceived');
    } else if (type === 'PURCHASE' || type === 'EXPENSE') {
      return t('entriesCommonText.cashPaid');
    } else if (type === 'S_RETURN') {
      return t('entriesCommonText.cashReturned1');
    } else if (type === 'P_RETURN') {
      return t('entriesCommonText.cashReturned2');
    }
  };

  const isAmoutInputEditable = () => {
    if (type === 'S_RETURN' || type === 'P_RETURN') {
      if (isFullPayment || !originalFullPayment) {
        return false;
      }
    } else {
      if (isFullPayment) {
        return false;
      }
    }
    return true;
  };

  const getPartialAmountDescription = () => {
    if (type === 'S_RETURN' || type === 'P_RETURN') {
      // if (originalFullPayment && !isFullPayment) {
      if (type === 'S_RETURN' && !isFullPayment) {
        return '(any amount returned partially)';
      } else if (type === 'P_RETURN' && !isFullPayment) {
        return '(any amount received partially)';
      }
    } else if (type === 'SALES') {
      if (!isFullPayment) {
        return '(any amount received partially)';
      }
    } else if (type === 'PURCHASE' || type === 'EXPENSE') {
      if (!isFullPayment) {
        return '(any amount paid partially)';
      }
    }
  };

  return (
    <Stack>
      <Stack
        direction={'row'}
        gap={4}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <Stack flex={3}>
          <Typography>
            {getCashPaymentText()}
            {getPartialAmountDescription() && (
              <>
                {'\n'}
                <Typography>{getPartialAmountDescription()}</Typography>
              </>
            )}
          </Typography>
        </Stack>

        <Stack flex={1}>
          <InputForm
            isRequired={false}
            placeHolder={'0.00'}
            isNumberic={true}
            silentInvalid={true}
            isInvalid={'paidAmount' in errors}
            // editable={isAmoutInputEditable()}
            isDisabled={isFullPayment ? true : false}
            defaultValue={String(finalAmount || paidAmount || '')}
            onChangeText={(value: string) => onValueChange(value)}
          />
        </Stack>
      </Stack>
      {'paidAmount' in errors && (
        <TransactionError error={errors.paidAmount} align="right" />
      )}
    </Stack>
  );
};

export default PaymentAmount;
