import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';
import { Chip, Stack, Typography } from '@mui/material';

import { useSelector } from '../../../../store/reduxHooks';
import {
  getDiscountAmount,
  getDiscountPercent,
  getFinalProductPrice,
  getTaxAmount,
} from '../../../../shared/utils/vatAndDiscount';
import InputForm from '../../../../components/forms/InputForm';
import { colors } from '../../../../assets/styles/colors';
import SwitchForm from '../../../../components/forms/SwitchForm';

const ItemDiscountAndVat = ({
  formData,
  errors,
  isReturn,
  isSales,
  onFormChange,
}: any): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth.authUser);

  const { amount, discountPrice, discountPercent, taxable = false } = formData;

  const onDiscountPercentChange = (percent: number) => {
    const discount = getDiscountAmount(percent, amount);
    const totalAmount = getFinalProductPrice(amount, discount, taxable);

    const data: any = { discountPrice: discount, totalAmount };
    data.discountPercent = percent;
    onFormChange(data, 'discountPercent');
  };

  const onDiscountAmountChange = (discount: number) => {
    const totalAmount = getFinalProductPrice(amount, discount, taxable);
    const disPercent = getDiscountPercent(discount, amount);

    const data: any = { discountPrice: discount, totalAmount };
    data.discountPercent = disPercent;
    onFormChange(data, 'discountPrice');
  };

  const onTaxChange = (vatable: boolean) => {
    const totalAmt = getFinalProductPrice(amount, discountPrice, vatable);
    onFormChange({ totalAmount: totalAmt, taxable: vatable }, 'taxable');
  };

  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid xs={5} alignSelf={'center'}>
        <Typography mt={1}>{t('newItem.label.discount')}</Typography>
      </Grid>

      <Grid xs={3}>
        <Stack flexDirection={'row'}>
          <InputForm
            size="small"
            maxLength={6}
            isNumberic={true}
            // isDisabled={disPercent ? false : true}
            defaultValue={String(discountPercent || '')}
            onChangeText={(v: string) => onDiscountPercentChange(+v)}
          />
          <Chip
            label="%"
            variant="outlined"
            sx={{
              mt: 0.8,
              borderRadius: 1,
              backgroundColor: colors.gray200,
              height: 40,
              borderLeftColor: colors.white,
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={4}>
        <InputForm
          size="small"
          maxLength={12}
          isNumberic={true}
          silentInvalid={true}
          // editable={discountPercent ? false : true}
          defaultValue={String(discountPrice || '')}
          placeHolder={'Discount'}
          isInvalid={'discountPrice' in errors}
          onChangeText={(v: string) => onDiscountAmountChange(+v)}
        />
      </Grid>

      {(!isSales || (isSales && user.business.onVat)) && (
        <>
          <Grid xs={3} alignSelf={'center'}>
            <Typography mt={1}>{t('newItem.label.vat')}</Typography>
          </Grid>
          <Grid xs={2} alignSelf={'center'}>
            <SwitchForm
              isChecked={taxable}
              onToggle={(e, v: boolean) => onTaxChange(v)}
            />
          </Grid>
          <Grid xs={3}>
            <Stack flexDirection={'row'}>
              <InputForm
                size="small"
                isDisabled={true}
                defaultValue={taxable ? '13' : ''}
              />
              <Chip
                label="%"
                variant="outlined"
                sx={{
                  mt: 0.8,
                  borderRadius: 1,
                  backgroundColor: colors.gray200,
                  height: 40,
                  borderLeftColor: colors.white,
                }}
              />
            </Stack>
          </Grid>
          <Grid xs={4}>
            <InputForm
              size="small"
              defaultValue={getTaxAmount(taxable, discountPrice, amount, 13)}
              placeHolder={'0.00'}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ItemDiscountAndVat;
