import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { dayBook } from '../../../controllers/reports/reportControllers';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import { useTranslation } from 'react-i18next';
import { useSelector } from '../../../store/reduxHooks';
import { validateDayBookFilters } from '../../../validations/reports/validateDayBookFilters';
import PeriodSelect, {
  TODAY,
  initialDate,
} from '../../../components/forms/PeriodSelect';
import Button from '../../../components/buttons/Button';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';

function DayBookReport() {
  const { t } = useTranslation();
  const { user } = useSelector(s => s.auth.authUser);

  const initalState: any = { ...initialDate };
  const [filters, setFilters] = useState<any>(initalState);
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [errors, setErrors] = React.useState({});
  const [showFilters, setShowFilter] = React.useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(TODAY);

  // const businesses = useQuery(['getBusinesses'], getBusinesses, initial);
  const dayBooks = useQuery(['dayBook', filters], () => dayBook(filters), {
    enabled: false,
  });

  const onSubmit = async () => {
    const { isValid, errs } = validateDayBookFilters(filters, errors);

    if (isValid) {
      dayBooks.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onFilterChange = (name: string, value: any) => {
    const updatedData = { ...filters, [name]: value };
    setFilters(updatedData);

    const { errs } = validateDayBookFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };

  // console.log('dayBooks data:', dayBooks.data);

  if (dayBooks.isSuccess && !showFilters) {
    return (
      <Card sx={{ paddingX: 2 }}>
        <CardContent>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.dayBook')}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={dayBooks.isLoading}
            isError={dayBooks.isError}
            isEmpty={!dayBooks.data?.voucherItems.length}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Account</TbCellHeader>
                    <TbCellHeader>Txn Type</TbCellHeader>
                    <TbCellHeader>Txn Date</TbCellHeader>
                    <TbCellHeader>Txn No</TbCellHeader>
                    <TbCellHeader>Ref. No</TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {dayBooks.data?.voucherItems.map((row, index) => (
                    <TableRowStyled key={row.id}>
                      <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                      <TbCellBody>{row.account.name}</TbCellBody>
                      <TbCellBody>{row.voucher.voucherType.name}</TbCellBody>
                      <TbCellBody>
                        {getSettingBasedDate(row.voucher.voucherDate, user)}
                      </TbCellBody>
                      <TbCellBody>{row.voucher.voucherNumber}</TbCellBody>
                      <TbCellBody>{row.voucher.referenceId}</TbCellBody>

                      <TbCellAmount>
                        {formatAmount(row.normalAmount)}
                      </TbCellAmount>
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={false}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.dayBook')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid py={3} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Day Book'}
              onClick={onSubmit}
              loading={dayBooks.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default DayBookReport;
