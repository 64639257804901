import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate } from 'react-router-dom';

import { colors } from '../../../../assets/styles/colors';
import { formatDate } from '../../../../shared/utils/dateTime';
import { useSelector } from '../../../../store/reduxHooks';
import { getSettingBasedDate } from '../../../../shared/utils/getSettingBasedDate';
import { SubscriptionType } from '../../../../constants/defaultCodes/systemTypes';

const ActiveSubscription = ({ hideBilling }) => {
  const navigate = useNavigate();
  const { authUser } = useSelector(state => state.auth);
  const { activeSubscription } = authUser.companyAccount;

  const getValidityDays = () => {
    const expiryAt = new Date(activeSubscription.expiryDate);

    const conversion = 1000 * 60 * 60 * 24;
    const timeDiff = expiryAt.getTime() - Date.now();

    const remainingDays = Math.ceil(timeDiff / conversion);

    return remainingDays;
  };

  // console.log('activeSubscription', activeSubscription.totalBusiness);

  const getSubscriptionName = () => {
    const name1 = activeSubscription.product.type.split('_').join(' ');
    const name2 = activeSubscription.product.name.split(' ')[1];
    return `${name1} ${name2}`;
  };

  const getProductMonths = product => {
    if (product.type === SubscriptionType.TRIAL) {
      return 0.5;
    }
    return product.months;
  };

  return (
    <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 2 }}>
      <Grid xs={12} sm={12} md={6} lg={4}>
        <Stack
          minHeight={140}
          sx={{
            p: 2,
            borderWidth: 1,
            boxShadow: 1,
            borderRadius: 1,
            position: 'relative',
          }}>
          <Typography
            sx={{ fontSize: 17, fontWeight: 'bold', color: colors.theme1 }}>
            CURRENT SUBSCRIPTION
          </Typography>

          <Typography
            sx={{ fontWeight: 'bold', color: colors.darker2, fontSize: 15 }}>
            {activeSubscription.product.name.toUpperCase()}
            {' - '}
            <span style={{ fontSize: 16 }}>
              {getProductMonths(activeSubscription.product)}M
            </span>

            {/* {getSubscriptionName().toUpperCase()} */}
          </Typography>

          <Stack flexDirection={'row'}>
            <Typography width={80} sx={{ fontSize: 15 }}>
              AMOUNT
            </Typography>
            <Typography>{activeSubscription.totalPaidPrice} NPR</Typography>
          </Stack>
          <Stack flexDirection={'row'}>
            <Typography width={80} sx={{ fontSize: 15 }}>
              ORDER#
            </Typography>
            <Typography>{activeSubscription.orderNumber}</Typography>
          </Stack>

          <Button
            size="small"
            color="primary"
            variant="outlined"
            sx={{
              fontWeight: 'bold',
              position: 'absolute',
              right: 8,
              top: '38%',
              bottom: '38%',
              backgroundColor: colors.info100,
              borderColor: colors.info100,
            }}
            onClick={hideBilling}>
            upgrade
          </Button>
        </Stack>
      </Grid>

      <Grid xs={12} sm={12} md={6} lg={4}>
        <Stack
          minHeight={140}
          sx={{ p: 2, borderWidth: 1, boxShadow: 1, borderRadius: 1 }}>
          <Typography
            sx={{ fontSize: 17, fontWeight: 'bold', color: colors.theme1 }}>
            EXPIRY DATE & LIMITS
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>
            {getSettingBasedDate(activeSubscription.expiryDate, authUser.user)}
          </Typography>
          <Stack flexDirection={'row'}>
            <Typography width={120}>User Limit </Typography>
            <Typography>{activeSubscription.totalUsers}</Typography>
          </Stack>
          <Stack flexDirection={'row'}>
            <Typography width={120}>Business Limit </Typography>
            <Typography>{activeSubscription.totalBusiness}</Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid xs={12} sm={12} md={6} lg={4}>
        <Stack
          minHeight={140}
          sx={{ p: 2, borderWidth: 1, boxShadow: 1, borderRadius: 1 }}>
          <Typography
            sx={{ fontSize: 17, fontWeight: 'bold', color: colors.theme1 }}>
            EXPIRES IN
          </Typography>
          <Stack gap={1} direction={'row'} alignItems={'flex-end'}>
            <Typography
              lineHeight={1}
              sx={{
                fontSize: 48,
                fontWeight: 'bold',
                color: getValidityDays() > 15 ? colors.green700 : colors.theme2,
              }}>
              {getValidityDays()}
            </Typography>
            <Typography sx={{ fontSize: 20, color: colors.darker2, pb: 0.2 }}>
              days
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ActiveSubscription;
