import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { colors } from '../../assets/styles/colors';
import SettingCard from './components/SettingCard';
import { settings } from '../../constants/cards/settingCards';

export default function Settings() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
        {settings().map((txnCard, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
            <SettingCard title={txnCard.name} items={txnCard.items} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
