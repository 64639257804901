import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import { colors } from '../../../../assets/styles/colors';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const SalesContactModal = ({
  modalVisible,
  onModalClose,
  paymentContact,
}: any) => {
  const { t } = useTranslation();

  if (!modalVisible) return null;
  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={1}>
          <Typography
            sx={{ fontSize: 18, color: colors.theme1, fontWeight: 'bold' }}>
            Sales & Customer Support Options
          </Typography>
          <Typography
            sx={{ fontSize: 20, color: colors.darker3, lineHeight: 1 }}>
            {paymentContact}
          </Typography>
        </Stack>
        <Divider />

        <Stack gap={1} py={2}>
          <Stack direction={'row'} gap={1.5} alignItems={'center'}>
            <PhoneIcon sx={{ color: colors.theme1, fontSize: 20 }} />
            <Typography fontSize={18}>Phone Call/SMS</Typography>
          </Stack>
          <Stack direction={'row'} gap={1.5} alignItems={'center'}>
            <QuestionAnswerIcon sx={{ color: colors.theme1, fontSize: 20 }} />
            <Typography fontSize={18}>Viber Call/Message</Typography>
          </Stack>
          <Stack direction={'row'} gap={1.5} alignItems={'center'}>
            <WhatsAppIcon sx={{ color: colors.theme1, fontSize: 20 }} />
            <Typography fontSize={18}>WhatsApp Call/Message</Typography>
          </Stack>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onModalClose}>
            Okay
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SalesContactModal;
