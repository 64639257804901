import React from 'react';
import { Route } from 'react-router-dom';

import AuthRequired from './AuthRequired';
import Settings from '../pages/settings/Settings';
import AccountListPage from '../pages/settings/account/AccountList';
import CreateAccount from '../pages/settings/account/CreateAccount';
import PartnerListPage from '../pages/settings/partner/PartnerList';
import PartnerCategoriesListPage from '../pages/settings/partner/PartnerCategories';
import CreatePartnerCategory from '../pages/settings/partner/CreatePartnerCategory';
import ProductItemListPage from '../pages/settings/product/ProductItemList';
import CreateProductUnitPage from '../pages/settings/product/CreateProductUnit';
import ProductUnitListPage from '../pages/settings/product/ProductUnitList';
import ProductCategoryListPage from '../pages/settings/product/ProductCategoryList';
import BusinessListPage from '../pages/settings/business/BusinessList';
import EmployeeListPage from '../pages/settings/employee/EmployeeList';
import CreateBusinessPage from '../pages/settings/business/CreateBusiness';
import CreateEmployeePage from '../pages/settings/employee/CreateEmployee';
import UserSettingsPage from '../pages/settings/userProfile/UserSettings';
import CreateExpenseItem from '../pages/settings/expenseItem/CreateExpenseItem';
import ExpenseItemListPage from '../pages/settings/expenseItem/ExpenseItemList';
import CreateProductCategoryPage from '../pages/settings/product/CreateProductCategory';
import CreateProductItemPage from '../pages/settings/product/CreateProductItem';
import CreatePartnerPage from '../pages/settings/partner/CreatePartner';
import UpdateAccount from '../pages/settings/account/UpdateAccount';
import UpdatePartnerPage from '../pages/settings/partner/UpdatePartner';
import UpdateBusinessPage from '../pages/settings/business/UpdateBusiness';
import UpdateEmployeePage from '../pages/settings/employee/UpdateEmployee';

const settingRoutes = [
  {
    path: '/settings',
    element: (
      <AuthRequired>
        <Settings />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/accounts',
    element: (
      <AuthRequired>
        <AccountListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/accounts/create',
    element: (
      <AuthRequired>
        <CreateAccount />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/accounts/update',
    element: (
      <AuthRequired>
        <UpdateAccount />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/partners',
    element: (
      <AuthRequired>
        <PartnerListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/partners/create',
    element: (
      <AuthRequired>
        <CreatePartnerPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/partners/update',
    element: (
      <AuthRequired>
        <UpdatePartnerPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/partner-categories',
    element: (
      <AuthRequired>
        <PartnerCategoriesListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/partner-categories/create',
    element: (
      <AuthRequired>
        <CreatePartnerCategory />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/products',
    element: (
      <AuthRequired>
        <ProductItemListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/products/create',
    element: (
      <AuthRequired>
        <CreateProductItemPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/products/update',
    element: (
      <AuthRequired>
        <CreateProductItemPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/product-units',
    element: (
      <AuthRequired>
        <ProductUnitListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/product-units/create',
    element: (
      <AuthRequired>
        <CreateProductUnitPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/product-categories',
    element: (
      <AuthRequired>
        <ProductCategoryListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/product-categories/create',
    element: (
      <AuthRequired>
        <CreateProductCategoryPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/expense-items',
    element: (
      <AuthRequired>
        <ExpenseItemListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/expense-items/create',
    element: (
      <AuthRequired>
        <CreateExpenseItem />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/expense-items/update',
    element: (
      <AuthRequired>
        <CreateExpenseItem />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/businesses',
    element: (
      <AuthRequired>
        <BusinessListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/businesses/create',
    element: (
      <AuthRequired>
        <CreateBusinessPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/businesses/update',
    element: (
      <AuthRequired>
        <UpdateBusinessPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/employees',
    element: (
      <AuthRequired>
        <EmployeeListPage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/employees/create',
    element: (
      <AuthRequired>
        <CreateEmployeePage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/employees/update',
    element: (
      <AuthRequired>
        <UpdateEmployeePage />
      </AuthRequired>
    ),
  },
  {
    path: '/settings/user-settings',
    element: (
      <AuthRequired>
        <UserSettingsPage />
      </AuthRequired>
    ),
  },
];

export default settingRoutes;
