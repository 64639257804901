import { store } from '../../store/store';
import {
  borrowLoanUrl,
  getLentLoansUrl,
  getBorrowedLoansUrl,
  borrowedLoanPaymentUrl,
  lendLoanUrl,
  receiveLentLoanUrl,
  addBankLoanUrl,
  getBankLoanUrl,
  emiPaymentUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const borrowLoan = async (data: any) => {
  const path = borrowLoanUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getBorrowedLoans = async () => {
  const path = getBorrowedLoansUrl();
  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getDashboardBorrowedLoans = async (filters: any) => {
  const path = v1('/transactions/loans/dashboard-borrowed-loans');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const borrowedLoanPayment = async (data: any) => {
  const path = borrowedLoanPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const lendLoan = async (data: any) => {
  const path = lendLoanUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getLentLoans = async (filters: any) => {
  const path = getLentLoansUrl();
  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const getDashboardLentLoans = async (filters: any) => {
  const path = v1('/transactions/loans/dashboard-lent-loans');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const receiveLentLoan = async (data: any) => {
  const path = receiveLentLoanUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const addBankLoan = async (data: any) => {
  const path = addBankLoanUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getBankLoans = async (partnerId: number) => {
  const path = getBankLoanUrl(partnerId);
  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getDashboardBankLoans = async (filters: any) => {
  const path = v1('/transactions/loans/dashboard-bank-loans');

  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const emiPayment = async (data: any) => {
  const path = emiPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};
