import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import EmptyReport from '../../../components/feedbacks/EmptyReport';

import DateRangeCard from '../../../components/datePickers/DateRangeCard';
// import { formatAmount } from '../../../shared/utils/locale';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import { useSelector } from '../../../store/reduxHooks';
import { colors } from '../../../assets/styles/colors';
import OpenignBalanceAsOf from '../components/OpeningBalnaceAsOf';
import {
  StockType,
  stockDecrementTypes,
  stockIncrementTypes,
} from '../../../constants/defaultCodes/stockType';
import { formatAmount } from '../../../shared/utils/locale';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';
import EmptyTable from '../../../components/feedbacks/EmptyTable';

const StockMovementTable = ({
  stockMovement,
  filters,
  setShowFilter,
  product,
  onCloseDetail,
  fromDetailScreen = false,
}: any): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useSelector(s => s.auth.authUser);
  const navigate = useNavigate();

  const chipColor = (type: string) => {
    if (type === StockType.openingBalance) {
      return colors.lightBlue900;
    } else if (type === StockType.sales) {
      return colors.green700;
    } else if (type === StockType.purchase) {
      return colors.lightBlue900;
    } else if (stockDecrementTypes.find(x => x.id === type)) {
      return colors.red500;
    } else if (stockIncrementTypes.find(x => x.id === type)) {
      return colors.green700;
    } else if (type === StockType.salesReturn) {
      return colors.lightBlue900;
    } else if (type === StockType.purchaseReturn) {
      return colors.lightBlue900;
    }
  };

  const openingBalance = stockMovement.data?.openingBalance;
  const closingBalance = stockMovement.data?.closingBalance;
  const prodItem = stockMovement.data?.productItem;
  const openingFromDate = stockMovement.data?.fromDate;

  const tbCell = { fontWeight: 'bold', textAlign: 'right' };

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1.4 }}>
        <Stack pb={1.6} flexDirection={'row'} alignItems={'center'}>
          <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
            <IconButton
              onClick={() => {
                if (fromDetailScreen) {
                  onCloseDetail();
                } else {
                  setShowFilter(true);
                }
              }}
              sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
              <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
            </IconButton>
          </Stack>
          <Stack flex={1} alignItems={'center'}>
            <Typography sx={{ fontSize: 20 }}>
              {t('reportStack.stockMovement')} - {product.name}
            </Typography>
            {fromDetailScreen && openingFromDate && (
              <Typography
                sx={{ fontWeight: 'bold', fontSize: 16, lineHeight: 1 }}>
                {getSettingBasedDate(openingFromDate, user)} - PRESENT
              </Typography>
            )}
            {!fromDetailScreen && <DateRangeCard {...{ filters }} />}
          </Stack>

          <Stack direction="row" justifyContent={'flex-end'} flex={1}>
            <Button
              variant="contained"
              onClick={() => navigate('/transaction/stock-adjusment')}>
              Adjustment
            </Button>
          </Stack>
        </Stack>
        <Divider />

        <ScreenWrapper
          isLoading={stockMovement.isLoading || stockMovement.isFetching}
          isError={stockMovement.isError}
          isEmpty={false}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader style={{ maxWidth: 24 }}>SN</TbCellHeader>
                  <TbCellHeader>Movement Type</TbCellHeader>
                  <TbCellHeader>Date</TbCellHeader>
                  <TbCellHeader align="right">Quantity X PerUnit</TbCellHeader>
                  <TbCellHeader align="right">Discount</TbCellHeader>
                  <TbCellHeader align="right">Total Amount</TbCellHeader>
                  <TbCellHeader align="right">In/Out Quantity</TbCellHeader>
                </TbRowHeaderStyled>
              </TableHead>
              {openingFromDate && (
                <TableRow sx={{ backgroundColor: colors.gray100 }}>
                  <TbCellBody />
                  <TbCellBody colSpan={6} align="right">
                    <OpenignBalanceAsOf
                      fromDate={openingFromDate}
                      openingBalance={openingBalance}
                    />
                  </TbCellBody>
                </TableRow>
              )}
              {stockMovement.data?.productStockItems.length > 0 ? (
                <TableBody>
                  {stockMovement.data?.productStockItems.map((row, index) => (
                    <TableRowStyled key={row.id}>
                      <TbCellBody sx={{ maxWidth: 24 }}>{index + 1}</TbCellBody>
                      <TbCellBody>
                        <Chip
                          size="small"
                          label={row.stockType}
                          variant="outlined"
                          sx={{
                            color: chipColor(row.stockType),
                            borderColor: chipColor(row.stockType),
                          }}
                        />
                      </TbCellBody>
                      <TbCellBody>
                        {getSettingBasedDate(row.inventoryDate, user)}
                      </TbCellBody>
                      <TbCellBody align="right">
                        {row.quantity} {row.productUnit?.name || ''} X{' '}
                        {row.pricePerUnit
                          ? formatAmount(row.pricePerUnit)
                          : '--'}
                      </TbCellBody>
                      <TbCellBody align="right">
                        {row.discountPrice || 0}
                      </TbCellBody>
                      <TbCellAmount sx={{ color: colors.darker3 }}>
                        {formatAmount(row.totalPrice)}
                      </TbCellAmount>
                      <TbCellAmount>{row.normalAmount}</TbCellAmount>
                    </TableRowStyled>
                  ))}
                </TableBody>
              ) : (
                <EmptyTable message="No Stock Movements in Given Period !!" />
              )}

              <TableHead sx={{ backgroundColor: colors.gray200 }}>
                <TableRow>
                  <TableCell />
                  <TbCellAmount
                    colSpan={5}
                    sx={{ color: colors.darker2, fontStyle: 'italic' }}>
                    Closing Balance
                  </TbCellAmount>
                  <TbCellSummary sx={tbCell}>
                    {closingBalance} {prodItem?.primaryUnit}
                  </TbCellSummary>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
};

export default StockMovementTable;
