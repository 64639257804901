import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import { bankAndCashAccounts } from '../../../controllers/settings/accountControllers';
import Button from '../../../components/buttons/Button';

import {
  validateFTVoucherNo,
  validateFundTransfer,
} from '../../../validations/transactions/validateTransfer';
import {
  bankAndCashBalanceCheck,
  getCashBalance,
} from '../../../controllers/reports/reportControllers';
import { fundTransfer } from '../../../controllers/transactions/transferControllers';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import ImagePicker from '../../../components/image/ImagePicker';

const voucherType = voucherTypes.FUND_TRANSFER;

function FundTransfer() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [formData, setData] = useState<any>({ voucherDate: new Date() });
  const [images, setImages] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [cashOutAccBalance, setCashOutAccBalance] = useState<any>();
  const [cashInAccBalance, setCashInAccBalance] = useState<any>();

  const { data: voucherNumber } = useQuery(['getVoucherNo', voucherType], () =>
    getVoucherNumber(voucherType),
  );
  const accounts = useQuery(['bankAndCashAccounts'], bankAndCashAccounts);

  const cashBalanceQuery = useMutation(getCashBalance);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  const { isLoading, mutateAsync } = useMutation(fundTransfer);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async () => {
    const { amount, cashOutAccount } = formData;

    const validity = await validateFTVoucherNo(formData, errors);
    const { isVoucherNoValid: isInitialValid } = validity;

    let hasBal = true;
    if (isInitialValid) {
      hasBal = await bankAndCashBalanceCheck(cashOutAccount, amount);
    }

    const { isValid, errs } = validateFundTransfer(formData, errors, hasBal);
    const { isVoucherNoValid, errss } = await validateFTVoucherNo(
      formData,
      errs,
    );

    if (isValid && isVoucherNoValid && hasBal) {
      try {
        if (images && images.length) {
          const imgPaylaod = { images, voucherType: 'transfers' };
          const image = await saveImage.mutateAsync(imgPaylaod);
          formData.image = image.id;
        }
        await mutateAsync(formData);

        queryClient.invalidateQueries(['getDashboardReport']);
        navigate('/journals', { replace: true });
        enqueueSnackbar(t('fundTransfer.createMsg.success'), successToast);
      } catch (err) {
        let title = t('fundTransfer.createMsg.error');
        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }
        enqueueSnackbar(title, errorToast);
      }
    } else {
      setErrors(errss);
      if (!isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    const updatedData = { ...formData, [name]: value };
    setData(updatedData);

    const { errs } = validateFundTransfer(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});

    if (
      name === 'cashOutAccount' &&
      value &&
      formData.cashOutAccount !== value
    ) {
      const response = await cashBalanceQuery.mutateAsync(value);
      setCashOutAccBalance({ type: 'CASH_IN', balance: response });
    } else if (name === 'cashOutAccount' && !value) {
      setCashOutAccBalance(null);
    }

    if (name === 'cashInAccount' && value && formData.cashInAccount !== value) {
      const response = await cashBalanceQuery.mutateAsync(value);
      setCashInAccBalance({ type: 'CASH_IN', balance: response });
    } else if (name === 'cashInAccount' && !value) {
      setCashInAccBalance(null);
    }
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const remainingAccounts = (fieldName: string) => {
    if (fieldName === 'cashOutAccount') {
      const { cashInAccount } = formData;
      return accounts.data?.filter((x: any) => x.id !== +cashInAccount);
    } else if (fieldName === 'cashInAccount') {
      const { cashOutAccount } = formData;
      return accounts.data?.filter((x: any) => x.id !== +cashOutAccount);
    }
  };

  // console.log('formData pay in:', formData);
  // console.log('errors:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('transactionStack.cashTransfer')}
          </Typography>
          <Divider />
          <Stack py={2} spacing={4}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('fundTransfer.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('fundTransfer.label.voucherNumber')}
                  isDisabled={true}
                  isRequired={true}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  defaultValue={formData.voucherNumber}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('paymentOut.label.referenceId')}
                  defaultValue={formData.referenceId}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`fundTransfer.label.cashOutAccount`)}
                  selectedValue={formData.cashOutAccount}
                  selectItems={remainingAccounts('cashOutAccount')}
                  isInvalid={'cashOutAccount' in errors}
                  helpText={getHelpText('cashOutAccount')}
                  onValueChange={(v: any) => onFormChange('cashOutAccount', v)}
                  partnerBalance={cashOutAccBalance && cashOutAccBalance}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`fundTransfer.label.cashInAccount`)}
                  selectedValue={formData.cashInAccount}
                  selectItems={remainingAccounts('cashInAccount')}
                  isInvalid={'cashInAccount' in errors}
                  helpText={getHelpText('cashInAccount')}
                  onValueChange={(v: any) => onFormChange('cashInAccount', v)}
                  partnerBalance={cashInAccBalance && cashInAccBalance}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <InputForm
                  isRequired={true}
                  isNumberic={true}
                  defaultValue={formData.amount}
                  isInvalid={'amount' in errors}
                  helpText={getHelpText('amount')}
                  label={t('fundTransfer.label.amount')}
                  onChangeText={(v: string) => onFormChange('amount', v)}
                />
              </Grid>
              {/* <Grid xs={6}>
                  <InputForm
                    isNumberic={true}
                    defaultValue={formData.discount}
                    label={t('paymentOut.label.discount')}
                    isInvalid={'discount' in errors}
                    helpText={getHelpText('discount')}
                    onChangeText={(v: string) => onFormChange('discount', v)}
                  />
                </Grid> */}
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
                  <Stack flex={1}>
                    <InputForm
                      inLineLabel={true}
                      defaultValue={formData.description}
                      label={t('fundTransfer.label.description')}
                      isInvalid={'description' in errors}
                      helpText={'description' in errors && errors.description}
                      onChangeText={(v: string) =>
                        onFormChange('description', v)
                      }
                    />
                  </Stack>
                  <Stack pt={0.1}>
                    <ImagePicker {...{ images, setImages }} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Fund Transfer"
            onClick={onSubmit}
            loading={isLoading || saveImage.isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default FundTransfer;
