import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import {
  Box,
  CardContent,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { _7DaysAgo } from '../../components/forms/PeriodSelect';
import { useSelector } from '../../store/reduxHooks';
import {
  getDashboardReport,
  partnerDashboardLedger,
} from '../../controllers/reports/reportControllers';
import InputForm from '../../components/forms/InputForm';
import { colors } from '../../assets/styles/colors';
import { formatAmount } from '../../shared/utils/locale';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../components/styled/TableCellStyled';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../components/styled/TableRowStyled';
import {
  dateFileName,
  getSettingBasedDate,
} from '../../shared/utils/getSettingBasedDate';
import { DashboardCardType } from '../../constants/defaultCodes/transactionCodes';
import { partnerLedgerDashTemplate } from '../../templates/partnerLedgerDashTemplate';
import { createPDF } from '../../shared/utils/createPDF';
import { generateDashboardData } from '../dashboard/utils/dashboardData';
import {
  partnerCreditTitle,
  partyAmountColor,
} from '../../shared/utils/partnerUtils';
import Button, { TextButton } from '../../components/buttons/Button';

const PartnerTotalCredits = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const paths = location.pathname.split('/').filter(x => x);

  const queryClient = useQueryClient();
  const { user } = useSelector(s => s.auth.authUser);

  const code = paths[0] === 'payables' ? 'payable' : 'receivable';

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [refresh, setRefresh] = useState(false);
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });
  const [dashboardReports, setDashboardReports] = useState<any>([]);

  const ledgerFn = () => partnerDashboardLedger({ code });
  const dashLedgers = useQuery(['partyDashledgers'], ledgerFn);
  const { isLoading, isError, data } = dashLedgers;

  const enableDash = { enabled: false };
  const dash = useQuery(['getDashboardReport'], getDashboardReport);
  const { data: dashReport, isFetched } = dash;

  // const dashboarData = queryClient.getQueryData(['getDashboardReport']);

  useEffect(() => {
    dashLedgers.refetch();
  }, [code]);

  // useEffect(() => {
  //   if (dashboarData) {
  //     setDashboardReports(generateDashboardData(dashboarData));
  //   } else {
  //     dash.refetch();
  //   }
  // }, []);

  useEffect(() => {
    if (isFetched) {
      setDashboardReports(generateDashboardData(dashReport));
    }
  }, [dashReport]);

  let accountItems = [];
  let totalBalance = 0;

  // const dashboardReports = generateDashboardData(dashboarData || dash.data);

  for (const report of dashboardReports) {
    for (const row of report.rows) {
      if (row.code === code) {
        accountItems = row.balance.accountItems;
        totalBalance = row.balance.totalBalance;
      }
    }
  }

  // console.log('data:', data);
  // console.log('dash:', dash.data);

  const onNaviation = (item: any) => {
    const isNCLiability = item.accountLedgers?.find(y => y.account?.isNCL)
      ?.account?.isNCL;

    const state = { code, partner: item.partner, isNCLiability };
    if (code === 'receivable') {
      navigate('partner-receivables', { state });
    } else {
      navigate('partner-payables', { state });
    }
  };

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = data?.filter((item: any) => {
      const { name, phoneNumber, address, closingBalance } = item.partner;

      const hasName = name?.toLowerCase().includes(text);
      const hasPhone = phoneNumber?.toLowerCase().includes(text);
      const hasAddress = address?.toLowerCase().includes(text);
      const hasBalance = closingBalance?.toString().includes(text);

      if (hasName || hasPhone || hasAddress || hasBalance) {
        return true;
      }
    });
    // console.log('filtered', filtered.length);

    setFilteredItems(filtered);
  };

  let partnerLedgers = data || [];
  if (searchText) {
    partnerLedgers = filteredItems;
  }

  const onCreatePDF = async () => {
    const now = new Date();
    const createdDate = getSettingBasedDate(now, user);
    const createdTime = now.toLocaleTimeString();

    const dataX = {
      user,
      totalBalance,
      partnerLedgers,
      allVendors: code === DashboardCardType.payable ? true : false,
      allCustomers: code === DashboardCardType.receivable ? true : false,
      createdAt: `${createdDate} ${createdTime}`,
    };

    const template = partnerLedgerDashTemplate(dataX);

    const fileName = `party_${code}_${dateFileName(user)}`.toLowerCase();
    const source = await createPDF(template, fileName);
    setPdfSource(source);
  };

  // console.log('filters:', filters);
  // console.log('dashLedgers:', dashLedgers.data);

  return (
    <ScreenWrapper isLoading={isLoading || dash.isLoading} isError={isError}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} flexDirection={'row'} alignItems={'center'} gap={4}>
            <Typography sx={{ fontSize: 20 }} flex={1}>
              {partnerCreditTitle(code)} ({partnerLedgers.length})
            </Typography>
            <Stack flex={1} />

            <Stack flex={1} pb={0.8}>
              <InputForm
                size="small"
                inLineLabel={true}
                label={'Search by name, phone, address or amount...'}
                onChangeText={(v: string) => onSearch(String(v))}
              />
            </Stack>
            {data?.length > 0 && (
              <IconButton
                sx={{ backgroundColor: colors.gray300 }}
                onClick={onCreatePDF}>
                <PictureAsPdfIcon sx={{ color: colors.theme1, fontSize: 28 }} />
              </IconButton>
            )}

            <Box pb={0.4}>
              <Button
                size="md"
                title="Add Partner"
                onClick={() => navigate('/settings/partners/create')}
              />
            </Box>
          </Stack>

          <Divider />

          <ScreenWrapper isEmpty={!data?.length}>
            <TableContainer>
              <Table aria-label="table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 24 }}>SN</TbCellHeader>
                    <TbCellHeader>Partner</TbCellHeader>
                    <TbCellHeader>Phone No</TbCellHeader>
                    <TbCellHeader>Address</TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                    <TbCellHeader />
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {partnerLedgers.map((item, index) => (
                    <TableRowStyled key={index}>
                      <TbCellBody sx={{ maxWidth: 24 }}>{index + 1}</TbCellBody>
                      <TbCellBody>{item.partner.name}</TbCellBody>
                      <TbCellBody>{item.partner.phoneNumber}</TbCellBody>
                      <TbCellBody>{item.partner.address}</TbCellBody>
                      <TbCellAmount
                        sx={{
                          color: partyAmountColor(
                            code,
                            item.partner.closingBalance,
                          ),
                        }}>
                        {formatAmount(item.partner.closingBalance)}
                      </TbCellAmount>

                      <TbCellBody align="right">
                        <TextButton
                          size="sm"
                          title="Details"
                          onClick={() => onNaviation(item)}
                        />
                      </TbCellBody>
                    </TableRowStyled>
                  ))}
                </TableBody>
                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TbCellBody />
                    <TbCellBody colSpan={3} sx={{ fontWeight: 'bold' }}>
                      Closing Balance
                    </TbCellBody>
                    <TbCellAmount
                      sx={{
                        fontSize: 17,
                        color: partyAmountColor(code, totalBalance),
                      }}>
                      {formatAmount(totalBalance)}
                    </TbCellAmount>
                    <TbCellBody />
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
};

export default PartnerTotalCredits;
