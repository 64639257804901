import React from 'react';

import { Autocomplete, TextField, Box, Stack, Typography } from '@mui/material';

import { colors } from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

export default function MultiSelectPicker({
  label,
  placeHolder,
  selectItems = [],
  selectedValue,
  onValueChange,
  helpText,
  isInvalid,
  setHandles,
  addItemText,
  defaultValue,
  isRequired = false,
  isDisabled = false,
  inLineLabel = false,
  size = 'medium',
  searchPlaceholder = 'Search...',
}: any) {
  const { t } = useTranslation();

  const findSelected = () => {
    if (selectedValue.length) {
      const selItems = [];
      for (const v of selectedValue) {
        const items = selectItems.find(x => x.id === v);
        selItems.push(items);
      }
      return selItems;
    }
  };

  const getStyles = () => {
    let inputStyles: any = { color: 'red' };
    if (isDisabled) {
      inputStyles = { ...inputStyles, backgroundColor: colors.gray100 };
    }
    return inputStyles;
  };

  // console.log('select picker:', findSelected());
  // console.log('select picker:', selectedValue);

  const onMultiSelect = (newValue: any = []) => {
    // console.log('v:', v);
    // const selIds = [];
    // for (const vItem of v) {
    //   selIds.push(vItem.id);
    // }
    // onValueChange(selIds);

    const selectedIds = newValue.map(x => x.id);
    onValueChange(selectedIds);
  };

  return (
    <Stack>
      {label && (
        <Stack py={0.4} direction={'row'} justifyContent={'space-between'}>
          {label && !inLineLabel && (
            <Box py={0.4}>
              <label>{label}</label>
            </Box>
          )}
        </Stack>
      )}
      <Autocomplete
        multiple
        size={size}
        id="multi-select"
        style={getStyles()}
        disabled={isDisabled}
        options={selectItems}
        getOptionLabel={(option: any) => option?.name}
        value={findSelected() || []}
        // defaultValue={findSelected()}
        onChange={(e, newVal) => onMultiSelect(newVal)}
        isOptionEqualToValue={(option, v) => option?.id === v?.id}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: colors.darker3,
                // backgroundColor: colors.gray100,
              },
              '.MuiOutlinedInput-notchedOutline': isRequired
                ? {
                    'border-left-color': colors.red500,
                    'border-left-width': '2px',
                  }
                : {},
            }}
            error={isInvalid ? true : false}
            label={inLineLabel ? label : undefined}
            placeholder={placeHolder ? placeHolder : label}
          />
        )}
      />
      {isInvalid && (
        <Typography pt={0.4} fontSize={14} color={colors.red500}>
          {`${t('commonText.error')}: ${helpText}`}
        </Typography>
      )}

      {helpText && !isInvalid && (
        <Typography pt={0.4} fontSize={14}>
          {helpText}
        </Typography>
      )}
    </Stack>
  );
}
