import React from 'react';

function LearnToUsePage() {
  return (
    <div>
      <h1>This is the Learn To Use Page</h1>
    </div>
  );
}

export default LearnToUsePage;
