import i18next from 'i18next';

export const shortCutCodes = {
  setting: 'setting',
  sales: 'sales',
  purchase: 'purchase',
  transfer: 'transfer',
  loan: 'loan',
  other: 'other',
  advance: 'advance',
};

// TO DO: ADD FEATURE SWITCH FOR NEW PAYMENT IN/OUT
export const shortcutNavItems = () => {
  const rootPath = 'accounting';

  const navListItems = [
    // {
    //   code: shortCutCodes.transfer,
    //   sectionTitle: i18next.t('addShortcut.setting.title'),
    //   sectionItems: [
    //     {
    //       name: i18next.t('addShortcut.setting.party'),
    //       nav: '/settings/partners/create',
    //       icon: 'users',
    //     },
    //     // {
    //     //   name: i18next.t('addShortcut.setting.loanParty'),
    //     //   nav: 'settings/partners/create',
    //     //   icon: 'user-tie',
    //     // },
    //     {
    //       name: i18next.t('addShortcut.setting.product'),
    //       nav: '/settings/products/create',
    //       icon: 'store',
    //     },
    //   ],
    // },
    {
      code: shortCutCodes.sales,
      sectionTitle: i18next.t('addShortcut.salesIncome.title'),
      sectionItems: [
        {
          name: i18next.t('addShortcut.salesIncome.salesIncome'),
          nav: `sales`,
          icon: 'hand-holding-usd',
        },

        {
          name: i18next.t('addShortcut.salesIncome.PaymentIn'),
          nav: `payment-in`,
          icon: 'arrow-circle-down',
        },
        {
          name: i18next.t('addShortcut.salesIncome.return'),
          nav: `sales-return`,
          icon: 'reply',
        },
        {
          name: i18next.t('addShortcut.salesIncome.otherIncome'),
          nav: `incomes`,
          icon: 'money-bill-alt',
        },
      ],
    },
    {
      code: shortCutCodes.purchase,
      sectionTitle: i18next.t('addShortcut.purchaseExp.title'),
      sectionItems: [
        {
          name: i18next.t('addShortcut.purchaseExp.purchase'),
          nav: `purchases`,
          icon: 'shopping-cart',
        },
        {
          name: i18next.t('addShortcut.purchaseExp.paymentIn'),
          nav: `payment-out`,
          icon: 'arrow-circle-up',
        },
        {
          name: i18next.t('addShortcut.purchaseExp.return'),
          nav: `purchase-return`,
          icon: 'reply',
        },
        {
          name: i18next.t('addShortcut.purchaseExp.expense'),
          nav: `expenses`,
          icon: 'cart-plus',
        },
      ],
    },
    {
      code: shortCutCodes.transfer,
      sectionTitle: i18next.t('addShortcut.transfer.title'),
      sectionItems: [
        {
          name: i18next.t('addShortcut.transfer.party'),
          nav: `partner-transfer`,
          icon: 'people-arrows',
        },
        {
          name: i18next.t('addShortcut.transfer.cash'),
          nav: `fund-transfer`,
          icon: 'exchange-alt',
        },
      ],
    },
    // {
    //   code: shortCutCodes.loan,
    //   sectionTitle: i18next.t('addShortcut.loan.title'),
    //   sectionItems: [
    //     {
    //       name: i18next.t('addShortcut.loan.loan'),
    //       nav: 'addBankLoan',
    //       icon: 'briefcase',
    //     },
    //     {
    //       name: i18next.t('addShortcut.loan.payment'),
    //       nav: 'bankLoanPayment',
    //       icon: 'arrow-circle-up',
    //     },
    //     {
    //       name: i18next.t('addShortcut.loan.lend'),
    //       nav: 'lendLoan',
    //       icon: 'hands-helping',
    //     },
    //     {
    //       name: i18next.t('addShortcut.loan.paymentIn'),
    //       nav: 'receiveLentLoan',
    //       icon: 'arrow-circle-down',
    //     },
    //   ],
    // },
    {
      code: shortCutCodes.other,
      sectionTitle: i18next.t('addShortcut.other.title'),
      sectionItems: [
        {
          name: i18next.t('addShortcut.other.salary'),
          nav: `salary-payment`,
          icon: 'arrow-circle-up',
        },
        // {
        //   name: i18next.t('addShortcut.other.vat'),
        //   nav: `/${rootPath}/vat-payment`,
        //   icon: 'box-tissue',
        // },
        {
          name: i18next.t('addShortcut.other.adjust'),
          nav: `stock-adjusment`,
          icon: 'equals',
        },
        // {
        //   name: i18next.t('addShortcut.other.general'),
        //   nav: `general-voucher`,
        //   icon: 'random',
        // },
      ],
    },
  ];

  return navListItems;
};
