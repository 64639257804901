import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import Grid from '@mui/material/Unstable_Grid2';
import { Card, CardContent, Divider } from '@mui/material';

import { getSubscriptionProducts } from '../../../controllers/settings/otherSettingControllers';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { colors } from '../../../assets/styles/colors';
import {
  getLookups,
  getSystemConfigs,
} from '../../../controllers/system/systemControllers';
import { reactQueryStatus } from '../../../shared/utils/queryUtils';
import { Box, Button, Stack } from '@mui/material';
import Loading from '../../../components/feedbacks/Loading';
import SubscriptionCard from './components/SubscriptionCard';
import HeaderSwitch from '../../../components/switch/HeaderSwitch';
import { useSelector } from '../../../store/reduxHooks';
import BillingInformation from '../billing/BillingInfo';
import { SubscriptionType } from '../../../constants/defaultCodes/systemTypes';
import SalesContactModal from './components/SalesContactModal';

const SubscriptionPlans = (): JSX.Element => {
  const { companyAccount } = useSelector(s => s.auth.authUser);
  const activeSubsProduct = companyAccount.activeSubscription.product.type;
  const showBillingPage = activeSubsProduct !== SubscriptionType.TRIAL;
  // const [esewaPayment, setEsewaPayment] = useState<boolean>(false);
  // const [khaltiPayment, setKhaltiPayment] = useState<boolean>(false);
  // const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  // const [orderId, setOrderId] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [isAnnualActive, setIsAnnualActive] = useState(true);
  const [showSalesContact, setShowSalesContact] = useState(false);
  const [showBilling, setShowBilling] = useState(showBillingPage);

  const subsProd = () => getSubscriptionProducts();
  const subsProdQuery = useQuery(['subscriptionProducts'], subsProd);
  const { isFetched, data: subsProducts = [] } = subsProdQuery;

  const lookupFn = () => getLookups('basic-subscription-features');
  const lookupsQuery = useQuery(['featureLookups'], lookupFn);
  const { data: lookups = [] } = lookupsQuery;

  const sysConfigsQuery = useQuery(['sysConfigs'], getSystemConfigs);
  const { data: sysConfigs = {} } = sysConfigsQuery;

  const vatEnabled = sysConfigs.enableVATOnSubscription === 'true';
  const ewalletsEnabled = sysConfigs.enablePaymentGateways === 'true';
  const paymentContact = sysConfigs.paymentContactNumber;
  // const newPricingEnabled = sysConfigs.newPricingEnabled === 'true';

  // console.log('data', data);

  const getMergedLookups = (product: any) => {
    const mergedLookups: any = [...lookups];
    mergedLookups.unshift({ id: 0, text: product.description });
    return mergedLookups;
  };

  const getSubsProducts = () => {
    let filteredProducts = [];
    if (isAnnualActive) {
      filteredProducts = subsProducts.filter(x => x.months === 12);
    } else {
      filteredProducts = subsProducts.filter(x => x.months === 3);
    }
    return filteredProducts;
  };

  // console.log('subsProducts', subsProducts);

  const renderSubscriptionCards = () => (
    <Stack gap={4} mx={{ xl: 12 }}>
      <Stack justifyContent={'flex-end'} direction={'row'} gap={2}>
        <HeaderSwitch
          width={170}
          leftEnabled={isAnnualActive}
          leftText="Yearly (12M)"
          rightText="Quarterly (3M)"
          onToggle={v => setIsAnnualActive(v)}
        />

        <Button
          color="primary"
          variant="outlined"
          sx={{
            fontWeight: 'bold',
            backgroundColor: colors.info100,
            borderColor: colors.info100,
          }}
          onClick={() => setShowBilling(true)}>
          billing info
        </Button>
      </Stack>

      <Grid
        container
        rowSpacing={{ xs: 4 }}
        columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 3, xl: 3 }}>
        {getSubsProducts().map(product => (
          <Grid key={product.id} xs={12} sm={12} md={6} lg={4}>
            <SubscriptionCard
              product={product}
              vatEnabled={vatEnabled}
              isAnnualActive={isAnnualActive}
              lookups={getMergedLookups(product)}
              setShowSalesContact={setShowSalesContact}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  );

  if (showBilling) {
    return <BillingInformation hideBilling={() => setShowBilling(false)} />;
  }

  if (isFetched && lookupsQuery.isFetched && sysConfigsQuery.isFetched) {
    return (
      <ScreenWrapper {...reactQueryStatus([subsProdQuery, lookupsQuery])}>
        <Card sx={{ py: 1, pb: 6 }}>
          <CardContent>
            <Box>
              {!paymentSuccess && renderSubscriptionCards()}

              {/* {paymentSuccess && <PaymentSuccessStatus />} */}

              {/* {showPaymentOptions && ewalletsEnabled && (
              <PaymentOptionsModal
                showModal={showPaymentOptions}
                setEsewaPayment={setEsewaPayment}
                setKhaltiPayment={setKhaltiPayment}
                selectedProduct={selectedProduct}
                finalSubscriptionAmount={finalSubsAmount}
                totalBusinesses={totalBusinesses}
                setOrderId={setOrderId}
                onModalClose={() => setShowPaymentOptions(false)}
              />
            )} */}

              {/* {showPaymentOptions && !ewalletsEnabled && (
              <CustomerSupportModal
                showModal={showPaymentOptions}
                setEsewaPayment={setEsewaPayment}
                setKhaltiPayment={setKhaltiPayment}
                selectedProduct={selectedProduct}
                finalSubscriptionAmount={finalSubsAmount}
                totalBusinesses={totalBusinesses}
                setOrderId={setOrderId}
                paymentContact={paymentContact}
                onModalClose={() => setShowPaymentOptions(false)}
              />
            )} */}

              {/* {esewaPayment && orderId && (
              <Esewa
                isVisible={esewaPayment}
                setIsVisible={setEsewaPayment}
                setShowPaymentOptions={setShowPaymentOptions}
                selectedProduct={selectedProduct}
                finalSubscriptionAmount={finalSubsAmount}
                totalBusinesses={totalBusinesses}
                setPaymentSuccess={setPaymentSuccess}
                orderId={orderId}
              />
            )} */}

              {/* {khaltiPayment && orderId && (
              <Khalti
                isVisible={khaltiPayment}
                setIsVisible={setKhaltiPayment}
                setShowPaymentOptions={setShowPaymentOptions}
                selectedProduct={selectedProduct}
                finalSubscriptionAmount={finalSubsAmount}
                totalBusinesses={totalBusinesses}
                setPaymentSuccess={setPaymentSuccess}
                orderId={orderId}
              />
            )} */}
            </Box>
          </CardContent>

          {showSalesContact && (
            <SalesContactModal
              paymentContact={paymentContact}
              modalVisible={showSalesContact}
              onModalClose={() => setShowSalesContact(false)}
            />
          )}
        </Card>
      </ScreenWrapper>
    );
  } else {
    return <Loading />;
  }
};

export default SubscriptionPlans;
