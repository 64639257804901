import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import { createProductCategory } from '../../../controllers/settings/inventoryControllers';
import { validateProductCategory } from '../../../validations/settings/validateProductItem';
import InputForm from '../../../components/forms/InputForm';
import Button from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

const CreateProductCategoryPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const { isLoading, mutateAsync } = useMutation(createProductCategory);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validateProductCategory(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['prodCategories']);
        enqueueSnackbar(t('productCategory.createMsg.success'), successToast);
        navigate('/settings/product-categories', { replace: true });
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('productCategory.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData((prev: any) => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateProductCategory(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData prod cat:', formData);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.createProductCategory')}
          </Typography>
          <Divider />
          <Stack py={4}>
            <InputForm
              isRequired={true}
              defaultValue={formData.name}
              label={t('productCategory.label.name')}
              placeHolder={t('productCategory.label.name')}
              isInvalid={'name' in errors}
              onChangeText={(v: string) => onFormChange('name', v)}
              helpText={'name' in errors && errors.name}
            />
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            title="Save Product Category"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default CreateProductCategoryPage;
