import { accountTypesUrl, voucherTypesUrl } from '../endPoints';
import { store } from '../../store/store';

const v1 = (path: string) => `/v1${path}`;

const httpClient = () => {
  return store.getState().http.client;
};

export const getAccountTypes = async query => {
  const path = accountTypesUrl();
  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const getVoucherTypes = async () => {
  const path = voucherTypesUrl();
  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getSystemConfigs = async () => {
  const path = v1('/system/system-configurations/app');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getLookups = async (type: string) => {
  const path = v1(`/system/lookups/${type}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getCurrentTime = async () => {
  const path = v1('/system/current-time');

  const response = await httpClient().get<any>(path);
  return response.data;
};
