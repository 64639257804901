import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded';

import { colors } from '../../assets/styles/colors';
import { getSettingBasedDate } from '../../shared/utils/getSettingBasedDate';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const UserProfileInfoModal = ({
  authUser,
  modalVisible,
  onModalClose,
}: any): JSX.Element => {
  const { t } = useTranslation();

  if (!modalVisible) return null;

  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack py={2} gap={1} alignItems={'center'}>
          <Stack alignItems={'center'} gap={1}>
            <AccountCircleIcon sx={{ fontSize: 56 }} />
            <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>
              {authUser.user.firstName} {authUser.user.lastName}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
            <BusinessRoundedIcon />
            <Typography sx={{ fontSize: 18 }}>
              {authUser.user?.business?.name}
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
            <PhoneRoundedIcon />
            <Typography sx={{ fontSize: 18 }}>
              {authUser.user.phoneNumber}
            </Typography>
          </Stack>

          <Typography sx={{ fontSize: 18 }}>
            Date Joined:{' '}
            {getSettingBasedDate(authUser.user.createdAt, authUser.user)}
          </Typography>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onModalClose}>
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UserProfileInfoModal;
