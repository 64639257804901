import i18next from 'i18next';

import { colors } from '../../../assets/styles/colors';

export const dashboardQuickLinks = () => [
  {
    // name: 'New Sale',
    name: i18next.t('dashboard.quickLinks.sale'),
    navigateTo: '/transaction/sales',
    text: 'add sale transaction',
    color: colors.green700,
  },
  {
    name: i18next.t('dashboard.quickLinks.paymentIn'),
    navigateTo: '/transaction/payment-in',
    text: 'add payment received',
    color: colors.green700,
  },
  {
    name: i18next.t('dashboard.quickLinks.purchase'),
    navigateTo: '/transaction/purchases',
    text: 'add purchase transaction',
    color: colors.blueGrey900,
  },
  {
    name: i18next.t('dashboard.quickLinks.addAccount'),
    navigateTo: '/settings/accounts/create',
    text: 'add transaction account',
    color: colors.theme1,
  },
  {
    name: i18next.t('dashboard.quickLinks.addPartner'),
    navigateTo: '/settings/partners/create',
    text: 'add customer or vendor',
    color: colors.theme1,
  },
  {
    name: i18next.t('dashboard.quickLinks.addProduct'),
    navigateTo: '/settings/products/create',
    text: 'add new product item',
    color: colors.theme1,
  },
];
