import React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import { colors } from '../../../assets/styles/colors';

function DashboardQuickLinks({ quickLink }) {
  const boxStyle = {
    // height: { lg: 120, xl: 140 },
    marginY: { lg: 2, md: 1, sm: 0.4, xs: 0.4 },
    paddingY: 1,
    borderRadius: 1,
    // boxShadow: 1,
    borderWidth: 1,
    // bgcolor: quickLink.bgColor,
    // bgcolor: colors.white2,
    '&:hover': {
      bgcolor: colors.gray200,
      cursor: 'pointer',
      color: colors.darker3,
    },
  };

  const navigate = useNavigate();

  const getIcon = () => {
    const { name, color } = quickLink;
    if (name === 'New Sale') {
      return <MonetizationOnIcon sx={{ color }} />;
    } else if (name === 'New Purchase') {
      return <ShoppingCartIcon sx={{ color }} />;
    } else if (name === 'Add Partner') {
      return <PeopleAltRoundedIcon sx={{ color }} />;
    } else if (name === 'Add Product') {
      return <StorefrontRoundedIcon sx={{ color }} />;
    } else if (name === 'Add Account') {
      return <PriceChangeRoundedIcon sx={{ color }} />;
    } else if (name === 'Payment In') {
      return <ExpandCircleDownIcon sx={{ color }} />;
    } else {
      return <PriceChangeRoundedIcon sx={{ color }} />;
    }
  };

  return (
    <Box sx={boxStyle} onClick={() => navigate(quickLink.navigateTo)}>
      <Stack alignItems={'center'} gap={0.5}>
        {/* <Avatar sx={{ bgcolor: colors.info100 }}>{getIcon()}</Avatar> */}
        {getIcon()}
        <Stack>
          <Typography
            // fontWeight={'bold'}
            sx={{ fontSize: 14, color: quickLink.color }}
            align="center">
            {quickLink.name.toUpperCase()}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default DashboardQuickLinks;
