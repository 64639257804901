import voucherTypes from '../constants/defaultCodes/voucherTypes';

export const invoices = [
  voucherTypes.CREDIT_SALES,
  voucherTypes.CREDIT_INCOME,
  voucherTypes.CASH_SALES,
  voucherTypes.CASH_INCOME,
];
const expenses = [voucherTypes.CASH_EXPENSE, voucherTypes.CREDIT_EXPENSE];
const incomes = [voucherTypes.CASH_INCOME, voucherTypes.CREDIT_INCOME];

const { CASH_SALES, CASH_INCOME, CASH_PURCHASE, CASH_EXPENSE } = voucherTypes;
const cashs = [CASH_SALES, CASH_INCOME, CASH_PURCHASE, CASH_EXPENSE];

export const salesAndPurchaseTemplate = (templateData: any) => {
  const isInvoice = invoices.includes(templateData.voucherType);
  const isExpense = expenses.includes(templateData.voucherType);
  const isIncomes = incomes.includes(templateData.voucherType);
  const isCashVoucher = cashs.includes(templateData.voucherType);
  const isIncOrExp = isIncomes || isExpense;

  const createdAt = templateData.createdAt;
  const { businessName, customerName, phoneNumber, vendorName } = templateData;
  const { voucherNumber, referenceId, voucherDate } = templateData;
  const { businessPanNumber, businessAddress, isFullPayment } = templateData;
  const { isTaxable, hasTaxableItem } = templateData;
  const voucherDateLocale = templateData.transactionDate;

  const { amountSum, discountAmount, paidAmount } = templateData;
  const { taxAmount, totalAmount, remainingAmount } = templateData;
  const voucherType = templateData.voucherType.split('_').join(' ');

  // console.log('hasTaxableItem', hasTaxableItem);

  const getHtmlElem = (item: any) => {
    // console.log('item.itemTaxAmount', item.itemTaxAmount);
    // console.log('item.amountFormatted', item.amountFormatted);

    const htmlElem = `<tr class="item">
    <td>${item.sn}</td>
    <td class="text-left">${item.particularItemName || ''}</td>
    <td class="text-right">${item.quantity || ''}</td>
    <td>${item.pricePerUnit || ''}</td>
    <td>${item.discountPrice || ''}</td>
    ${
      hasTaxableItem
        ? `<td class="text-right">${item.itemTaxAmount || ''}</td>`
        : ''
    }
    <td class="text-right">${item.amountFormatted}</td>
  </tr>`;
    return htmlElem;
  };

  const getIncExpHtmlElem = (item: any) => {
    const htmlElem = `<tr class="item">
    <td>${item.sn}</td>
    <td class="text-left">${item.accountName}</td>
    <td>${item.particularItemName || ''}</td>
    <td class="text-right">${item.amountFormatted}</td>
  </tr>`;
    return htmlElem;
  };

  let tableItems = '';
  for (const item of templateData.voucherItems) {
    if (!isIncOrExp) {
      tableItems += getHtmlElem(item);
    } else {
      tableItems += getIncExpHtmlElem(item);
    }
  }
  // let tableItems = '';
  // if (isInvoice) {
  //   for (const item of templateData.invoiceItems) {
  //     tableItems += getHtmlElem(item);
  //   }
  // } else {
  //   for (const item of templateData.billItems) {
  //     if (!isExpense) {
  //       tableItems += getHtmlElem(item);
  //     } else {
  //       tableItems += getExpenseHtmlElem(item);
  //     }
  //   }
  // }

  const getAccountName = () => {
    if (isIncomes) {
      return 'Income Account';
    } else if (isExpense) {
      return 'Expense Account';
    }
  };

  const getHeaderText = () => {
    if (!isIncOrExp) {
      return `<td class="text-right">Quantity</td>
      <td>per unit</td>
      <td>Discount</td>
      ${hasTaxableItem ? '<td class="text-right">VAT (13%)</td>' : ''}`;
    } else {
      return '';
    }
  };

  const getTaxSummary = () => {
    if (isTaxable) {
      return `<tr class="item">
      <td class="summaryText">VAT (13%)</td>
      <td class="summaryAmount">${taxAmount}</td>
    </tr>`;
    } else {
      return '';
    }
  };

  const getSummaryBalance = () => {
    if (!isFullPayment && !isCashVoucher && paidAmount) {
      return `<tr class="item">
      <td class="summaryText">${
        isInvoice ? 'Received Amount' : 'Paid Amount'
      }</td>
      <td class="summaryAmount">${paidAmount}</td>
    </tr>
    <tr class="item">
      <td class="summaryText">Remaining (total - ${
        isInvoice ? 'Received' : 'Paid'
      })</td>
      <td class="summaryAmount">${remainingAmount}</td>
    </tr>`;
    } else {
      return '';
    }
  };

  const template = `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="utf-8" />
      <title>A simple, clean, and responsive HTML invoice template</title>

      <style>
          @page {
              size: A4;
              margin: 64px;
              margin-left: 32px;
              margin-right: 32px;
          }

        .invoice-box {
          margin: 16px;
          margin-left: 36px;
          margin-right: 36px;
        }

        .invoice-box table {
          width: 100%;
          line-height: inherit;
          text-align: left;
        }

        .invoice-box table td {
          padding: 5px;
          vertical-align: top;
        }

        .invoice-box table tr td.billInfo {
          text-align: right;
        }


        .invoice-box table tr.top table td {
          padding-bottom: 20px;
        }

        .invoice-box table tr.top table td.title {
          font-size: 45px;
          line-height: 45px;
          color: #333;
        }

        .invoice-box table tr.information table td {
          padding-bottom: 40px;
        }

        .invoice-box table tr.heading td {
          background: #eee;
          border-bottom: 1px solid #ddd;
          font-weight: bold;
        }

        .invoice-box table tr.details td {
          padding-bottom: 20px;
        }

        .invoice-box table tr.item td {
          border-bottom: 1px solid #eee;
        }

        .invoice-box table tr.item.last td {
          border-bottom: none;
        }

        .invoice-box table tr.total td:nth-child(2) {
          border-top: 2px solid #eee;
          font-weight: bold;
        }

        @media only screen and (max-width: 600px) {
          .invoice-box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
          }

          .invoice-box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
          }
        }

        .text-right {
          text-align: right;
        }

        .text-left {
          text-align: left;
        }

        .row {
            display: flex;
        }

        .column {
            width: 50%;
        }

        hr.new1 {
        border-top: 1px solid Silver;
        }
        .invoiceText {
            font-size: 20px;
            font-weight: bold;
        }
        .companyName {
            font-size: 24px;
            font-weight: bold;
        }
        .phoneNo {
            font-size: 18px;
            font-weight: medium;
        }
        .summaryText {
            font-size: 16px;
            font-weight: medium;
        }
        .summaryAmount {
          font-size: 18px;
          font-weight: medium;
        }
        .summaryTotal {
            font-size: 18px;
            font-weight: bold;
        }
        .partyName {
          font-size: 18px;
          font-weight: bold;
        }
        .sn {
          width: 24px;
        }
      </style>
    </head>

    <body>
      <div class="invoice-box">
        <table cellpadding="0" cellspacing="0">
          <tr class="top">
            <td colspan="2">
              <table>
                <tr>
                  <td>
                    <span class="companyName">${businessName}</span><br />
                    ${
                      businessAddress
                        ? `<span class="phoneNo">${businessAddress}</span><br />`
                        : ''
                    }
                    ${
                      isInvoice && businessPanNumber
                        ? `<span class="phoneNo">PAN/VAT: ${businessPanNumber}</span><br />`
                        : ''
                    }
                    <span class="phoneNo">${phoneNumber}</span><br /><br />

                    <span>${voucherType}</span><br />
                    Bill ${isInvoice ? 'To: ' : 'From: '}
                    <span class="partyName">${
                      isInvoice ? customerName : vendorName
                    }</span><br />
                  </td>

                  <td class="billInfo">
                    <span class="invoiceText">${
                      isInvoice ? 'Bill' : 'Purchase Bill'
                    }</span><br />
                    Bill # ${referenceId ? referenceId : 'N/A'}<br />
                    Voucher # ${voucherNumber}<br />
                    ${'Bill'} Date: ${voucherDateLocale}<br />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <table>
          <tr class="heading">
          <td class="sn">SN</td>
          ${isIncOrExp ? `<td>${getAccountName()}</td>` : ''}
            <td>Particulars</td>
            ${getHeaderText()}
            <td class="text-right">Total</td>
          </tr>
          ${tableItems}
        </table>
        <br />
        <div class="row">
            <div class="column"></div>
            <div class="column">
                <table class="table-subtotal">
                    <tbody>
                      <tr class="item">
                        <td class="summaryText">Sub-total</td>
                        <td class="summaryAmount">${amountSum}</td>
                      </tr>
                      <tr class="item">
                        <td class="summaryText">Other Discounts</td>
                        <td class="summaryAmount">${discountAmount}</td>
                      </tr>
                      ${getTaxSummary()}
                      <tr class="item">
                        <td class="summaryTotal">Total</td>
                        <td class="summaryTotal">${totalAmount}</td>
                      </tr>
                      <tr><td><br /></td></tr>
                      ${getSummaryBalance()}
                    </tbody>
                  </table>
            </div>
          </div>

          <br />
          <br />
          <hr class="new1" />
          <span>Created at: ${createdAt}</span><br />
          <span>karodapp.com</span>
      </div>
    </body>
  </html>`;
  return template;
};
