import React, { useState } from 'react';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';

import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { reactQueryStatus } from '../../../shared/utils/queryUtils';
import HeaderSwitch from '../../../components/switch/HeaderSwitch';
import SubscriptionCard from './components/SubscriptionCard';
import {
  getLookupsPublic,
  getSubsProductsPublic,
  getSystemConfigsPublic,
} from '../../../controllers/public/publicControllers';
import SalesContactModal from './components/SalesContactModal';

function SubscriptionPricing() {
  const [isAnnualActive, setIsAnnualActive] = useState(true);
  const [showSalesContact, setShowSalesContact] = useState(false);

  const subsProd = () => getSubsProductsPublic();
  const subsProdQuery = useQuery(['subscriptionProducts'], subsProd);
  const { isFetched, data: subsProducts = [] } = subsProdQuery;

  const lookupFn = () => getLookupsPublic('basic-subscription-features');
  const lookupsQuery = useQuery(['featureLookups'], lookupFn);
  const { data: lookups = [] } = lookupsQuery;

  const sysConfigsQuery = useQuery(['sysConfigs'], getSystemConfigsPublic);
  const { data: sysConfigs = {} } = sysConfigsQuery;

  const vatEnabled = sysConfigs.enableVATOnSubscription === 'true';
  const paymentContact = sysConfigs.paymentContactNumber;

  const getSubsProducts = () => {
    let filteredProducts = [];
    if (isAnnualActive) {
      filteredProducts = subsProducts.filter(x => x.months === 12);
    } else {
      filteredProducts = subsProducts.filter(x => x.months === 3);
    }
    return filteredProducts;
  };

  const getMergedLookups = (product: any) => {
    const mergedLookups: any = [...lookups];
    mergedLookups.unshift({ id: 0, text: product.description });
    return mergedLookups;
  };
  // console.log('subsProducts', subsProducts?.length);

  return (
    <ScreenWrapper {...reactQueryStatus([subsProdQuery, lookupsQuery])}>
      <Stack gap={4} mx={{ xl: 24, lg: 4, md: 4, sm: 4 }} py={8} pb={16}>
        <Stack justifyContent={'flex-end'} direction={'row'} gap={2}>
          <HeaderSwitch
            width={170}
            leftEnabled={isAnnualActive}
            leftText="Yearly (12M)"
            rightText="Quarterly (3M)"
            onToggle={v => setIsAnnualActive(v)}
          />
        </Stack>

        <Grid
          container
          rowSpacing={{ xs: 4 }}
          columnSpacing={{ xs: 2, sm: 4, md: 8, lg: 4, xl: 6 }}>
          {getSubsProducts().map(product => (
            <Grid key={product.id} xs={12} sm={12} md={6} lg={4} xl={4}>
              <SubscriptionCard
                product={product}
                vatEnabled={vatEnabled}
                isAnnualActive={isAnnualActive}
                lookups={getMergedLookups(product)}
                setShowSalesContact={setShowSalesContact}
              />
            </Grid>
          ))}
        </Grid>

        {showSalesContact && (
          <SalesContactModal
            paymentContact={paymentContact}
            modalVisible={showSalesContact}
            onModalClose={() => setShowSalesContact(false)}
          />
        )}
      </Stack>
    </ScreenWrapper>
  );
}

export default SubscriptionPricing;
