import i18next from 'i18next';
import { ProductItemType } from '../../constants/defaultCodes/producttemTypes';
import { AdjustmentType } from '../../constants/defaultCodes/stockType';

export const validateProductItem = (data, errors) => {
  let isValid = true;

  if (!data.name) {
    errors = { ...errors, name: 'Name is required' };
    isValid = false;
  } else if (data.name.length > 64) {
    errors = {
      ...errors,
      name: 'Name cannot be greater than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  if (!data.code) {
    errors = { ...errors, code: 'Product Code is required' };
    isValid = false;
  } else if (data.code.length > 16) {
    errors = {
      ...errors,
      code: 'Product Code cannot be greater than 16 characters',
    };
    isValid = false;
  } else {
    delete errors.code;
  }

  if (!data.sellingPrice) {
    errors = { ...errors, sellingPrice: 'Selling Price is required' };
    isValid = false;
  } else if (+data.sellingPrice <= 0) {
    errors = {
      ...errors,
      sellingPrice: 'Selling Price cannot be 0 or less',
    };
    isValid = false;
  } else {
    delete errors.sellingPrice;
  }

  if (data.productType === ProductItemType.product && !data.purchasePrice) {
    errors = { ...errors, purchasePrice: 'Purchase Price is required' };
    isValid = false;
  } else if (+data.purchasePrice <= 0) {
    errors = {
      ...errors,
      purchasePrice: 'Purchase Price cannot be 0 or less',
    };
    isValid = false;
  } else {
    delete errors.purchasePrice;
  }

  if (!data.productType) {
    errors = {
      ...errors,
      productType: 'Product Type is Required',
    };
    isValid = false;
  } else {
    delete errors.productType;
  }

  if (data.description && data.description.length > 128) {
    errors = {
      ...errors,
      description: 'Description should be between 2 and 256 characters',
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (
    (data.primaryUnit && data.secondaryUnit && !data.primaryToSecScale) ||
    data.primaryToSecScale <= 0
  ) {
    errors = {
      ...errors,
      primaryToSecScale: 'Scale conversion is required',
    };
    isValid = false;
  } else {
    delete errors.primaryToSecScale;
  }

  if (data.salesDiscount && !data.discountType) {
    errors = {
      ...errors,
      discountType: 'Discount Type is required for Sales Discount',
    };
    isValid = false;
  } else {
    delete errors.discountType;
  }

  if (data.inventoryDate && !data.openingStock) {
    errors = {
      ...errors,
      inventoryDate: 'Opening Stock is required to add Opening Date',
    };
    isValid = false;
  } else {
    delete errors.inventoryDate;
  }

  if (data.pricePerUnit && !data.openingStock) {
    errors = {
      ...errors,
      pricePerUnit: 'Opening Stock is required to add Price per unit',
    };
    isValid = false;
  } else {
    delete errors.pricePerUnit;
  }

  if (
    data.openingStock &&
    parseInt(data.openingStock, 2) !== 0 &&
    !data.inventoryDate
  ) {
    errors = {
      ...errors,
      inventoryDate: 'Inventory Date is required for Opening Stock',
    };
    isValid = false;
  } else {
    delete errors.inventoryDate;
  }

  return { isValid, errors };
};

export const validateProductUnit = (data, errors, productUnits) => {
  const unitExist = productUnits.find(x => x.code === data.code?.toUpperCase());

  let isValid = true;
  if (!data.code) {
    errors = { ...errors, code: 'Code is required' };
    isValid = false;
  } else if (data.code && unitExist) {
    errors = {
      ...errors,
      code: 'Code already exists',
    };
    isValid = false;
  } else if (data.code.length > 32) {
    errors = {
      ...errors,
      code: 'Code cannot be greater than 3 characters',
    };
    isValid = false;
  } else {
    delete errors.code;
  }

  if (!data.name) {
    errors = { ...errors, name: 'Name is required' };
    isValid = false;
  } else if (data.name.length > 32) {
    errors = {
      ...errors,
      name: 'Name cannot be greater than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  return { isValid, errors };
};

export const validateProductCategory = (data, errors) => {
  let isValid = true;
  if (!data.name) {
    errors = { ...errors, name: 'Category Name is required' };
    isValid = false;
  } else if (data.name.length > 64) {
    errors = {
      ...errors,
      name: 'Category Name cannot be greater than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  return { isValid, errors };
};

export const validateExpenseItem = (data, errors) => {
  let isValid = true;
  if (data.name === undefined) {
    errors = {
      ...errors,
      name: i18next.t('expenseItem.validation.nameRequired'),
    };
    isValid = false;
  } else if (data.name.length > 64) {
    errors = {
      ...errors,
      name: i18next.t('expenseItem.validation.nameLength'),
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  return { isValid, errors };
};

export const validateStockAdjustment = (data, errors) => {
  let isValid = true;

  if (!data.voucherNumber) {
    const vNoMsg = i18next.t('voucher.validation.voucherNoRequired');
    errors = { ...errors, voucherNumber: vNoMsg };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (!data.product) {
    errors = { ...errors, product: 'Product is required' };
    isValid = false;
  } else {
    delete errors.product;
  }

  if (
    data.adjustmentType === AdjustmentType.quantityAdjustment &&
    !data.quantity
  ) {
    errors = {
      ...errors,
      quantity: 'Adjusted Inventory/Stock Quantity is required',
    };
    isValid = false;
  } else {
    delete errors.quantity;
  }

  if (!data.stockType) {
    errors = {
      ...errors,
      stockType: 'Inventory/Stock adjustment Type is required',
    };
    isValid = false;
  } else {
    delete errors.stockType;
  }

  if (!data.adjustmentType) {
    errors = {
      ...errors,
      adjustmentType: 'Inventory/Stock Adjustment Type is required',
    };
    isValid = false;
  } else {
    delete errors.adjustmentType;
  }

  if (!data.adjustedValue) {
    errors = {
      ...errors,
      adjustedValue: 'Adjusted Value is required',
    };
    isValid = false;
  } else {
    delete errors.adjustedValue;
  }

  return { isValid, errs: errors };
};
