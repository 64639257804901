// import { Platform } from 'react-native';
// import Config from 'react-native-config';
// import ReactNativeBlobUtil from 'react-native-blob-util';

import { store } from '../../store/store';
import {
  voucherNumberUrl,
  adjustingEntryUrl,
  deleteImageUrl,
  imageUploadUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getVoucherNumber = async (voucherTypeCode: string) => {
  const path = voucherNumberUrl(voucherTypeCode);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const adjustingEntry = async (data: any) => {
  const path = adjustingEntryUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const deleteImage = async (imageId: number) => {
  const path = deleteImageUrl(imageId);

  const response = await httpClient().delete<any>(path);
  return response.data;
};

export const checkPartnerTxn = async (partnerId: number) => {
  const path = v1(`/transactions/check-partner-txns/${partnerId}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const checkEmployeeTxn = async (employeeId: number) => {
  const path = v1(`/transactions/check-employee-txns/${employeeId}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const checkProductTxn = async (productId: number) => {
  const path = v1(`/transactions/check-product-txns/${productId}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const uploadVoucherImage = async (data: any) => {
  const path = v1(`/transactions/voucher-image`);

  const formData = new FormData();
  formData.append('voucherImage', data.images[0]);
  formData.append('voucherType', data.voucherType);

  const response: any = await httpClient().post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  // console.log('response =>', response.data);

  return response.data;
};
