import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import Dashboard from '../pages/dashboard/Dashboard';
import UserLogin from '../pages/auth/UserLogin';
import Error404Page from './Error404';
import AuthRequired from './AuthRequired';

import DataDeletionRequest from '../pages/profle/DataDeletion';
import LandingPage from '../landing/LandingPage';

import transactionRoutes from './TransactionRoutes';
import reportRoutes from './ReportRoutes';
import settingRoutes from './SettingRoutes';
import otherRoutes from './OtherRoutes';
import { useSelector } from '../store/reduxHooks';
import { getCurrentTime } from '../controllers/system/systemControllers';
import AccountBalance from '../pages/dashboard/AccountBalance';
import UserRegistration from '../pages/auth/UserRegistration';
import ResetPassword from '../pages/auth/PasswordReset';
import systemRoutes from './SystemRoutes';
import { isSystemSuperAdmin } from '../shared/utils/rolesUtils';
import systemAdminRoutes from './sysAdminRoutes';
import SubscriptionPricing from '../pages/system/subscription/SubsPricing';

function Runway34() {
  const location = useLocation();
  const navigate = useNavigate();

  const { companyAccount, user } = useSelector(s => s.auth.authUser);

  const paths = location.pathname.split('/').filter(x => x);
  const pathZero = paths[0];

  const currentTimeQuery = useQuery(['currentTime'], getCurrentTime);
  const { data: currentTime, isSuccess: success } = currentTimeQuery;

  useEffect(() => {
    const expiryDate = new Date(companyAccount.activeSubscription.expiryDate);
    const nowFromServer = new Date(currentTime);

    if (pathZero !== 'subscription' && nowFromServer > expiryDate) {
      navigate('/');
    }
  }, [pathZero, success]);

  const isSystemAdmin = isSystemSuperAdmin(user);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthRequired>
            <Dashboard />
          </AuthRequired>
        }
      />
      <Route
        path="/dashboard"
        element={
          <AuthRequired>
            <Dashboard />
          </AuthRequired>
        }
      />

      <Route
        path="/account-balance"
        element={
          <AuthRequired>
            <AccountBalance />
          </AuthRequired>
        }
      />

      {transactionRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {settingRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {reportRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {systemRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {otherRoutes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}

      {isSystemAdmin &&
        systemAdminRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}

      <Route path="/login" element={<Navigate to="/" replace />} />
      <Route path="/signup" element={<Navigate to="/" replace />} />

      <Route path="/*" element={<Error404Page />} />
    </Routes>
  );
}

export default Runway34;

export function Freeway() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/pricing" element={<SubscriptionPricing />} />

      <Route path="/login" element={<UserLogin />} />
      <Route path="/signup" element={<UserRegistration />} />
      <Route path="/password-reset" element={<ResetPassword />} />

      <Route
        path="/request-account-deletion"
        element={<DataDeletionRequest />}
      />
      <Route path="/*" element={<Error404Page />} />
    </Routes>
  );
}
