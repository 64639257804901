import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { useSelector } from '../../../store/reduxHooks';
import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import { bankAndCashAccounts } from '../../../controllers/settings/accountControllers';
import { bankAndCashBook } from '../../../controllers/reports/reportControllers';
import { validateCashBookFilters } from '../../../validations/reports/validateCashBookFilters';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { formatAmount } from '../../../shared/utils/locale';
import { colors } from '../../../assets/styles/colors';
import OpenignBalanceAsOf from '../components/OpeningBalnaceAsOf';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import { formatDate } from '../../../shared/utils/dateTime';
import { getNepaliDate } from '../../../shared/utils/nepaliDate';
import { getStaticPeriodByDate } from '../../../constants/defaultCodes/accountingPeriods';
import EmptyTable from '../../../components/feedbacks/EmptyTable';

function CashBook() {
  const { t } = useTranslation();
  // const { user } = useSelector(s => s.auth.authUser);

  const initalState: any = { ...initialDate };
  const [filters, setFilters] = useState<any>(initalState);
  const [errors, setErrors] = useState<any>({});
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [showFilters, setShowFilter] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);
  const [enableAllSelect, setEnableAllSelect] = useState<boolean>(false);

  const accounts = useQuery(['bankAndCashAccounts'], bankAndCashAccounts);

  const queryFunc = () => bankAndCashBook(filters);
  const cashBooks = useQuery(['bankAndCashBook', filters], queryFunc, {
    enabled: false,
  });

  const onSubmit = async () => {
    const { isValid, errs } = validateCashBookFilters(filters, errors);

    if (isValid) {
      cashBooks.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onFilterChange = (name: string, value: any) => {
    const updatedData = { ...filters, [name]: value };
    // setFilters(updatedData);

    let fromDate = filters.fromDate;

    if (name === 'account' && value) {
      setEnableAllSelect(true);
      setSelectedPeriod(LAST_7_DAYS);
      fromDate = initialDate.fromDate;
    } else {
      setEnableAllSelect(false);
    }
    setFilters(prev => ({ ...prev, ...updatedData, fromDate }));

    const { errs } = validateCashBookFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  // const onCreatePDF = async () => {
  //   const now = new Date();
  //   const createdDate = getSettingBasedDate(now, user);
  //   const createdTime = now.toLocaleTimeString();

  //   const data = {
  //     user,
  //     account,
  //     cashBookData: cashBooks.data,
  //     fromDate: getSettingBasedDate(filters.fromDate, user),
  //     toDate: getSettingBasedDate(filters.toDate, user),
  //     createdAt: `${createdDate} ${createdTime}`,
  //   };

  //   const template = cashBookTemplate(data);

  //   const fileName = `cash_book_${dateFileName(user)}`.toLowerCase();
  //   const source = await createPDF(template, fileName);
  //   setPdfSource(source);
  // };

  // if (pdfSource.uri) {
  //   return <PDFView pdfSource={pdfSource} />;
  // }
  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const tbCell = { fontWeight: 'bold', textAlign: 'right' };

  // console.log('cashBooks data:', cashBooks.data);

  const selAccount = accounts.data?.find(x => x.id === filters.account);
  const periodOnCreated = getStaticPeriodByDate(selAccount?.createdAt);

  if (cashBooks.isSuccess && !showFilters) {
    const account = accounts.data?.find(a => a.id === +filters.account);

    return (
      <Card sx={{ paddingX: 2 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={4}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.cashBook')} - {account.name}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={cashBooks.isLoading}
            isError={cashBooks.isError}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Txn Type</TbCellHeader>
                    <TbCellHeader>Txn Date</TbCellHeader>
                    <TbCellHeader>Txn No</TbCellHeader>
                    <TbCellHeader>Ref. No</TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                    <TbCellHeader align="right">Balance</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TbCellBody colSpan={7}>
                      <OpenignBalanceAsOf
                        fromDate={filters.fromDate}
                        openingBalance={formatAmount(
                          cashBooks.data?.accountOpeningBalance,
                        )}
                      />
                    </TbCellBody>
                  </TableRow>
                </TableHead>
                {cashBooks.data?.voucherItems.length ? (
                  <TableBody>
                    {cashBooks.data?.voucherItems.map((row, index) => (
                      <TableRowStyled key={row.id}>
                        <TbCellBody sx={{ maxWidth: 24 }}>
                          {index + 1}
                        </TbCellBody>
                        <TbCellBody>{row.voucher.voucherType.name}</TbCellBody>
                        <TbCellBody>
                          {getNepaliDate(row.voucher.voucherDate)} {' / '}
                          {formatDate(row.voucher.voucherDate)}
                        </TbCellBody>
                        <TbCellBody>{row.voucher.voucherNumber}</TbCellBody>
                        <TbCellBody>{row.voucher.referenceId}</TbCellBody>
                        <TbCellAmount sx={{ fontWeight: '500' }}>
                          {formatAmount(row.normalAmount)}
                        </TbCellAmount>
                        <TbCellAmount>{formatAmount(row.balance)}</TbCellAmount>
                      </TableRowStyled>
                    ))}
                  </TableBody>
                ) : (
                  <EmptyTable message="No Transactions in Given Period !!" />
                )}

                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TbCellSummary />
                    <TbCellAmount colSpan={4} sx={{ fontStyle: 'italic' }}>
                      Closing Balance
                    </TbCellAmount>
                    <TbCellSummary sx={tbCell}>
                      {formatAmount(cashBooks.data?.accountClosingBalance)}
                    </TbCellSummary>
                    <TbCellSummary />
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={accounts.isError || cashBooks.isError}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.cashBook')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid
            py={2}
            container
            rowSpacing={{ xs: 2 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <SelectPicker
                isRequired={true}
                label={t(`cashBook.label.account`)}
                placeHolder={t(`cashBook.placeholder.account`)}
                selectedValue={filters.account}
                selectItems={accounts.data}
                isInvalid={'account' in errors}
                helpText={getHelpText('account')}
                onValueChange={(v: any) => onFilterChange('account', v)}
              />
            </Grid>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
                enableAllSelect={enableAllSelect}
                startFromDate={periodOnCreated?.startDate || new Date()}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Bank & Cash Book'}
              onClick={onSubmit}
              loading={cashBooks.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default CashBook;
