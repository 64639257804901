import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { incomeStatement } from '../../../controllers/reports/reportControllers';
import { validateFromAndToDate } from '../../../validations/reports/validateFromAndToDate';
import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import { useSelector } from '../../../store/reduxHooks';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { formatAmount } from '../../../shared/utils/locale';
import { colors } from '../../../assets/styles/colors';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';

function IncomeStatement() {
  const { t } = useTranslation();
  const { user } = useSelector(s => s.auth.authUser);

  const initalState: any = { ...initialDate };
  const [filters, setFilters] = useState<any>(initalState);
  const [errors, setErrors] = useState<any>({});
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [showFilters, setShowFilter] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  const queryFunc = () => incomeStatement(filters);
  const statements = useQuery(['incomeStatement', filters], queryFunc, {
    enabled: false,
  });

  const onSubmit = async () => {
    const { isValid, errs } = validateFromAndToDate(filters, errors);

    if (isValid) {
      statements.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onDateChange = (dateRange: any, period: string) => {
    setFilters(prev => ({ ...prev, ...dateRange }));
    setSelectedPeriod(period);
  };
  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const tbCell = { fontWeight: 'bold', textAlign: 'right' };

  const incomes = statements.data?.incomeStatements.incomes || [];
  const directCosts = statements.data?.incomeStatements.directCosts || [];
  const expenses = statements.data?.incomeStatements.expenses || [];

  const totalIncome = formatAmount(statements.data?.totalIncome);
  const totalExpenditure = formatAmount(statements.data?.totalExpenditure);
  const totalDirectCost = formatAmount(statements.data?.totalDirectCosts);
  const netProfit = formatAmount(statements.data?.netProfit);

  const statmentsItems = [
    { name: 'Income', data: incomes, totalAmount: totalIncome },
    { name: 'Direct Costs', data: directCosts, totalAmount: totalDirectCost },
    { name: 'Expenses', data: expenses, totalAmount: totalExpenditure },
  ];

  if (statements.isSuccess && !showFilters) {
    return (
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {/* Profit & Loss Statement */}
                {t('reportStack.incomeStat')}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={statements.isLoading}
            isError={statements.isError}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ paddingLeft: 8 }}>
                      Account Name
                    </TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {statmentsItems.map((row, index) => (
                    <>
                      <TableRow
                        sx={{
                          backgroundColor: colors.gray100,
                          borderTopWidth: index == 0 ? 0 : 8,
                          borderColor: colors.white,
                        }}>
                        <TbCellBody
                          colSpan={2}
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 16,
                            color: colors.darker2,
                          }}>
                          {row.name.toUpperCase()}
                        </TbCellBody>
                      </TableRow>

                      {row.data.map((row2, index2) => (
                        <TableRowStyled key={index2}>
                          <TbCellBody sx={{ paddingLeft: 8 }}>
                            {row2.account.code} {row2.account.name}
                          </TbCellBody>
                          <TbCellAmount>
                            {formatAmount(row2.balance)}
                          </TbCellAmount>
                        </TableRowStyled>
                      ))}

                      <TableRow sx={{ backgroundColor: colors.gray100 }}>
                        <TbCellAmount
                          sx={{ fontStyle: 'italic', color: colors.darker3 }}>
                          Total {row.name}
                        </TbCellAmount>
                        <TbCellSummary>{row.totalAmount}</TbCellSummary>
                      </TableRow>
                    </>
                  ))}

                  <TableRow
                    sx={{
                      backgroundColor: colors.gray200,
                    }}>
                    <TbCellSummary sx={{ color: colors.darker2 }}>
                      Net Profit (total income - direct costs - total expenses)*
                    </TbCellSummary>
                    <TbCellSummary
                      sx={{
                        fontSize: 20,
                        color:
                          statements.data?.netProfit > 0
                            ? colors.green700
                            : colors.red500,
                      }}>
                      {netProfit}
                    </TbCellSummary>
                  </TableRow>

                  <Typography sx={{ py: 1 }}>
                    (*net profit in negative means loss)
                  </Typography>
                </TableBody>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={false}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.incomeStat')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid py={3} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Profit Loss'}
              onClick={onSubmit}
              loading={statements.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default IncomeStatement;
