import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Divider, Box, Stack, Typography, Container } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';

import InputForm from '../../../components/forms/InputForm';

import SelectPicker from '../../../components/forms/SelectPicker';
import DatePicker from '../../../components/datePickers/DatePicker';

import Button from '../../../components/buttons/Button';
import {
  createPartner,
  getPartnerCode,
  getPartyCategories,
} from '../../../controllers/settings/partnerControllers';
import {
  validatePartner,
  validatePartnerCode,
  validatePartnerSwitchFields,
} from '../../../validations/settings/validatePartner';
import {
  partnerInputFields,
  partnerSwitchFields,
} from '../../../constants/formFields/settings/partnerFormFields';
import { useNavigate } from 'react-router-dom';
import SwitchForm from '../../../components/forms/SwitchForm';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

const CreatePartnerPage = (): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const defaultInit = { isCustomer: true, isVendor: false };
  const [formData, setData] = useState<any>(defaultInit);
  const [errors, setErrors] = useState<any>({});
  const [showContactList, setShowContactList] = useState<boolean>(false);

  const { isCustomer, isVendor } = formData;

  const partnerCodeFn = () => getPartnerCode({ isCustomer, isVendor });
  const partnerCodeQuery = useQuery(['getPartnerCode'], partnerCodeFn);
  const { data: partnerCode } = partnerCodeQuery;

  const partyCatFn = () => getPartyCategories();
  const partyCategories = useQuery(['partyCategories'], partyCatFn);

  useEffect(() => {
    partnerCodeQuery.refetch();
  }, [isCustomer, isVendor]);

  useEffect(() => {
    if (partnerCode) {
      setData((prev: any) => ({ ...prev, code: partnerCode }));
    }
  }, [partnerCode]);

  const { isLoading, mutateAsync } = useMutation(createPartner);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validatePartner(formData, errors);
    const codeValidity = await validatePartnerCode(formData, errs);
    const { isCodeValid, errss } = codeValidity;

    if (isValid && isCodeValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getPartners']);

        // if (initialData || setHandlePartner) {
        //   queryClient.invalidateQueries(['getCustomers']);
        //   queryClient.invalidateQueries(['getVendors']);
        //   setHandlePartner(prev => ({ ...prev, show: false }));
        // } else {
        //   // navigation.navigate('partnersList');
        // }
        queryClient.invalidateQueries(['getDashboardReport']);

        navigate('/settings/partners', { replace: true });
        enqueueSnackbar(t('partner.createMsg.success'), successToast);
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('partner.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errss);
      enqueueSnackbar('Validation Failed', errorToast);
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validatePartner(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const onSelectContact = (contactItem: any) => {
    const { displayName: name, emailAddresses, phoneNumbers } = contactItem;
    const address = contactItem.postalAddresses[0]?.formattedAddress;
    const phone = phoneNumbers[0]?.number;
    const email = emailAddresses[0]?.email;

    const phoneNo = phone.replace('+977', '');
    const phoneNumber = String(parseInt(phoneNo.replace(/[^0-9]/g, '')));

    const data = { name, phoneNumber, email, address };

    setData(prev => ({ ...prev, ...data }));

    const updatedData = { ...formData, ...data };
    const { errors: err } = validatePartner(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});

    setShowContactList(false);
  };

  const onToggle = (name: string) => {
    setData(validatePartnerSwitchFields(name, formData));
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  // console.log('formData Partner:', formData);

  return (
    <Container>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.addParty')}
          </Typography>
          <Divider />
          <Stack py={2} gap={3}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <InputForm
                  isRequired={true}
                  isDisabled={true}
                  defaultValue={formData.code}
                  label={t('partner.label.code')}
                  placeHolder={t('partner.label.code')}
                  isInvalid={'code' in errors}
                  onChangeText={(v: string) => onFormChange('code', v)}
                  helpText={'code' in errors && errors.code}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`partner.label.category`)}
                  selectedValue={formData.category}
                  selectItems={partyCategories.data || []}
                  isInvalid={'category' in errors}
                  helpText={'category' in errors && errors.category}
                  onValueChange={(v: any) => onFormChange('category', v)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {partnerInputFields.map(({ fieldName, required, numberic }) => (
                <Grid xs={6}>
                  <InputForm
                    isRequired={required}
                    isNumberic={numberic}
                    isFloatNo={false}
                    // isDisabled={disabled}
                    defaultValue={formData[fieldName]}
                    maxLength={fieldName === 'phoneNumber' && 10}
                    label={t(`partner.label.${fieldName}`)}
                    placeHolder={t(`partner.placeholder.${fieldName}`)}
                    isInvalid={fieldName in errors}
                    helpText={getHelpText(fieldName)}
                    onChangeText={(v: string) => onFormChange(fieldName, v)}
                  />
                </Grid>
              ))}
            </Grid>

            {partnerSwitchFields.map(({ fieldName }) => (
              <SwitchForm
                key={fieldName}
                isChecked={formData[fieldName]}
                label={t(`partner.label.${fieldName}`)}
                helpText={t(`partner.helpText.${fieldName}`)}
                onToggle={() => onToggle(fieldName)}
              />
            ))}

            {formData.isCustomer && (
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={6}>
                  <InputForm
                    isNumberic={true}
                    label={t(`partner.label.customerReceivable`)}
                    isInvalid={'customerReceivable' in errors}
                    helpText={getHelpText('customerReceivable')}
                    onChangeText={(v: string) =>
                      onFormChange('customerReceivable', v)
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    entryMinDate={true}
                    isRequired={formData.customerReceivable ? true : false}
                    label={t('partner.label.cusOpeningDate')}
                    value={formData.cusOpeningDate}
                    isInvalid={'cusOpeningDate' in errors}
                    helpText={
                      'cusOpeningDate' in errors && errors.cusOpeningDate
                    }
                    onDateChange={(v: any) => onFormChange('cusOpeningDate', v)}
                  />
                </Grid>
              </Grid>
            )}

            {formData.isVendor && (
              <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid xs={6}>
                  <InputForm
                    isNumberic={true}
                    label={t(`partner.label.vendorPayable`)}
                    isInvalid={'vendorPayable' in errors}
                    helpText={getHelpText('vendorPayable')}
                    onChangeText={(v: string) =>
                      onFormChange('vendorPayable', v)
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePicker
                    entryMinDate={true}
                    isRequired={formData.vendorPayable ? true : false}
                    label={t('partner.label.venOpeningDate')}
                    value={formData.venOpeningDate}
                    isInvalid={'venOpeningDate' in errors}
                    helpText={
                      'venOpeningDate' in errors && errors.venOpeningDate
                    }
                    onDateChange={(v: any) => onFormChange('venOpeningDate', v)}
                  />
                </Grid>
              </Grid>
            )}
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button title="Save Partner" onClick={onSubmit} loading={isLoading} />
        </Stack>
      </Card>
    </Container>
  );
};

export default CreatePartnerPage;
