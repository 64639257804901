import React, { useState } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Fab,
  IconButton,
} from '@mui/material';
import { AddPhotoAlternate, Cancel } from '@mui/icons-material';

import { colors } from '../../assets/styles/colors';
import ImagePreview from './ImagePreview';
import { useMutation } from '@tanstack/react-query';
import { deleteImage } from '../../controllers/transactions/transactionControllers';

export default function ImagePicker({ images, setImages }) {
  const [showPreview, setShowPreview] = useState<boolean>(false);

  // In base64 format
  // const onImageChange = event => {
  //   var file = event.target.files[0];
  //   const reader = new FileReader();
  //   if (file) {
  //     reader.readAsDataURL(file);
  //     reader.onloadend = function (e) {

  //       // setImages(reader.result);
  //       // setImgSelected(true);
  //     };
  //   }
  // };

  const removeImage = useMutation(deleteImage);

  const onRemoveImage = async () => {
    setShowPreview(false);
    setImages([]);
    if (images.length > 0 && images[0].id) {
      removeImage.mutateAsync(images[0]?.id);
    }
  };

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const img = [event.target.files[0]];
      setImages(img);
    }
  };

  if (showPreview && images.length) {
    return (
      <ImagePreview
        images={images}
        showModal={showPreview}
        onClose={() => setShowPreview(false)}
      />
    );
  }

  if (images && images.length) {
    let imgURL;
    if (images[0].id) {
      imgURL = images[0].uri;
    } else {
      imgURL = URL.createObjectURL(images[0]);
    }

    return (
      <Card sx={{ width: 60, height: 60, position: 'relative' }}>
        <CardContent sx={{ padding: 0 }}>
          <CardActionArea onClick={() => setShowPreview(true)}>
            <img
              style={{ width: '100%', height: 'auto' }}
              src={imgURL}
              alt="img"
            />
          </CardActionArea>
        </CardContent>

        <IconButton
          style={{ position: 'absolute', bottom: 0, right: 0, padding: 3 }}
          onClick={onRemoveImage}>
          <Cancel sx={{ color: colors.red500 }} />
        </IconButton>
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          width: 60,
          height: 60,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CardContent>
          <input
            style={{ display: 'none' }}
            accept="image/jpeg, image/jpg, image/png,image/webp"
            id="img-file"
            name="logo"
            type="file"
            onChange={onImageChange}
          />
          <label
            htmlFor="img-file"
            style={{ display: images.length && 'none' }}>
            <Fab
              size="small"
              component="span"
              sx={{ color: colors.theme1, marginTop: 0.8 }}>
              <AddPhotoAlternate />
            </Fab>
          </label>
        </CardContent>
      </Card>
    );
  }
}
