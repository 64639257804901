import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

import SelectPicker from '../../../../components/forms/SelectPicker';
import InputForm from '../../../../components/forms/InputForm';
import { useTranslation } from 'react-i18next';
import { validateInvoiceItem } from '../../../../validations/transactions/validatenewInvoice';
import {
  getDiscountAmount,
  getFinalProductPrice,
  getTotalProductPrice,
} from '../../../../shared/utils/vatAndDiscount';
import {
  DiscountType,
  ProductItemType,
  productItemTypes,
} from '../../../../constants/defaultCodes/producttemTypes';
import ItemDiscountAndVat from './ItemDiscountAndVat';
import { colors } from '../../../../assets/styles/colors';
import { FormError } from '../../../../components/utility/FormError';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

function TransactionItemModal({
  showModal,
  setShowModal,
  voucherItem,
  productItems,
  productUnits,
  onFormValueChange,
  onRemoveLine,
  setHandleProduct,
  defaultAccount,
  isSales,
  isPurchase,
  isSalesReturn,
  isPurchaseReturn,
  isUpdate,
}: any) {
  const { t } = useTranslation();

  const sales = isSales || isSalesReturn;

  // const salesEntry = isSales || isSalesReturn
  // console.log('voucherItem', voucherItem);
  const account = defaultAccount?.id;
  const initial = { account, lineNo: voucherItem.lineNo };
  const init = isUpdate ? voucherItem : initial;

  const [formData, setData] = useState<any>(init);
  const [errors, setErrors] = useState<any>({});
  const [productType, setProdType] = useState<string>('');
  const [showDTS, setShowDTS] = useState<boolean>(false);
  const [showCalculator, setShowCalculator] = useState<boolean>(false);

  const { product, quantity, pricePerUnit, taxable, ...rest } = formData;
  const { discountPrice, amount, totalAmount, productUnit, lineNo } = rest;
  const { discountPercent } = rest;

  useEffect(() => {
    setErrors({});
  }, [showModal]);

  useEffect(() => {
    (discountPrice || taxable || discountPercent) && setShowDTS(true);
  }, [discountPrice, taxable, discountPercent]);

  const onFormChange = async (data: any, name: string) => {
    setData((prev: any) => ({ ...prev, ...data }));

    const updatedData = { ...formData, ...data };
    const { errs } = validateInvoiceItem(updatedData, errors, sales);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onAddOrUpdate = async () => {
    const { errs, isValid } = validateInvoiceItem(formData, errors, sales);
    // console.log('isValid', isValid);
    // console.log('errs', errs);

    if (isValid) {
      onFormValueChange({ ...formData }, lineNo);
      setShowModal(false);
    } else {
      setErrors(errs);
    }
  };

  const getTitle = () => {
    if (isSales) {
      return 'SALE';
    } else if (isPurchase) {
      return 'PURCHASE';
    } else if (isSalesReturn) {
      return 'SALE RETURN';
    } else if (isPurchaseReturn) {
      return 'PURCHASE RETURN';
    }
  };

  const getProductPrice = (
    prod: any,
    isSal = isSales || isSalesReturn,
    isPur = isPurchase || isPurchaseReturn,
    prodUnit = formData.productUnit,
  ) => {
    const { secondaryUnitId, primaryToSecScale } = prod;
    const { sellingPrice, purchasePrice } = prod;

    if (isSal) {
      if (secondaryUnitId && +secondaryUnitId === +prodUnit) {
        return +(+sellingPrice / +primaryToSecScale || 1).toFixed(2);
      } else {
        return +sellingPrice;
      }
    } else if (isPur) {
      if (secondaryUnitId && +secondaryUnitId === +prodUnit) {
        return +(+purchasePrice / +primaryToSecScale || 1).toFixed(2);
      } else {
        return +purchasePrice;
      }
    }
  };

  const getCostOfPurchase = (
    prod: any,
    qnty = formData.quantity,
    prodUnit = formData.productUnit,
  ) => {
    let prodPurchasePrice;
    if (prod) {
      prodPurchasePrice = getProductPrice(prod, false, true, prodUnit);
    }

    return (prodPurchasePrice || 0) * (+qnty || 1);
  };

  const getDiscountAmt = (
    prod: any,
    qnty = formData.quantity,
    prodUnit = formData.productUnit,
  ) => {
    const productPrice = getTotalProductPrice(qnty, pricePerUnit);
    let discount = getDiscountAmount(discountPercent, productPrice);

    if (isSales) {
      if (prod?.discountType === DiscountType.amount) {
        if (prod.secondaryUnitId && +prod?.secondaryUnitId === +prodUnit) {
          return 0;
        } else {
          discount = prod?.salesDiscount * (qnty || 1);
        }
      }
      return discount;
    }
  };

  const onProductChange = (productId: any) => {
    const selected = productItems.find(x => x.id === +productId);
    const prodType = selected?.productType;
    setProdType(prodType);

    let discount = 0;
    let disPercent = '';
    if (isSales) {
      if (selected?.discountType === DiscountType.amount) {
        discount = selected.salesDiscount;
      } else if (selected?.discountType === DiscountType.percent) {
        disPercent = selected.salesDiscount;
      }
    }

    const unit = selected?.primaryUnitId;
    let perUnit;
    if (selected) {
      perUnit = getProductPrice(selected);
    }

    const initProd = { amount: 0, quantity: 0, totalAmount: 0, taxable: false };
    let payload: any = { ...initProd, productType: prodType };
    payload = { ...payload, productUnit: unit, pricePerUnit: perUnit };

    if (prodType === ProductItemType.service) {
      if (disPercent) {
        discount = getDiscountAmount(+disPercent, perUnit || 0);
      }
      payload.quantity = 1;
      payload.amount = perUnit;
    }
    payload.discountPrice = discount;
    payload.discountPercent = disPercent;

    onFormChange({ product: productId, ...payload }, 'product');
  };

  const onQuantityChange = (qnty: number) => {
    const productPrice = getTotalProductPrice(qnty, pricePerUnit);
    let discount = getDiscountAmount(discountPercent, productPrice);
    const selected = productItems.find(x => x.id === +product);

    if (selected) {
      discount = getDiscountAmt(selected, qnty) || 0;
    }

    const totalAmt = getFinalProductPrice(amount, discount, taxable);

    const data: any = {
      quantity: qnty,
      amount: productPrice,
      totalAmount: totalAmt,
      discountPrice: discount,
    };
    sales && (data.costsOfProduct = getCostOfPurchase(selected, qnty));

    onFormChange(data, 'quantity');
  };

  const onUnitChange = (unit: number) => {
    const selected = productItems.find(x => x.id === +product);

    let perUnit;
    if (selected) {
      perUnit = getProductPrice(selected, isSales, isPurchase, unit);
    }

    let costsOfProduct = formData.costsOfProduct;
    if (isSales) {
      costsOfProduct = getCostOfPurchase(selected, formData.quantity, unit);
    }

    const amt = getTotalProductPrice(quantity, perUnit);

    const data: any = { productUnit: unit, pricePerUnit: perUnit, amount: amt };
    isSales && (data.costsOfProduct = costsOfProduct);

    if (+selected?.secondaryUnitId === +unit) {
      data.discountPrice = 0;
    } else {
      data.discountPrice = selected?.salesDiscount * (formData.quantity || 1);
    }
    onFormChange(data, 'productUnit');
  };

  const onPerUnitPriceChange = (perUnit: number) => {
    const prodPrice = getTotalProductPrice(quantity, perUnit);
    const totalAmt = getFinalProductPrice(prodPrice, discountPrice, taxable);
    const discount = getDiscountAmount(discountPercent, prodPrice);

    const data = {
      pricePerUnit: perUnit,
      amount: prodPrice,
      totalAmount: totalAmt,
      discountPrice: discount,
    };
    onFormChange(data, 'pricePerUnit');
  };

  const onProductAmountChange = (prodAmount: number) => {
    const discount = getDiscountAmount(discountPercent, prodAmount);
    const totalAmt = getFinalProductPrice(prodAmount, discount, taxable);
    const data: any = { amount: prodAmount, totalAmount: totalAmt };
    data.discountPrice = discount;
    onFormChange(data, 'amount');
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const getDefaultProductUnits = () => {
    const selected = productItems.find(x => x.id === +product);
    if (selected && (selected.primaryUnit || selected.secondaryUnit)) {
      let units = [];
      const { primaryUnitId, secondaryUnitId } = selected;
      const primaryUnit = productUnits.find(x => x.id === primaryUnitId);
      const secondaryUnit = productUnits.find(x => x.id === secondaryUnitId);
      primaryUnit && units.push(primaryUnit);
      secondaryUnit && units.push(secondaryUnit);
      return units;
    } else {
      return productUnits;
    }
  };

  const showCostsOfGoods = () => {
    const isProd = formData.productType === ProductItemType.product;
    if (sales && formData.quantity && isProd) {
      return true;
    }
    return false;
  };

  const getProducts = () => {
    if (isPurchase) {
      return productItems.filter(
        x => x?.productType === ProductItemType.product,
      );
    } else {
      return productItems;
    }
  };

  const getAmountTitle = () => {
    if (taxable && discountPrice) {
      return '(amount - discount + vat)';
    } else if (taxable && !discountPrice) {
      return '(amount + vat)';
    } else if (!taxable && discountPrice) {
      return '(amount - discount)';
    }
  };

  const prodCurrentStock = () => {
    if (formData.product && productType === ProductItemType.product) {
      const prodItem = productItems?.find(x => x.id === formData.product);
      const stock = +prodItem?.currentStock;
      const unit = prodItem?.primaryUnit?.name || '';

      const balance = `Current Stocks: ${stock} ${unit}`;

      if (stock <= 0) {
        return { type: 'NEGATIVE', balance };
      } else {
        return { type: 'POSITIVE', balance };
      }
    }
  };

  const isReturn = isSalesReturn || isPurchaseReturn;

  // console.log('=== formData item:', formData);
  // console.log('=== errors item:', errors);

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography sx={{ fontSize: 18, color: colors.theme1, mb: 1 }}>
          ADD {getTitle()} ITEM
        </Typography>
        <Divider />

        <Stack py={1} gap={2}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <SelectPicker
                isRequired={true}
                label={t('newItem.label.product')}
                selectItems={getProducts()}
                selectedValue={formData.product}
                isInvalid={'product' in errors}
                helpText={'product' in errors && errors.product}
                onValueChange={(v: any) => onProductChange(v)}
                productBalance={prodCurrentStock() && prodCurrentStock()}
                //  setHandles={setHandleProduct}
              />
            </Grid>
            <Grid xs={3}>
              <InputForm
                // size="small"
                isRequired={true}
                label={t('newItem.label.quantity')}
                defaultValue={quantity > 0 && String(quantity)}
                maxLength={7}
                isNumberic={true}
                silentInvalid={true}
                placeHolder="quantity"
                isInvalid={'quantity' in errors}
                // helpText={'quantity' in errors && errors.quantity}
                onChangeText={(v: string) => onQuantityChange(+v)}
              />
            </Grid>
            <Grid xs={6}>
              <SelectPicker
                label={t('newItem.label.productUnit')}
                // isDisabled={isReturn ? true : false}
                isRequired={false}
                selectItems={getDefaultProductUnits()}
                selectedValue={formData.productUnit}
                isInvalid={'productUnit' in errors}
                helpText={'productUnit' in errors && errors.productUnit}
                onValueChange={(v: any) => onUnitChange(+v)}
              />
            </Grid>
            <Grid xs={3}>
              <InputForm
                label={t('newItem.label.perUnit')}
                defaultValue={String(formData.pricePerUnit || '')}
                maxLength={7}
                isNumberic={true}
                silentInvalid={true}
                placeHolder={t('newItem.label.perUnit')}
                isInvalid={'pricePerUnit' in errors}
                onChangeText={(v: string) => onPerUnitPriceChange(+v)}
              />
            </Grid>
            {'quantity' in errors && <FormError error={errors.quantity} />}
            {'pricePerUnit' in errors && (
              <FormError error={errors.pricePerUnit} />
            )}
          </Grid>
          {showCostsOfGoods() && (
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={8} alignSelf={'center'}>
                <Typography>Costs of purchase</Typography>
                <Typography sx={{ fontSize: 14, mt: -1 }}>
                  (purchase price X quantity)
                </Typography>
              </Grid>
              <Grid xs={4}>
                <InputForm
                  size="small"
                  maxLength={12}
                  isRequired={true}
                  isNumberic={true}
                  silentInvalid={true}
                  // editable={isSalesReturn ? false : true}
                  placeHolder={t('newItem.label.costsOfProduct')}
                  defaultValue={String(formData.costsOfProduct || '')}
                  isInvalid={'costsOfProduct' in errors}
                  onChangeText={v =>
                    onFormChange({ costsOfProduct: +v }, 'costsOfProduct')
                  }
                />
              </Grid>
              {'costsOfProduct' in errors && (
                <FormError error={errors.costsOfProduct} align="right" />
              )}
            </Grid>
          )}

          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={8} alignSelf={'center'}>
              <Typography>{isSales ? 'Sale ' : ''}Amount</Typography>
            </Grid>
            <Grid xs={4}>
              <InputForm
                inLine={true}
                maxLength={12}
                isRequired={true}
                isNumberic={true}
                silentInvalid={true}
                // editable={quantity && pricePerUnit ? false : true}
                placeHolder={t('newItem.label.amount')}
                defaultValue={String(formData.amount || '')}
                isInvalid={'amount' in errors}
                onChangeText={v => onProductAmountChange(+v)}
              />
            </Grid>
            {'amount' in errors && (
              <FormError error={errors.amount} align="right" />
            )}
          </Grid>

          <ItemDiscountAndVat
            formData={formData}
            errors={errors}
            isReturn={isReturn}
            onFormChange={onFormChange}
            isSales={isSales}
          />

          <Grid
            container
            sx={{ backgroundColor: colors.gray200, my: 1, py: 0.6 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={8}>
              <Typography>Total Amount {getAmountTitle()}</Typography>
            </Grid>
            <Grid xs={4} alignSelf={'center'}>
              <Typography
                textAlign={'right'}
                sx={{ fontSize: 18, fontWeight: 'bold' }}>
                {getFinalProductPrice(amount, discountPrice, taxable)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <InputForm
                label={t('newItem.label.memo')}
                defaultValue={voucherItem.memo}
                isInvalid={'memo' in errors}
                helpText={getHelpText('memo')}
                onChangeText={(v: string) => onFormChange({ memo: v }, 'memo')}
              />
            </Grid>
          </Grid>
        </Stack>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={() => setShowModal(false)}>
            Close
          </Button>

          {isUpdate && (
            <Button
              color="error"
              variant="contained"
              size="large"
              onClick={() => {
                setShowModal(false);
                onRemoveLine(lineNo);
              }}>
              Remove
            </Button>
          )}

          <Button
            variant="contained"
            size="large"
            onClick={() => onAddOrUpdate()}>
            {isUpdate ? 'Update' : 'Add Item'}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default TransactionItemModal;
