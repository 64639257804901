// EDIT AT
// https://jsfiddle.net/

import { dateFormat } from '../constants/defaultCodes/otherEnums';
import { formatDate } from '../shared/utils/dateTime';
import { formatAmount } from '../shared/utils/locale';

export const partnerStatDashTemplate = (data: any) => {
  const { partnerStatments, user, fromDate, toDate, createdAt } = data;

  const userDateFormat = user.business.dateFormat;
  const businessName = user.business.name;
  const phoneNumber = user.business.phoneNumber || user.phoneNumber;
  const businessAddress = user.business.address;
  const reportTitle = `Partner Statements (${data.code})`;
  const dateRange = `${fromDate} - ${toDate}`;

  const { opening, closing, statements, partner } = partnerStatments;
  const { debitSum, creditSum } = partnerStatments;

  const getDate = (item: any) => {
    if (userDateFormat === dateFormat.BS) {
      return item.voucher.voucherDateBS;
    } else {
      return formatDate(item.voucher.voucherDate);
    }
  };

  const getVoucherElem = (item: any) => {
    const { referenceId } = item.voucher;
    const htmlElem = `<tr class="item">
    <td>${item.voucher.voucherType.name} </td>
    <td class="text-center">${getDate(item)}</td>
    <td class="text-center">${referenceId ? referenceId : ''}</td>
    <td class="text-right">${formatAmount(item.debit)}</td>
    <td class="text-right">${formatAmount(item.credit)}</td>
    <td class="text-right">${formatAmount(item.normalAmount)}</td>
    </tr>`;
    return htmlElem;
  };

  let voucherItemsElem = '';
  for (const statement of statements) {
    voucherItemsElem += getVoucherElem(statement);
  }

  const template = `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="utf-8" />
      <title>A simple, clean, and responsive HTML invoice template</title>

      <style>
          @page {
              size: A4;
              margin: 64px;
              margin-left: 32px;
              margin-right: 32px;
          }

        .main {
            margin: 16px;
            margin-left: 36px;
            margin-right: 36px;
          }

        .box table {
          width: 100%;
          line-height: inherit;
          text-align: left;
        }

        .box table td {
          padding: 5px;
          vertical-align: top;
        }

        .box table tr td:nth-child(2) {
          text-align: right;
        }

        .box table tr.top table td {
          padding-bottom: 20px;
        }

        .box table tr.top table td.title {
          font-size: 45px;
          line-height: 45px;
          color: #333;
        }

        .box table tr.heading td {
          /* font-size: 18px; */
          background: #AFAFAF;
          border-bottom: 1px solid #ddd;
          font-weight: bold;
          padding-left: 32px;
        }

        .box table tr.item td {
          font-size: 18px;
          border-bottom: 1px solid #eee;
        }

        .box table tr.sub-heading td {
          font-size: 18px;
          border-bottom: 1px solid #eee;
          padding-top: 8px;
          font-weight: bold;
        }

        .box table tr.summary td {
          font-size: 18px;
          border-bottom: 1px solid #eee;
          font-weight: bold;
          background: #DFDFDF;
          text-align: right;
        }


        .box table tr.total-summary td {
          font-size: 20px;
          border-bottom: 1px solid #eee;
          font-weight: bold;
          background: #BFBFBF;
          text-align: right;
        }

        .box table tr.item.last td {
          border-bottom: none;
        }

        .box table tr.total td:nth-child(2) {
          border-top: 2px solid #eee;
          font-weight: bold;
        }

        .footer {
          bottom: 0px;
          left: 16px;
          right: 16px;
          margin-top: 16px;
          padding: 8px 0 16px 2px;
          border-top: 1px solid #eee;
          border-top-color: #AFAFAF;
          font-weight: bold;
        }

        @media only screen and (max-width: 600px) {
          .box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
          }

          .box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
          }
        }

        .text-right {
          text-align: right;
        }

        .text-center {
          text-align: center;
        }

        .titleText {
            font-size: 20px;
            font-weight: bold;
        }
        .companyName {
            font-size: 24px;
            font-weight: bold;
        }
        .phoneNo {
            font-size: 18px;
            font-weight: medium;
        }

        .italic {
          font-style: italic;
      }


      </style>
    </head>

    <body class="main">
      <div class="box">
        <table cellpadding="0" cellspacing="0">
          <tr class="top">
            <td colspan="2">
              <table>
                <tr>
                  <td>
                    <span class="companyName">${businessName}</span><br />
                    ${
                      businessAddress
                        ? `<span class="phoneNo">${businessAddress}</span><br />`
                        : ''
                    }
                    <span class="phoneNo">${phoneNumber}</span><br /><br />
                  </td>

                  <td>
                    <span class="titleText">${reportTitle}</span><br />
                    <span class="titleText">${partner.name}</span><br />
                    ${
                      partner.phoneNumber
                        ? `<span class="phoneNo">${partner.phoneNumber}</span><br />`
                        : ''
                    }
                    ${dateRange}<br />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <table>
          <tr class="heading">
            <td>Txn Type</td>
            <td>Date</td>
            <td>Ref No.</td>
            <td class="text-right">Debit</td>
            <td class="text-right">Credit</td>
            <td class="text-right">Balance</td>
          </tr>
          ${
            opening
              ? `<tr><td colspan="5" class="text-right phoneNo">Opening Balance</td><td class="text-right phoneNo">${formatAmount(
                  opening,
                )}</td></tr>`
              : ''
          }

          ${voucherItemsElem}
          <tr class="total-summary">
            <td colspan="3" class="text-right">Total Closing Balance</td>
            <td class="text-right">${formatAmount(debitSum)}</td>
            <td class="text-right">${formatAmount(creditSum)}</td>
            <td class="text-right">${formatAmount(closing)}</td>
          </tr>
        </table>
      </div>

      <div class="footer">
        <span>Created at: ${createdAt}</span><br />
        <span>karodapp.com</span>
      </div>
    </body>
  </html>`;
  return template;
};
