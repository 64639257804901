export enum dateFormat {
  BS = 'BS',
  AD = 'AD',
}

export const dateFormats = [
  { id: dateFormat.BS, name: 'BS' },
  { id: dateFormat.AD, name: 'AD' },
  // { id: dateFormat.BS, name: 'Bikram Sambat (BS)' },
  // { id: dateFormat.AD, name: 'Anno Domini (AD)' },
];

export enum PaymentMode {
  ESEWA = 'esewa',
  KHALTI = 'khalti',
  IMEPAY = 'ime-pay',
  CIPS = 'connect-ips',
  BANK = 'bank-transfer',
  CASH = 'cash',
  OTHER = 'other',
  TRIAL = 'trial',
}

export const paymentModes = [
  { id: PaymentMode.ESEWA, name: PaymentMode.ESEWA },
  { id: PaymentMode.KHALTI, name: PaymentMode.KHALTI },
  { id: PaymentMode.IMEPAY, name: PaymentMode.IMEPAY },
  { id: PaymentMode.CIPS, name: PaymentMode.CIPS },
  { id: PaymentMode.BANK, name: PaymentMode.BANK },
  { id: PaymentMode.CASH, name: PaymentMode.CASH },
  { id: PaymentMode.OTHER, name: PaymentMode.OTHER },
];
