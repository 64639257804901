import i18next from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';

export const validateFTVoucherNo = async (data, errors) => {
  const newVoucherNo = await getVoucherNumber(voucherTypes.FUND_TRANSFER);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNo) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('fundTransfer.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validatePTVoucherNo = async (data, errors) => {
  const newVoucherNo = await getVoucherNumber(voucherTypes.PARTNER_TRANSFER);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNo) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('fundTransfer.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateFundTransfer = (data, errors, hasBalance?: boolean) => {
  let isValid = true;
  if (data.voucherNumber === undefined) {
    errors = { ...errors, voucherNumber: 'Voucher No is required' };
    isValid = false;
  } else if (data.voucherNumber.length < 3 || data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('fundTransfer.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('fundTransfer.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.voucherDate === undefined) {
    errors = {
      ...errors,
      voucherDate: i18next.t('fundTransfer.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (data.cashOutAccount === undefined) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('fundTransfer.validation.accountFromRequired'),
    };
    isValid = false;
  } else if (hasBalance === false) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('voucher.validation.inSufficientBalance'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  if (data.cashInAccount === undefined) {
    errors = {
      ...errors,
      cashInAccount: i18next.t('fundTransfer.validation.accountToRequired'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  if (data.amount === undefined || data.amount <= 0) {
    errors = {
      ...errors,
      amount: i18next.t('fundTransfer.validation.amountRequired'),
    };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('fundTransfer.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};

export const validatePartnerTransfer = (data, errors) => {
  let isValid = true;
  if (!data.voucherNumber) {
    errors = { ...errors, voucherNumber: 'Voucher No is required' };
    isValid = false;
  } else if (data.voucherNumber.length < 3 || data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('fundTransfer.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('fundTransfer.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18next.t('fundTransfer.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.fromPartner) {
    errors = {
      ...errors,
      fromPartner: i18next.t('partnerTransfer.validation.fromPartnerReq'),
    };
    isValid = false;
  } else if (data.fromPartner && +data.customerBalance === 0) {
    errors = {
      ...errors,
      fromPartner: i18next.t('partnerTransfer.validation.noReceivables'),
    };
    isValid = false;
    // } else if (data.fromPartner && +data.customerBalance < +data.vendorBalance) {
    //   errors = {
    //     ...errors,
    //     fromPartner: i18next.t('partnerTransfer.validation.receivableIsLess'),
    //   };
    //   isValid = false;
  } else {
    delete errors.fromPartner;
  }

  if (!data.toPartner) {
    errors = {
      ...errors,
      toPartner: i18next.t('partnerTransfer.validation.toPartnerReq'),
    };
    isValid = false;
  } else if (data.toPartner && +data.vendorBalance === 0) {
    errors = {
      ...errors,
      toPartner: i18next.t('partnerTransfer.validation.noPayables'),
    };
    isValid = false;
  } else {
    delete errors.toPartner;
  }

  if (!data.amount || data.amount <= 0) {
    errors = {
      ...errors,
      amount: i18next.t('partnerTransfer.validation.amountRequired'),
    };
    isValid = false;
  } else if (data.amount && data.amount > +data.customerBalance) {
    errors = {
      ...errors,
      amount: i18next.t('partnerTransfer.validation.amountGTReceivable'),
    };
    isValid = false;
  } else if (data.amount && data.amount > +data.vendorBalance) {
    errors = {
      ...errors,
      amount: i18next.t('partnerTransfer.validation.amountGTPayable'),
    };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('fundTransfer.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};
