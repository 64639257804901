// import Joi from 'joi';
// import { errorFormat } from '../../utils/FormErrors';

// const userLoginSchema = Joi.object().keys({
//   email: Joi.string()
//     .trim(true)
//     .required()
//     .email({ tlds: { allow: false } }),
//   password: Joi.string().trim(true).required().min(8).max(32).required(),
// });

// export const validateLoginData = formData => {
//   let isValid = true;

//   let errors = {};
//   const { error } = userLoginSchema.validate(formData);
//   if (error) {
//     isValid = false;
//     errors = errorFormat(error?.details);
//   }
//   return { isValid, errors };
// };

import i18next from 'i18next';
import { emailIsValid, phoneNoIsValid } from '../common/commonValidations';

export const validateLoginData = (data: any, errors: any) => {
  let isValid = true;

  // if (data.email === undefined) {
  //   errors = {
  //     ...errors,
  //     email: i18next.t('userLogin.validation.emailRequired'),
  //   };
  //   isValid = false;
  // } else if (!emailIsValid(data.email)) {
  //   errors = {
  //     ...errors,
  //     email: i18next.t('userLogin.validation.validEmail'),
  //   };
  //   isValid = false;
  // } else {
  //   delete errors.email;
  // }

  if (!data.phoneNumber) {
    errors = {
      ...errors,
      phoneNumber: i18next.t('userLogin.validation.phoneRequired'),
    };
    isValid = false;
  } else if (!phoneNoIsValid(data.phoneNumber)) {
    errors = {
      ...errors,
      phoneNumber: i18next.t('userLogin.validation.validPhoneNo'),
    };
    isValid = false;
  } else {
    delete errors.phoneNumber;
  }

  if (!data.password) {
    errors = {
      ...errors,
      password: i18next.t('userLogin.validation.passwordRequired'),
    };
    isValid = false;
  } else if (data.password.length < 8 || data.password.length > 36) {
    errors = {
      ...errors,
      password: i18next.t('userLogin.validation.passwordLength'),
    };
    isValid = false;
  } else if (data.password.indexOf(' ') >= 0) {
    errors = {
      ...errors,
      password: i18next.t('userLogin.validation.invalidPassword'),
    };
    isValid = false;
  } else {
    delete errors.password;
  }

  return { isValid, errors };
};
