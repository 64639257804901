import React from 'react';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddNewLine = ({ onAddNewLine, t }: any) => (
  <Stack alignItems={'flex-end'}>
    <Button
      size="large"
      startIcon={<AddIcon />}
      onClick={onAddNewLine}
      sx={{ fontWeight: 'bold', fontSize: 16 }}>
      {t('entriesCommonText.addNewItem')}
    </Button>
  </Stack>
);

export default AddNewLine;
