import { store } from '../../store/store';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getSubsProductsPublic = async () => {
  const path = v1('/public-api/subscription-products');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getLookupsPublic = async (type: string) => {
  const path = v1(`/public-api/lookups/${type}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getSystemConfigsPublic = async () => {
  const path = v1('/public-api/system-configurations/web');

  const response = await httpClient().get<any>(path);
  return response.data;
};
