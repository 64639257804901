import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {
  BrowserRouter,
  // createBrowserRouter,
  // RouterProvider,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import TagManager from 'react-gtm-module';

import './index.css';
import './assets/i18n';
import { store } from './store/store';

import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './styles/GlobalStyles';
import configs from './config/configs';

// import ErrorPage from './routes/Error';

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <App />,
//     errorElement: <ErrorPage />,
//   },
// ]);

TagManager.initialize({
  gtmId: configs.googleTMId ?? '',
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router} /> */}
    <BrowserRouter>
      <Provider store={store}>
        <GlobalStyles />
        <CssBaseline />
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
