import i18next from 'i18next';

export const partnerLedgerTypes = {
  VENDOR_CUSTOMER: 'VENDOR_CUSTOMER',
  LOAN_PARTNER: 'LOAN_PARTNER',
};
export const reportCardItems = () => {
  return [
    {
      id: 11,
      name: i18next.t('report.accounting.title'),
      items: [
        {
          name: i18next.t('report.accounting.general'),
          navigateTo: 'general-ledger',
          helpText: i18next.t('report.helpText.general'),
        },
        {
          name: i18next.t('report.accounting.trialBalance'),
          navigateTo: 'trial-balance',
          helpText: i18next.t('report.helpText.trialBalance'),
        },
        {
          name: i18next.t('report.accounting.dayBook'),
          navigateTo: 'day-book',
          helpText: i18next.t('report.helpText.dayBook'),
        },
        {
          name: i18next.t('report.accounting.cashBook'),
          navigateTo: 'cash-book',
          helpText: i18next.t('report.helpText.cashBook'),
        },
        {
          name: i18next.t('report.accounting.incomeStat'),
          navigateTo: 'profit-loss-statement',
          helpText: i18next.t('report.helpText.incomeStat'),
        },

        {
          name: i18next.t('report.accounting.balanceSheet'),
          navigateTo: 'balance-sheet',
          helpText: i18next.t('report.helpText.balanceSheet'),
          isLast: true,
        },
      ],
    },
    {
      id: 53412,
      name: i18next.t('report.product.title'),
      items: [
        {
          name: i18next.t('report.product.sales'),
          navigateTo: 'sales-report',
          helpText: i18next.t('report.helpText.sales'),
        },
        {
          name: i18next.t('report.product.purchase'),
          navigateTo: 'purchase-report',
          helpText: i18next.t('report.helpText.purchase'),
        },
        {
          name: i18next.t('report.product.prodStock'),
          navigateTo: 'product-inventory',
          helpText: i18next.t('report.helpText.prodStock'),
        },
        {
          name: i18next.t('report.product.stockMovement'),
          navigateTo: 'stock-movement',
          helpText: i18next.t('report.helpText.stockMovement'),
        },
        {
          name: i18next.t('report.product.lowStock'),
          navigateTo: 'low-stock-report',
          helpText: i18next.t('report.helpText.lowStock'),
        },
        {
          name: i18next.t('report.product.productExpiry'),
          navigateTo: 'product-expiry-report',
          helpText: i18next.t('report.helpText.productExpiry'),
          isLast: true,
        },
      ],
    },
    {
      id: 12,
      name: i18next.t('report.partner.title'),
      items: [
        {
          name: i18next.t('report.partner.partyLedger'),
          navigateTo: 'partner-ledger',
          code: partnerLedgerTypes.VENDOR_CUSTOMER,
          helpText: i18next.t('report.helpText.partyLedger'),
        },
        // {
        //   name: i18next.t('report.partner.partyProd'),
        //   navigateTo: 'partner-product-ledger',
        //   helpText: i18next.t('report.helpText.partyProd'),
        // },
        {
          name: i18next.t('report.expense.expenseItem'),
          navigateTo: 'expenses-on-items',
          helpText: i18next.t('report.helpText.expenseItem'),
        },
        {
          name: i18next.t('report.expense.salary'),
          navigateTo: 'salary-expenses',
          helpText: i18next.t('report.helpText.salary'),
          isLast: true,
        },
        // {
        //   name: i18next.t('report.partner.lnPartyLedger'),
        //   navigateTo: 'partnerLedger',
        //   code: partnerLedgerTypes.LOAN_PARTNER,
        //   helpText: i18next.t('report.helpText.lnPartyLedger'),
        // },
        // {
        //   name: i18next.t('report.partner.bankLoan'),
        //   navigateTo: 'bankloanStatement',
        //   helpText: i18next.t('report.helpText.bankLoan'),
        //   isLast: true,
        // },
      ],
    },

    // {
    //   id: 53412,
    //   name: i18next.t('report.expense.title'),
    //   items: [
    //     {
    //       name: i18next.t('report.expense.expenseItem'),
    //       navigateTo: 'expenseStatement',
    //       helpText: i18next.t('report.helpText.expenseItem'),
    //     },
    //     {
    //       name: i18next.t('report.expense.salary'),
    //       navigateTo: 'employeeStatement',
    //       helpText: i18next.t('report.helpText.salary'),
    //       isLast: true,
    //     },
    //   ],
    // },
  ];
};
