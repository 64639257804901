export enum EntryType {
  cashSales = 'cash-sales',
  creditSales = 'credit-sales',
  cashPurchase = 'cash-purchase',
  creditPurchase = 'credit-purchase',
  cashExpense = 'cash-expense',
  creditExpense = 'credit-expense',
  invoiceReceive = 'invoice-receive',
  billPayment = 'bill-payment',
  advanceReceive = 'advance-receive',
  advancePayment = 'advance-payment',
  salesReturn = 'sales-return',
  purchaseReturn = 'purchase-return',
  expenseReturn = 'expense-return',
  vatPayment = 'vat-payment',
  bankLoan = 'bank-loan',
  emiPayment = 'emi-payment',
  lendLoan = 'lend-loan',
  recieveLentLoan = 'receive-lent-loan',
  borrowLoan = 'borrow-loan',
  payBorrowedLoan = 'pay-borrowed-loan',
  fundTransfer = 'fund-transfer',
  salaryPayment = 'salary-payment',
  general = 'general',
  other = 'other',
}

export const EntryTypes = [
  {
    name: EntryType.cashSales,
    id: EntryType.cashSales,
  },
  {
    name: EntryType.creditSales,
    id: EntryType.creditSales,
  },
  {
    name: EntryType.cashPurchase,
    id: EntryType.cashPurchase,
  },
  {
    name: EntryType.creditPurchase,
    id: EntryType.creditPurchase,
  },
  {
    name: EntryType.cashExpense,
    id: EntryType.cashExpense,
  },
  {
    // name: EntryType.creditExpense,
    name: 'Credit & Cash Expense',
    id: EntryType.creditExpense,
  },
  {
    name: EntryType.invoiceReceive,
    id: EntryType.invoiceReceive,
  },
  {
    name: EntryType.billPayment,
    id: EntryType.billPayment,
  },
  {
    name: EntryType.advanceReceive,
    id: EntryType.advanceReceive,
  },
  {
    name: EntryType.advancePayment,
    id: EntryType.advancePayment,
  },
  {
    name: EntryType.salesReturn,
    id: EntryType.salesReturn,
  },
  {
    name: EntryType.purchaseReturn,
    id: EntryType.purchaseReturn,
  },
  {
    name: EntryType.expenseReturn,
    id: EntryType.expenseReturn,
  },
  {
    name: EntryType.vatPayment,
    id: EntryType.vatPayment,
  },
  {
    name: EntryType.bankLoan,
    id: EntryType.bankLoan,
  },
  {
    name: EntryType.emiPayment,
    id: EntryType.emiPayment,
  },
  {
    name: EntryType.lendLoan,
    id: EntryType.lendLoan,
  },
  {
    name: EntryType.recieveLentLoan,
    id: EntryType.recieveLentLoan,
  },
  {
    name: EntryType.borrowLoan,
    id: EntryType.borrowLoan,
  },
  {
    name: EntryType.payBorrowedLoan,
    id: EntryType.payBorrowedLoan,
  },
  {
    name: EntryType.fundTransfer,
    id: EntryType.fundTransfer,
  },
  {
    name: EntryType.salaryPayment,
    id: EntryType.salaryPayment,
  },
  {
    name: EntryType.general,
    id: EntryType.general,
  },
  {
    name: EntryType.other,
    id: EntryType.other,
  },
];
