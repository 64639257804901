import { store } from '../../store/store';
import {
  businessUrl,
  updateBusinessUrl,
  activateBusinessUrl,
  deActivateBusinessUrl,
  userBusinessesUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

export const getBusinesses = async () => {
  const path = businessUrl();
  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getUserBusinesses = async () => {
  const path = userBusinessesUrl();

  const response = await httpClient().get<any>(path);

  return response.data;
};

export const createBusiness = async (data: any) => {
  const path = businessUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updateBusiness = async (payload: any) => {
  const path = updateBusinessUrl(payload.businessId);
  const response = await httpClient().patch<any>(path, payload.data);
  return response.data;
};

export const activateBusiness = async (businessId: number) => {
  const path = activateBusinessUrl(businessId);

  const response = await httpClient().patch<any>(path);
  return response.data;
};

export const deActivateBusiness = async (businessId: number) => {
  const path = deActivateBusinessUrl(businessId);

  const response = await httpClient().patch<any>(path);
  return response.data;
};
