import i18next from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';

export const validateAEVoucherNo = async (data, errors) => {
  const newVoucherNo = await getVoucherNumber(voucherTypes.ADJUSTING_ENTRY);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNo) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('form.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateAdjustingEntry = (data, errors, hasBalance?: boolean) => {
  let isValid = true;
  if (!data.voucherNumber) {
    errors = { ...errors, voucherNumber: 'Voucher No is required' };
    isValid = false;
  } else if (data.voucherNumber.length < 3 || data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('form.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('form.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18next.t('adjustingEntry.validation.adjustignDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (data.isIncomeAdjustment && !data.incomeAccount) {
    errors = {
      ...errors,
      incomeAccount: i18next.t('adjustingEntry.validation.incomeAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.incomeAccount;
  }

  if (data.isIncomeAdjustment && !data.cashInAccount) {
    errors = {
      ...errors,
      cashInAccount: i18next.t('adjustingEntry.validation.cashInAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  if (!data.isIncomeAdjustment && !data.expenseAccount) {
    errors = {
      ...errors,
      expenseAccount: i18next.t('adjustingEntry.validation.expenseAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.expenseAccount;
  }

  if (!data.isIncomeAdjustment && !data.cashOutAccount) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('adjustingEntry.validation.cashOutAccountReq'),
    };
    isValid = false;
  } else if (!data.isIncomeAdjustment && hasBalance === false) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('voucher.validation.inSufficientBalance'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  if (data.amount === undefined || data.amount <= 0) {
    errors = {
      ...errors,
      amount: i18next.t('adjustingEntry.validation.amountRequired'),
    };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('adjustingEntry.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};
