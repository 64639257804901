import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { formatAmount } from '../../../shared/utils/locale';
import { useSelector } from '../../../store/reduxHooks';
import { getAllPostingAccounts } from '../../../controllers/settings/accountControllers';
import {
  getLoanPartners,
  getPartners,
} from '../../../controllers/settings/partnerControllers';
import { getEmployees } from '../../../controllers/settings/employeeControllers';
import {
  getExpenseItems,
  getProductItems,
} from '../../../controllers/settings/inventoryControllers';
import { generalLedger } from '../../../controllers/reports/reportControllers';
import { validateGeneralLedgerFilters } from '../../../validations/reports/validateGeneralLedgerFilters';
import { colors } from '../../../assets/styles/colors';
import OpenignBalanceAsOf from '../components/OpeningBalnaceAsOf';
import SwitchForm from '../../../components/forms/SwitchForm';
import { generalLedgerSelectFilters } from '../../../constants/formFields/reports/generalLedgerFields';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';

function GeneralLedger() {
  const { t } = useTranslation();
  const { user } = useSelector(s => s.auth.authUser);

  const initalState: any = { ...initialDate, allAccounts: false };
  const [filters, setFilters] = useState<any>(initalState);
  const [errors, setErrors] = useState<any>({});
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [showFilters, setShowFilter] = React.useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  // const businesses = useQuery(['getBusinesses'], getBusinesses);
  const accounts = useQuery(['allAccounts'], getAllPostingAccounts);
  const partners = useQuery(['getPartners'], getPartners);
  const employees = useQuery(['getEmployee'], getEmployees);
  const { data: loanPartners } = useQuery(['getLoanPartners'], getLoanPartners);
  const { data: productItems } = useQuery(['getProductItems'], getProductItems);
  const { data: expenseItems } = useQuery(['getExpenseItems'], getExpenseItems);

  const ledgers = useQuery(['ledgers', filters], () => generalLedger(filters), {
    enabled: false,
  });

  const onSubmit = async () => {
    const { isValid, errs } = validateGeneralLedgerFilters(filters, errors);

    if (isValid) {
      ledgers.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onFilterChange = (name: string, value: any) => {
    const xValue = name === 'allAccounts' ? !filters[name] : value;

    const updatedData = { ...filters, [name]: xValue };

    if (name === 'vendor') {
      delete updatedData.customer;
      delete updatedData.loanPartner;
    }
    if (name === 'customer') {
      delete updatedData.vendor;
      delete updatedData.loanPartner;
    }
    if (name === 'loanPartner') {
      delete updatedData.vendor;
      delete updatedData.customer;
    }
    setFilters(updatedData);

    const { errs } = validateGeneralLedgerFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const getSelectItems = (selectType: string) => {
    const getName = (i, c, n) => ({ id: i, name: `${c} ${n}` });

    const accountItems = accounts.data?.map(x => getName(x.id, x.code, x.name));

    const vendors = partners.data?.filter((x: any) => x.isVendor);
    const customers = partners.data?.filter((x: any) => x.isCustomer);

    const vendorItems = vendors?.map((x: any) => getName(x.id, x.code, x.name));
    const customerItems = customers?.map(x => getName(x.id, x.code, x.name));

    const employeeItems = employees.data?.map((x: any) =>
      getName(x.id, x.firstName, x.lastName),
    );

    if (selectType === 'vendor') {
      return vendorItems;
    } else if (selectType === 'customer') {
      return customerItems;
    } else if (selectType === 'account') {
      return accountItems;
    } else if (selectType === 'employee') {
      return employeeItems;
    } else if (selectType === 'loanPartner') {
      return loanPartners;
    } else if (selectType === 'product') {
      return productItems;
    } else if (selectType === 'expenseItem') {
      return expenseItems;
    }
  };

  const getHelpText = (filter: string) => {
    if (filter in errors) {
      return errors[filter];
    }
  };

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  // console.log('ledgers', ledgers.data);

  if (ledgers.isSuccess && !showFilters) {
    return (
      <Card sx={{ paddingX: 2 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.general')}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={ledgers.isLoading}
            isError={ledgers.isError}
            isEmpty={!ledgers.data?.length}>
            <TableContainer>
              <Table aria-label="ledger-table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 16 }}>SN</TbCellHeader>
                    <TbCellHeader>Transaction Type</TbCellHeader>
                    <TbCellHeader>Date</TbCellHeader>
                    <TbCellHeader>Partner</TbCellHeader>
                    <TbCellHeader>Txn No</TbCellHeader>
                    <TbCellHeader>Ref. No</TbCellHeader>
                    <TbCellHeader align="right">Amount</TbCellHeader>
                    <TbCellHeader align="right">Balance</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {ledgers.data?.map((row, index) => (
                    <>
                      <TableRowStyled
                        sx={{
                          backgroundColor: colors.gray100,
                          borderTopWidth: index == 0 ? 0 : 8,
                          borderColor: colors.white,
                        }}>
                        <TbCellBody sx={{ maxWidth: 16, fontWeight: 'bold' }}>
                          {index + 1}
                        </TbCellBody>
                        <TbCellBody sx={{ fontWeight: 'bold' }}>
                          {row.account}
                        </TbCellBody>

                        <TbCellAmount colSpan={6}>
                          {row.hasOpeningBalance && (
                            <OpenignBalanceAsOf
                              fromDate={filters.fromDate}
                              openingBalance={formatAmount(row.openingBalance)}
                            />
                          )}
                        </TbCellAmount>
                      </TableRowStyled>

                      {row.voucherItems.map((row2, index2) => (
                        <TableRowStyled key={index2}>
                          <TbCellBody sx={{ maxWidth: 16, pl: 3 }}>
                            {index2 + 1}
                          </TbCellBody>

                          <TbCellBody>
                            {row2.voucher.voucherType.name}
                          </TbCellBody>
                          <TbCellBody>
                            {getSettingBasedDate(
                              row2.voucher.voucherDate,
                              user,
                            )}
                          </TbCellBody>
                          {row2.vendor?.name && row2.customer?.name ? (
                            <TbCellBody>
                              {row2.vendor?.name}, {row2.customer?.name}
                            </TbCellBody>
                          ) : (
                            <TbCellBody>
                              {row2.vendor?.name || row2.customer?.name}
                            </TbCellBody>
                          )}

                          <TbCellBody>{row2.voucher.voucherNumber}</TbCellBody>
                          <TbCellBody>{row2.voucher.referenceId}</TbCellBody>
                          <TbCellBody align="right">
                            {formatAmount(row2.normalAmount)}
                          </TbCellBody>
                          <TbCellAmount>
                            {formatAmount(row2.balance)}
                          </TbCellAmount>
                        </TableRowStyled>
                      ))}

                      {row.voucherItems.length > 0 && (
                        <TableRowStyled
                          sx={{ backgroundColor: colors.gray100 }}>
                          <TbCellAmount
                            colSpan={6}
                            sx={{ fontStyle: 'italic', color: colors.darker3 }}>
                            {row.hasOpeningBalance
                              ? 'Closing Balance'
                              : 'Total Transaction Amount'}
                          </TbCellAmount>
                          <TbCellAmount sx={{ fontSize: 17 }}>
                            {formatAmount(row.normalAmountSum)}
                          </TbCellAmount>
                          <TableCell />
                        </TableRowStyled>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper
      isError={partners.isError || accounts.isError || ledgers.isError}>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.general')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Stack my={2}>
            <Grid
              container
              rowSpacing={{ xs: 2, sm: 2, md: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <PeriodSelect
                  pickerSize="medium"
                  inLineLabel={false}
                  dateRange={dateRange}
                  selectedPeriod={selectedPeriod}
                  onDateChange={onDateChange}
                />
              </Grid>
              <Grid xs={6} display={'flex'} pt={2}>
                <Stack flex={1} justifyContent={'center'}>
                  <SwitchForm
                    isChecked={filters.allAccounts}
                    label={t('generalLedger.label.allAccounts')}
                    onToggle={() => onFilterChange('allAccounts', undefined)}
                  />
                </Stack>
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  isRequired={filters.allAccounts ? false : true}
                  isDisabled={filters.allAccounts ? true : false}
                  label={t(`cashBook.label.account`)}
                  placeHolder={t(`cashBook.placeholder.account`)}
                  selectedValue={filters.account}
                  selectItems={accounts.data}
                  isInvalid={'account' in errors}
                  helpText={getHelpText('account')}
                  onValueChange={(v: any) => onFilterChange('account', v)}
                />
              </Grid>

              {generalLedgerSelectFilters.map(({ filter, required }) => (
                <Grid xs={6}>
                  <SelectPicker
                    isRequired={required}
                    selectedValue={filters[filter]}
                    selectItems={getSelectItems(filter)}
                    label={t(`generalLedger.label.${filter}`)}
                    placeHolder={t(`generalLedger.placeholder.${filter}`)}
                    isInvalid={filter in errors}
                    helpText={getHelpText(filter)}
                    onValueChange={(v: any) => onFilterChange(filter, v)}
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get General Ledger'}
              onClick={onSubmit}
              loading={ledgers.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default GeneralLedger;
