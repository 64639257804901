import React from 'react';
import { Box, Modal } from '@mui/material';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
};

const ImagePreview = ({
  images,
  showModal,
  onClose,
  serverUri = false,
}: any): JSX.Element => {
  let imgURL;

  if (serverUri) {
    imgURL = images[0].uri;
  } else {
    imgURL = URL.createObjectURL(images[0]);
  }

  return (
    <Modal
      open={showModal}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box component="img" sx={style} alt="img" src={imgURL} />
    </Modal>
  );
};

export default ImagePreview;
