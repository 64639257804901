import { phoneNoIsValid } from '../common/commonValidations';

export const validateBusiness = (data, errors) => {
  let isValid = true;
  if (!data.name) {
    errors = { ...errors, name: 'Name is required' };
    isValid = false;
  } else if (data.name.length > 128) {
    errors = {
      ...errors,
      name: 'Name should cannot be greater than 128 characters',
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  if (!data.dateFormat) {
    errors = {
      ...errors,
      dateFormat: 'Date Format is required',
    };
    isValid = false;
  } else {
    delete errors.dateFormat;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: 'Description cannot be greater than 256 characters',
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.phoneNumber && !phoneNoIsValid(data.phoneNumber)) {
    errors = { ...errors, phoneNumber: 'Invalid phone number' };
    isValid = false;
  } else {
    delete errors.phoneNumber;
  }

  return { isValid, errors };
};
