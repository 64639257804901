import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

import { useSelector } from '../../store/reduxHooks';
import BSDatePicker from './BSDatePicker';
import ADDatePicker from './ADDatePicker';
import { dateFormat } from '../../constants/defaultCodes/otherEnums';
import { getStaticPeriodByDate } from '../../constants/defaultCodes/accountingPeriods';

const DatePicker = (props: any) => {
  const { user } = useSelector(state => state.auth.authUser);
  const format: dateFormat = user.business?.dateFormat || dateFormat.BS;

  // // DISABLES ENTRIES BEFORE 1ST ACC PERIOD START DATE
  let minDate;
  if (props.entryMinDate) {
    const firstPeriod = getStaticPeriodByDate(user?.business.createdAt);
    minDate = new Date(firstPeriod?.startDate || new Date());
  }

  // const minDate = new Date();

  if (format === dateFormat.BS) {
    return <BSDatePicker {...props} minDate={minDate} />;
  } else {
    return <ADDatePicker {...props} minDate={minDate} />;
  }
};

export default DatePicker;
