import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

import { colors } from '../../assets/styles/colors';

export const TbRowHeaderStyled = styled(TableRow)(() => ({
  backgroundColor: colors.info100,
  borderBottomColor: colors.info100,
  borderBottomWidth: 2,
}));

export const TableRowStyled = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': { border: 0 },
  '&:hover': { backgroundColor: colors.gray100 },
}));
