import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RepeatOnIcon from '@mui/icons-material/RepeatOn';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import PaymentsIcon from '@mui/icons-material/Payments';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import BalanceIcon from '@mui/icons-material/Balance';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import InsertChartIcon from '@mui/icons-material/InsertChart';

import { SectionHeading, Subheading as SubheadingBase } from "../misc/Headings.js";
import { SectionDescription } from "../misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "../../assets/images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Features = ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "features that are most suitable for your business needs." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { icon: 'sales', title: "Sales/Income", description: "Sales Income and Other Income entries" },
    { icon: 'purchase', title: "Purchase/Expense", description: "Purchases, Assets Purchase and Other Expenses" },
    { icon: 'inout', title: "Payment In/Out", description: 'Payment received (IN) and Payment made (OUT)' },
    { icon: 'return', title: "Return Entries", description: 'Return entry for Sales or Purchase Transaction' },
    { icon: 'bankTrns', title: "Cash Transfer", description: 'Transfer fund from one account to another' },
    { icon: 'partyTrns', title: "Partner Transfer", description: 'Transfer receivables directly to payables' },
    { icon: 'adjustment', title: "Adjustment Entry", description: 'Adjust product quantity and current stock value'  },
    { icon: 'reports', title: "20+ Reports", description: "20+ accounting reports" },
    { icon: 'multi', title: "Multiple Business", description: "Multiple users & businesses in same account" },
    { icon: 'account', title: "Custom Accounts", description: "Add custom accounts for transaction" },
    { icon: 'appweb', title: "App & Web", description: "Mobile app and web app availability" },
    { icon: 'unlimited', title: "Unlimited Entries", description: "Unlimited transaction entries" },
  ];

  const getFeatureIcon = (icon) => {
    const style = { color: '#01579B' }
    if (icon === 'sales') {
      return <MonetizationOnIcon sx={style} />
    } else if (icon === 'purchase') {
      return <ShoppingCartIcon sx={style} />
    } else if (icon === 'inout') {
      return <RepeatOnIcon sx={style} />
    } else if (icon === 'return') {
      return <ReplayCircleFilledIcon sx={style} />
    } else if (icon === 'bankTrns') {
      return <PaymentsIcon sx={style} />
    } else if (icon === 'partyTrns') {
      return <SocialDistanceIcon sx={style} />
    } else if (icon === 'adjustment') {
      return <BalanceIcon sx={style} />
    } else if (icon === 'reports') {
      return <InsertChartIcon sx={style} />
    } else if (icon === 'multi') {
      return <SwitchAccountIcon sx={style} />
    } else if (icon === 'account') {
      return <AdminPanelSettingsIcon sx={style} />
    } else if (icon === 'appweb') {
      return <LaptopMacIcon sx={style} />
    } else if (icon === 'unlimited') {
      return <FormatListNumberedIcon sx={style} />
    }
  }

  if (!cards) cards = defaultCards;

  return (
    <Container id="features">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                {getFeatureIcon(card.icon)}
                {/* <img src={card.imageSrc || defaultCardImage} alt="" /> */}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default Features;
