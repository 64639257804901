import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { colors } from '../../../../assets/styles/colors';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const PartyPaymentWarning = ({ warning, handleClose }: any) => {
  const { t } = useTranslation();

  if (!warning.open) return null;
  return (
    <Modal
      open={warning.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={1}>
          <Typography
            sx={{ fontSize: 20, color: colors.red500, fontWeight: 'bold' }}>
            Payment Warning !!
          </Typography>
        </Stack>
        <Divider />

        <Stack py={2} gap={2}>
          <Typography sx={{ fontSize: 20 }}>{warning.title}</Typography>
          <Typography sx={{ fontSize: 18, color: colors.blueGrey700 }}>
            {warning.description}
          </Typography>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={handleClose}>
            No, Change Partner
          </Button>

          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={handleClose}>
            Yes Proceed
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PartyPaymentWarning;
