import React, { useState } from 'react';
import Card from '@mui/material/Card';
import {
  CardContent,
  Divider,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useNavigate, useLocation } from 'react-router-dom';

import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../components/styled/TableCellStyled';
import { formatAmount } from '../../shared/utils/locale';
import { colors } from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';
import InputForm from '../../components/forms/InputForm';

const AccountBalance = (): JSX.Element => {
  const { t } = useTranslation();

  const { state } = useLocation();
  const navigate = useNavigate();

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');

  if (!state) {
    navigate('/');
    return null;
  }

  const { accountItems, totalBalance } = state.balance;

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = accountItems.filter((account: any) => {
      const { name, balance } = account;

      const hasName = name?.toLowerCase().includes(text);
      const hasBalance = String(balance)?.toLowerCase().includes(text);

      if (hasName || hasBalance) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let accountList = accountItems;
  if (searchText) {
    accountList = filteredItems;
  }

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1 }}>
        <Stack pb={1} direction={'row'} alignItems={'center'} spacing={2}>
          <Stack flex={2}>
            <Typography sx={{ fontSize: 20 }}>
              {t('dashboardStack.accountBalance')} ({accountList.length}{' '}
              accounts)
            </Typography>
          </Stack>
          <Stack flex={1}>
            <InputForm
              size="small"
              inLineLabel={true}
              label={'search account by name or balance...'}
              onChangeText={(v: string) => onSearch(String(v))}
            />
          </Stack>
        </Stack>

        <Divider />

        <TableContainer>
          <Table aria-label="table">
            <TableHead>
              <TbRowHeaderStyled>
                <TbCellHeader>SN</TbCellHeader>
                <TbCellHeader>Account Name</TbCellHeader>
                <TbCellHeader align="right">Balance</TbCellHeader>
              </TbRowHeaderStyled>
            </TableHead>
            <TableBody>
              {accountList.map((item, index) => (
                <TableRowStyled key={index}>
                  <TbCellBody sx={{ width: 24 }}>{index + 1}</TbCellBody>
                  <TbCellBody>{item.name}</TbCellBody>
                  <TbCellAmount>{formatAmount(item.balance)}</TbCellAmount>
                </TableRowStyled>
              ))}
            </TableBody>
            <TableHead sx={{ backgroundColor: colors.gray200 }}>
              <TableRow>
                <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                  Total
                </TableCell>
                <TbCellAmount sx={{ fontSize: 16 }}>
                  {formatAmount(totalBalance)}
                </TbCellAmount>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default AccountBalance;
