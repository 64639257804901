import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, IconButton, Stack } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import PriceChangeRoundedIcon from '@mui/icons-material/PriceChangeRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import ExploreRoundedIcon from '@mui/icons-material/ExploreRounded';
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';

import { colors } from '../../../assets/styles/colors';
import { isCompanySuperAdmin } from '../../../shared/utils/rolesUtils';
import { useSelector } from '../../../store/reduxHooks';

const boxStyle = {
  width: '100%',
  padding: 1,
  paddingY: 0.6,
  borderRadius: 1,
  borderBottomWidth: 1,
  '&:hover': {
    bgcolor: colors.gray100,
    cursor: 'pointer',
  },
};

export default function SettingCard({ title, items }) {
  const navigate = useNavigate();
  const { user } = useSelector(s => s.auth.authUser);

  const getSettingIcon = (iconName: string) => {
    const style = { fontSize: 20, color: colors.theme1 };
    if (iconName === 'money-bill') {
      return <PriceChangeRoundedIcon sx={style} />;
    } else if (iconName === 'users') {
      return <PeopleAltRoundedIcon sx={style} />;
    } else if (iconName === 'th-list') {
      return <ViewListRoundedIcon sx={style} />;
    } else if (iconName === 'store') {
      return <StorefrontRoundedIcon sx={style} />;
    } else if (iconName === 'compass') {
      return <ExploreRoundedIcon sx={style} />;
    } else if (iconName === 'th-list') {
      return <ViewListRoundedIcon sx={style} />;
    } else if (iconName === 'cart-plus') {
      return <LocalMallRoundedIcon sx={style} />;
    } else if (iconName === 'building') {
      return <MeetingRoomRoundedIcon sx={style} />;
    } else if (iconName === 'user') {
      return <GroupAddRoundedIcon sx={style} />;
    } else if (iconName === 'user-cog') {
      return <ManageAccountsRoundedIcon sx={style} />;
    }
  };

  return (
    <Card sx={{ minHeight: 340 }}>
      <CardContent>
        <Typography
          color={colors.theme1}
          mb={1}
          pl={1}
          sx={{ fontWeight: 'bold' }}>
          {title.toUpperCase()}
        </Typography>

        <Stack
          mt={1}
          alignItems={'flex-start'}
          divider={<Divider orientation="vertical" flexItem />}>
          {items.map((item, index) => (
            <Box
              sx={{ ...boxStyle, borderBottomWidth: item.isLast ? 0 : 1 }}
              key={index}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                gap={3}
                justifyContent={'space-between'}>
                <Stack
                  p={1}
                  direction={'row'}
                  gap={2}
                  flex={1}
                  alignItems={'center'}
                  onClick={() => navigate(item.navigateTo)}>
                  <Box
                    sx={{
                      backgroundColor: colors.info100,
                      borderRadius: 100,
                      display: 'flex',
                      width: 34,
                      height: 34,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    {getSettingIcon(item.icon)}
                  </Box>
                  <Typography>{item.name}</Typography>
                </Stack>
                <IconButton
                  aria-label="add"
                  onClick={() => navigate(item.addNavigateTo)}
                  sx={{ p: 2, color: colors.blueGrey700 }}>
                  {item.id === 'business' || item.id === 'employee' ? (
                    isCompanySuperAdmin(user) ? (
                      <AddCircleRoundedIcon />
                    ) : (
                      <ChevronRightIcon />
                    )
                  ) : (
                    <AddCircleRoundedIcon />
                  )}
                </IconButton>
              </Stack>
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
