import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { css } from 'styled-components/macro'; //eslint-disable-line
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';

import Runway34, { Freeway } from './routes/MainRoutes';
import MainLayout from './layout/MainLayout';
import { useDispatch, useSelector } from './store/reduxHooks';
import { refreshAccessToken } from './store/authSlice';
import FullLoading from './components/feedbacks/FullLoading';
import UnderMaintenance from './components/utility/UnderMaintenance';
import LandingLayout from './landing/LandingLayout';

const queryClient = new QueryClient();

function App() {
  const { i18n } = useTranslation();

  const dispatch = useDispatch();
  const authState = useSelector(s => s.auth);
  const [working, setWorking] = useState(true);

  const { isAuthenticated, authUser } = authState;
  // const { remoteConfigs } = useSelector(state => state.config);
  const { expiresAt, user } = authUser;
  const locale = JSON.parse(localStorage.getItem('language'));

  const refreshUserSession = async () => {
    const usr = JSON.parse(localStorage.getItem('userSession'));
    // console.log('usr from localstorate:', usr);

    try {
      if (usr && usr.user && usr.companyAccount && usr.refreshToken) {
        await dispatch(refreshAccessToken(usr.refreshToken));
        // i18n.changeLanguage(usr.user.userSetting?.locale || 'en');
      }
    } finally {
      setWorking(false);
    }
  };

  const refreshUserToken = async () => {
    // console.log('==>>> refreshing the token...', new Date());
    console.log(new Date());
    await dispatch(refreshAccessToken(authUser.refreshToken));
  };

  useEffect(() => {
    i18n.changeLanguage(locale);
    refreshUserSession();
    // fetchRemoteConfig();
  }, []);

  useEffect(() => {
    let refreshAccessTokenTimerId: any;

    if (isAuthenticated) {
      const expiresIn = new Date(expiresAt).getTime() - Date.now() - 60 * 1000;
      // console.log('=> token refreshes in secs:', expiresIn / 1000, new Date());
      refreshAccessTokenTimerId = setTimeout(() => {
        refreshUserToken();
      }, expiresIn);
    }

    return () => {
      if (isAuthenticated && refreshAccessTokenTimerId) {
        clearTimeout(refreshAccessTokenTimerId);
      }
    };
  }, [expiresAt, isAuthenticated]);

  // console.log('=>>> user...', user);
  // console.log('=> isAuthenticated...', isAuthenticated);
  // console.log('=> locale...', locale);

  // if (remoteConfigs.underMaintenance) {
  if (false) {
    return <UnderMaintenance />;
  }

  if (working) {
    return <FullLoading />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        <React.Fragment>
          {isAuthenticated && user?.phoneVerified ? (
            <MainLayout>
              <Runway34 />
            </MainLayout>
          ) : (
            <LandingLayout>
              <Freeway />
            </LandingLayout>
          )}
        </React.Fragment>
      </SnackbarProvider>
    </QueryClientProvider>
  );
}

export default App;
