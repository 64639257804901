import i18next from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';
import { itemsAmountSum } from '../../shared/utils/amountSum';
import { getTaxAmount } from '../../shared/utils/vatAndDiscount';
import { ProductItemType } from '../../constants/defaultCodes/producttemTypes';
const { CREDIT_SALES, CASH_SALES } = voucherTypes;
const { CREDIT_INCOME, CASH_INCOME } = voucherTypes;

export const validateVoucherNo = async (data: any, errors: any) => {
  const voucherType = data.isFullPayment ? CASH_SALES : CREDIT_SALES;
  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateOtherIncomeVoucherNo = async (data: any, errors: any) => {
  const voucherType = data.isFullPayment ? CASH_INCOME : CREDIT_INCOME;
  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateInvoice = (data: any, errors: any) => {
  let isValid = true;

  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('voucher.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18next.t('invoice.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.isFullPayment && !data.customer) {
    errors = {
      ...errors,
      customer: i18next.t('voucher.validation.customerRequired'),
    };
    isValid = false;
  } else {
    delete errors.customer;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('voucher.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  if (!data.voucherItems.length) {
    errors = {
      ...errors,
      salesAmount: 'Sales amount is required',
    };
    isValid = false;
  } else {
    delete errors.salesAmount;
  }

  data.voucherItems.forEach((voucherItem: any) => {
    const accountField = `${voucherItem.lineNo}-account`;
    const amountField = `${voucherItem.lineNo}-amount`;
    const quantityField = `${voucherItem.lineNo}-quantity`;
    const discountField = `${voucherItem.lineNo}-discountPrice`;
    const pricePerUnitField = `${voucherItem.lineNo}-pricePerUnit`;

    if (!voucherItem.account) {
      errors = {
        ...errors,
        [accountField]: i18next.t('invoice.validation.invoiceAccountRequired'),
      };
      isValid = false;
    } else {
      delete errors[accountField];
    }

    if (!voucherItem.amount || voucherItem.amount <= 0) {
      errors = {
        ...errors,
        [amountField]: i18next.t('invoice.validation.invoiceAmountRequired'),
      };
      isValid = false;
    } else {
      delete errors[amountField];
    }

    if (voucherItem.quantity && !voucherItem.product) {
      errors = {
        ...errors,
        [quantityField]: 'Product is compulsory if quantity is added',
      };
      isValid = false;
    } else {
      delete errors[quantityField];
    }

    if (voucherItem.pricePerUnit && !voucherItem.product) {
      errors = {
        ...errors,
        [pricePerUnitField]:
          'Price per unit will be ignored if product is not selected',
      };
      isValid = false;
    } else {
      delete errors[pricePerUnitField];
    }

    /* TO HANDLE MINOR DIFFERENCES IN REAL DATA & PRESENTED IN UI */
    if (+voucherItem.discountPrice - (voucherItem.amount || 0) >= 1) {
      // if (voucherItem.discountPrice > (voucherItem.amount || 0)) {
      errors = {
        ...errors,
        [discountField]: 'Discount amount cannot be greater than sales amount',
      };
      isValid = false;
    } else {
      delete errors[discountField];
    }
  });

  if (
    data.discountAmount &&
    // +data.discountAmount - itemsAmountSum(data.voucherItems) >= 1
    data.discountAmount > itemsAmountSum(data.voucherItems)
  ) {
    errors = {
      ...errors,
      discountAmount: i18next.t('invoice.validation.invalidDiscount'),
    };
    isValid = false;
  } else {
    delete errors.discountAmount;
  }

  if (data.paidAmount) {
    const { isTaxable } = data;
    const discountAmount = +data.discountAmount || 0;
    const amountSum = itemsAmountSum(data.voucherItems);
    const taxAmount = getTaxAmount(isTaxable, discountAmount, amountSum, 13);

    const finalRawAmount = amountSum - discountAmount + (+taxAmount || 0);
    const finalAmount = +finalRawAmount.toFixed(2);

    // if (+data.paidAmount - finalAmount >= 1) {
    if (data.paidAmount > finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('invoice.validation.invalidPaidAmount'),
      };
      isValid = false;
    }

    if (!data.isFullPayment && data.paidAmount === finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('invoice.validation.isCashPayment'),
      };
      isValid = false;
    }
  } else {
    delete errors.paidAmount;
  }

  if ((data.paidAmount || data.isFullPayment) && !data.cashInAccount) {
    errors = {
      ...errors,
      cashInAccount: i18next.t('invoice.validation.cashInAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  return { isValid, errs: errors };
};

export const validateOtherIncome = (data: any, errors: any) => {
  let isValid = true;

  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('voucher.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18next.t('invoice.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.isFullPayment && !data.customer) {
    errors = {
      ...errors,
      customer: i18next.t('voucher.validation.customerRequired'),
    };
    isValid = false;
  } else {
    delete errors.customer;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('voucher.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  if (!data.voucherItems.length) {
    errors = {
      ...errors,
      amount: 'At least one Item with amount is required',
    };
    isValid = false;
  } else {
    delete errors.amount;
  }

  data.voucherItems.forEach((voucherItem: any) => {
    const accountField = `${voucherItem.lineNo}-account`;
    const amountField = `${voucherItem.lineNo}-amount`;

    if (!voucherItem.account) {
      errors = {
        ...errors,
        [accountField]: i18next.t('invoice.validation.invoiceAccountRequired'),
      };
      isValid = false;
    } else {
      delete errors[accountField];
    }

    if (!voucherItem.amount || voucherItem.amount <= 0) {
      errors = {
        ...errors,
        [amountField]: i18next.t('invoice.validation.invoiceAmountRequired'),
      };
      isValid = false;
    } else {
      delete errors[amountField];
    }
  });

  if (
    data.discountAmount &&
    data.discountAmount > itemsAmountSum(data.voucherItems)
  ) {
    errors = {
      ...errors,
      discountAmount: i18next.t('invoice.validation.invalidDiscount'),
    };
    isValid = false;
  } else {
    delete errors.discountAmount;
  }

  if (data.paidAmount) {
    const { isTaxable } = data;
    const discountAmount = +data.discountAmount || 0;
    const amountSum = itemsAmountSum(data.voucherItems);
    const taxAmount = getTaxAmount(isTaxable, discountAmount, amountSum, 13);

    const finalRawAmount = amountSum - discountAmount + (+taxAmount || 0);
    const finalAmount = +finalRawAmount.toFixed(2);

    // console.log('paidAmount xx:', data.paidAmount);
    // console.log('finalAmount xx:', finalAmount);

    if (data.paidAmount > finalAmount) {
      errors = {
        ...errors,
        paidAmount: 'Received Amount cannot be greater than income amount',
      };
      isValid = false;
    }

    if (!data.isFullPayment && data.paidAmount === finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('invoice.validation.isCashPayment'),
      };
      isValid = false;
    }
  } else {
    delete errors.paidAmount;
  }

  if ((data.paidAmount || data.isFullPayment) && !data.cashInAccount) {
    errors = {
      ...errors,
      cashInAccount: i18next.t('invoice.validation.cashInAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  return { isValid, errs: errors };
};

export const validateInvoiceItem = (
  data: any,
  errors: any,
  isSales?: boolean,
) => {
  let isValid = true;

  if (!data.account) {
    errors = {
      ...errors,
      account: i18next.t('invoice.validation.invoiceAccountRequired'),
    };
    isValid = false;
  } else {
    delete errors.account;
  }

  if (!data.product) {
    errors = {
      ...errors,
      product: 'Product is Required',
    };
    isValid = false;
  } else {
    delete errors.product;
  }

  if (!data.quantity) {
    errors = {
      ...errors,
      quantity: 'Product Quantity is Required',
    };
    isValid = false;
  } else if (data.quantity && !data.product) {
    errors = {
      ...errors,
      quantity: 'Product is compulsory if quantity is added',
    };
    isValid = false;
  } else {
    delete errors.quantity;
  }

  if (!data.amount || data.amount <= 0) {
    let message;
    if (isSales) {
      message = i18next.t('invoice.validation.invoiceAmountRequired');
    } else {
      message = 'Amount is required';
    }
    errors = { ...errors, amount: message };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.pricePerUnit && !data.product) {
    errors = {
      ...errors,
      pricePerUnit: 'Price per unit will be ignored if product is not selected',
    };
    isValid = false;
  } else {
    delete errors.pricePerUnit;
  }

  /* TO HANDLE MINOR DIFFERENCES IN REAL DATA & PRESENTED IN UI */
  if (+data.discountPrice - +data.amount >= 1) {
    // if (data.discountPrice > (data.amount || 0)) {
    errors = {
      ...errors,
      discountPrice: 'Discount amount cannot be greater than sales amount',
    };
    isValid = false;
  } else {
    delete errors.discountPrice;
  }

  if (
    isSales &&
    data.quantity &&
    data.productType === ProductItemType.product &&
    (!data.costsOfProduct || data.costsOfProduct <= 0)
  ) {
    errors = {
      ...errors,
      costsOfProduct:
        'Cost of purchase is required for sale with stock, to manage current stock value',
    };
    isValid = false;
  } else {
    delete errors.costsOfProduct;
  }

  return { isValid, errs: errors };
};
