import i18next from 'i18next';

import { colors } from '../../assets/styles/colors';

export const partyAmountColor = (code: string, balance: number) => {
  if (code === 'receivable') {
    return balance > 0 ? colors.green700 : colors.theme2;
  } else if (code === 'payable') {
    return balance > 0 ? colors.theme2 : colors.green700;
  }
  return colors.theme2;
};

export const partnerCreditTitle = (code: string) => {
  if (code === 'receivable') {
    return i18next.t('dashboardStack.receivable');
  } else if (code === 'payable') {
    return i18next.t('dashboardStack.payable');
  }
};

export const partnerNavigateTo = (code: string) => {
  if (code === 'receivable') {
    return '/receivables';
  } else if (code === 'payable') {
    return '/payables';
  }
};
