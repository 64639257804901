import { store } from '../../store/store';
import {
  advanceBillPaymentUrl,
  billPaymentUrl,
  billsUrl,
  getBillsToPayUrl,
  newBillUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getBills = async () => {
  const path = billsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const createNewBill = async (data: any) => {
  const path = newBillUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const advanceBillPayment = async (data: any) => {
  const path = advanceBillPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getBillsToPay = async (query: any) => {
  const path = getBillsToPayUrl();
  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const billPayment = async (data: any) => {
  const path = billPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const paymetnOutAPI = async (data: any) => {
  const path = v1('/transactions/purchases/payment-out/');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};
