import React, { useEffect, useState } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from '../../../store/reduxHooks';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import { getVoucherForEdit } from '../../../controllers/transactions/voucherControllers';
import { getCustomers } from '../../../controllers/settings/partnerControllers';
import {
  bankAndCashAccounts,
  incomeAccounts,
} from '../../../controllers/settings/accountControllers';
import { getCustomerBalance } from '../../../controllers/reports/partnerReports';
import {
  getProductItems,
  getProductUnits,
} from '../../../controllers/settings/inventoryControllers';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import {
  editOtherIncome,
  otherIncome,
} from '../../../controllers/transactions/incomeControllers';
import {
  validateOtherIncome,
  validateOtherIncomeVoucherNo,
} from '../../../validations/transactions/validatenewInvoice';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import Button from '../../../components/buttons/Button';
import AddNewLine from './components/AddNewLine';
import IncomeExpenseItem from '../purchases/components/IncomeExpenseItem';
import { colors } from '../../../assets/styles/colors';
import { incomeNavTitle } from '../../../shared/utils/transactionUtils';
import CashOrCreditSwitch from '../components/CashOrCreditSwitch';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import BillOrInvoiceSummary from '../components/BillOrInvoiceSummary';
import PaymentAmount from '../components/PaymentAmount';
import BillOrInvoiceDiscountAndTax from '../components/BillNInvoiceDiscountNTax';
import ImagePicker from '../../../components/image/ImagePicker';
import { createPDF, getPDFData } from '../../../shared/utils/createPDF';
import { salesAndPurchaseTemplate } from '../../../templates/salesAndPurchaseTemplate';
import { dateFileName } from '../../../shared/utils/getSettingBasedDate';

const invoiceVoucher = voucherTypes.CREDIT_INCOME;
const receiptVoucher = voucherTypes.CASH_INCOME;
const initial = { initialData: [] };
const initHPState = { show: false, customer: true, vendor: false };

function OtherIncome({ isUpdate = false }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { state: editVoucherId } = useLocation();
  const isEdit = Boolean(editVoucherId);

  if (isUpdate && !editVoucherId) {
    navigate('/journals');
    return null;
  }

  const { authUser } = useSelector(state => state.auth);

  const init1 = { voucherItems: [{ lineNo: 1 }], isFullPayment: true };
  const initState = { ...init1, voucherDate: new Date() };
  const [formData, setData] = useState<any>(initState);
  const [images, setImages] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [showDTS, setShowDTS] = useState<boolean>(false);
  const [customerReceivable, setCustomerReceivable] = useState<any>();

  const [handlePartner, setHandlePartner] = useState<any>({ ...initHPState });
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const { isFullPayment, paidAmount } = formData;

  const voucherType = isFullPayment ? receiptVoucher : invoiceVoucher;

  const vNoFunc = () => getVoucherNumber(voucherType);
  const getVNo = { enabled: !isEdit, cacheTime: 0 };

  const vNoQuery = useQuery(['getVoucherNo', voucherType], vNoFunc, getVNo);
  const { data: voucherNumber } = vNoQuery;

  const editFunc = () => getVoucherForEdit(editVoucherId);
  const fetchEV = { enabled: isEdit, cacheTime: 0 };
  const evQuery = useQuery(['editVoucher', editVoucherId], editFunc, fetchEV);
  const { data: editVoucher, isFetched: editTxnFetched } = evQuery;

  const customers = useQuery(['getCustomers'], getCustomers, initial);
  const accounts = useQuery(['incomeAccounts'], incomeAccounts, initial);
  const bankAndCash = useQuery(['bankAndCash'], bankAndCashAccounts, initial);
  const customerBalanceQuery = useMutation(getCustomerBalance);

  const prodItemFn = () => getProductItems({ isActive: true });
  const products = useQuery(['products'], prodItemFn, initial);
  const productUnits = useQuery(['productUnits'], getProductUnits, initial);

  useEffect(() => {
    if (isEdit) {
      if (editVoucher) {
        const voucherDate = new Date(editVoucher.voucherDate);
        editVoucher.voucherImages && setImages(editVoucher.voucherImages);
        delete editVoucher.voucherImages;
        setData((prev: any) => ({ ...prev, ...editVoucher, voucherDate }));
      }
    }
  }, [editTxnFetched]);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  useEffect(() => {
    if (isEdit && editVoucher) {
      if (editVoucher.isFullPayment !== isFullPayment) {
        vNoQuery.refetch();
      } else if (editVoucher.isFullPayment === isFullPayment) {
        const { referenceId, voucherNumber: vNo } = editVoucher;
        setData((pr: any) => ({ ...pr, voucherNumber: vNo, referenceId }));
      }
    }
  }, [isFullPayment]);

  const addIncome = useMutation(otherIncome);
  const editIncome = useMutation(editOtherIncome);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async (generatePDF: boolean) => {
    if (!isFullPayment && paidAmount <= 0 && formData.cashInAccount) {
      delete formData.cashInAccount;
    }

    const { isValid, errs } = validateOtherIncome(formData, errors);

    let otherValidity: any = { isVoucherNoValid: true };
    if (!isEdit) {
      otherValidity = await validateOtherIncomeVoucherNo(formData, errs);
    }

    if (isValid && otherValidity.isVoucherNoValid) {
      try {
        if (isUpdate && editVoucherId) {
          // console.log('updating voucher...');

          if (images && images.length && !images[0].id) {
            // console.log('is new image...');
            const imgPaylaod = { images, voucherType: 'incomes' };
            const image = await saveImage.mutateAsync(imgPaylaod);
            formData.image = image.id;
          }

          await editIncome.mutateAsync(formData);
          queryClient.invalidateQueries(['vouchers']);
          queryClient.invalidateQueries(['getVoucher']);
          queryClient.invalidateQueries(['invoicesToReceive']);
          queryClient.invalidateQueries(['partyDashledgers']);

          const msg = t(`salesIncome.incomeUpdateMsg.success-${isFullPayment}`);
          enqueueSnackbar(msg, successToast);
        } else {
          // console.log('creating voucher...');

          if (images && images.length) {
            const imgPaylaod = { images, voucherType: 'incomes' };
            const image = await saveImage.mutateAsync(imgPaylaod);
            formData.image = image.id;
          }

          await addIncome.mutateAsync(formData);

          const msg = t(`salesIncome.incomeCreateMsg.success-${isFullPayment}`);
          enqueueSnackbar(msg, successToast);
        }

        queryClient.invalidateQueries(['getDashboardReport']);
        generatePDF && (await onCreatePDF());
        navigate('/journals', { replace: true });
      } catch (err) {
        // console.error('err:', err);
        const cErr = t(`salesIncome.incomeCreateMsg.error-${isFullPayment}`);
        const uErr = t(`salesIncome.incomeUpdateMsg.error-${isFullPayment}`);
        let title = isEdit ? uErr : cErr;

        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }

        enqueueSnackbar(title, errorToast);
      }
    } else {
      isEdit && setErrors(errs);
      !isEdit && setErrors(otherValidity.errss);
      if (!otherValidity.isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    const updatedData = { ...formData, [name]: value };
    setData((prev: any) => ({ ...prev, [name]: value }));

    const { errs } = validateOtherIncome(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});

    if (name === 'customer' && value && formData.customer !== value) {
      const response = await customerBalanceQuery.mutateAsync(value);
      setCustomerReceivable({ type: 'CASH_IN', balance: response });
    } else if (name === 'customer' && !value) {
      setCustomerReceivable(null);
    }
  };

  const onFormItemValueChange = (
    name: string,
    value: string,
    lineNo: number,
  ) => {
    const myItem = formData.voucherItems.find(item => item.lineNo === lineNo);
    if (!myItem) {
      const payload = { [name]: value, lineNo };
      setData(p => ({ ...p, voucherItems: [...p.voucherItems, payload] }));
      return;
    }

    const voucherItems = formData.voucherItems.map(item => {
      if (item.lineNo === lineNo) {
        item[name] = value;
      }
      return item;
    });

    setData(prev => ({ ...prev, voucherItems: [...voucherItems] }));

    const updatedData = { ...formData, voucherItems };
    const { errs } = validateOtherIncome(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onAddNewLine = () => {
    let newLine = formData.voucherItems.length + 1;

    while (true) {
      const lineNo = formData.voucherItems.find(i => i.lineNo === newLine);
      if (!lineNo) {
        break;
      }
      newLine += 1;
    }

    const updatedData = {
      ...formData,
      voucherItems: [...formData.voucherItems, { lineNo: newLine }],
    };
    setData(updatedData);
  };

  const onRemoveLine = (lineNo: number) => {
    if (formData.voucherItems.length <= 1) {
      return;
    }

    const voucherItems = formData.voucherItems.filter(
      (item: any) => item.lineNo !== lineNo,
    );
    const updatedData = { ...formData, voucherItems: [...voucherItems] };
    setData(updatedData);
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const onCreatePDF = async () => {
    const data = {
      voucherType,
      user: authUser.user,
      customers: customers.data,
      accounts: accounts.data,
      products: products.data,
    };

    const templateData = getPDFData(formData, data);
    const template = salesAndPurchaseTemplate(templateData);

    const voucherNo = formData.voucherNumber.split('-').join('_');
    const fileName = `${voucherNo}_${dateFileName(
      authUser.user,
    )}`.toLowerCase();
    const source = await createPDF(template, fileName);

    setPdfSource(source);
  };

  return (
    <Container>
      <Card sx={{ paddingX: 2 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {incomeNavTitle(isEdit, isFullPayment)}
            </Typography>

            <CashOrCreditSwitch
              isFullPayment={isFullPayment}
              onToggle={v => onFormChange('isFullPayment', v)}
            />
          </Stack>

          <Divider />

          <Stack py={2} gap={2}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('salesIncome.label.voucherDate2')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  isDisabled={true}
                  isRequired={true}
                  defaultValue={formData.voucherNumber}
                  label={t('salesIncome.label.voucherNumber')}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  onChangeText={(v: string) => onFormChange('voucherNumber', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  defaultValue={formData.referenceId}
                  label={t('salesIncome.label.referenceId')}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
              <Grid xs={12}>
                <SelectPicker
                  isRequired={isFullPayment ? false : true}
                  label={t('salesIncome.label.customer')}
                  selectedValue={formData.customer}
                  selectItems={customers.data}
                  isInvalid={'customer' in errors}
                  helpText={'customer' in errors && errors.customer}
                  onValueChange={(v: any) => onFormChange('customer', v)}
                  // setHandles={setHandlePartner}
                  partnerBalance={customerReceivable && customerReceivable}
                />
              </Grid>
            </Grid>

            <Typography
              paddingX={1}
              borderRadius={1}
              sx={{ size: 15, fontWeight: 'bold' }}
              bgcolor={colors.info100}>
              Income Items
            </Typography>

            {formData.voucherItems.map((item, index) => (
              <IncomeExpenseItem
                key={item.lineNo}
                itemsLength={formData.voucherItems.length}
                voucherItem={item}
                lineNo={item.lineNo}
                accountItems={accounts.data}
                productItems={products.data}
                productUnits={productUnits.data}
                onRemoveLine={onRemoveLine}
                onFormValueChange={onFormItemValueChange}
                errors={errors}
                getHelpText={getHelpText}
                // zIndex={(index - 40) * -1}
                isOtherIncome
              />
            ))}

            <AddNewLine {...{ onAddNewLine, t }} />

            <BillOrInvoiceSummary
              t={t}
              voucherItems={formData.voucherItems}
              subTotalOnly
            />

            <BillOrInvoiceDiscountAndTax
              t={t}
              voucherItems={formData.voucherItems}
              discountAmount={formData.discountAmount}
              isTaxable={formData.isTaxable}
              onFormDataChange={onFormChange}
              isSales={true}
              errors={errors}
            />

            <PaymentAmount
              {...{ formData, onFormChange, errors, type: 'SALES', t }}
            />

            {(paidAmount > 0 || isFullPayment) && (
              <SelectPicker
                isRequired={formData.paidAmount || isFullPayment ? true : false}
                selectedValue={formData.cashInAccount}
                selectItems={bankAndCash.data}
                label={t('salesIncome.label.cashInAccount')}
                isInvalid={'cashInAccount' in errors}
                helpText={getHelpText('cashInAccount')}
                onValueChange={(v: any) => onFormChange('cashInAccount', v)}
              />
            )}

            <BillOrInvoiceSummary
              t={t}
              voucherItems={formData.voucherItems}
              discountAmount={formData.discountAmount}
              paidAmount={formData.paidAmount}
              isTaxable={formData.isTaxable}
              isFullPayment={formData.isFullPayment}
              errors={errors}
              isInvoice
            />

            <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
              <Stack flex={1}>
                <InputForm
                  inLineLabel={true}
                  defaultValue={formData.description}
                  label={t('salesIncome.label.description')}
                  isInvalid={'description' in errors}
                  helpText={'description' in errors && errors.description}
                  onChangeText={(v: string) => onFormChange('description', v)}
                />
              </Stack>
              <Stack pt={0.1}>
                <ImagePicker {...{ images, setImages }} />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title={isEdit ? 'Update & Get PDF' : 'Save & Get PDF'}
            loading={
              addIncome.isLoading || editIncome.isLoading || saveImage.isLoading
            }
            onClick={() => onSubmit(true)}
          />
          <Button
            title={isEdit ? 'Update Transaction' : 'Save Transaction'}
            loading={
              addIncome.isLoading || editIncome.isLoading || saveImage.isLoading
            }
            onClick={() => onSubmit(false)}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default OtherIncome;
