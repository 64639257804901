import {
  invoiceReceiveUrl,
  invoiceUrl,
  // creditSalesUrl,
  advanceReceiveUrl,
  getInvoicesToReceiveUrl,
} from '../endPoints';
import { store } from '../../store/store';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getInvoices = async () => {
  const path = invoiceUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

// export const createNewInvoice = async (data: any) => {
//   const path = creditSalesUrl();

//   const response = await httpClient().post<any>(path, data);
//   return response.data;
// };

export const advanceInvoiceReceive = async (data: any) => {
  const path = advanceReceiveUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getInvoicesToReceive = async (query: any) => {
  const path = getInvoicesToReceiveUrl();
  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const invoiceReceive = async (data: any) => {
  const path = invoiceReceiveUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const paymentInAPI = async (data: any) => {
  const path = v1('/transactions/sales/payment-in/');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};
