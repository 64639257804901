import { AxiosRequestConfig } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import HttpClientWithRetry from '../config/httpClient';

const initializeHttpClientWithToken = (user: {
  accessToken: string;
}): HttpClientWithRetry => {
  const client: HttpClientWithRetry = new HttpClientWithRetry();
  // console.log('yes init with new token', Boolean(user.accessToken));

  if (user.accessToken) {
    client.interceptors?.request.use(
      (config): AxiosRequestConfig => {
        config.headers = {
          Authorization: `Bearer ${user.accessToken}`,
        };
        return config;
      },
      async error => {
        return Promise.reject(error);
      },
    );
  }
  return client;
};

export interface HttpClient {
  client: HttpClientWithRetry;
  token: boolean;
}

const initialState: HttpClient = {
  client: new HttpClientWithRetry(),
  token: false,
};

export const httpSlice = createSlice({
  name: 'httpSlice',
  initialState,

  reducers: {
    setClient: (state, action) => {
      state.client = initializeHttpClientWithToken(action.payload);
      state.token = true;
    },

    removeClient: () => initialState,
  },
});

export const { setClient, removeClient } = httpSlice.actions;

export default httpSlice.reducer;
