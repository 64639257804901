export const validateAccount = (data, errors) => {
  let isValid = true;

  if (!data.code) {
    errors = { ...errors, code: 'Code is required' };
    isValid = false;
  } else if (data.code.length < 2) {
    errors = { ...errors, code: 'Code is too short' };
    isValid = false;
  } else {
    delete errors.code;
  }

  if (!data.name) {
    errors = { ...errors, name: 'Name is required' };
    isValid = false;
  } else if (data.name.length > 64) {
    errors = {
      ...errors,
      name: 'Name should be less than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.name;
  }

  if (
    data.description &&
    (data.description.length < 2 || data.description.length > 132)
  ) {
    errors = {
      ...errors,
      description: 'Description should be between 2 and 132 characters',
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.accountType === undefined) {
    errors = { ...errors, accountType: 'accountType is required' };
    isValid = false;
  } else {
    delete errors.accountType;
  }

  if (data.openingBalance && !data.openingDate) {
    errors = { ...errors, openingDate: 'Opening Date is required' };
    isValid = false;
  } else {
    delete errors.openingDate;
  }

  return { isValid, errors };
};
