export const queryStatus = (queries: any[]) => {
  const isLoading = queries.some(query => query.isLoading);
  const isFetching = queries.some(query => query.isFetching);
  const isError = queries.some(query => query.isError);

  return { isLoading, isError, isFetching: isFetching || isLoading };
};

/* USED FOR SCREENS WHERE LOADING AND ERROR CAN BE RENDERED BUT NOT EMPTY */
/* ALSO THE LOADING FOR THE FIST FETCH, QUERY WITH OUT INITOAL DATA OPTION */
export const reactQueryStatus = (queries: any[]) => {
  const isLoading = queries.some(query => query.isLoading);
  const isError = queries.some(query => query.isError);

  const isPrerender = isLoading || isError;

  return { isLoading, isError, isPrerender };
};

export const queryStatusWithLength = (queries: any[]) => {
  const isLoading = queries.some(query => query.isLoading);
  const fetching = queries.some(query => query.isFetching);
  const isError = queries.some(query => query.isError);
  const isEmpty = queries.some(query => !query.data?.length);

  const isFetching = fetching || isLoading;

  // NOT BEING USED
  const isPreRender = isLoading || isFetching || isError || isEmpty;

  return { isLoading, isError, isFetching, isEmpty, isPreRender };
};
