import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';
import {
  getFinalProductPrice,
  getTaxAmount,
} from '../../../../shared/utils/vatAndDiscount';
import { formatAmount } from '../../../../shared/utils/locale';
import { colors } from '../../../../assets/styles/colors';
import TransactionItemModal from './TransactionItemModal';

function TransactionItem({
  index,
  voucherItem,
  productItems,
  productUnits,
  onFormValueChange,
  onRemoveLine,
  setHandleProduct,
  isSales,
  isPurchase,
  isPurchaseReturn,
  isSalesReturn,
}: any) {
  const { t } = useTranslation();

  const [showItemModal, setShowItemModal] = useState<boolean>(false);

  const { product, quantity, discountPrice, ...rest } = voucherItem;
  const { productUnit, pricePerUnit, amount, lineNo, memo } = rest;
  const { taxable } = rest;

  const productItem = productItems.find(x => x.id === product);
  const prodUnit = productUnits.find(x => x.id === productUnit);

  const taxAmount = getTaxAmount(taxable, discountPrice, amount);
  const totalAmount = getFinalProductPrice(amount, discountPrice, taxable);

  // console.log('prodUnit?.onlyName', prodUnit?.onlyName);
  // console.log('showItemModal =>', showItemModal);

  return (
    <Box>
      <Grid
        onClick={() => setShowItemModal(true)}
        container
        px={0.4}
        borderBottom={1}
        borderColor={colors.gray300}
        sx={{ '&:hover': { bgcolor: colors.gray100, cursor: 'pointer' } }}
        // bgcolor={'pink'}
        py={2.6}>
        {/* <Grid xs={1}>
          <Typography>{index}</Typography>
        </Grid> */}
        <Grid xs={3}>
          <Typography>{productItem?.name ? productItem?.name : '—'}</Typography>
        </Grid>
        <Grid xs={2}>
          <Typography align="right">
            {`${quantity || 0} ${prodUnit?.onlyName || ''} x ${formatAmount(
              pricePerUnit || 0,
            )}`}
          </Typography>
        </Grid>
        <Grid xs={2}>
          <Typography align="right">{formatAmount(amount)}</Typography>
        </Grid>
        <Grid xs={1.5}>
          <Typography align="right">
            {formatAmount(discountPrice || 0)}
          </Typography>
        </Grid>
        <Grid xs={1.5}>
          <Typography align="right">{formatAmount(taxAmount || 0)}</Typography>
        </Grid>
        <Grid xs={2}>
          <Typography fontWeight={'bold'} align="right">
            {formatAmount(totalAmount)}
          </Typography>
        </Grid>

        {memo && (
          <Grid xs={12}>
            <Typography
              // p={0.5}
              // mt={1}
              borderRadius={1}
              fontStyle={'italic'}
              // bgcolor={colors.gray100}
            >
              NOTE: {memo}
            </Typography>
          </Grid>
        )}
      </Grid>

      {showItemModal && (
        <TransactionItemModal
          key={lineNo}
          showModal={showItemModal}
          setShowModal={setShowItemModal}
          voucherItem={voucherItem}
          productItems={productItems}
          productUnits={productUnits}
          onFormValueChange={onFormValueChange}
          onRemoveLine={onRemoveLine}
          setHandleProduct={setHandleProduct}
          isSales={isSales}
          isPurchase={isPurchase}
          isSalesReturn={isSalesReturn}
          isPurchaseReturn={isPurchaseReturn}
          isUpdate
        />
      )}
    </Box>
  );
}

export default TransactionItem;
