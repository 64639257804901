import { UserRole } from '../../constants/defaultCodes/userRoles';

export const isCompanySuperAdmin = (user: any) => {
  if (user?.userRole === UserRole.companySuperAdmin) {
    return true;
  }
  return false;
};

export const isSystemSuperAdmin = (user: any) => {
  if (user?.userRole === UserRole.systemSuperAdmin) {
    return true;
  }
  return false;
};
