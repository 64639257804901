import { store } from '../../store/store';
import {
  otherExpenseUrl,
  purchaseExpenseEditUrl,
  otherExpenseEditUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const purchaseExpense = async (data: any) => {
  const path = v1('/transactions/purchases/purchase-expense');
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const editPurchaseExpense = async (data: any) => {
  const path = purchaseExpenseEditUrl(data.id);
  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const otherExpense = async (data: any) => {
  const path = v1('/transactions/expenses/other-expense');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const editOtherExpense = async (data: any) => {
  const path = v1(`/transactions/expenses/other-expense/${data.id}`);

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};
