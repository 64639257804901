import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { colors } from '../../../assets/styles/colors';
// import { colors } from '../../../../assets/styles/colors';

const ItemListHeader = ({ itemsLen, title }: any) => {
  const textStyle = {
    size: 15,
    // color: colors.white,
    fontWeight: 'bold',
  };

  const textStyle2 = {
    ...textStyle,
    textAlign: 'right',
  };

  return (
    <Grid bgcolor={colors.info100} container px={1}>
      {itemsLen ? (
        <>
          <Grid xs={3}>
            <Typography sx={textStyle}>Product</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography sx={textStyle2}>Quantity x perUnit</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography sx={textStyle2}>Sub-Total</Typography>
          </Grid>
          <Grid xs={1.5}>
            <Typography sx={textStyle2}>Discount</Typography>
          </Grid>
          <Grid xs={1.5}>
            <Typography sx={textStyle2}>VAT</Typography>
          </Grid>
          <Grid xs={2}>
            <Typography sx={textStyle2}>Total</Typography>
          </Grid>
        </>
      ) : (
        <Grid xs={12}>
          <Typography sx={textStyle}>{title ? title : 'Bill Items'}</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ItemListHeader;
