import React from 'react';
import { Box, Typography } from '@mui/material';
// import { colors } from '../../assets/styles/colors';

const EmptyReport = ({ message }: any): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: '#FFF',
        // backgroundColor: 'red',
        minHeight: 480,
      }}>
      <Typography sx={{ fontSize: 22 }} color="text.primary">
        {message ? message : 'No records found!'}
      </Typography>
    </Box>
  );
};

export default EmptyReport;
