import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { getAccountingPeriods } from '../../controllers/settings/otherSettingControllers';
import DatePicker from '../datePickers/DatePicker';
import SelectPicker from './SelectPicker';
import { getThisNepaliMonthsFirstDay } from '../../shared/utils/nepaliDate';
import { useSelector } from '../../store/reduxHooks';
import { dateFormat } from '../../constants/defaultCodes/otherEnums';
import { dateFilters } from '../../constants/formFields/reports/reportFilters';

const initial = { initialData: [] };

const today = new Date();
const todayStart = today.setHours(0, 0, 0, 0); // set to 12 AM
// console.log('todayStart', todayStart);

const _30DaysAgo = new Date(todayStart - 30 * 24 * 60 * 60 * 1000);
export const _7DaysAgo = new Date(todayStart - 7 * 24 * 60 * 60 * 1000);
export const initialDate = { fromDate: _30DaysAgo, toDate: today };

export const THIS_MONTH = 'thisMonth';
export const LAST_30_DAYS = 'last30Days';
export const LAST_7_DAYS = 'last7Days';
export const CUSTOM = 'custom';
export const TODAY = 'today';
export const ALL = 'all'; // all should be used fro individual partner/item

const PeriodSelect = ({
  dateRange,
  onDateChange,
  selectedPeriod,
  pickerSize = 'small',
  horizontal = true,
  inLineLabel = true,
  enableAllSelect = false,
  startFromDate,
}: any): JSX.Element => {
  const { authUser } = useSelector(s => s.auth);
  const userDateFormat = authUser.user?.business.dateFormat;

  const { t } = useTranslation();
  // const toast = useToast();

  const toDateToday = new Date();

  const periodsOptions = [
    { name: t('periodSelect.options.today'), id: 'today' },
    { name: t('periodSelect.options.last7Days'), id: LAST_7_DAYS },
    { name: t('periodSelect.options.last30Days'), id: LAST_30_DAYS },
    { name: t('periodSelect.options.thisMonth'), id: THIS_MONTH },
    { name: t('periodSelect.options.customDate'), id: CUSTOM },
  ];

  const [periodsSelectOptions, setPeriodOptions] = useState(periodsOptions);
  const [fiscalPeriodsX, setFiscalPeriod] = useState([]);

  // const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
  // const yesterday = new Date('2022-04-01T08:27:00.000Z');

  const periodQuery = useQuery(['periods'], getAccountingPeriods, initial);
  const { data: fiscalPeriods = [], isFetched } = periodQuery;

  useEffect(() => {
    const hasAll = periodsOptions.find(x => x.id === ALL);
    if (enableAllSelect && !hasAll) {
      periodsOptions.unshift({ name: t('periodSelect.options.all'), id: ALL });
    }
    setPeriodOptions(periodsOptions);
  }, [enableAllSelect]);

  useEffect(() => {
    const serverOptions = [];
    for (const f of fiscalPeriods) {
      serverOptions.push({ id: f.id, name: f.name });
    }
    setFiscalPeriod(serverOptions);
  }, [isFetched]);

  useEffect(() => {
    onPeriodChange(selectedPeriod);
  }, [isFetched]);

  const onPeriodChange = (value: string) => {
    let fromDate;

    const todayEnd = toDateToday.setHours(23, 59, 59, 0); // set to 11:59 PM
    let toDate = new Date(todayEnd);

    const defaults = [
      ALL,
      TODAY,
      LAST_7_DAYS,
      LAST_30_DAYS,
      THIS_MONTH,
      CUSTOM,
    ];

    if (defaults.includes(value)) {
      switch (value) {
        case TODAY:
          var todayNow = new Date();
          todayNow.setHours(0, 0, 0, 0); // set to 12 AM
          fromDate = todayNow;
          break;
        case LAST_7_DAYS:
          fromDate = _7DaysAgo;
          break;
        case LAST_30_DAYS:
          fromDate = _30DaysAgo;
          break;
        case THIS_MONTH:
          const date = new Date();
          let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

          if (userDateFormat === dateFormat.BS) {
            firstDay = getThisNepaliMonthsFirstDay();
          }
          // console.log('firstDay', firstDay);

          fromDate = firstDay;
          break;
        case ALL:
          if (enableAllSelect) {
            fromDate = new Date(startFromDate);
          } else {
            fromDate = dateRange.fromDate;
          }
          break;

        case CUSTOM:
          fromDate = dateRange.fromDate;
          break;
      }
    } else {
      const period = fiscalPeriods.find(x => x.id === Number(value));

      fromDate = new Date(period?.startDate);
      toDate = new Date(period?.endDate);
    }
    onDateChange({ fromDate, toDate }, value);
  };

  const onDateValueChange = (name: string, value: any) => {
    let newDateRange = { ...dateRange, [name]: value };

    if (name === 'fromDate' && value > dateRange.toDate) {
      newDateRange = { ...initialDate };
      const toastTitle = t('periodSelect.validation.fromDateIsBigger');
      // toast.show({ duration: 2000, title: toastTitle });
    }

    if (name === 'toDate' && value) {
      const todayEnd = value.setHours(23, 59, 59, 0); // set to 11:59 PM
      const toDate = new Date(todayEnd);
      newDateRange[name] = toDate;
    }

    // No date range selection of more than 1 year
    let diff = newDateRange.toDate.getTime() - newDateRange.fromDate.getTime();
    // if (diff / 1000 > 31536000) {
    // 90 DAYS RANGE
    if (diff / 1000 > 7776000) {
      newDateRange = { ...initialDate };
      const toastTitle = t('periodSelect.validation.dateOutOfRange');
      // toast.show({ duration: 2000, title: toastTitle });
    }

    onDateChange(newDateRange, 'custom');
  };

  return (
    <Grid
      container
      rowSpacing={{ xs: 1 }}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid xs={horizontal ? 4 : 12}>
        <SelectPicker
          size={pickerSize}
          inLineLabel={inLineLabel}
          // inLineLabel={horizontal ? true : false}
          disableClearable={true}
          label={t('periodSelect.label.timePeriod')}
          placeHolder={t('periodSelect.label.timePeriod')}
          selectedValue={selectedPeriod}
          // selectItems={periodsSelectOptions}
          selectItems={periodsSelectOptions.concat(fiscalPeriodsX)}
          onValueChange={(v: string) => onPeriodChange(v)}
        />
      </Grid>
      {dateFilters.map(({ filter }) => (
        <Grid xs={horizontal ? 4 : 6} key={filter}>
          <DatePicker
            size={pickerSize}
            value={dateRange[filter]}
            inLineLabel={inLineLabel}
            label={t(`periodSelect.label.${filter}`)}
            onDateChange={(v: any) => onDateValueChange(filter, v)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PeriodSelect;
