import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { Card, CardActionArea, CardContent } from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';

import { useSelector } from '../../../store/reduxHooks';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import { useQuery } from '@tanstack/react-query';
import { getVoucher } from '../../../controllers/transactions/voucherControllers';
import { EntryType } from '../../../constants/defaultCodes/voucherEntryTypes';
import { normalBalance } from '../../../constants/defaultCodes/accountTypeCodes';
import Loading from '../../../components/feedbacks/Loading';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import ImagePreview from '../../../components/image/ImagePreview';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: '50%',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const TransactionDetailModal = ({
  showModal,
  onClose,
  voucherId: vId,
}: any): JSX.Element => {
  const { user } = useSelector(state => state.auth.authUser);
  const { t } = useTranslation();

  const [showPreview, setShowPreview] = useState<boolean>(false);

  const query = useQuery(['getVoucher', vId], () => getVoucher(vId));
  const { data: voucher, isLoading, isSuccess } = query;
  const voucherItems: any = voucher?.voucherItems || [];

  const hasProduct = Boolean(voucherItems.find(x => x.product));
  const hasVendor = Boolean(voucherItems.find(x => x.vendor));
  const hasCustomer = Boolean(voucherItems.find(x => x.customer));
  const hasExpense = Boolean(voucherItems.find(x => x.expenseItem));
  const hasLoanPrt = Boolean(voucherItems.find(x => x.loanPartner));
  const hasEmployee = Boolean(voucherItems.find(x => x.employee));

  const hasAssignedItem = () => {
    const hasItem1 = hasVendor || hasCustomer;
    const hasItem2 = hasLoanPrt || hasEmployee;
    return hasItem1 || hasItem2;
  };

  const getAssignedItemTitle = (title: string) => {
    const lending = [EntryType.recieveLentLoan, EntryType.lendLoan];
    const borrwng = [EntryType.payBorrowedLoan, EntryType.borrowLoan];

    if (title === 'Customer' && lending.includes(voucher.entryType)) {
      return 'Money Lent Party';
    } else if (title === 'Vendor' && borrwng.includes(voucher.entryType)) {
      return 'Money Borrowed Party';
    }
    return title;
  };

  const getVoucherAmount = (voucherItem: any) => {
    const accountTypeCode = voucherItem.account.accountType.code;
    if (normalBalance(accountTypeCode).isDrBalance) {
      return voucherItem.debit - voucherItem.credit;
    } else if (normalBalance(accountTypeCode).isCrBalance) {
      return voucherItem.credit - voucherItem.debit;
    }
  };

  const renderAssignedItem = (title: string, field: string) => {
    const subField = field === 'employee' ? 'firstName' : 'name';
    return (
      <Stack>
        <Typography>{getAssignedItemTitle(title)}</Typography>
        <Typography>
          {voucherItems.find(x => x[field])[field][subField]}
        </Typography>
      </Stack>
    );
  };

  // console.log('voucher: ', voucher);

  return (
    <Modal
      open={showModal}
      onClose={onClose}
      aria-labelledby="txn-detail"
      aria-describedby="txn-detail-des">
      <Box sx={style}>
        <Stack pb={2} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography
            sx={{ fontSize: 18, color: colors.red500, fontWeight: 'bold' }}>
            Transaction Detail
          </Typography>
          {/* <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
            #{voucher.voucherNumber}
          </Typography> */}
        </Stack>
        <Divider />

        {isLoading ? (
          <Loading minHeight={220} />
        ) : (
          <Stack py={1} gap={1}>
            <Grid
              container
              rowSpacing={{ xs: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <Stack>
                  <Typography>Transaction Type</Typography>
                  <Typography>{voucher.voucherType.name}</Typography>
                </Stack>
              </Grid>

              <Grid xs={4}>
                <Typography>Transaction Date</Typography>
                <Typography>
                  {getSettingBasedDate(voucher.voucherDate, user)}
                </Typography>
              </Grid>

              <Grid xs={4}>
                <Typography>Status</Typography>
                <Typography>
                  {voucher.isCancelled ? 'CANCELLED' : 'POSTED'}
                </Typography>
              </Grid>

              <Grid xs={4}>
                <Stack>
                  <Typography>Voucher No.</Typography>
                  <Typography>{voucher.voucherNumber}</Typography>
                </Stack>
              </Grid>

              <Grid xs={4}>
                <Stack>
                  <Typography>Ref No.</Typography>
                  <Typography>{voucher.referenceId || 'N/A'}</Typography>
                </Stack>
              </Grid>

              <Grid xs={4}>
                {voucher.voucherImages?.length > 0 && (
                  <CardActionArea
                    sx={{ width: 50, height: 50 }}
                    onClick={() => setShowPreview(true)}>
                    {voucher.voucherImages.map((img, index) => (
                      <img
                        key={index}
                        alt={`img-{index}`}
                        style={{ width: '100%', height: 'auto' }}
                        src={img.uri}
                      />
                    ))}
                  </CardActionArea>
                )}
              </Grid>

              <Grid xs={12}>
                {hasAssignedItem() && (
                  <>
                    {hasVendor && renderAssignedItem('Vendor', 'vendor')}
                    {hasCustomer && renderAssignedItem('Customer', 'customer')}
                    {hasLoanPrt &&
                      renderAssignedItem('Loan Partner', 'loanPartner')}
                    {hasEmployee && renderAssignedItem('Employee', 'employee')}
                  </>
                )}
              </Grid>

              {voucher.description?.length > 0 && (
                <Grid xs={12}>
                  <Stack>
                    <Typography>Description</Typography>
                    <Typography>{voucher.description}</Typography>
                  </Stack>
                </Grid>
              )}
            </Grid>

            <TableContainer sx={{ marginTop: 1 }}>
              <Table aria-label="table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 16 }}>LN</TbCellHeader>
                    <TbCellHeader>Account</TbCellHeader>

                    {hasProduct && <TbCellHeader>Product</TbCellHeader>}
                    {hasExpense && <TbCellHeader>ExpenseItem</TbCellHeader>}
                    <TbCellHeader align="right">Debit</TbCellHeader>
                    <TbCellHeader align="right">Credit</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {voucher.voucherItems.map((item, index) => (
                    <TableRowStyled key={index}>
                      <TbCellBody sx={{ maxWidth: 16 }}>{index + 1}</TbCellBody>
                      <TbCellBody>{item.account.name}</TbCellBody>
                      {hasProduct && (
                        <TbCellBody>{item.product?.name}</TbCellBody>
                      )}
                      {hasExpense && (
                        <TbCellBody>{item.expenseItem?.name}</TbCellBody>
                      )}
                      <TbCellAmount>{formatAmount(item.debit)}</TbCellAmount>
                      <TbCellAmount>{formatAmount(item.credit)}</TbCellAmount>
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        )}

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onClose}>
            Close
          </Button>
        </Stack>

        {showPreview && (
          <ImagePreview
            serverUri={true}
            images={voucher.voucherImages}
            showModal={showPreview}
            onClose={() => setShowPreview(false)}
          />
        )}
      </Box>
    </Modal>
  );
};

export default TransactionDetailModal;
