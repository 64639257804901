import i18next from 'i18next';

export const salesNavTitle = (isEdit: boolean, isFullPayment: boolean) => {
  if (!isEdit) {
    if (isFullPayment) {
      return i18next.t('transactionStack.cashSales');
    } else {
      return i18next.t('transactionStack.creditSales');
    }
  } else {
    return i18next.t('transactionStack.salesUpdate');
  }
};

export const incomeNavTitle = (isEdit: boolean, isFullPayment: boolean) => {
  if (!isEdit) {
    if (isFullPayment) {
      return i18next.t('transactionStack.cashIncome');
    } else {
      return i18next.t('transactionStack.creditIncome');
    }
  } else {
    return i18next.t('transactionStack.incomeUpdate');
  }
};

export const purchaseNavTitle = (isEdit: boolean, isFullPayment: boolean) => {
  if (!isEdit) {
    if (isFullPayment) {
      return i18next.t('transactionStack.cashPurchase');
    } else {
      return i18next.t('transactionStack.creditPurchase');
    }
  } else {
    return i18next.t('transactionStack.purchaseUpdate');
  }
};

export const expenseNavTitle = (isEdit: boolean, isFullPayment: boolean) => {
  if (!isEdit) {
    if (isFullPayment) {
      return i18next.t('transactionStack.cashExpense');
    } else {
      return i18next.t('transactionStack.creditExpense');
    }
  } else {
    return i18next.t('transactionStack.expenseUpdate');
  }
};

export const productNavTitle = (isEdit: boolean, isProduct: boolean) => {
  if (!isEdit) {
    if (isProduct) {
      return i18next.t('settingStack.addProduct');
    } else {
      return i18next.t('settingStack.addService');
    }
  } else {
    return i18next.t('settingStack.updateProduct');
  }
};
