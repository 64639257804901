import { userRoles } from '../../constants/defaultCodes/userRoles';
import { emailIsValid, phoneNoIsValid } from '../common/commonValidations';

export const validateEmployee = (data, errors) => {
  let isValid = true;

  if (data.appUser) {
    if (!data.email) {
      errors = { ...errors, email: 'Email is required' };
      isValid = false;
    } else if (data.email.length < 2 || data.email.length > 64) {
      errors = {
        ...errors,
        email: 'Email should be between 3 and 64 characters',
      };
      isValid = false;
    } else if (!emailIsValid(data.email)) {
      errors = { ...errors, email: 'Invalid email' };
      isValid = false;
    } else {
      delete errors.email;
    }
  }

  if (!data.phoneNumber) {
    errors = { ...errors, phoneNumber: 'Phone Number is required' };
    isValid = false;
  } else if (data.phoneNumber && !phoneNoIsValid(data.phoneNumber)) {
    errors = { ...errors, phoneNumber: 'Invalid phone number' };
    isValid = false;
  } else {
    delete errors.phoneNumber;
  }

  if (!data.firstName) {
    errors = { ...errors, firstName: 'first name is required' };
    isValid = false;
  } else if (data.firstName.length > 64) {
    errors = {
      ...errors,
      firstName: 'first name should be less than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.firstName;
  }

  if (data.lastName === undefined) {
    errors = { ...errors, lastName: 'last name is required' };
    isValid = false;
  } else if (data.lastName.length > 64) {
    errors = {
      ...errors,
      lastName: 'last name should be less than 64 characters',
    };
    isValid = false;
  } else {
    delete errors.lastName;
  }

  if (!data.businesses.length) {
    errors = {
      ...errors,
      businesses: 'At least one permitted business is required',
    };
    isValid = false;
  } else {
    delete errors.businesses;
  }

  if (data.appUser && !data.userRole) {
    errors = {
      ...errors,
      userRole: 'User role is required for app user',
    };
    isValid = false;
  } else if (
    data.appUser &&
    data.userRole &&
    !userRoles.find(x => x.id === data.userRole)
  ) {
    errors = {
      ...errors,
      userRole: 'Valid User role is required for app user',
    };
    isValid = false;
  } else {
    delete errors.userRole;
  }

  return { isValid, errors };
};
