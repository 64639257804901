export const purchaseItemSelectFields = [
  { fieldName: 'account', required: true, flex: 5, enableCreate: false },
  { fieldName: 'product', required: false, flex: 4, enableCreate: true },
];

export const purchaseItemInputFields = [
  { fieldName: 'memo', required: false, flex: 5 },
  { fieldName: 'amount', required: true, flex: 4 },
];

export const productStockSelectFields = [
  { fieldName: 'productUnit', required: false },
];

export const productStockInputFields = [
  { fieldName: 'quantity', required: false },
  { fieldName: 'pricePerUnit', required: false },
  { fieldName: 'discountPrice', required: false },
];

export const expenseItemSelectFields = [
  { fieldName: 'account', required: true, flex: 5, enableCreate: false },
  { fieldName: 'expenseItem', required: false, flex: 4, enableCreate: true },
];

export const incomeItemSelectFields = [
  { fieldName: 'account', required: true, flex: 5, enableCreate: false },
  {
    fieldName: 'product',
    required: false,
    flex: 4,
    enableCreate: true,
  },
];
