export const validatePartnerLedgerFilters = (data: any, errors: any) => {
  let isValid = true;

  if (!data.fromDate) {
    errors = { ...errors, fromDate: 'From date is required' };
    isValid = false;
  } else {
    delete errors.fromDate;
  }

  if (!data.toDate) {
    errors = { ...errors, toDate: 'To Date is required' };
    isValid = false;
  } else {
    delete errors.toDate;
  }

  if (
    !data.allVendors &&
    !data.allCustomers &&
    !data.allLoanPartners &&
    !data.partner
  ) {
    errors = { ...errors, partner: 'At least one partner should be selected' };
    isValid = false;
  } else {
    delete errors.partner;
  }

  return { isValid, errs: errors };
};

export const validatePartnerProductTxn = (data: any, errors: any) => {
  let isValid = true;

  if (!data.fromDate) {
    errors = { ...errors, fromDate: 'From date is required' };
    isValid = false;
  } else {
    delete errors.fromDate;
  }

  if (!data.toDate) {
    errors = { ...errors, toDate: 'To Date is required' };
    isValid = false;
  } else {
    delete errors.toDate;
  }

  if (!data.allVendors && !data.allCustomers && !data.partner) {
    errors = { ...errors, partner: 'At least one partner should be selected' };
    isValid = false;
  } else {
    delete errors.partner;
  }

  return { isValid, errs: errors };
};
