import React from 'react';
import Box from '@mui/material/Box';
import { Avatar, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LockOutlined } from '@mui/icons-material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import { colors } from '../../../assets/styles/colors';
import { formattedAmountDashboard } from '../../../shared/utils/locale';

function DashboardCard({
  code,
  title,
  balance,
  iconName,
  closing,
  nonClosing,
  closingAssets,
  currentPeriod,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const boxStyle = {
    // height: { lg: 120, xl: 140 },
    marginY: { lg: 2, md: 1, sm: 0.4, xs: 0.4 },
    paddingY: 3,
    paddingX: 0.5,
    borderRadius: 1,
    // boxShadow: 4,
    // display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    bgcolor: colors.info50,
    '&:hover': {
      bgcolor: colors.gray200,
      cursor: 'pointer',
      color: colors.darker3,
    },
  };

  const textStyle: any = {
    fontSize: 15,
    color: colors.theme1,
    '&:hover': {
      color: colors.darker3,
    },
    // fontWeight: '600',
  };

  const getAmountColor = () => {
    const inc = ['revenue', 'receivable'];
    const exp = ['expense', 'payable'];
    const cashNProg = ['bankAndCash', 'netProfit'];

    if (inc.includes(code)) {
      return colors.green600;
    } else if (exp.includes(code)) {
      return colors.theme2;
    } else if (cashNProg.includes(code)) {
      return colors.theme1;
    } else {
      // return '#333';
      return colors.theme1;
    }
  };

  const getInfoText = () => {
    if (nonClosing) {
      return `0${currentPeriod?.year} Q${currentPeriod?.quarter}`;
    } else if (closingAssets) {
      return t('dashboard.subTitle.todaysBalance');
    } else if (closing) {
      return t('dashboard.subTitle.asOfToday');
    } else if (code === 'stockValue') {
      return t('dashboard.subTitle.todaysValue');
    }
  };

  const handleNavigation = () => {
    const data = { currentPeriod, title, balance, code };
    if (code === 'receivable') {
      navigate('/receivables');
    } else if (code === 'payable') {
      navigate('/payables');
    } else {
      navigate('/account-balance', { state: data });
    }
  };

  const amount = balance?.totalBalance;
  const amountFormatted = formattedAmountDashboard(amount);

  return (
    <Box sx={boxStyle} onClick={handleNavigation} gap={1}>
      <Stack alignItems={'center'}>
        {/* <ExpandCircleDownIcon sx={{ mb: 1 }} /> */}
        <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
          {title.toUpperCase()}
        </Typography>
        <Typography sx={{ fontSize: 15, mt: -0.5 }}>{getInfoText()}</Typography>
      </Stack>

      <Stack flexDirection={'row'} justifyContent={'center'}>
        {/* <Typography
          alignSelf={'flex-end'}
          sx={{ fontSize: 36, color: getAmountColor() }}>
          RS
        </Typography> */}

        <Typography
          sx={{
            fontSize: 28,
            fontWeight: 'bold',
            color: getAmountColor(),
          }}>
          {amountFormatted}
        </Typography>
      </Stack>
    </Box>
  );
}

export default DashboardCard;
