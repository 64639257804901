export const partnerInputFields = [
  { fieldName: 'name', required: true, numberic: false },
  { fieldName: 'phoneNumber', required: false, numberic: true },
  { fieldName: 'address', required: false, numberic: false },
  { fieldName: 'email', required: false, numberic: false },
  { fieldName: 'panNumber', required: false, numberic: true },
];

export const partnerSelectFields = [
  { fieldName: 'accountPayable', required: false },
  { fieldName: 'accountReceivable', required: false },
];

export const partnerSwitchFields = [
  { fieldName: 'isCustomer', required: true },
  { fieldName: 'isVendor', required: true },
];

export const partnerUpdateSwitchFields = [
  { fieldName: 'isCustomer', required: true, disabled: true },
  { fieldName: 'isVendor', required: true, disabled: true },
  { fieldName: 'isActive', required: false, disabled: false }, // commneted until inactive is implemented
];

export const lnPartnerInputFields = [
  { fieldName: 'code', required: true, disabled: true }, // auto generate code by system
  { fieldName: 'name', required: true, disabled: false },
  { fieldName: 'phoneNumber', required: false, disabled: false },
  { fieldName: 'address', required: false, disabled: false },
];

export const loanPartnerUpdateInputFields = [
  ...partnerInputFields,
  // { fieldName: 'address', required: false, disabled: false },
  // { fieldName: 'email', required: false, disabled: false },
];

export const loanPartnerUpdateSwitchFields = [
  { fieldName: 'isActive', required: false },
];
