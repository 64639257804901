import React, { useEffect, useState } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import DatePicker from '../../../components/datePickers/DatePicker';
import { useTranslation } from 'react-i18next';
import TransactionItemModal from './components/TransactionItemModal';
import { useSelector } from '../../../store/reduxHooks';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { defaultAccountCodes } from '../../../constants/defaultCodes/accountDefaultCodes';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import { getVoucherForEdit } from '../../../controllers/transactions/voucherControllers';
import {
  accountByDefCode,
  bankAndCashAccounts,
} from '../../../controllers/settings/accountControllers';
import { getCustomers } from '../../../controllers/settings/partnerControllers';
import {
  getProductItems,
  getProductUnits,
} from '../../../controllers/settings/inventoryControllers';
import { getCustomerBalance } from '../../../controllers/reports/partnerReports';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import {
  editSalesIncome,
  salesIncome,
} from '../../../controllers/transactions/incomeControllers';
import {
  validateInvoice,
  validateVoucherNo,
} from '../../../validations/transactions/validatenewInvoice';
import TransactionItem from './components/TransactionItem';
import AddNewLine from './components/AddNewLine';
import Button from '../../../components/buttons/Button';
import CashOrCreditSwitch from '../components/CashOrCreditSwitch';
import { salesNavTitle } from '../../../shared/utils/transactionUtils';
import ItemListHeader from '../components/ItemListHeader';
import TransactionError from './components/TransactionError';
import PaymentAmount from '../components/PaymentAmount';
import BillOrInvoiceSummary from '../components/BillOrInvoiceSummary';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import ImagePicker from '../../../components/image/ImagePicker';
import { createPDF, getPDFData } from '../../../shared/utils/createPDF';
import { salesAndPurchaseTemplate } from '../../../templates/salesAndPurchaseTemplate';
import { dateFileName } from '../../../shared/utils/getSettingBasedDate';

const { INCOME_FROM_SALES } = defaultAccountCodes;
const invoiceVoucher = voucherTypes.CREDIT_SALES;
const receiptVoucher = voucherTypes.CASH_SALES;
const initial = { initialData: [] };
const initHPState = { show: false, customer: true, vendor: false };

function SalesIncome({ isUpdate = false }) {
  const { user } = useSelector(state => state.auth.authUser);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state: editVoucherId } = useLocation();
  const isEdit = Boolean(editVoucherId);

  if (isUpdate && !editVoucherId) {
    navigate('/journals');
    return null;
  }

  const now = new Date();
  const initState = { voucherDate: now, voucherItems: [], isFullPayment: true };
  const [formData, setData] = useState<any>(initState);
  const [images, setImages] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [showDTS, setShowDTS] = useState<boolean>(false);
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const [activeLineNo, setActiveLineNo] = useState<number>(1);
  const [customerReceivable, setCustomerReceivable] = useState<any>();

  const [handlePartner, setHandlePartner] = useState<any>({ ...initHPState });
  const [handleProduct, setHandleProduct] = useState<any>({ show: false });
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const { isFullPayment, paidAmount } = formData;

  const voucherType = isFullPayment ? receiptVoucher : invoiceVoucher;
  const vNoFunc = () => getVoucherNumber(voucherType);
  const getVNo = { enabled: !isEdit, cacheTime: 0 };

  const vNoQuery = useQuery(['getVoucherNo', voucherType], vNoFunc, getVNo);
  const { data: voucherNumber } = vNoQuery;

  const editFunc = () => getVoucherForEdit(editVoucherId);
  const options = { enabled: isEdit, cacheTime: 0 };
  const evQuery = useQuery(['editVoucher', editVoucherId], editFunc, options);
  const { data: editVoucher, isFetched: editTxnFetched } = evQuery;

  const accountByCodeFn = () => accountByDefCode(INCOME_FROM_SALES);
  const { data: defAccount } = useQuery(['incomeAccount'], accountByCodeFn);

  const customers = useQuery(['getCustomers'], getCustomers, initial);

  const prodItemFn = () => getProductItems({ isActive: true });
  const products = useQuery(['products'], prodItemFn, initial);
  const customerBalanceQuery = useMutation(getCustomerBalance);

  const bankAndCashQry = useQuery(['bankAndCash'], bankAndCashAccounts);
  const { data: bankAndCash = [] } = bankAndCashQry;
  const productUnitsQry = useQuery(['productUnits'], getProductUnits);
  const { data: productUnits = [] } = productUnitsQry;

  // console.log('editTxnFetched...', editTxnFetched);

  useEffect(() => {
    if (isEdit) {
      // console.log('yes is edit vitra...');

      if (editVoucher) {
        const voucherDate = new Date(editVoucher.voucherDate);
        editVoucher.voucherImages && setImages(editVoucher.voucherImages);
        delete editVoucher.voucherImages;
        setData((prev: any) => ({ ...prev, ...editVoucher, voucherDate }));

        if (editVoucher.discountAmount || editVoucher.isTaxable) {
          setShowDTS(true);
        }
      }
    }
  }, [editTxnFetched]);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  useEffect(() => {
    if (isEdit && editVoucher) {
      if (editVoucher.isFullPayment !== isFullPayment) {
        vNoQuery.refetch();
      } else if (editVoucher.isFullPayment === isFullPayment) {
        const { referenceId, voucherNumber: vNo } = editVoucher;
        setData((pr: any) => ({ ...pr, voucherNumber: vNo, referenceId }));
      }
    }
  }, [isFullPayment]);

  const addSales = useMutation(salesIncome);
  const editSales = useMutation(editSalesIncome);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async (generatePDF: boolean) => {
    if (!isFullPayment && paidAmount <= 0 && formData.cashInAccount) {
      delete formData.cashInAccount;
    }

    const { isValid, errs } = validateInvoice(formData, errors);

    let otherValidity: any = { isVoucherNoValid: true };
    !isEdit && (otherValidity = await validateVoucherNo(formData, errs));

    if (isValid && otherValidity.isVoucherNoValid) {
      try {
        if (isUpdate && editVoucherId) {
          // console.log('updating voucher...');
          // console.log('images images...', images);

          if (images && images.length && !images[0].id) {
            // console.log('is new image...');

            const imgPaylaod = { images, voucherType: 'sales' };
            const image = await saveImage.mutateAsync(imgPaylaod);
            formData.image = image.id;
          }

          await editSales.mutateAsync(formData);
          queryClient.invalidateQueries(['vouchers']);
          queryClient.invalidateQueries(['getVoucher']);
          queryClient.invalidateQueries(['invoicesToReceive']);
          queryClient.invalidateQueries(['partyDashledgers']);

          const msg = t(`salesIncome.updateMsg.success-${isFullPayment}`);
          enqueueSnackbar(msg, successToast);
        } else {
          // console.log('creating voucher...');

          if (images && images.length) {
            const imgPaylaod = { images, voucherType: 'sales' };
            const image = await saveImage.mutateAsync(imgPaylaod);
            formData.image = image.id;
          }

          await addSales.mutateAsync(formData);

          const msg = t(`salesIncome.createMsg.success-${isFullPayment}`);
          enqueueSnackbar(msg, successToast);
        }

        queryClient.invalidateQueries(['getDashboardReport']);
        generatePDF && (await onCreatePDF());
        navigate('/journals', { replace: true });
      } catch (err) {
        // console.error('err:', err?.response?.data.message);
        const createMsg = t(`salesIncome.createMsg.error-${isFullPayment}`);
        const updateMsg = t(`salesIncome.updateMsg.error-${isFullPayment}`);
        let title = isEdit ? updateMsg : createMsg;

        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }

        enqueueSnackbar(title, errorToast);
      }
    } else {
      isEdit && setErrors(errs);
      !isEdit && setErrors(otherValidity.errss);
      if (!otherValidity.isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    setData((prev: any) => ({ ...prev, [name]: value }));

    const { errs } = validateInvoice({ ...formData, [name]: value }, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});

    if (name === 'customer' && value && formData.customer !== value) {
      const response = await customerBalanceQuery.mutateAsync(value);
      setCustomerReceivable({ type: 'CASH_IN', balance: response });
    } else if (name === 'customer' && !value) {
      setCustomerReceivable(null);
    }
  };

  const onFormItemValueChange = (data: any, lineNo: number) => {
    let myItem = formData.voucherItems.find(item => item.lineNo === lineNo);

    if (!myItem) {
      const vItems = [...formData.voucherItems, { ...data, lineNo }];
      setData(prev => ({ ...prev, voucherItems: vItems }));
      return;
    }

    const voucherItems = formData.voucherItems.map((item: any) => {
      item.lineNo === lineNo && (item = { ...myItem, ...data });
      return item;
    });

    setData((prev: any) => ({ ...prev, voucherItems }));
  };

  const onAddNewLine = () => {
    let newLine = formData.voucherItems.length + 1;

    let loopBreaker = 0;
    while (true) {
      const lineNo = formData.voucherItems.find(i => i.lineNo === newLine);
      if (!lineNo) {
        break;
      }
      newLine += 1;
      loopBreaker += 1;
      if (loopBreaker >= 100) {
        break;
      }
    }
    setShowItemModal(true);
    setActiveLineNo(newLine);
  };

  const onRemoveLine = (lineNo: number) => {
    const voucherItems = formData.voucherItems.filter(
      (item: any) => item.lineNo !== lineNo,
    );

    setData(p => ({ ...p, voucherItems: [...voucherItems] }));
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const onCreatePDF = async () => {
    const data = {
      user,
      voucherType,
      accounts: [defAccount],
      customers: customers.data,
      products: products.data,
    };

    const templateData = getPDFData(formData, data);

    const template = salesAndPurchaseTemplate(templateData);

    const voucherNo = formData.voucherNumber.split('-').join('_');
    const fileName = `${voucherNo}_${dateFileName(user)}`.toLowerCase();

    const source = await createPDF(template, fileName);
    setPdfSource(source);
  };

  // console.log('formData: ', formData.voucherDate);
  // console.log('errors: ', errors);
  const activeItem = formData.voucherItems.find(x => x.lineNo === activeLineNo);
  // console.log('images: ', images);

  return (
    <Container>
      <Card sx={{ paddingX: 2 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {salesNavTitle(isEdit, isFullPayment)}
            </Typography>

            <CashOrCreditSwitch
              isFullPayment={isFullPayment}
              onToggle={v => onFormChange('isFullPayment', v)}
            />
          </Stack>
          <Divider />

          <Stack py={2} gap={2}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('salesIncome.label.voucherDate2')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>

              <Grid xs={4}>
                <InputForm
                  label={t('paymentIn.label.voucherNumber')}
                  isDisabled={true}
                  isRequired={true}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  defaultValue={formData.voucherNumber}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  defaultValue={formData.referenceId}
                  label={t('salesIncome.label.referenceId2')}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <SelectPicker
                  isRequired={isFullPayment ? false : true}
                  label={t('salesIncome.label.customer')}
                  selectedValue={formData.customer}
                  selectItems={customers.data}
                  isInvalid={'customer' in errors}
                  helpText={'customer' in errors && errors.customer}
                  onValueChange={(v: any) => onFormChange('customer', v)}
                  // setHandles={setHandlePartner}
                  partnerBalance={customerReceivable && customerReceivable}
                />
              </Grid>
            </Grid>
            <Stack pt={1}>
              <ItemListHeader itemsLen={formData.voucherItems.length} />

              {formData.voucherItems.map((item: any, index) => (
                <TransactionItem
                  key={item.lineNo}
                  index={index + 1}
                  voucherItem={item}
                  productItems={products.data}
                  productUnits={productUnits}
                  onFormValueChange={onFormItemValueChange}
                  onRemoveLine={onRemoveLine}
                  setHandleProduct={setHandleProduct}
                  isSales
                />
              ))}
            </Stack>

            {showItemModal && (
              <TransactionItemModal
                showModal={showItemModal}
                setShowModal={setShowItemModal}
                voucherItem={activeItem || { lineNo: activeLineNo }}
                productItems={products.data || []}
                productUnits={productUnits}
                onFormValueChange={onFormItemValueChange}
                setHandleProduct={setHandleProduct}
                defaultAccount={defAccount}
                isSales
              />
            )}

            <AddNewLine {...{ onAddNewLine, t }} />

            {errors.salesAmount && (
              <TransactionError error={errors.salesAmount} />
            )}

            <PaymentAmount
              {...{ formData, onFormChange, errors, type: 'SALES', t }}
            />

            {(paidAmount > 0 || isFullPayment) && (
              <SelectPicker
                isRequired={paidAmount || isFullPayment ? true : false}
                selectedValue={formData.cashInAccount}
                selectItems={bankAndCash}
                label={t('salesIncome.label.cashInAccount')}
                placeHolder={t('salesIncome.placeholder.cashInAccount')}
                isInvalid={'cashInAccount' in errors}
                helpText={'cashInAccount' in errors && errors.cashInAccount}
                onValueChange={(v: string) => onFormChange('cashInAccount', v)}
              />
            )}

            <BillOrInvoiceSummary
              t={t}
              voucherItems={formData.voucherItems}
              discountAmount={formData.discountAmount}
              paidAmount={formData.paidAmount}
              isTaxable={formData.isTaxable}
              isFullPayment={formData.isFullPayment}
              isInvoice
              isSales
            />

            <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
              <Stack flex={1}>
                <InputForm
                  inLineLabel={true}
                  defaultValue={formData.description}
                  label={t('salesIncome.label.description')}
                  isInvalid={'description' in errors}
                  helpText={'description' in errors && errors.description}
                  onChangeText={(v: string) => onFormChange('description', v)}
                />
              </Stack>
              <Stack pt={0.1}>
                <ImagePicker {...{ images, setImages }} />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title={isEdit ? 'Update & Get PDF' : 'Save & Get PDF'}
            onClick={() => onSubmit(true)}
            loading={
              addSales.isLoading || editSales.isLoading || saveImage.isLoading
            }
          />
          <Button
            title={isEdit ? 'Update Transaction' : 'Save Transaction'}
            onClick={() => onSubmit(false)}
            loading={
              addSales.isLoading || editSales.isLoading || saveImage.isLoading
            }
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default SalesIncome;
