export enum StockType {
  openingBalance = 'opening-balance',
  purchase = 'purchase',
  sales = 'sales',
  salesReturn = 'sales-return',
  purchaseReturn = 'purchase-return',
  increment = 'increment-adjustment',
  decrement = 'decrement-adjustment',
  prodIncrement = 'production-increment',
  lossDecrement = 'loss-decrement',
  usedDecrement = 'used-decrement',
}

export enum AdjustmentType {
  valueAdjustment = 'value-adjustment',
  quantityAdjustment = 'quantity-adjustment',
}

export const adjustmentTypes = [
  { id: AdjustmentType.quantityAdjustment, name: 'Quantity' },
  { id: AdjustmentType.valueAdjustment, name: 'Value Only' },
];

export const stockIncrementTypes = [
  // { id: StockType.prodIncrement, name: 'Production/Manufacture Increment' }, // not finalized
  { id: StockType.increment, name: 'Increment Adjustment' },
  // { id: StockType.increment, name: 'Other Increment Adjustment' },
];

export const stockDecrementTypes = [
  { id: StockType.usedDecrement, name: 'Used/Consumed Decrement' },
  { id: StockType.lossDecrement, name: 'Stock Loss Decrement' },
  { id: StockType.decrement, name: 'Other Decrement Adjustment' },
];
