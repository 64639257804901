export const accountInputFields1 = [
  { fieldName: 'code', required: true, disabled: true },
];

export const accountInputFields2 = [
  { fieldName: 'name', required: true, disabled: false },
  // { fieldName: 'description', required: false, disabled: false },
];

export const accountSelectFields = [
  { fieldName: 'accountType', required: true },
  // { fieldName: 'accountGroup', required: true },
];

export const accountUpdateSelectFields = [
  { fieldName: 'accountType', required: true, disabled: true },
];

export const accountUpdateSwitchFields = [
  // { fieldName: 'requireBusiness', required: false },
  // { fieldName: 'requireVendor', required: false },
  // { fieldName: 'requireCustomer', required: false },
  // { fieldName: 'requireEmployee', required: false },
  { fieldName: 'isActive', required: false },
];
