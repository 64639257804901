import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const maintenance = require('../../assets/images/maintenance.png');

const UnderMaintenance = () => {
  const { t } = useTranslation();

  const image = {
    maxWidth: 400,
    height: 'auto',
  };
  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        gap: 12,
      }}>
      <img src={maintenance} style={image} />

      <Stack alignItems={'center'}>
        <Typography sx={{ fontSize: 32, fontWeight: 'bold' }}>
          {t('maintenance.title')}
        </Typography>
        <Typography sx={{ fontSize: 22 }}>
          {t('maintenance.description')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default UnderMaintenance;
