export const calendarData: any = {
  '2070': [31, 31, 31, 32, 31, 31, 29, 30, 30, 29, 30, 30, 365],
  '2071': [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  '2072': [31, 32, 31, 32, 31, 30, 30, 29, 30, 29, 30, 30, 365],
  '2073': [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 31, 366],
  '2074': [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  '2075': [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  '2076': [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  '2077': [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 29, 31, 366],
  '2078': [31, 31, 31, 32, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  '2079': [31, 31, 32, 31, 31, 31, 30, 29, 30, 29, 30, 30, 365],
  '2080': [31, 32, 31, 32, 31, 30, 30, 30, 29, 29, 30, 30, 365],
  '2081': [31, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366],
  '2082': [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  '2083': [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  '2084': [31, 31, 32, 31, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  '2085': [31, 32, 31, 32, 30, 31, 30, 30, 29, 30, 30, 30, 366],
  '2086': [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  '2087': [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366],
  '2088': [30, 31, 32, 32, 30, 31, 30, 30, 29, 30, 30, 30, 365],
  '2089': [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  '2090': [30, 32, 31, 32, 31, 30, 30, 30, 29, 30, 30, 30, 365],
  '2091': [31, 31, 32, 31, 31, 31, 30, 30, 29, 30, 30, 30, 366],
  '2092': [31, 31, 32, 32, 31, 30, 30, 30, 29, 30, 30, 30, 366],
};

export const weekDays = ['आइत', 'सोम', 'मंगल', 'बुध', 'बिहि', 'शुक्र', 'शनि'];

export const daysName = [
  { name: 'आइत', id: 1 },
  { name: 'सोम', id: 2 },
  { name: 'मंगल', id: 3 },
  { name: 'बुध', id: 4 },
  { name: 'बिहि', id: 5 },
  { name: 'शुक्र', id: 6 },
  { name: 'शनि', id: 7 },
];

export const monthsName = [
  'बैशाख',
  'जेठ',
  'असार',
  'श्रावण',
  'भाद्र',
  'आश्विन',
  'कार्तिक',
  'मंसिर',
  'पौष',
  'माघ',
  'फाल्गुण',
  'चैत्र',
];

// export const monthsList = [
//   { name: 'बैशाख', id: 1 },
//   { name: 'जेठ', id: 2 },
//   { name: 'असार', id: 3 },
//   { name: 'श्रावण', id: 4 },
//   { name: 'भाद्र', id: 5 },
//   { name: 'आश्विन', id: 6 },
//   { name: 'कार्तिक', id: 7 },
//   { name: 'मंसिर', id: 8 },
//   { name: 'पौष', id: 9 },
//   { name: 'माघ', id: 10 },
//   { name: 'फाल्गुण', id: 11 },
//   { name: 'चैत्र', id: 12 },
// ];

export const monthsList = [
  { name: 'Baishak', id: 1 },
  { name: 'Jestha', id: 2 },
  { name: 'Ashar', id: 3 },
  { name: 'Shrawan', id: 4 },
  { name: 'Bhadra', id: 5 },
  { name: 'Ashwin', id: 6 },
  { name: 'Kartik', id: 7 },
  { name: 'Mangsir', id: 8 },
  { name: 'Poush', id: 9 },
  { name: 'Magh', id: 10 },
  { name: 'Falgun', id: 11 },
  { name: 'Chaitra', id: 12 },
];

export const yearsList = [
  // { name: '2090', id: 2090 },
  // { name: '2089', id: 2089 },
  { name: '2088', id: 2088 },
  { name: '2087', id: 2087 },
  { name: '2086', id: 2086 },
  { name: '2085', id: 2085 },
  { name: '2084', id: 2084 },
  { name: '2083', id: 2083 },
  { name: '2082', id: 2082 },
  { name: '2081', id: 2081 },
  { name: '2080', id: 2080 },
  { name: '2079', id: 2079 },
  // { name: '2078', id: 2078 },
  // { name: '2077', id: 2077 },
  // { name: '2076', id: 2076 },
  // { name: '2075', id: 2075 },
  // { name: '2074', id: 2074 },
  // { name: '2073', id: 2073 },
  // { name: '2072', id: 2072 },
  // { name: '2071', id: 2071 },
  // { name: '2070', id: 2070 },
];

export const adMonthsList = [
  { name: 'Jan', id: 1 },
  { name: 'Feb', id: 2 },
  { name: 'Mar', id: 3 },
  { name: 'Apr', id: 4 },
  { name: 'May', id: 5 },
  { name: 'Jun', id: 6 },
  { name: 'Jul', id: 7 },
  { name: 'Aug', id: 8 },
  { name: 'Sep', id: 9 },
  { name: 'Oct', id: 10 },
  { name: 'Nov', id: 11 },
  { name: 'Dec', id: 12 },
];
