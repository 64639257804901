import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Divider, Box, Stack, Typography, Container } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import InputForm from '../../../components/forms/InputForm';

import { getAccountTypes } from '../../../controllers/system/systemControllers';
import {
  accountInputFields1,
  accountInputFields2,
  accountUpdateSelectFields,
} from '../../../constants/formFields/settings/accountFields';
import { updateAccount } from '../../../controllers/settings/accountControllers';
import { validateAccount } from '../../../validations/settings/validateAccount';
import SelectPicker from '../../../components/forms/SelectPicker';
import DatePicker from '../../../components/datePickers/DatePicker';
import { assetsTypesBasic } from '../../../constants/defaultCodes/accountTypeCodes';
import { colors } from '../../../assets/styles/colors';
import Button from '../../../components/buttons/Button';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import { deleteEmptyFields } from '../../../shared/utils/objectUtils';
import SwitchForm from '../../../components/forms/SwitchForm';

function UpdateAccount() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { state: account } = useLocation();

  if (!account) {
    navigate('/settings/accounts');
    return null;
  }

  const [formData, setData] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>({});
  // const { account } = route.params;

  // const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  useEffect(() => {
    const { code, name, description, accountType, isActive } = account;
    const { openingBalance, openingDate, isOBEditable } = account;
    const updatedData = {
      name,
      description,
      isActive,
      openingBalance,
      openingDate: openingDate ? new Date(openingDate) : '',
      isOBEditable,
      code: code.toString(),
      accountType: accountType.id,
    };
    deleteEmptyFields(updatedData);
    setData(prev => ({ ...prev, ...updatedData }));
  }, [account]);

  const getAccTypeFn = () => getAccountTypes({ getAll: true });
  const accountTypes: any = useQuery(['getAccountTypes'], getAccTypeFn);

  const { isLoading, mutateAsync } = useMutation(updateAccount);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validateAccount(formData, errors);

    if (isValid) {
      try {
        const payload = { accountId: account.id, data: formData };
        const data = await mutateAsync(payload);
        queryClient.invalidateQueries(['getAccounts']);
        queryClient.invalidateQueries(['getDashboardReport']);
        navigate('/settings/accounts', { replace: true });
        enqueueSnackbar(t('account.updateMsg.success'), successToast);
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('account.updateMsg.error'), errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateAccount(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const onToggle = (name: string) => {
    const updatedData = { ...formData, [name]: !formData[name] };
    setData(updatedData);
  };

  // console.log('formData:', formData);
  // console.log('errors:', errors);

  const accType = accountTypes.data?.find(x => x.id === formData.accountType);
  const hasOpening = assetsTypesBasic.includes(accType?.code); // considereing receivable for account update, need for parther though

  // console.log('=== defaultCode:', account.defaultCode);
  // console.log('=== hasOpening:', !hasOpening);
  // console.log('=== !formData.isOBEditable:', !formData.isOBEditable);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.updateAccount')}
          </Typography>
          <Divider />
          <Stack py={4} gap={3}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                {accountUpdateSelectFields.map(
                  ({ fieldName, required, disabled }) => (
                    <SelectPicker
                      key={fieldName}
                      enableSearch={false}
                      isDisabled={disabled}
                      isRequired={required}
                      selectedValue={formData[fieldName]}
                      defaultValue={formData[fieldName]}
                      selectItems={accountTypes.data}
                      label={t(`account.label.${fieldName}`)}
                      placeHolder={t(`account.placeholder.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      onValueChange={(v: string) => onFormChange(fieldName, v)}
                      helpText={fieldName in errors && errors[fieldName]}
                    />
                  ),
                )}
              </Grid>
              <Grid xs={4}>
                {accountInputFields1.map(
                  ({ required, fieldName, disabled }) => (
                    <InputForm
                      key={fieldName}
                      isRequired={required}
                      defaultValue={formData[fieldName] || ''}
                      isDisabled={disabled}
                      isNumberic={fieldName === 'code' ? true : false}
                      label={t(`account.label.${fieldName}`)}
                      placeHolder={t(`account.placeholder.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      onChangeText={(v: string) => onFormChange(fieldName, v)}
                      helpText={fieldName in errors && errors[fieldName]}
                    />
                  ),
                )}
              </Grid>
            </Grid>
            {accountInputFields2.map(({ required, fieldName, disabled }) => (
              <InputForm
                key={fieldName}
                isRequired={required}
                defaultValue={formData[fieldName]}
                isDisabled={account.defaultCode ? true : disabled}
                label={t(`account.label.${fieldName}`)}
                placeHolder={t(`account.placeholder.${fieldName}`)}
                isInvalid={fieldName in errors}
                onChangeText={(v: string) => onFormChange(fieldName, v)}
                helpText={fieldName in errors && errors[fieldName]}
              />
            ))}

            {hasOpening && formData.isOBEditable && (
              <Stack direction="row" spacing={2}>
                <Box flex="1">
                  <InputForm
                    isNumberic={true}
                    maxLength={12}
                    defaultValue={String(formData.openingBalance || '')}
                    label={t('account.label.openingBalance')}
                    isInvalid={'openingBalance' in errors}
                    onChangeText={(v: string) =>
                      onFormChange('openingBalance', +v)
                    }
                    helpText={
                      'openingBalance' in errors && errors.openingBalance
                    }
                  />
                </Box>
                <Box flex="1" zIndex={999}>
                  <DatePicker
                    entryMinDate={true}
                    isRequired={formData.openingBalance ? true : false}
                    label={t('account.label.openingDate')}
                    value={formData.openingDate}
                    isInvalid={'openingDate' in errors}
                    helpText={'openingDate' in errors && errors.openingDate}
                    onDateChange={(v: any) => onFormChange('openingDate', v)}
                  />
                </Box>
              </Stack>
            )}

            <SwitchForm
              isChecked={formData.isActive}
              disabled={account.defaultCode ? true : false}
              label={t(`account.label.isActive`)}
              onToggle={() => onToggle('isActive')}
              helpText={t(`account.helpText.isActive-${formData.isActive}`)}
            />
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          {account.defaultCode && !hasOpening && !formData.isOBEditable ? (
            <Typography sx={{ fontSize: 18, fontStyle: 'italic' }}>
              NOTE: DEFAULT ACCOUNTS ARE READ ONLY !
            </Typography>
          ) : (
            <Button
              title="Update Account"
              onClick={onSubmit}
              loading={isLoading}
            />
          )}
        </Stack>
      </Card>
    </Container>
  );
}

export default UpdateAccount;
