import { store } from '../../store/store';
import {
  accountsUrl,
  getAccountsByCodeUrl,
  bankdAndCashAccountsUrl,
  updateAccountUrl,
  accountCodeUrl,
  postingAccountsUrl,
  billablePurchaseAccountsUrl,
  billableExpenseAccountsUrl,
  incomeAccountsUrl,
  accountByDefaultCodeUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getAccountCode = async (accTypeCode: number) => {
  const path = accountCodeUrl(accTypeCode);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getAccounts = async (query: any) => {
  const path = accountsUrl();
  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const getAllPostingAccounts = async () => {
  const path = postingAccountsUrl();
  const response = await httpClient().get<any>(path);
  return response.data;
};

export const bankAndCashAccounts = async () => {
  const path = bankdAndCashAccountsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const billablePurchaseAccounts = async () => {
  const path = billablePurchaseAccountsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const billableExpenseAccounts = async () => {
  const path = billableExpenseAccountsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const billableAccounts = async () => {
  const path = v1('/settings/billable-accounts');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const incomeAccounts = async () => {
  const path = incomeAccountsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getAccountsByCode = async (accTypeCode: string) => {
  const path = getAccountsByCodeUrl(accTypeCode);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const accountByDefCode = async (defaultCode: string) => {
  const path = accountByDefaultCodeUrl(defaultCode);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const createAccount = async (data: any) => {
  const path = accountsUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updateAccount = async (payload: any) => {
  const path = updateAccountUrl(payload.accountId);

  const response = await httpClient().patch<any>(path, payload.data);
  return response.data;
};

export const updatePhoneVerification = async () => {
  const path = v1('/accounts/user/phone-verification');

  const response = await httpClient().patch<any>(path);
  return response.data;
};
