import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
// import { Platform } from 'react-native';
// import Config from 'react-native-config';

import HttpClientErrorLogger from './httpError';
import packageInfo from '../../package.json';

const calcExponentialDelay = (
  attempt: number,
  initialDelay: number,
): number => {
  return 2 ** (attempt - 1) * initialDelay;
};

const HTTP_STATUS_RETRY_RANGES = [
  [100, 199],
  [429, 429],
  [500, 599],
];

const isRetryableHttpStatus = (status: number): boolean => {
  return HTTP_STATUS_RETRY_RANGES.some(([min, max]) => {
    return status >= min && status <= max;
  });
};

export type ServiceConfiguration = Pick<
  AxiosRequestConfig,
  'baseURL' | 'headers' | 'timeout'
>;

export type RetryConfiguration = {
  noResponseRetries?: number;
  retry?: number;
  retryDelayInMs?: number;
};

export type ErrorLogger = {
  handleError: (error: AxiosError) => void;
};

export type HttpClientConfiguration = {
  serviceConfiguration?: ServiceConfiguration;
  retryConfiguration?: RetryConfiguration;
  errorLogger?: ErrorLogger;
};

export default class HttpClientWithRetry {
  private readonly client: AxiosInstance;
  private readonly retryConfiguration: Required<RetryConfiguration>;
  private readonly errorLogger: ErrorLogger;

  constructor() {
    // console.log('Config.API_BASE_URL', Config.API_BASE_URL);

    this.client = axios.create({
      // baseURL: Config.API_BASE_URL,
      baseURL: process.env.REACT_APP_API_BASE_URL,
      // baseURL: "http://127.0.0.1:3000",
      timeout: 20000, // update to 1000 later
      headers: {
        // Accept: 'application/json',
        appVersion: `${packageInfo.name}:${packageInfo.version}`,
        // os: Platform.OS,
        // osVersion: Platform.Version,
        // Authorization: `Bearer ${token}`,
      },
    });

    this.retryConfiguration = {
      noResponseRetries: 0, // replace with 2 later
      retry: 0, // replace with 3 later
      retryDelayInMs: 500, // replace with 500 later
    };

    this.errorLogger = new HttpClientErrorLogger();
  }

  get interceptors(): AxiosInstance['interceptors'] {
    return this.client.interceptors;
  }

  async head<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request({
      ...config,
      method: 'head',
      url,
    });
  }

  async get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    params?: any,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request({
      ...config,
      method: 'get',
      url,
      params,
    });
  }

  async post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request({
      ...config,
      method: 'post',
      url,
      data,
    });
  }

  async put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request({
      ...config,
      method: 'put',
      url,
      data,
    });
  }

  async patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request({
      ...config,
      method: 'patch',
      url,
      data,
    });
  }

  async delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.request({
      ...config,
      method: 'delete',
      url,
    });
  }

  async request<T = unknown, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    // console.log('config:', config);

    return this.requestAttempt(config, 0);
  }

  private async requestAttempt<T = unknown, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
    currentAttempt: number,
  ): Promise<R> {
    try {
      if (currentAttempt > 0) {
        await this.delayAttempt(currentAttempt);
        // console.info(`HTTP connection retry attempt #${currentAttempt}`);
      }
      return await this.client.request(config);
    } catch (error) {
      if (this.shouldRetry(error, currentAttempt)) {
        return this.requestAttempt(config, currentAttempt + 1);
      }

      this.errorLogger.handleError(error);
      throw error;
    }
  }

  private async delayAttempt(currentAttempt: number): Promise<void> {
    // For an initial delay of 500ms, the delays will be in that order: 500ms, 1s, 2s, 4s, 8s, etc.
    const delay = calcExponentialDelay(
      currentAttempt,
      this.retryConfiguration.retryDelayInMs,
    );
    return new Promise(resolve => setTimeout(resolve, delay));
  }

  private shouldRetry(error: AxiosError, currentAttempt: number): boolean {
    if (
      !error.response &&
      currentAttempt >= this.retryConfiguration.noResponseRetries
    ) {
      return false;
    }

    if (error.response?.status) {
      // console.log('error.request:', error.request._headers);

      if (!isRetryableHttpStatus(error.response.status)) {
        return false;
      }
    }

    return currentAttempt < this.retryConfiguration.retry;
  }
}
