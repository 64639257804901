export enum OTPUsage {
  REGISTRATION = 'REGISTRATION',
  PASSWORD_RESET = 'PASSWORD_RESET',
  LOGIN_VERIFICATION = 'LOGIN_VERIFICATION',
}

export const localeOptions = [
  { name: 'English', id: 'en' },
  { name: 'नेपाली', id: 'np' },
];

export enum RequestFrom {
  web = 'web-app',
  mobile = 'mobile-app',
}

export enum RegisteredFrom {
  web = 'web',
  mobile = 'mobile',
}
