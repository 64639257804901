import React from 'react';
import { Typography } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { colors } from '../../../../assets/styles/colors';

function ProductAccordianSummary({ title }) {
  return (
    <AccordionSummary
      sx={{ backgroundColor: colors.info100, mt: 2 }}
      expandIcon={<ArrowDropDownIcon sx={{ fontSize: 36 }} />}>
      <Typography color={colors.theme1} sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </AccordionSummary>
  );
}

export default ProductAccordianSummary;
