import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import { expenseStatement } from '../../../controllers/reports/reportControllers';
import { validateFromAndToDate } from '../../../validations/reports/validateFromAndToDate';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';

const initial = { initialData: [] };

function ExpensesOnItems() {
  const { t } = useTranslation();

  const initalState: any = { ...initialDate, toDate: new Date() };
  const [filters, setFilters] = useState<any>(initalState);

  const [errors, setErrors] = React.useState({});
  const [showFilters, setShowFilter] = React.useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  const expStatFn = () => expenseStatement(filters);
  const expenseStatements = useQuery(['expStatements', filters], expStatFn, {
    enabled: false,
  });
  // console.log('expenseStatements', expenseStatements.data);

  const onSubmit = async () => {
    const { isValid, errs } = validateFromAndToDate(filters, errors);

    if (isValid) {
      expenseStatements.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const pickerSize = 'small';

  if (expenseStatements.isSuccess && !showFilters) {
    return (
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.expenseItem')}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={expenseStatements.isLoading}
            isError={expenseStatements.isError}
            isEmpty={!expenseStatements.data?.expenseStatements.length}>
            <TableContainer>
              <Table aria-label="expense">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Expense Item</TbCellHeader>
                    <TbCellHeader align="right">Expense Amount</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {expenseStatements.data?.expenseStatements?.map(
                    (row, index) => (
                      <TableRowStyled key={row.id}>
                        <TbCellBody sx={{ maxWidth: 24 }}>
                          {index + 1}
                        </TbCellBody>
                        <TbCellBody>{row.expenseItem}</TbCellBody>
                        <TbCellAmount>{formatAmount(row.expense)}</TbCellAmount>
                      </TableRowStyled>
                    ),
                  )}
                </TableBody>
                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TableCell />
                    <TbCellAmount sx={{ fontStyle: 'italic' }}>
                      Total Expense
                    </TbCellAmount>
                    <TbCellSummary>
                      {formatAmount(expenseStatements.data?.totalExpense)}
                    </TbCellSummary>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={false}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.expenseItem')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid py={3} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Expense Items Report'}
              onClick={onSubmit}
              loading={expenseStatements.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default ExpensesOnItems;
