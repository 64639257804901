import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { validatePartnerCategory } from '../../../validations/settings/validatePartner';
import { addPartnerCategory } from '../../../controllers/settings/partnerControllers';
import Button from '../../../components/buttons/Button';
import InputForm from '../../../components/forms/InputForm';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

function CreatePartnerCategory() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const { isLoading, mutateAsync } = useMutation(addPartnerCategory);
  const onSubmit = async () => {
    const { isValid, errors: errs } = validatePartnerCategory(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['partyCategories']);
        enqueueSnackbar(t('partnerCategory.createMsg.success'), successToast);
        navigate('/settings/partner-categories', { replace: true });
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('partnerCategory.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData((prev: any) => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validatePartnerCategory(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData partner category:', formData);
  // console.log('errors:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.addPartnerCategory')}
          </Typography>
          <Divider />
          <Stack py={4}>
            <InputForm
              isRequired={true}
              defaultValue={formData.name}
              label={t('partnerCategory.label.name')}
              placeHolder={'Partner category name'}
              isInvalid={'name' in errors}
              helpText={'name' in errors && errors.name}
              onChangeText={(v: string) => {
                onFormChange('name', v);
              }}
            />
          </Stack>
        </CardContent>
        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            title="Save Partner Category"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default CreatePartnerCategory;
