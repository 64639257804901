import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

import { useSelector } from '../../../store/reduxHooks';
import { colors } from '../../../assets/styles/colors';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const VoucherDeleteWarningModal = ({
  isLoading,
  showModal,
  setShowModal,
  onConfirmDeletion,
  vouchersToDelete,
}: any): JSX.Element => {
  const { user } = useSelector(state => state.auth.authUser);
  const { t } = useTranslation();

  const checkIfVouchersHasStock = () => {
    let hasStock = false;
    vouchersToDelete.forEach(x => {
      const stock = x.voucherItems.find(y => y.productStock);
      stock && (hasStock = true);
    });
    return hasStock;
  };

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={2}>
          <Typography
            sx={{ fontSize: 18, color: colors.red500, fontWeight: 'bold' }}>
            {t('voucherDeletionWarning.header.title')}
          </Typography>
          <Typography>
            {checkIfVouchersHasStock()
              ? t('voucherDeletionWarning.header.subTitle1')
              : t('voucherDeletionWarning.header.subTitle2')}
          </Typography>
        </Stack>
        <Divider />

        <Stack py={2} gap={2}>
          {vouchersToDelete.map((voucher: any, index) => (
            <Grid key={index} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 18 }}>
                  {getSettingBasedDate(voucher.voucherDate, user)}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 18 }}>
                  {voucher.voucherType.name}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 18 }}>
                  {voucher.voucherNumber}
                </Typography>
              </Grid>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 18 }}>
                  Ref# {voucher.referenceId ? voucher.referenceId : 'N/A'}
                </Typography>
              </Grid>

              <Grid xs={12}>
                {voucher.voucherItems.map(
                  (item: any, index2) =>
                    item.productStock && (
                      <Stack
                        key={index2}
                        mt={1}
                        py={1}
                        sx={{ borderTop: 1, borderColor: colors.gray200 }}>
                        <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                          <Grid xs={2}>
                            <Typography>STOCK:</Typography>
                          </Grid>
                          <Grid xs={2}>
                            <Typography>
                              {item.productStock?.quantity}
                            </Typography>
                          </Grid>
                          <Grid xs={6}>
                            <Typography>
                              {item.productStock?.productItem?.name}
                            </Typography>
                          </Grid>
                          <Grid xs={2}>
                            <Typography>
                              {item.productStock.productUnit?.name}rwr
                            </Typography>
                          </Grid>
                        </Grid>
                      </Stack>
                    ),
                )}
              </Grid>
            </Grid>
          ))}
        </Stack>

        {vouchersToDelete?.length > 1 && (
          <Typography sx={{ fontSize: 14 }}>
            {t('voucherDeletionWarning.message.info')}
          </Typography>
        )}

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={() => setShowModal(false)}>
            {t('voucherDeletionWarning.button.cancelDelete')}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            onClick={onConfirmDeletion}>
            {t('voucherDeletionWarning.button.confirmDelete')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default VoucherDeleteWarningModal;
