export const voucherTypes = {
  GENERAL_VOUCHER: 'GENERAL_VOUCHER',
  CASH_SALES: 'CASH_SALES', // PAYMENT VOUCHER
  CREDIT_SALES: 'CREDIT_SALES', // CUSTOMER_INVOICE
  CASH_INCOME: 'CASH_INCOME', // PAYMENT VOUCHER
  CREDIT_INCOME: 'CREDIT_INCOME', // CUSTOMER_INVOICE
  CASH_PURCHASE: 'CASH_PURCHASE', // PAYMENT VOUCHER
  CREDIT_PURCHASE: 'CREDIT_PURCHASE', // VENDOR BILL
  CASH_EXPENSE: 'CASH_EXPENSE', // PAYMENT VOUCHER
  CREDIT_EXPENSE: 'CREDIT_EXPENSE', // VENDOR BILL
  INVOICE_RECEIVE: 'INVOICE_RECEIVE', // RECEIPT_VOUCHER
  BILL_PAYMENT: 'BILL_PAYMENT', // PAYMENT VOUCHER
  ADVANCE_RECEIVE: 'ADVANCE_RECEIVE', // RECEIPT_VOUCHER
  ADVANCE_PAYMENT: 'ADVANCE_PAYMENT', // PAYMENT VOUCHER
  SALES_RETURN: 'SALES_RETURN',
  CUSTOMER_PAYMENT: 'CUSTOMER_PAYMENT',
  PURCHASE_RETURN: 'PURCHASE_RETURN',
  EXPENSE_RETURN: 'EXPENSE_RETURN',
  VENDOR_RECEIPT: 'VENDOR_RECEIPT', // RECEIPT_VOUCHER
  VAT_PAYMENT: 'VAT_PAYMENT', // PAYMENT VOUCHER
  BANK_LOAN: 'BANK_LOAN',
  EMI_PAYMENT: 'EMI_PAYMENT', // PAYMENT VOUCHER
  LEND_MONEY: 'LEND_MONEY',
  RECEIVE_LENT_MONEY: 'RECEIVE_LENT_MONEY', // RECEIPT_VOUCHER
  BORROW_MONEY: 'BORROW_MONEY',
  PAY_BORROWED_MONEY: 'PAY_BORROWED_MONEY', // PAYMENT VOUCHER
  FUND_TRANSFER: 'FUND_TRANSFER',
  PARTNER_TRANSFER: 'PARTNER_TRANSFER',
  SALARY_PAYMENT: 'SALARY_PAYMENT',
  REVERSE_ENRTY: 'REVERSE_ENRTY',
  ACCRUED_INTEREST: 'ACCRUED_INTEREST',
  ADJUSTING_ENTRY: 'ADJUSTING_ENTRY',
  PAYMENT_IN: 'PAYMENT_IN', // New voucher type to handle, advance receive, invoice recieve and customer payment
  PAYMENT_OUT: 'PAYMENT_OUT', // New voucher type to handle, advance payment, bill payment and vendor receipt
};

export const voucherTypesList = [
  {
    name: voucherTypes.CASH_SALES,
    id: voucherTypes.CASH_SALES,
  },
  {
    name: voucherTypes.CREDIT_SALES,
    // name: 'CREDIT & CASH SALES',
    id: voucherTypes.CREDIT_SALES,
  },
  {
    name: voucherTypes.CASH_INCOME,
    id: voucherTypes.CASH_INCOME,
  },
  {
    name: voucherTypes.CREDIT_INCOME,
    id: voucherTypes.CREDIT_INCOME,
  },
  {
    name: voucherTypes.CASH_PURCHASE,
    id: voucherTypes.CASH_PURCHASE,
  },
  {
    name: voucherTypes.CREDIT_PURCHASE,
    // name: 'CREDIT & CASH PURCHASES',
    id: voucherTypes.CREDIT_PURCHASE,
  },
  {
    name: voucherTypes.CASH_EXPENSE,
    id: voucherTypes.CASH_EXPENSE,
  },
  {
    name: voucherTypes.CREDIT_EXPENSE,
    // name: 'CREDIT & CASH EXPENSES',
    id: voucherTypes.CREDIT_EXPENSE,
  },
  {
    name: voucherTypes.PAYMENT_IN,
    id: voucherTypes.PAYMENT_IN,
  },
  {
    name: voucherTypes.PAYMENT_OUT,
    id: voucherTypes.PAYMENT_OUT,
  },
  {
    name: `${voucherTypes.INVOICE_RECEIVE}`,
    id: voucherTypes.INVOICE_RECEIVE,
  },
  {
    name: `${voucherTypes.BILL_PAYMENT}`,
    id: voucherTypes.BILL_PAYMENT,
  },
  {
    name: `${voucherTypes.ADVANCE_RECEIVE}`,
    id: voucherTypes.ADVANCE_RECEIVE,
  },
  {
    name: `${voucherTypes.ADVANCE_PAYMENT}`,
    id: voucherTypes.ADVANCE_PAYMENT,
  },
  {
    name: voucherTypes.SALES_RETURN,
    id: voucherTypes.SALES_RETURN,
  },
  // NEED TO HANDLE THIS CASE FROM INVOICE RECIEVE
  // {
  //   name: `${voucherTypes.CUSTOMER_PAYMENT}_(OUT)`,
  //   id: voucherTypes.CUSTOMER_PAYMENT,
  // },
  {
    name: voucherTypes.PURCHASE_RETURN,
    id: voucherTypes.PURCHASE_RETURN,
  },
  // {
  //   name: voucherTypes.EXPENSE_RETURN,
  //   id: voucherTypes.EXPENSE_RETURN,
  // },
  // NEED TO HANDLE THIS CASE FROM BILL PAYMENT
  // {
  //   name: `${voucherTypes.VENDOR_RECEIPT}_(IN)`,
  //   id: voucherTypes.VENDOR_RECEIPT,
  // },
  {
    name: voucherTypes.VAT_PAYMENT,
    id: voucherTypes.VAT_PAYMENT,
  },
  {
    name: voucherTypes.BANK_LOAN,
    id: voucherTypes.BANK_LOAN,
  },
  {
    name: voucherTypes.EMI_PAYMENT,
    // name: 'EMI & BORROWED PAYMENTS',
    id: voucherTypes.EMI_PAYMENT,
  },
  {
    name: voucherTypes.LEND_MONEY,
    id: voucherTypes.LEND_MONEY,
  },
  {
    name: voucherTypes.RECEIVE_LENT_MONEY,
    id: voucherTypes.RECEIVE_LENT_MONEY,
  },
  {
    name: voucherTypes.BORROW_MONEY,
    id: voucherTypes.BORROW_MONEY,
  },
  {
    name: voucherTypes.PAY_BORROWED_MONEY,
    id: voucherTypes.PAY_BORROWED_MONEY,
  },
  {
    name: voucherTypes.FUND_TRANSFER,
    id: voucherTypes.FUND_TRANSFER,
  },
  {
    name: voucherTypes.PARTNER_TRANSFER,
    id: voucherTypes.PARTNER_TRANSFER,
  },
  {
    name: voucherTypes.SALARY_PAYMENT,
    id: voucherTypes.SALARY_PAYMENT,
  },
  // {
  //   name: voucherTypes.REVERSE_ENRTY,
  //   id: voucherTypes.REVERSE_ENRTY,
  // },
  {
    name: voucherTypes.ACCRUED_INTEREST,
    id: voucherTypes.ACCRUED_INTEREST,
  },
  {
    name: voucherTypes.ADJUSTING_ENTRY,
    id: voucherTypes.ADJUSTING_ENTRY,
  },
  {
    name: voucherTypes.GENERAL_VOUCHER,
    id: voucherTypes.GENERAL_VOUCHER,
  },
];

export default voucherTypes;

export const creditVouchers = [
  voucherTypes.CREDIT_SALES,
  voucherTypes.CREDIT_INCOME,
  voucherTypes.CREDIT_PURCHASE,
  voucherTypes.CREDIT_EXPENSE,
  voucherTypes.LEND_MONEY,
  voucherTypes.BORROW_MONEY,
  voucherTypes.BANK_LOAN,
];

export const customerVouchers = [
  voucherTypes.CREDIT_SALES,
  voucherTypes.CASH_SALES,
  voucherTypes.CREDIT_INCOME,
  voucherTypes.CASH_INCOME,
  voucherTypes.SALES_RETURN,
  voucherTypes.INVOICE_RECEIVE,
  voucherTypes.ADVANCE_RECEIVE,
  voucherTypes.CUSTOMER_PAYMENT,
];

export const vendorVouchers = [
  voucherTypes.CREDIT_PURCHASE,
  voucherTypes.CASH_PURCHASE,
  voucherTypes.CREDIT_EXPENSE,
  voucherTypes.CASH_EXPENSE,
  voucherTypes.PURCHASE_RETURN,
  voucherTypes.BILL_PAYMENT,
  voucherTypes.ADVANCE_PAYMENT,
  voucherTypes.VENDOR_RECEIPT,
];

export const salesPurchaseVouchers = [
  voucherTypes.CREDIT_SALES,
  voucherTypes.CASH_SALES,
  voucherTypes.CREDIT_INCOME,
  voucherTypes.CASH_INCOME,
  voucherTypes.SALES_RETURN,
  voucherTypes.CREDIT_PURCHASE,
  voucherTypes.CASH_PURCHASE,
  voucherTypes.CREDIT_EXPENSE,
  voucherTypes.CASH_EXPENSE,
  voucherTypes.PURCHASE_RETURN,
];

export const loanPartnerVouchers = [
  voucherTypes.LEND_MONEY,
  voucherTypes.RECEIVE_LENT_MONEY,
  voucherTypes.BANK_LOAN,
  voucherTypes.EMI_PAYMENT,
  voucherTypes.BORROW_MONEY,
  voucherTypes.PAY_BORROWED_MONEY,
];

export const multiPartnerVouchers = [
  voucherTypes.GENERAL_VOUCHER,
  voucherTypes.PARTNER_TRANSFER,
  voucherTypes.ADJUSTING_ENTRY,
];

export const paymentInOutVouchers = [
  voucherTypes.PAYMENT_IN,
  voucherTypes.PAYMENT_OUT,
];
