import React, { useState } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';

import InputForm from '../../../components/forms/InputForm';
import { useSelector } from '../../../store/reduxHooks';
import { colors } from '../../../assets/styles/colors';
import Button from '../../../components/buttons/Button';
import { LoadingButton } from '@mui/lab';

const OTPCodeInput = ({
  onSubmit,
  code,
  setCode,
  phoneNumber,
  isLoading,
}: any) => {
  const { invalidCode, confirmingCode } = useSelector(s => s.auth);

  return (
    <>
      <Stack gap={4}>
        <Stack gap={2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 22 }}>
            OTP Verification
          </Typography>

          <Typography sx={{ fontSize: 18 }}>
            Please enter OTP code sent to {phoneNumber}
          </Typography>
          {/* <Typography>
          कृपया {phoneNumber} मा पठाइएको OTP कोड राख्नुहोस्
        </Typography> */}
        </Stack>

        <InputForm
          maxLength={6}
          size="large"
          isRequired={true}
          isFloatNo={false}
          placeHolder={'verification code'}
          onChangeText={(v: string) => setCode(v)}
        />

        {invalidCode && (
          <Typography
            sx={{ color: colors.red500, fontWeight: 'bold', fontSize: 17 }}
            align="center">
            Invalid OTP Code, Try Again!
          </Typography>
        )}

        <LoadingButton
          size="large"
          variant="contained"
          loading={confirmingCode || isLoading}
          onClick={code.length === 6 ? onSubmit : () => {}}>
          Confirm Code
        </LoadingButton>

        {/* <Button
          title="Confirm Code"
          onClick={code.length === 6 ? onSubmit : () => {}}
          loading={confirmingCode || isLoading}
        /> */}
      </Stack>
    </>
  );
};

export default OTPCodeInput;
