import { store } from '../../store/store';
import { fundTransferUrl, partnerTransferUrl } from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};
export const getFundTransfers = async () => {
  const path = fundTransferUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const fundTransfer = async (data: any) => {
  const path = fundTransferUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const partnerTransfer = async (data: any) => {
  const path = partnerTransferUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};
