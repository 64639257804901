import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, Switch, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

const SwitchForm = ({
  label,
  onToggle,
  helpText,
  isRequired,
  size = 'medium',
  isChecked = false,
  disabled = false,
}: any): JSX.Element => {
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid xs={6}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Box py={0.4}>
            <label>{label}</label>
            {helpText && (
              <Typography pt={0.4} fontSize={14}>
                ({helpText})
              </Typography>
            )}
          </Box>
          <Switch
            disabled={disabled}
            checked={isChecked}
            size={size}
            onChange={onToggle}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SwitchForm;

export const VerticalSwitchForm = ({
  label,
  onToggle,
  helpText,
  isRequired,
  size = 'medium',
  isChecked = false,
  disabled = false,
}: any): JSX.Element => {
  return (
    <Stack alignItems={'center'}>
      <Box py={0.4}>
        <label>{label}</label>
      </Box>
      <Switch
        disabled={disabled}
        checked={isChecked}
        size={size}
        onChange={onToggle}
      />
    </Stack>
  );
};
