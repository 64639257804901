import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import { useTranslation } from 'react-i18next';

import { useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';

import HeaderStyled from './HeaderStyled';
import { colors } from '../../assets/styles/colors';
import { useDispatch, useSelector } from '../../store/reduxHooks';
import { userLogoutAPI } from '../../controllers/accounts/userAccountControllers';
import { logout } from '../../store/authSlice';
import { removeClient } from '../../store/httpSlice';
import UserProfileInfoModal from '../../components/modals/UserProfileInformation';
import { getNepaliDate } from '../../shared/utils/nepaliDate';
import { formatToTextDate } from '../../shared/utils/dateTime';

// project import
// import DrawerHeader from './DrawerHeader';
// import DrawerContent from './DrawerContent';
// import MiniDrawerStyled from './MiniDrawerStyled';
// import { drawerWidth } from 'config';

// ==============================|| MAIN LAYOUT - DRAWER ||============================== //

const Header = ({ open, handleDrawerToggle }: any) => {
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { authUser } = useSelector(state => state.auth);
  const avatarLetter = authUser.user.firstName.split('')[0];
  const dispatch = useDispatch();

  const [userInfoVisible, setUserInfoVisible] = useState(false);

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  // responsive drawer container
  //   const container =
  //     window !== undefined ? () => window().document.body : undefined;

  // header content
  //   const drawerContent = useMemo(() => <DrawerContent />, []);
  //   const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  const onUserLogout = async () => {
    await userLogoutAPI();
    localStorage.clear();
    dispatch(logout());
    dispatch(removeClient());
    queryClient.clear();
    navigate('/login');
  };

  const today = new Date();
  return (
    <HeaderStyled
      // position="absolute"
      position="fixed"
      open={open}
      style={{ backgroundColor: colors.theme1 }}>
      <Toolbar
        variant="dense"
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}>
          <MenuIcon sx={{ fontSize: 26 }} />
        </IconButton>

        <Typography sx={{ flexGrow: 1 }}>
          <Link to="/" style={{ fontSize: 16, fontWeight: 'bold' }}>
            {/* {authUser.user?.business?.name} */}
            KAROD - DIGITAL KHATA
          </Link>
        </Typography>
        {/* <Typography sx={{ flexGrow: 1 }}>Karod Khata</Typography> */}
        {/* <IconButton color="inherit">
          <Badge badgeContent={4} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton> */}

        <Stack flexDirection="row" sx={{ pr: 4, gap: 1 }} alignItems={'center'}>
          <Typography sx={{}}>{getNepaliDate(today)}</Typography>
          <Typography fontSize={24}>|</Typography>
          <Typography sx={{}}>{formatToTextDate(today)}</Typography>
        </Stack>

        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="User Menu">
            <IconButton
              onClick={e => setAnchorElUser(e.currentTarget)}
              sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: colors.theme2, width: 34, height: 34 }}>
                {avatarLetter}
              </Avatar>
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: '32px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={() => setAnchorElUser(null)}>
            <MenuItem
              sx={{ pr: 8 }}
              onClick={() => {
                setUserInfoVisible(true);
                setAnchorElUser(null);
              }}>
              <ListItemIcon>
                <AccountCircleRoundedIcon />
              </ListItemIcon>

              <Typography textAlign="center" fontSize={18} sx={{ py: 1.4 }}>
                {t('navBar.user')}
              </Typography>
            </MenuItem>

            <MenuItem
              onClick={() => {
                navigate('/settings/user-settings');
                setAnchorElUser(null);
              }}>
              <ListItemIcon>
                <ManageAccountsRoundedIcon />
              </ListItemIcon>
              <Typography textAlign="center" fontSize={18} sx={{ py: 1.4 }}>
                {t('navBar.setting')}
              </Typography>
            </MenuItem>

            {/* <MenuItem
              onClick={() => {
                navigate('/billing');
                setAnchorElUser(null);
              }}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <Typography textAlign="center" fontSize={18} sx={{ py: 1.4 }}>
                Billing Info
              </Typography>
            </MenuItem> */}

            <MenuItem onClick={onUserLogout}>
              <ListItemIcon>
                <LogoutRoundedIcon />
              </ListItemIcon>
              <Typography textAlign="center" fontSize={18} sx={{ py: 1.4 }}>
                {t('navBar.logout')}
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>

      <UserProfileInfoModal
        authUser={authUser}
        modalVisible={userInfoVisible}
        onModalClose={() => setUserInfoVisible(false)}
      />
    </HeaderStyled>
  );
};

export default Header;
