import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

import { colors } from '../../assets/styles/colors';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const SubscriptionUpgradeNeeded = ({
  showWarning,
  setShowWarning,
  totalBusiness,
  totalEmployee,
}: any): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onNavigationPress = (navigateTo: any) => {
    navigate(navigateTo, { replace: true });
    setShowWarning(false);
  };

  if (!showWarning) return null;

  return (
    <Modal
      open={showWarning}
      onClose={() => setShowWarning(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={2}>
          <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
            {t('subscriptionWarning.header.title')}
          </Typography>

          {totalBusiness && (
            <Typography sx={{ color: colors.red500, fontWeight: 'bold' }}>
              {t('subscriptionWarning.header.subTitleBusiness')}
            </Typography>
          )}

          {totalEmployee && (
            <Typography sx={{ color: colors.red500, fontWeight: 'bold' }}>
              {t('subscriptionWarning.header.subTitleEmployee')}
            </Typography>
          )}
        </Stack>
        <Divider />

        <Stack py={4} gap={1}>
          {totalBusiness && (
            <Typography sx={{ fontSize: 18 }}>
              {t('subscriptionWarning.message.business', { totalBusiness })}
            </Typography>
          )}
          {totalEmployee && (
            <Typography sx={{ fontSize: 18 }}>
              {t('subscriptionWarning.message.employee', { totalEmployee })}
            </Typography>
          )}
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={() => navigate(-1)}>
            Go Back
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            onClick={() => onNavigationPress('/subscription')}>
            {t('subscriptionWarning.button.upgradeNow')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SubscriptionUpgradeNeeded;
