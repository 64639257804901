import React from 'react';
import { Typography } from '@mui/material';

import { useSelector } from '../../store/reduxHooks';
import { dateFormat } from '../../constants/defaultCodes/otherEnums';
import { getNepaliDate } from '../../shared/utils/nepaliDate';
import { formatToTextDate } from '../../shared/utils/dateTime';
import { colors } from '../../assets/styles/colors';

const DateRangeCard = ({ filters, title }: any) => {
  const { user } = useSelector(state => state.auth.authUser);
  const format: dateFormat = user.business?.dateFormat || dateFormat.BS;

  const style = { fontWeight: 'bold', fontSize: 17, color: colors.darker4 };
  if (format === dateFormat.BS) {
    return (
      <Typography align="center" sx={style}>
        {title && `${title} `}
        {getNepaliDate(filters.fromDate)} - {getNepaliDate(filters.toDate)}
      </Typography>
    );
  } else {
    return (
      <Typography align="center" sx={style}>
        {title && `${title} `}
        {formatToTextDate(filters.fromDate)} -{' '}
        {formatToTextDate(filters.toDate)}
      </Typography>
    );
  }
};

export default DateRangeCard;
