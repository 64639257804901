export const generalLedgerDateFilters = [
  { filter: 'fromDate', required: true },
  { filter: 'toDate', required: true },
];

export const generalLedgerSelectFilters = [
  { filter: 'vendor', required: false },
  { filter: 'customer', required: false },
  // { filter: 'loanPartner', required: false },
  { filter: 'employee', required: false },
  { filter: 'product', required: false },
  { filter: 'expenseItem', required: false },
];
