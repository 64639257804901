import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

import { colors } from '../../assets/styles/colors';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const ExpiredSubscriptionWarning = ({
  showWarning,
  setShowWarning,
}: any): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onNavigationPress = (navigateTo: any) => {
    navigate(navigateTo, { replace: true });
    setShowWarning(false);
  };

  if (!showWarning) return null;

  return (
    <Modal
      open={showWarning}
      onClose={() => setShowWarning(true)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={2}>
          <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
            {t('expiredSubscriptionWarning.header.title')}
          </Typography>

          <Typography sx={{ color: colors.red500, fontWeight: 'bold' }}>
            {t('expiredSubscriptionWarning.header.subTitle')}
          </Typography>
        </Stack>
        <Divider />

        <Stack py={4} gap={1}>
          <Typography sx={{ fontSize: 18 }}>
            {t('expiredSubscriptionWarning.message.transaction')}
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            {t('expiredSubscriptionWarning.message.settings')}
          </Typography>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="error"
            variant="contained"
            size="large"
            onClick={() => onNavigationPress('/subscription')}>
            {t('expiredSubscriptionWarning.button.upgradeNow')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ExpiredSubscriptionWarning;
