import React from 'react';
import tw from 'twin.macro';
import { useLocation } from 'react-router-dom';

import AnimationRevealPage from './helpers/AnimationRevealPage';
import Header from "./components/headers/light.jsx";
import Footer from './components/footers/Footer.jsx';
import FacebookChatBot from '../components/utility/FacebookChatBot';

const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

function LandingLayout({ children }) {
  const location = useLocation();
  const paths = location.pathname.split('/').filter(x => x);
  const root = paths[0];

  let isLandingPage=  true;
  if (root === 'signup' || root === 'login' || root === 'pricing') {
    isLandingPage = false
  }


  return (
    <StyledDiv className="App">
      <Header isLandingPage={isLandingPage} />
      {children}
      {/* <FacebookChatBot /> */}
      <Footer />
    </StyledDiv>

  )
}

export default LandingLayout;
