import { store } from '../../store/store';
import {
  getSalesForReturnUrl,
  getSoldItemsUrl,
  salesReturnUrl,
  vatPaymentUrl,
  cashSalesEditUrl,
  getSalesVoucherUrl,
  creditSalesEditUrl,
  customerPaymentUrl,
  otherIncomeUrl,
  vatAmountUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const salesIncome = async (data: any) => {
  const path = v1('/transactions/sales/sales-income');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

// FOR INCOME OTHER THAN SALES INCOME WITH OUT STOCK MANAGEMENT
export const otherIncome = async (data: any) => {
  const path = v1('/transactions/sales/other-income');

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getSalesForReturn = async (query: any) => {
  const path = getSalesForReturnUrl();
  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const getSoldItems = async (voucherId: string) => {
  const path = getSoldItemsUrl(voucherId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const salesReturn = async (data: any) => {
  const path = salesReturnUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const salesVatAmountAPI = async (filters: any) => {
  const path = vatAmountUrl();
  const response = await httpClient().get<any>(path, filters);
  return response.data;
};

export const salesVatPayment = async (data: any) => {
  const path = vatPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getSalesVoucher = async (voucherId: string) => {
  const path = getSalesVoucherUrl(voucherId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const editCashSales = async (data: any) => {
  const path = cashSalesEditUrl(data.id);
  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const editSalesIncome = async (data: any) => {
  const path = creditSalesEditUrl(data.id);
  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const editOtherIncome = async (data: any) => {
  const path = v1(`/transactions/sales/other-income/${data.id}`);

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const customerPayment = async (data: any) => {
  const path = customerPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};
