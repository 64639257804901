import i18next from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';
import { itemsAmountSum } from '../../shared/utils/amountSum';
import { getTaxAmount } from '../../shared/utils/vatAndDiscount';
const { CREDIT_PURCHASE, CASH_PURCHASE } = voucherTypes;
const { CREDIT_EXPENSE, CASH_EXPENSE } = voucherTypes;

export const validateVoucherNo = async (data: any, errors: any) => {
  const voucherType = data.isFullPayment ? CASH_PURCHASE : CREDIT_PURCHASE;
  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateExpenseVoucherNo = async (data: any, errors: any) => {
  const voucherType = data.isFullPayment ? CASH_EXPENSE : CREDIT_EXPENSE;
  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateBill = (data: any, errors: any) => {
  let isValid = true;

  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('voucher.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18next.t('bill.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.isFullPayment && !data.vendor) {
    errors = {
      ...errors,
      vendor: i18next.t('voucher.validation.vendorRequired'),
    };
    isValid = false;
  } else {
    delete errors.vendor;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('voucher.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  if (!data.voucherItems.length) {
    errors = {
      ...errors,
      purchaseAmount: 'Purchase amount is required',
    };
    isValid = false;
  } else {
    delete errors.purchaseAmount;
  }

  data.voucherItems.forEach((billItem: any) => {
    const accountField = `${billItem.lineNo}-account`;
    const amountField = `${billItem.lineNo}-amount`;

    if (!billItem.account) {
      errors = {
        ...errors,
        [accountField]: i18next.t('bill.validation.billAccountRequired'),
      };
      isValid = false;
    } else {
      delete errors[accountField];
    }

    if (!billItem.amount || billItem.amount <= 0) {
      errors = {
        ...errors,
        [amountField]: i18next.t('bill.validation.billAmountRequired'),
      };
      isValid = false;
    } else {
      delete errors[amountField];
    }
  });

  if (
    data.discountAmount &&
    data.discountAmount > itemsAmountSum(data.voucherItems)
  ) {
    errors = {
      ...errors,
      discountAmount: i18next.t('bill.validation.invalidDiscount'),
    };
    isValid = false;
  } else {
    delete errors.discountAmount;
  }

  if (data.paidAmount) {
    const discountAmount = Number(data.discountAmount || 0);
    const amountSum = itemsAmountSum(data.voucherItems);
    const taxAmount = getTaxAmount(
      data.isTaxable,
      data.discountAmount,
      amountSum,
      13,
    );
    const finalAmount = amountSum - discountAmount + +(taxAmount || 0);

    if (data.paidAmount > finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('bill.validation.invalidPaidAmount'),
      };
      isValid = false;
    }

    if (!data.isFullPayment && data.paidAmount === finalAmount) {
      errors = {
        ...errors,
        paidAmount: i18next.t('invoice.validation.isCashPayment'),
      };
      isValid = false;
    }
  } else {
    delete errors.paidAmount;
  }

  if ((data.paidAmount || data.isFullPayment) && !data.cashOutAccount) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('bill.validation.cashOutAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  return { isValid, errs: errors };
};
