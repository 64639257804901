import React, { useEffect, useState } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';

import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useSelector } from '../../../store/reduxHooks';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import { getVoucherForEdit } from '../../../controllers/transactions/voucherControllers';
import { getVendors } from '../../../controllers/settings/partnerControllers';
import {
  bankAndCashAccounts,
  billableAccounts,
} from '../../../controllers/settings/accountControllers';
import { getVendorBalance } from '../../../controllers/reports/partnerReports';
import {
  getExpenseItems,
  getProductItems,
  getProductUnits,
} from '../../../controllers/settings/inventoryControllers';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import Button from '../../../components/buttons/Button';
import IncomeExpenseItem from '../purchases/components/IncomeExpenseItem';
import { colors } from '../../../assets/styles/colors';
import AddNewLine from '../sales/components/AddNewLine';
import {
  editOtherExpense,
  otherExpense,
} from '../../../controllers/transactions/expenseControllers';
import {
  validateBill,
  validateExpenseVoucherNo,
} from '../../../validations/transactions/validateNewBill';
import { assetsTypes } from '../../../constants/defaultCodes/accountTypeCodes';
import { successToast } from '../../../constants/snackbar/snackbar';
import { expenseNavTitle } from '../../../shared/utils/transactionUtils';
import CashOrCreditSwitch from '../components/CashOrCreditSwitch';
import BillOrInvoiceSummary from '../components/BillOrInvoiceSummary';
import BillOrInvoiceDiscountAndTax from '../components/BillNInvoiceDiscountNTax';
import PaymentAmount from '../components/PaymentAmount';
import ImagePicker from '../../../components/image/ImagePicker';
import { createPDF, getPDFData } from '../../../shared/utils/createPDF';
import { salesAndPurchaseTemplate } from '../../../templates/salesAndPurchaseTemplate';
import { dateFileName } from '../../../shared/utils/getSettingBasedDate';

const billVoucher = voucherTypes.CREDIT_EXPENSE;
const paymentVoucher = voucherTypes.CASH_EXPENSE;
const initial = { initialData: [] };
const initHPState = { show: false, vendor: true, customer: false };

function OtherExpense({ isUpdate = false }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { state: editVoucherId } = useLocation();
  const isEdit = Boolean(editVoucherId);

  if (isUpdate && !editVoucherId) {
    navigate('/journals');
    return null;
  }

  const { authUser } = useSelector(state => state.auth);

  const init1 = { voucherItems: [{ lineNo: 1 }], isFullPayment: true };
  const initState = { ...init1, voucherDate: new Date() };
  const [formData, setData] = useState<any>(initState);
  const [images, setImages] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [showDTS, setShowDTS] = useState<boolean>(false);
  const [enableLeft, setEnableLeft] = useState<boolean>(true);
  const [vendorPayable, setVendorPayable] = useState<any>();

  const [handlePartner, setHandlePartner] = useState<any>({ ...initHPState });
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const { isFullPayment, paidAmount } = formData;

  const voucherType = isFullPayment ? paymentVoucher : billVoucher;
  const vNoFunc = () => getVoucherNumber(voucherType);
  const getVNo = { enabled: !isEdit, cacheTime: 0 };
  const vnoQuery = useQuery(['getVoucherNo', voucherType], vNoFunc, getVNo);
  const { data: voucherNumber } = vnoQuery;

  const vendors = useQuery(['getVendors'], getVendors, initial);
  const accounts = useQuery(['billableAcc'], billableAccounts, initial);
  const bankAndCash = useQuery(['bankAndCash'], bankAndCashAccounts, initial);

  const editFunc = () => getVoucherForEdit(editVoucherId);
  const getEditV = { enabled: isEdit, cacheTime: 0 };
  const editQry = useQuery(['editVoucher', editVoucherId], editFunc, getEditV);
  const { data: editVoucher, isFetched: editTxnFetched } = editQry;

  const expItemFn = () => getExpenseItems({ isActive: true });
  const expenseItems = useQuery(['getExpenseItems'], expItemFn, initial);

  const prodItemFn = () => getProductItems({ isActive: true });
  const products = useQuery(['products'], prodItemFn, initial);
  const productUnits = useQuery(['productUnits'], getProductUnits, initial);
  const vendorBalanceQuery = useMutation(getVendorBalance);

  useEffect(() => {
    if (isEdit) {
      if (editVoucher) {
        const voucherDate = new Date(editVoucher.voucherDate);

        editVoucher.voucherImages && setImages(editVoucher.voucherImages);
        delete editVoucher.voucherImages;
        setData((prev: any) => ({ ...prev, ...editVoucher, voucherDate }));

        if (editVoucher.discountAmount || editVoucher.isTaxable) {
          setShowDTS(true);
        }
      }
    }
  }, [editTxnFetched]);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  useEffect(() => {
    if (isEdit && editVoucher) {
      if (editVoucher.isFullPayment !== isFullPayment) {
        vnoQuery.refetch();
      } else if (editVoucher.isFullPayment === isFullPayment) {
        const { referenceId, voucherNumber: vNo } = editVoucher;
        setData((pr: any) => ({ ...pr, voucherNumber: vNo, referenceId }));
      }
    }
  }, [isFullPayment]);

  const addExpense = useMutation(otherExpense);
  const editExpense = useMutation(editOtherExpense);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async (generatePDF: boolean) => {
    if (!isFullPayment && paidAmount <= 0 && formData.cashOutAccount) {
      delete formData.cashOutAccount;
    }

    const { isValid, errs } = validateBill(formData, errors);

    let othValidity: any = { isVoucherNoValid: true };
    !isEdit && (othValidity = await validateExpenseVoucherNo(formData, errs));

    if (isValid && othValidity.isVoucherNoValid) {
      try {
        if (isUpdate && editVoucherId) {
          if (images && images.length && !images[0].id) {
            const imgPaylaod = { images, voucherType: 'expenses' };
            const image = await saveImage.mutateAsync(imgPaylaod);
            formData.image = image.id;
          }

          await editExpense.mutateAsync(formData);
          queryClient.invalidateQueries(['vouchers']);
          queryClient.invalidateQueries(['getVoucher']);
          queryClient.invalidateQueries(['payBills']);
          queryClient.invalidateQueries(['partyDashledgers']);

          const msg = t(`purchaseExp.expUpdateMsg.success-${isFullPayment}`);
          enqueueSnackbar(msg, successToast);
        } else {
          if (images && images.length) {
            const imgPaylaod = { images, voucherType: 'expenses' };
            const image = await saveImage.mutateAsync(imgPaylaod);
            formData.image = image.id;
          }
          await addExpense.mutateAsync(formData);

          const msg = t(`purchaseExp.expCreateMsg.success-${isFullPayment}`);
          enqueueSnackbar(msg, successToast);
        }

        queryClient.invalidateQueries(['getDashboardReport']);
        generatePDF && (await onCreatePDF());
        navigate('/journals', { replace: true });
      } catch (err) {
        const cErr = t(`purchaseExp.expCreateMsg.error-${isFullPayment}`);
        const uErr = t(`purchaseExp.expUpdateMsg.error-${isFullPayment}`);
        let title = isEdit ? uErr : cErr;

        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }
        enqueueSnackbar(title, successToast);
      }
    } else {
      isEdit && setErrors(errs);
      !isEdit && setErrors(othValidity.errss);
      if (!othValidity.isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    setData((prev: any) => ({ ...prev, [name]: value }));

    const { errs } = validateBill({ ...formData, [name]: value }, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});

    if (name === 'vendor' && value && formData.vendor !== value) {
      const response = await vendorBalanceQuery.mutateAsync(value);
      setVendorPayable({ type: 'CASH_OUT', balance: response });
    } else if (name === 'vendor' && !value) {
      setVendorPayable(null);
    }
  };

  const onFormItemValueChange = (
    name: string,
    value: string,
    lineNo: number,
  ) => {
    // console.log(name, value, lineNo);

    const myItem = formData.voucherItems.find(item => item.lineNo === lineNo);
    if (!myItem) {
      const updatedData = {
        ...formData,
        voucherItems: [...formData.voucherItems, { [name]: value, lineNo }],
      };
      setData(updatedData);
      return;
    }

    const voucherItems = formData.voucherItems.map(item => {
      if (item.lineNo === lineNo) {
        myItem[name] = value;
      }
      return item;
    });
    const updatedData = { ...formData, voucherItems: [...voucherItems] };
    setData(updatedData);

    const { errs } = validateBill(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onAddNewLine = () => {
    let newLine = formData.voucherItems.length + 1;

    while (true) {
      const lineNo = formData.voucherItems.find(i => i.lineNo === newLine);
      if (!lineNo) {
        break;
      }
      newLine += 1;
    }

    const updatedData = {
      ...formData,
      voucherItems: [...formData.voucherItems, { lineNo: newLine }],
    };
    setData(updatedData);
  };

  const onRemoveLine = (lineNo: number) => {
    if (formData.voucherItems.length <= 1) {
      return;
    }

    const voucherItems = formData.voucherItems.filter(
      item => item.lineNo !== lineNo,
    );

    const updatedData = { ...formData, voucherItems: [...voucherItems] };
    setData(updatedData);
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const onToggleButtonSwitch = (value: boolean) => {
    const vItems = formData.voucherItems.map((item: any) => {
      value && delete item.product;
      !value && delete item.expenseItem;
      return item;
    });
    setEnableLeft(value);
    setData((prev: any) => ({ ...prev, voucherItems: [...vItems] }));
  };

  const getExpAssetsAccount = () => {
    const newAccoutns = accounts.data?.map(x => {
      if (assetsTypes.includes(x.accountType.code)) {
        // console.log('xx', x.name);
        if (!x.modified) {
          x.name = `${x.name} - ASSETS`;
          x.modified = true;
        }
      }
    });
    return newAccoutns || [];
  };
  getExpAssetsAccount();

  const onCreatePDF = async () => {
    const data = {
      voucherType,
      user: authUser.user,
      vendors: vendors.data,
      accounts: accounts.data,
      expenseItems: expenseItems.data,
      products: products.data,
    };

    const templateData = getPDFData(formData, data);
    const template = salesAndPurchaseTemplate(templateData);

    const voucherNo = formData.voucherNumber.split('-').join('_');
    const fileName = `${voucherNo}_${dateFileName(
      authUser.user,
    )}`.toLowerCase();

    const source = await createPDF(template, fileName);
    setPdfSource(source);
  };

  return (
    <Container>
      <Card sx={{ paddingX: 2 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {expenseNavTitle(isEdit, isFullPayment)}
            </Typography>

            <CashOrCreditSwitch
              isFullPayment={isFullPayment}
              onToggle={v => onFormChange('isFullPayment', v)}
            />
          </Stack>

          <Divider />
          <Stack py={2} gap={2}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('purchaseExp.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  isDisabled={true}
                  isRequired={true}
                  defaultValue={formData.voucherNumber}
                  label={t('purchaseExp.label.voucherNumber')}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  onChangeText={(v: string) => onFormChange('voucherNumber', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  defaultValue={formData.referenceId}
                  label={t('purchaseExp.label.referenceId')}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
              <Grid xs={12}>
                <SelectPicker
                  isRequired={isFullPayment ? false : true}
                  label={t('purchaseExp.label.vendor')}
                  selectedValue={formData.vendor}
                  selectItems={vendors.data}
                  isInvalid={'vendor' in errors}
                  helpText={'vendor' in errors && errors.vendor}
                  onValueChange={(v: any) => onFormChange('vendor', v)}
                  // setHandles={setHandlePartner}
                  partnerBalance={vendorPayable && vendorPayable}
                />
              </Grid>
            </Grid>

            <Typography
              paddingX={1}
              borderRadius={1}
              sx={{ size: 15, fontWeight: 'bold' }}
              bgcolor={colors.info100}>
              Expense Items
            </Typography>

            {formData.voucherItems.map((item, index) => (
              <IncomeExpenseItem
                key={item.lineNo}
                itemsLength={formData.voucherItems.length}
                voucherItem={item}
                accountItems={accounts.data}
                expenseItems={expenseItems.data}
                productItems={products.data}
                productUnits={productUnits.data}
                onRemoveLine={onRemoveLine}
                onFormValueChange={onFormItemValueChange}
                errors={errors}
                getHelpText={getHelpText}
                enableProduct={!enableLeft}
                isExpense
              />
            ))}

            <AddNewLine {...{ onAddNewLine, t }} />

            <BillOrInvoiceSummary
              t={t}
              voucherItems={formData.voucherItems}
              subTotalOnly
            />

            <BillOrInvoiceDiscountAndTax
              t={t}
              showDTS={true}
              voucherItems={formData.voucherItems}
              discountAmount={formData.discountAmount}
              isTaxable={formData.isTaxable}
              onFormDataChange={onFormChange}
              errors={errors}
            />

            <PaymentAmount
              {...{ formData, onFormChange, errors, type: 'EXPENSE', t }}
            />

            {(formData.paidAmount > 0 || formData.isFullPayment) && (
              <SelectPicker
                isRequired={paidAmount || isFullPayment ? true : false}
                selectedValue={formData.cashOutAccount}
                selectItems={bankAndCash.data}
                label={t('purchaseExp.label.cashOutAccount')}
                placeHolder={t('purchaseExp.label.cashOutAccount')}
                isInvalid={'cashOutAccount' in errors}
                helpText={getHelpText('cashOutAccount')}
                onValueChange={(v: string) => onFormChange('cashOutAccount', v)}
              />
            )}

            <BillOrInvoiceSummary
              t={t}
              voucherItems={formData.voucherItems}
              discountAmount={formData.discountAmount}
              paidAmount={formData.paidAmount}
              isTaxable={formData.isTaxable}
              isFullPayment={formData.isFullPayment}
            />

            <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
              <Stack flex={1}>
                <InputForm
                  inLineLabel={true}
                  defaultValue={formData.description}
                  label={t('purchaseExp.label.description')}
                  isInvalid={'description' in errors}
                  helpText={'description' in errors && errors.description}
                  onChangeText={(v: string) => onFormChange('description', v)}
                />
              </Stack>
              <Stack pt={0.1}>
                <ImagePicker {...{ images, setImages }} />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title={isEdit ? 'Update & Get PDF' : 'Save & Get PDF'}
            loading={
              addExpense.isLoading ||
              editExpense.isLoading ||
              saveImage.isLoading
            }
            onClick={() => onSubmit(true)}
          />
          <Button
            title={isEdit ? 'Update Transaction' : 'Save Transaction'}
            loading={
              addExpense.isLoading ||
              editExpense.isLoading ||
              saveImage.isLoading
            }
            onClick={() => onSubmit(false)}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default OtherExpense;
