import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Card, CardContent, Divider, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

import { trialBalance } from '../../../controllers/reports/reportControllers';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import DatePicker from '../../../components/datePickers/DatePicker';
import EmptyReport from '../../../components/feedbacks/EmptyReport';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';

function TrialBalanceReport() {
  const { t } = useTranslation();

  const [toDate, setToDate] = useState<any>(new Date());

  const trialFn = () => trialBalance({ toDate });
  const trialBalances = useQuery(['trialBalance'], trialFn, { enabled: false });
  const { data = {} } = trialBalances;

  useEffect(() => {
    if (toDate) {
      trialBalances.refetch();
    }
  }, [toDate]);

  const getBalance = (balance: string) => {
    const formatted = formatAmount(balance);
    if (formatted === '0.00') {
      return null;
    }
    return formatted;
  };

  const tbCell = { fontWeight: 'bold', textAlign: 'right' };

  return (
    <ScreenWrapper
      isLoading={trialBalances.isLoading}
      isError={trialBalances.isError}>
      <>
        <Card sx={{ paddingX: 4 }}>
          <CardContent sx={{ paddingY: 1 }}>
            <Stack direction={'row'} alignItems={'center'} pb={0.4} gap={3}>
              {/* <Typography sx={{ fontSize: 20 }}>Trial Balance as of</Typography> */}
              <Typography sx={{ fontSize: 20 }}>
                {t('reportStack.trialBalance')} as of
              </Typography>
              <Stack pb={1}>
                <DatePicker
                  size="small"
                  onDateChange={(v: any) => setToDate(v)}
                  value={toDate}
                />
              </Stack>
            </Stack>

            <Divider />

            {data.trialBalances?.length ? (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TbRowHeaderStyled>
                      <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                      <TbCellHeader>Account Name</TbCellHeader>
                      <TbCellHeader align="right">Opening Balance</TbCellHeader>
                      <TbCellHeader align="right">Debit</TbCellHeader>
                      <TbCellHeader align="right">Credit</TbCellHeader>
                      <TbCellHeader align="right">Closing Balance</TbCellHeader>
                    </TbRowHeaderStyled>
                  </TableHead>
                  <TableBody>
                    {data.trialBalances.map((row, index) => (
                      <TableRowStyled key={row.id}>
                        <TbCellBody sx={{ maxWidth: 24 }}>
                          {index + 1}
                        </TbCellBody>
                        <TbCellBody>{row.account}</TbCellBody>
                        <TbCellAmount sx={{ fontWeight: '500' }}>
                          {getBalance(row.openingBalance)}
                        </TbCellAmount>
                        <TbCellAmount sx={{ fontWeight: '500' }}>
                          {getBalance(row.debitBalance)}
                        </TbCellAmount>
                        <TbCellAmount sx={{ fontWeight: '500' }}>
                          {getBalance(row.creditBalance)}
                        </TbCellAmount>
                        <TbCellAmount>
                          {getBalance(row.closingBalance)}
                        </TbCellAmount>
                      </TableRowStyled>
                    ))}
                  </TableBody>
                  <TableHead sx={{ backgroundColor: colors.gray200 }}>
                    <TableRow>
                      <TbCellAmount />
                      <TbCellAmount sx={{ fontStyle: 'italic' }}>
                        Total Balance
                      </TbCellAmount>
                      <TbCellAmount />
                      <TbCellSummary>
                        {formatAmount(data.totalDebitBalance)}
                      </TbCellSummary>
                      <TbCellSummary>
                        {formatAmount(data.totalCreditBalance)}
                      </TbCellSummary>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            ) : (
              <EmptyReport />
            )}
          </CardContent>
        </Card>
      </>
    </ScreenWrapper>
  );
}

export default TrialBalanceReport;
