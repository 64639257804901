import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import InputForm from '../../../components/forms/InputForm';
import Button from '../../../components/buttons/Button';
import { checkAllowedSettingCount } from '../../../controllers/accounts/userAccountControllers';
import { createBusiness } from '../../../controllers/settings/businessControllers';
import { validateBusiness } from '../../../validations/settings/validateBusiness';
import { businessCreateFields } from '../../../constants/formFields/settings/businessFields';
import SwitchForm from '../../../components/forms/SwitchForm';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import { dateFormat } from '../../../constants/defaultCodes/otherEnums';
import SubscriptionUpgradeNeeded from '../../../components/modals/SubscriptionUpgradeNeeded';

function CreateBusinessPage() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const df = dateFormat.BS;
  const [formData, setData] = useState<any>({ onVat: false, dateFormat: df });
  const [errors, setErrors] = useState<any>({});
  const [showWarning, setShowWarning] = useState(false);

  const subsCount = useQuery(['getSubsCount'], checkAllowedSettingCount);
  const { isLoading, isError, isSuccess, data = {} } = subsCount;

  const addBusiness = useMutation(createBusiness);

  useEffect(() => {
    if (isSuccess) {
      if (!data.business?.allowedNew) {
        setShowWarning(true);
      }

      // const unsubscribe = navigation.addListener('focus', () => {
      //   if (!data.business?.allowedNew) {
      //     setShowWarning(true);
      //   }
      // });
      // return unsubscribe;
    }
    // }, [navigation, isSuccess]);
  }, [isSuccess]);

  const onSubmit = async () => {
    const { isValid, errors: errorMsg } = validateBusiness(formData, errors);

    if (isValid) {
      try {
        await addBusiness.mutateAsync(formData);
        queryClient.invalidateQueries(['getSubsCount']);
        queryClient.invalidateQueries(['getBusinesses']);
        queryClient.invalidateQueries(['getUserBusinesses']);

        navigate('/settings/businesses', { replace: true });
        enqueueSnackbar(t('business.createMsg.success'), successToast);
      } catch (err) {
        enqueueSnackbar(t('business.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errorMsg);
    }
  };

  const onFormChange = (name: string, value: string) => {
    const updatedData = { ...formData, [name]: value };
    setData(updatedData);
    const { errors: err } = validateBusiness(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData SP:', formData);
  // console.log('errors SP:', errors);
  const totalAllowed = data.business?.totalAllowed;

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.addBusiness')}
          </Typography>
          <Divider />
          <Stack py={1} gap={3}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {businessCreateFields.map(({ fieldName, required, numeric }) => (
                <Grid key={fieldName} xs={fieldName == 'name' ? 12 : 6}>
                  <InputForm
                    isRequired={required}
                    defaultValue={formData[fieldName]}
                    isNumberic={numeric}
                    isFloatNo={false}
                    maxLength={fieldName === 'phoneNumber' ? 10 : 128}
                    label={t(`business.label.${fieldName}`)}
                    placeHolder={t(`business.placeholder.${fieldName}`)}
                    isInvalid={fieldName in errors}
                    helpText={fieldName in errors && errors[fieldName]}
                    onChangeText={(v: string) => onFormChange(fieldName, v)}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid>
              <Grid xs={12}>
                <SwitchForm
                  isChecked={formData.onVat}
                  label={t(`business.label.onVat-${formData.onVat}`)}
                  onToggle={(e, v) => onFormChange('onVat', v)}
                />
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Business"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>

        <SubscriptionUpgradeNeeded
          {...{ showWarning, setShowWarning, totalBusiness: totalAllowed }}
        />
      </Card>
    </Container>
  );
}

export default CreateBusinessPage;
