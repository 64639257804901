import React, { useEffect, useState } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import { getVoucherNumber } from '../../../controllers/transactions/transactionControllers';
import {
  getCustomers,
  getVendors,
} from '../../../controllers/settings/partnerControllers';
import { getAllPostingAccounts } from '../../../controllers/settings/accountControllers';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import {
  validateJournal,
  validateVoucherNo,
} from '../../../validations/transactions/validateGeneralJournal';
import { createVoucher } from '../../../controllers/transactions/voucherControllers';
import accountTypes, {
  liabilitiesTypes,
  normalBalance,
} from '../../../constants/defaultCodes/accountTypeCodes';
import { defaultAccountCodes } from '../../../constants/defaultCodes/accountDefaultCodes';
import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import { colors } from '../../../assets/styles/colors';
import GeneralVoucherItem from './components/GeneralVoucherItem';

const voucherType = voucherTypes.GENERAL_VOUCHER;
const initial = { initialData: [] };

function GeneralVoucher() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const voucherDate = new Date();
  const line1 = { lineNo: 1, debit: 0, credit: 0 };
  const line2 = { lineNo: 2, debit: 0, credit: 0 };
  const requirement = { vendorRequired: false, customerRequired: false };
  const init = { ...requirement, voucherItems: [line1, line2], voucherDate };
  const [formData, setData] = useState<any>(init);
  const [errors, setErrors] = useState<any>({});

  const { data: voucherNumber } = useQuery(['getVoucherNo', voucherType], () =>
    getVoucherNumber(voucherType),
  );
  const vendors = useQuery(['getVendors'], getVendors, initial);
  const customers = useQuery(['getCustomers'], getCustomers, initial);
  const accounts = useQuery(['allAccounts'], getAllPostingAccounts, initial);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  const { isLoading, mutateAsync } = useMutation(createVoucher);

  const onSubmit = async () => {
    let hasBalance = true;
    // const amount = +formData.debit1 || +formData.debit1;
    // if (amount) {
    //   hasBalance = await bankAndCashBalanceCheck(account, amount);
    // }
    const { isValid, errs } = validateJournal(formData, errors, hasBalance);
    const { isVoucherNoValid, errss } = await validateVoucherNo(formData, errs);

    if (isValid && isVoucherNoValid && hasBalance) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getDashboardReport']);
        // toast.show({ title: t('generalVoucher.createMsg.success') });
        // navigation.navigate('transactions-tab');
      } catch (err) {
        // console.error('err:', err);
        // toast.show({ title: t('generalVoucher.createMsg.error') });
      }
    } else {
      setErrors(errss);
      // console.log('invalid voucher');
      if (!isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errs } = validateJournal(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onFormItemChange = (name: string, value: string, lineNo: number) => {
    let vendorRequired = formData.vendorRequired;
    let customerRequired = formData.customerRequired;

    if (name === 'account') {
      const acc = accounts.data?.find((x: any) => x.id === +value);

      if (acc?.accountType.code === accountTypes.RECEIVABLE) {
        customerRequired = true;
      }
      if (liabilitiesTypes.includes(acc?.accountType.code)) {
        const { VAT_ON_SALES, DIVIDENDS_PAYABLE } = defaultAccountCodes;
        const nonRequirable = [VAT_ON_SALES, DIVIDENDS_PAYABLE];
        if (!nonRequirable.includes(acc.defaultCode)) {
          vendorRequired = true;
        }
      }
    }
    const payload = { vendorRequired, customerRequired };

    const vItems = formData.voucherItems.map((item: any) => {
      item.lineNo === lineNo && (item[name] = value);
      return item;
    });

    setData((pv: any) => ({ ...pv, ...payload, voucherItems: vItems }));

    const updatedData = { ...formData, voucherItems: vItems };
    const { errs } = validateJournal(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const getRemainingAccounts = (lineNo: number) => {
    if (lineNo === 1) {
      const ln2 = formData.voucherItems.find(x => x.lineNo === 2);
      return accounts.data?.filter((x: any) => x.id !== ln2.account);
    } else if (lineNo === 2) {
      const ln1 = formData.voucherItems.find(x => x.lineNo === 1);
      return accounts.data?.filter((x: any) => x.id !== ln1.account);
    }
  };

  const getAccountingHelp = () => {
    const ln1 = formData.voucherItems.find(x => x.lineNo === 1);
    const ln2 = formData.voucherItems.find(x => x.lineNo === 2);

    const acc1 = accounts.data?.find((x: any) => x.id === ln1.account);
    const isDrBal1 = normalBalance(acc1?.accountType.code).isDrBalance;
    const isCrBal1 = normalBalance(acc1?.accountType.code).isCrBalance;

    const acc2 = accounts.data?.find((x: any) => x.id === ln2.account);
    const isDrBal2 = normalBalance(acc2?.accountType.code).isDrBalance;
    const isCrBal2 = normalBalance(acc2?.accountType.code).isCrBalance;

    let status1 = '';
    let status2 = '';

    if ((isDrBal1 && line1.debit) || (isCrBal1 && line1.credit)) {
      status1 = 'INCREASED';
    } else if ((isDrBal1 && line1.credit) || (isCrBal1 && line1.debit)) {
      status1 = 'DECREASED';
    }

    if ((isDrBal2 && line2.debit) || (isCrBal2 && line2.credit)) {
      status2 = 'INCREASED';
    } else if ((isDrBal2 && line2.credit) || (isCrBal2 && line2.debit)) {
      status2 = 'DECREASED';
    }

    const message1 = `${acc1?.name} is being ${status1}`;
    const message2 = `${acc2?.name} is being ${status2}`;

    if (status1 && status2) {
      return `${message1}\n${message2}`;
    } else if (status1 || status2) {
      return message1 || message2;
    }
    return;
  };

  // console.log('formData  GV:', formData);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            General Voucher
          </Typography>
          <Divider />
          <Stack py={4} spacing={2}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('generalVoucher.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  isDisabled={true}
                  isRequired={true}
                  defaultValue={formData.voucherNumber}
                  label={t('generalVoucher.label.voucherNumber')}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  onChangeText={(v: string) => onFormChange('voucherNumber', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  defaultValue={formData.referenceId}
                  label={t('generalVoucher.label.referenceId')}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              bgcolor={colors.theme1}
              borderRadius={1}
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <Typography fontWeight={'bold'} color={colors.white}>
                  Account
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography fontWeight={'bold'} color={colors.white}>
                  Debit
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography fontWeight={'bold'} color={colors.white}>
                  Credit
                </Typography>
              </Grid>
            </Grid>

            {formData.voucherItems.map((vItem: any) => (
              <GeneralVoucherItem
                key={vItem.lineNo}
                voucherItem={vItem}
                formData={formData}
                accounts={getRemainingAccounts(vItem.lineNo)}
                onFormChange={onFormChange}
                onFormItemChange={onFormItemChange}
                errors={errors}
                getHelpText={getHelpText}
              />
            ))}

            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <SelectPicker
                  isRequired={formData.customerRequired ? true : false}
                  label={t('generalVoucher.label.customer')}
                  selectedValue={formData.customer}
                  selectItems={customers.data}
                  isInvalid={'customer' in errors}
                  helpText={'customer' in errors && errors.customer}
                  onValueChange={(v: any) => onFormChange('customer', v)}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  isRequired={formData.vendorRequired ? true : false}
                  label={t('generalVoucher.label.vendor')}
                  selectedValue={formData.vendor}
                  selectItems={vendors.data}
                  isInvalid={'vendor' in errors}
                  helpText={'vendor' in errors && errors.vendor}
                  onValueChange={(v: any) => onFormChange('vendor', v)}
                />
              </Grid>
            </Grid>

            {(errors.sumNotEqual ||
              errors.drAndCrAdded ||
              errors.drCrBoth0) && (
              <>
                {errors.drAndCrAdded && (
                  <Typography color="red">{errors.drAndCrAdded}</Typography>
                )}
                {errors.sumNotEqual && (
                  <Typography color="red">{errors.sumNotEqual}</Typography>
                )}
                {errors.drCrBoth0 && (
                  <Typography color="red">{errors.drCrBoth0}</Typography>
                )}
              </>
            )}

            {getAccountingHelp() && (
              <Typography px="1" my="2" color={colors.theme1}>
                {getAccountingHelp()}
              </Typography>
            )}

            <InputForm
              defaultValue={formData.description}
              label={t('generalVoucher.label.description')}
              placeHolder={t('generalVoucher.placeholder.description')}
              isInvalid={'description' in errors}
              helpText={getHelpText('description')}
              onChangeText={(v: string) => onFormChange('description', v)}
            />
          </Stack>
        </CardContent>

        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button title="Save & Get PDF" onClick={onSubmit} />
          <Button title="Save Transaction" onClick={onSubmit} />
        </Stack>
      </Card>
    </Container>
  );
}

export default GeneralVoucher;
