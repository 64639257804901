import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Stack, Typography } from '@mui/material';
import { Card, CardContent, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import SelectPicker from '../../components/forms/SelectPicker';
import {
  getAllBusinesses,
  switchBusiness,
} from '../../controllers/admin/adminControllers';
import { useDispatch } from '../../store/reduxHooks';
import { refreshUserDetails } from '../../store/authSlice';
import { errorToast, successToast } from '../../constants/snackbar/snackbar';

function BusinessSwitchPage() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const getBusinesses = () => getAllBusinesses();
  const allBusinesses = useQuery(['allBusinesses'], getBusinesses);

  const businessChange = useMutation(switchBusiness);

  const onBusinessChange = async (businessId: number) => {
    try {
      await businessChange.mutateAsync(businessId);
      await dispatch(refreshUserDetails());

      queryClient.invalidateQueries(['getDashboardReport']);
      enqueueSnackbar('Business Switch Successful', successToast);
    } catch (er) {
      // log err
    }
  };

  const getModifiedBusiness = () => {
    const modifiedBizs = [];

    if (allBusinesses?.data) {
      for (const b of allBusinesses?.data) {
        const modified = { ...b };
        modified.name = `(${b.id}) ${b.name} - ${b.phoneNumber || 'x'}`;
        modifiedBizs.push(modified);
      }
    }
    return modifiedBizs;
  };

  return (
    <Container>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              Switch Business
            </Typography>
          </Stack>
          <Divider />

          <Stack py={2} gap={3}>
            <SelectPicker
              label={'Select Business to Switch'}
              selectItems={getModifiedBusiness() || []}
              onValueChange={(v: number) => onBusinessChange(+v)}
            />
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}

export default BusinessSwitchPage;
