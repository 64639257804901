// Accounts controllers

const v1 = (path: string) => `/v1${path}`;
// const v2 = (path: string) => `v2${path}`;

/* TO DO : MOVEL ALL URL TO RESPECTIVE CONTROLLERS */
export const loginUrl = () => v1('/auth/login');
export const userRegistrationUrl = () => v1('/accounts/register');

export const refreshUserDetailsUrl = () => v1('/auth/user-details');
export const firebaseUserLoginUrl = () => v1('/auth/firebase-login');
export const userPasswordResetUrl = () => v1('/auth/password-reset');
export const userDateFormatUrl = () => v1('/accounts/user/date-format');
export const userLoginCodeUrl = () => v1('/accounts/user/login-code');
export const loginCodeStatusUrl = () => v1('/accounts/user/login-code-status');
export const helpTextStatusUrl = () => v1('/accounts/user/help-text-status');
export const invoiceInterestStatusUrl = () =>
  v1('/accounts/user/invoice-interest-status');
export const billInterestStatusUrl = () =>
  v1('/accounts/user/bill-interest-status');
export const panNumberStatusUrl = () => v1('/accounts/user/pan-number-status');
export const updateActiveBusinessUrl = () =>
  v1('/accounts/user/active-business');

export const makeOwnerUrl = (id: number) => v1(`/accounts/make-owner/${id}`);
export const removeOwnershipUrl = (id: number) =>
  v1(`/accounts/remove-ownership/${id}`);
export const makeAdminUrl = (id: number) => v1(`/accounts/make-admin/${id}`);
export const removeAdminUrl = (id: number) =>
  v1(`/accounts/remove-admin-access/${id}`);

// Dashboard controllers
export const dashboardReportUrl = () => v1('/reports/dashboard');

// Settings - AccConfig Controllers
export const accountConfigsUrl = () => v1('/settings/account-config');
export const updateAccountConfigsUrl = (id: number) =>
  v1(`/settings/account-config/${id}`);

// Settings - Accounts Controllers
export const accountsUrl = () => v1('/settings/accounts');
export const postingAccountsUrl = () => v1('/settings/posting-accounts');
export const bankdAndCashAccountsUrl = () =>
  v1('/settings/bank-and-cash-accounts');
export const billablePurchaseAccountsUrl = () =>
  v1('/settings/billable-purchase-accounts');
export const billableExpenseAccountsUrl = () =>
  v1('/settings/billable-expense-accounts');
export const incomeAccountsUrl = () => v1('/settings/income-accounts');
export const getAccountsByCodeUrl = (code: string) =>
  v1(`/settings/accounts/${code}`);
export const updateAccountUrl = (id: number) => v1(`/settings/accounts/${id}`);
export const accountCodeUrl = (id: number) =>
  v1(`/settings/account-code/${id}`);
export const accountByDefaultCodeUrl = (code: string) =>
  v1(`/settings/account-by-defaultcode/${code}`);

// Settings - Accounts groups Controllers
export const accountGroupsUrl = () => v1('/settings/account-groups');
export const updateAccountGroupUrl = (id: number) =>
  v1(`/settings/account-groups/${id}`);

// Settings - Business Controllers
export const businessUrl = () => v1('/accounts/businesses');
export const userBusinessesUrl = () => v1('/accounts/user-businesses');
export const updateBusinessUrl = (id: number) =>
  v1(`/accounts/businesses/${id}`);
export const activateBusinessUrl = (id: number) =>
  v1(`/accounts/activate-business/${id}`);
export const deActivateBusinessUrl = (id: number) =>
  v1(`/accounts/deactivate-business/${id}`);

// Settings - Employee Controllers
export const employeeUrl = () => v1('/accounts/employees');
export const updateEmloyeeUrl = (id: number) => v1(`/accounts/employees/${id}`);
export const activateEmployeeUrl = (id: number) =>
  v1(`/accounts/activate-employee/${id}`);
export const deActivateEmployeeUrl = (id: number) =>
  v1(`/accounts/deactivate-employee/${id}`);

// Settings - Partner Controllers
export const partnerUrl = () => v1('/settings/partners');
export const vendorUrl = () => v1('/settings/vendors');
export const customerUrl = () => v1('/settings/customers');
export const updatePartnerUrl = (id: number) => v1(`/settings/partners/${id}`);
export const partnerCodeUrl = () => v1('/settings/partner-code');
export const loanPartnerUrl = () => v1('/settings/loan-partners');
export const updateLoanPartnerUrl = (id: number) =>
  v1(`/settings/loan-partners/${id}`);
export const loanPartnerCodeUrl = () => v1('/settings/loan-partner-code');

// Settings - Accounting periods Controllers
export const accountingPeriodsUrl = () => v1('/settings/accounting-periods');
export const currentAccountingPeriodUrl = () =>
  v1('/settings/current-accounting-period');
export const companySetupStatusUrl = () => v1('/settings/company-setup-status');
export const getOpeningBalanceUrl = () =>
  v1('/settings/initial-opening-balance');
export const addOpeningBalanceUrl = () => v1('/settings/add-opening-balance');

// Transaction Controllers
export const voucherNumberUrl = (voucherTypeCode: string) =>
  v1(`/transactions/voucher-number/${voucherTypeCode}`);
export const voucherUrl = (voucherId: string) =>
  v1(`/transactions/voucher/${voucherId}`);
export const voucherForEditUrl = (voucherId: string) =>
  v1(`/transactions/voucher-edit/${voucherId}`);
export const vouchersUrl = () => v1('/transactions/vouchers');
export const getSoldItemsUrl = (id: any) =>
  v1(`/transactions/sales/sold-items/${id}`);
export const getSalesVoucherUrl = (id: any) =>
  v1(`/transactions/sales/sales-voucher/${id}`);
export const adjustingEntryUrl = () => v1('/transactions/adjusting-entry/');
export const deleteImageUrl = (imageId: number) =>
  v1(`/transactions/voucher-image/${imageId}`);
export const imageUploadUrl = () => v1('/transactions/voucher-image');

// Transactions - Bill Controllers
export const newBillUrl = () => v1('/transactions/purchases/credit-purchase');
export const purchaseExpenseUrl = () =>
  v1('/transactions/purchases/purchase-expense');
export const purchaseExpenseEditUrl = (voucherId: number) =>
  v1(`/transactions/purchases/purchase-expense/${voucherId}`);

export const otherExpenseUrl = () => v1('/transactions/expenses/other-expense');
export const otherExpenseEditUrl = (voucherId: number) =>
  v1(`/transactions/purchases/purchase-expense/${voucherId}`);

export const advanceBillPaymentUrl = () =>
  v1('/transactions/purchases/advance-payment');

// export const cashPurchaseUrl = () => '/transactions/purchases/credit-purchase';
export const billsUrl = () => v1('/transactions/bills');
// export const updateBillUrl = (id: number) => `/transactions/bills/${id}`;

export const getBillsToPayUrl = () => v1('/transactions/purchases/bills');
export const billPaymentUrl = () => v1('/transactions/purchases/bill-payment');
export const getPurchasesForReturnUrl = () =>
  v1('/transactions/purchases/get-purchases');
export const getBoughtItemsUrl = (id: any) =>
  v1(`/transactions/purchases/bought-items/${id}`);
export const getPurchaseReturnUrl = () => v1('/transactions/purchases/return');

// export const cashPurchaseEditUrl = (voucherId: number) =>
//   `/transactions/purchases/cash-purchase/${voucherId}`;

export const vendorReceiptUrl = () =>
  v1('/transactions/purchases/vendor-receipt');

// Cash transactions
export const paymentOnCashUrl = () =>
  v1('/transactions/purchases/cash-purchase');
export const salesOnCashUrl = () => v1('/transactions/sales/cash-sales');
export const salaryPaymentUrl = () =>
  v1('/transactions/expenses/salary-payment');

// Lend or borrow money
export const borrowLoanUrl = () => v1('/transactions/loans/borrow-loan');
export const getBorrowedLoansUrl = () =>
  v1('/transactions/loans/get-borrowed-loans');
export const borrowedLoanPaymentUrl = () =>
  v1('/transactions/loans/borrowed-loan-payment');
export const lendLoanUrl = () => v1('/transactions/loans/lend-loan');
export const getLentLoansUrl = () => v1('/transactions/loans/get-lent-loans');
export const receiveLentLoanUrl = () =>
  v1('/transactions/loans/receive-lent-loan');
export const addBankLoanUrl = () => v1('/transactions/loans/bank-loans');
export const getBankLoanUrl = (id: number) =>
  v1(`/transactions/loans/bank-loans/${id}`);
export const emiPaymentUrl = () => v1('/transactions/loans/emi-payment');

// Transactions - Transfer Controllers
export const fundTransferUrl = () => v1('/transactions/fund-transfer');
export const partnerTransferUrl = () => v1('/transactions/partner-transfer');

// Transactions - Invoice Controllers
export const invoiceUrl = () => v1('/transactions/invoices');
export const salesIncomeUrl = () => v1('/transactions/sales/sales-income');
// export const cashSalesUrl = () => '/transactions/sales/cash-sales';
export const otherIncomeUrl = () => v1('/transactions/sales/other-income');
// export const cashIncomeUrl = () => '/transactions/sales/cash-income';
export const getSalesForReturnUrl = () => v1('/transactions/sales/get-sales');
export const salesReturnUrl = () => v1('/transactions/sales/return');
export const advanceReceiveUrl = () =>
  v1('/transactions/sales/advance-receive');
export const getInvoicesToReceiveUrl = () => v1('/transactions/sales/invoices');
export const invoiceReceiveUrl = () =>
  v1('/transactions/sales/invoice-receive');
export const vatAmountUrl = () => v1('/reports/vat-amount');
export const vatPaymentUrl = () =>
  v1('/transactions/expenses/vat-return-entry');
export const cashSalesEditUrl = (voucherId: number) =>
  v1(`/transactions/sales/cash-sales/${voucherId}`);
export const creditSalesEditUrl = (voucherId: number) =>
  v1(`/transactions/sales/sales-income/${voucherId}`);
export const customerPaymentUrl = () =>
  v1('/transactions/sales/customer-payment');

// Transactions - Voucher Controllers
export const generalVoucherUrl = () => v1('/transactions/general-vouchers');
export const updateVoucherUrl = (id: number) =>
  v1(`/transactions/general-vouchers/${id}`);
export const recentTransactionsUrl = () =>
  v1('/transactions/recent-transactions');
export const transactionsCountUrl = () =>
  v1('/transactions/transactions-count');
export const voucherDeleteCheckUrl = (id: string) =>
  v1(`/transactions/voucher-delete-check/${id}`);
export const voucherDeleteUrl = (id: string) =>
  v1(`/transactions/voucher-delete/${id}`);

// Inventory Controllers
export const productUnitUrl = () => v1('/inventory/product-unit');
export const productCategoryUrl = () => v1('/inventory/product-category');
export const productItemUrl = () => v1('/inventory/product-items');
// export const stockAdjustmentUrl = () => v1('/inventory/stock-adjustments');
export const expenseItemUrl = () => v1('/inventory/expense-items');
export const productStockUrl = () => v1('/inventory/product-stocks');
export const productStockDetailsUrl = (id: number) =>
  v1(`/inventory/product-stocks/${id}`);

// Reports Controllers
export const generalLedgerUrl = () => v1('/reports/general-ledger');
export const partnerLedgerUrl = () => v1('/reports/partner-ledger');
export const partnersAccountUrl = () => v1('/reports/partners-account-ledger');
export const dayBookUrl = () => v1('/reports/day-book');
export const trialBalanceUrl = () => v1('/reports/trial-balance');
export const balanceSheetUrl = () => v1('/reports/balance-sheet');
export const productStatementUrl = () => v1('/reports/product-statement');
export const expenseStatementUrl = () => v1('/reports/expense-statement');
export const employeeStatementUrl = () => v1('/reports/employee-statement');
export const bankloanStatementUrl = () => v1('/reports/bankloan-statement');
export const partnerStatementUrl = () =>
  v1('/reports/partner-income-statement');
export const partnerProductStatementUrl = () =>
  v1('/reports/partner-product-statement');
export const productStockMovementUrl = () =>
  v1('/inventory/product-stock-movement');
export const bankAndCashBookUrl = () => v1('/reports/bank-and-cash-book');
export const incomeStatementUrl = () => v1('/reports/income-statement');
export const bankAndCashBalanceCheckUrl = (id: number, amount: number) =>
  v1(`/reports/balance-check/${id}/${amount}`);

// Partner Reports
export const customerBalanceUrl = (cusId: number) =>
  v1(`/reports/customer-balance/${cusId}`);
export const vendorBalanceUrl = (venId: number) =>
  v1(`/reports/vendor-balance/${venId}`);

// Subscriptions
export const subscriptionProductsUrl = () =>
  v1('/subscriptions/subscription-products');

// System Controllers
export const accountTypesUrl = () => v1('/system/account-types');
export const voucherTypesUrl = () => v1('/system/voucher-types');

// UserLogs Controllers
export const userLogsUrl = (id: number) => v1(`/user-logs/${id}`);

// Synchronize Controllers
export const pullChangesUrl = () => v1('/synchronize/pull-changes');
export const pushChangesUrl = () => v1('/synchronize/push-changes');
