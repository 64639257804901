import { getFinalProductPrice } from './vatAndDiscount';

export const itemsAmountSum = (voucherItems: any) => {
  let amountSum = 0;
  for (const vItem of voucherItems) {
    const amount = +vItem.amount || 0;
    const discount = +vItem.discountPrice || 0;

    const final = getFinalProductPrice(amount, discount, vItem.taxable);
    amountSum += final;
  }
  return amountSum;
};
