import { store } from '../../store/store';
import { customerBalanceUrl, vendorBalanceUrl } from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};
export const getCustomerBalance = async (customerId: number) => {
  const path = customerBalanceUrl(customerId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getVendorBalance = async (customerId: number) => {
  const path = vendorBalanceUrl(customerId);

  const response = await httpClient().get<any>(path);
  return response.data;
};
