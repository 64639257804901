import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = ({ minHeight = 480 }): JSX.Element => {
  // console.log('yes was loading...');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        // backgroundColor: '#DFDFDF',
        borderRadius: 1,
        minHeight,
      }}>
      <CircularProgress />
    </Box>
  );
};

export default Loading;
