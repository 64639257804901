import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import { employeeStatement } from '../../../controllers/reports/reportControllers';
import { validateFromAndToDate } from '../../../validations/reports/validateFromAndToDate';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { formatAmount } from '../../../shared/utils/locale';
import { colors } from '../../../assets/styles/colors';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';

function StaffSalaryExpense() {
  const { t } = useTranslation();

  const initalState: any = { ...initialDate, toDate: new Date() };
  const [filters, setFilters] = useState<any>(initalState);

  const [errors, setErrors] = React.useState({});
  const [showFilters, setShowFilter] = React.useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  const empStatFn = () => employeeStatement(filters);
  const empStatements = useQuery(['employeeStat', filters], empStatFn, {
    enabled: false,
  });

  const onSubmit = async () => {
    const { isValid, errs } = validateFromAndToDate(filters, errors);

    if (isValid) {
      empStatements.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  // console.log('empStatements:', empStatements.data);

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const pickerSize = 'small';

  if (empStatements.isSuccess && empStatements.isFetched && !showFilters) {
    return (
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.salary')}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={empStatements.isLoading}
            isError={empStatements.isError}
            isEmpty={!empStatements.data?.employeeStatements.length}>
            <TableContainer>
              <Table aria-label="salary">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Employee Name</TbCellHeader>
                    <TbCellHeader align="right">
                      Salary Expense Amount
                    </TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {empStatements.data?.employeeStatements.map((row, index) => (
                    <TableRowStyled key={row.id}>
                      <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                      <TbCellBody>{row.employee}</TbCellBody>
                      <TbCellAmount>{formatAmount(row.expense)}</TbCellAmount>
                    </TableRowStyled>
                  ))}
                </TableBody>
                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TableCell />
                    <TbCellAmount sx={{ fontStyle: 'italic' }}>
                      Total Expense
                    </TbCellAmount>
                    <TbCellSummary>
                      {formatAmount(empStatements.data?.totalExpense)}
                    </TbCellSummary>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={false}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.salary')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid py={3} container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Employee Statements'}
              onClick={onSubmit}
              loading={empStatements.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}
export default StaffSalaryExpense;
