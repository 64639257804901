import React, { useEffect, useState } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import {
  validatePIVoucherNo,
  validatePaymentIn,
} from '../../../validations/transactions/validateAdvanceReceive';
import { useSelector } from '../../../store/reduxHooks';
import { useTranslation } from 'react-i18next';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import { getPartners } from '../../../controllers/settings/partnerControllers';
import { bankAndCashAccounts } from '../../../controllers/settings/accountControllers';
import {
  getCustomerBalance,
  getVendorBalance,
} from '../../../controllers/reports/partnerReports';
import { paymentInAPI } from '../../../controllers/transactions/invoiceControllers';
import { DashboardCardType } from '../../../constants/defaultCodes/transactionCodes';
import Button, { TextButton } from '../../../components/buttons/Button';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import ImagePicker from '../../../components/image/ImagePicker';
import PartyPaymentWarning from './components/PartyPaymentWarning';
import { getCashBalance } from '../../../controllers/reports/reportControllers';

const voucherType = voucherTypes.PAYMENT_IN;
const initial = { initialData: [] };

function PaymentInPage() {
  const { user } = useSelector(state => state.auth.authUser);
  const { enableInvoiceInterest } = user?.business;

  // const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const init = { voucherDate: new Date() };
  const [formData, setData] = useState<any>(init);
  const [errors, setErrors] = useState<any>({});
  const [images, setImages] = useState<any>([]);
  const [partnerBalance, setPartnerBalance] = useState<any>();
  const [dashCardType, setDashCardType] = useState<string>();
  const [warning, setWarning] = useState<any>({});
  const [cashInAccBalance, setCashInAccBalance] = useState<any>();

  const { data: voucherNumber } = useQuery(['getVoucherNo', voucherType], () =>
    getVoucherNumber(voucherType),
  );

  // const customers = useQuery(['getCustomers'], getCustomers, initial);
  const partners = useQuery(['getPartners'], getPartners, initial);

  const accounts = useQuery(['bankAndCashAcc'], bankAndCashAccounts, initial);
  const customerBalanceQuery = useMutation(getCustomerBalance);
  const vendorBalanceQuery = useMutation(getVendorBalance);
  const cashBalanceQuery = useMutation(getCashBalance);

  const customerWithPayableWarning = (party: any) => {
    const title = t('paymentIn.customerWithPayable.title', {
      name: party.name,
    });
    const description = t('paymentIn.customerWithPayable.subTitle');
    setWarning({ open: true, title, description });
  };

  const vendorWithPayableWarning = (party: any) => {
    const title = t('paymentIn.vendorWithPayable.title', { name: party.name });
    const description = t('paymentIn.vendorWithPayable.subTitle');
    setWarning({ open: true, title, description });
  };

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  const { isLoading, mutateAsync } = useMutation(paymentInAPI);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async () => {
    const { isValid, errs } = validatePaymentIn(formData, errors);
    const { isVoucherNoValid, errss } = await validatePIVoucherNo(
      formData,
      errs,
    );

    if (isValid && isVoucherNoValid) {
      try {
        if (images && images.length) {
          const imgPaylaod = { images, voucherType: 'payment-in' };
          const image = await saveImage.mutateAsync(imgPaylaod);
          formData.image = image.id;
        }

        await mutateAsync(formData);

        queryClient.invalidateQueries(['getDashboardReport']);
        navigate('/journals', { replace: true });
        enqueueSnackbar(t('paymentIn.createMsg.success'), successToast);
      } catch (err) {
        let errMsg = t('paymentIn.createMsg.error');
        if (err.response?.data?.statusCode === 400) {
          errMsg = err.response?.data?.message;
        }
        enqueueSnackbar(errMsg, errorToast);
      }
    } else {
      setErrors(errss);
      if (!isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    // to do herer...
    let discount = formData.discount;
    if (name === 'discount') {
      discount = value;
    }
    if (name === 'partner' && value && formData.partner !== value) {
      const party = partners.data?.find(x => x.id === value);

      if (party.isCustomer && !party.isVendor) {
        const response = await customerBalanceQuery.mutateAsync(value);
        setPartnerBalance({ type: 'CASH_IN', balance: response });
        setDashCardType(DashboardCardType.receivable);

        response <= 0 && (discount = 0);

        if (response < 0) {
          customerWithPayableWarning(party);
        }
      } else if (!party.isCustomer && party.isVendor) {
        const response = await vendorBalanceQuery.mutateAsync(value);
        setPartnerBalance({ type: 'CASH_OUT', balance: response });
        setDashCardType(DashboardCardType.payable);
        response >= 0 && (discount = 0);

        if (response > 0) {
          vendorWithPayableWarning(party);
        }
      }
    } else if (name === 'partner' && !value) {
      setPartnerBalance(null);
    }

    setData((prev: any) => ({ ...prev, [name]: value, discount }));

    const updatedData = { ...formData, [name]: value };
    const { errs } = validatePaymentIn(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});

    if (name === 'cashInAccount' && value && formData.cashInAccount !== value) {
      const response = await cashBalanceQuery.mutateAsync(value);
      setCashInAccBalance({ type: 'CASH_IN', balance: response });
    } else if (name === 'cashInAccount' && !value) {
      setCashInAccBalance(null);
    }
  };

  const getSelectItems = (selectType: string) => {
    const getNameId = (x: any) => ({
      id: x.id,
      name: `${x.code} ${x.name} ${x.phoneNumber ? `(${x.phoneNumber})` : ''}`,
    });

    const customersX = partners.data.filter(x => x.isCustomer);
    const vendorsX = partners.data.filter(x => x.isVendor);
    const mergedPartners = customersX.concat(vendorsX);

    if (selectType === 'partner') {
      const partnerItems = mergedPartners.map((x: any) => getNameId(x));
      return partnerItems;
    }
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const getSelPartner = () => {
    if (formData.partner) {
      return partners.data?.find(x => x.id === formData.partner);
    }
  };

  const showDiscountField = () => {
    if (getSelPartner()?.isVendor && partnerBalance?.balance >= 0) {
      return false;
    }

    if (getSelPartner()?.isCustomer && partnerBalance?.balance <= 0) {
      return false;
    }

    return true;
  };

  // console.log('formData pay in:', formData);
  // console.log('errors:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('transactionStack.paymentIn')}
          </Typography>
          <Divider />
          <Stack py={2} spacing={4}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('paymentIn.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('paymentIn.label.voucherNumber')}
                  isDisabled={true}
                  isRequired={true}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  defaultValue={formData.voucherNumber}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label="Reference Number"
                  defaultValue={formData.referenceId}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {/* <Grid xs={formData.partner ? 11 : 12}> */}
              <Grid xs={12}>
                <SelectPicker
                  isRequired={true}
                  label={t(`paymentIn.label.partner`)}
                  selectItems={getSelectItems('partner')}
                  selectedValue={formData.partner}
                  isInvalid={'partner' in errors}
                  helpText={getHelpText('partner')}
                  onValueChange={(v: any) => onFormChange('partner', v)}
                  partnerBalance={partnerBalance && partnerBalance}
                />
              </Grid>
              {/* {formData.partner && (
                <Grid
                  xs={1}
                  sx={{ display: 'flex', alignItems: 'flex-end', pb: 1 }}>
                  <TextButton onClick={onNaviation} title="VIEW" />
                </Grid>
              )} */}
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={8}>
                <InputForm
                  isRequired={true}
                  isNumberic={true}
                  defaultValue={formData.amount}
                  isInvalid={'amount' in errors}
                  helpText={getHelpText('amount')}
                  label={t('paymentIn.label.amount')}
                  onChangeText={(v: string) => onFormChange('amount', v)}
                />
              </Grid>

              {showDiscountField() && (
                <Grid xs={4}>
                  <InputForm
                    isNumberic={true}
                    defaultValue={formData.discount}
                    label={t('paymentIn.label.discount')}
                    isInvalid={'discount' in errors}
                    helpText={getHelpText('discount')}
                    onChangeText={(v: string) => onFormChange('discount', v)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <SelectPicker
                  isRequired={true}
                  selectedValue={formData.cashInAccount}
                  selectItems={accounts.data}
                  label={t('paymentIn.label.cashInAccount')}
                  isInvalid={'cashInAccount' in errors}
                  helpText={getHelpText('cashInAccount')}
                  onValueChange={(v: any) => onFormChange('cashInAccount', v)}
                  partnerBalance={cashInAccBalance && cashInAccBalance}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
                  <Stack flex={1}>
                    <InputForm
                      inLineLabel={true}
                      defaultValue={formData.description}
                      label={t('paymentIn.label.description')}
                      isInvalid={'description' in errors}
                      helpText={'description' in errors && errors.description}
                      onChangeText={(v: string) =>
                        onFormChange('description', v)
                      }
                    />
                  </Stack>
                  <Stack pt={0.1}>
                    <ImagePicker {...{ images, setImages }} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Payment In"
            onClick={onSubmit}
            loading={isLoading || saveImage.isLoading}
          />
        </Stack>

        {warning.open && (
          <PartyPaymentWarning
            warning={warning}
            handleClose={prev => setWarning({ ...prev, open: false })}
          />
        )}
      </Card>
    </Container>
  );
}

export default PaymentInPage;
