import i18next from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';
const { SALARY_PAYMENT } = voucherTypes;

export const validateVoucherNo = async (data: any, errors: any) => {
  const newVoucherNumber = await getVoucherNumber(SALARY_PAYMENT);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateSalaryPayment = (
  data: any,
  errors: any,
  hasBalance?: boolean,
) => {
  let isValid = true;

  if (data.voucherNumber === undefined) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18next.t('voucher.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.voucherDate === undefined) {
    errors = {
      ...errors,
      voucherDate: i18next.t('voucher.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (data.employee === undefined) {
    errors = {
      ...errors,
      employee: i18next.t('voucher.validation.employeeRequired'),
    };
    isValid = false;
  } else {
    delete errors.employee;
  }

  if (data.cashOutAccount === undefined) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('voucher.validation.paidFromAccount'),
    };
    isValid = false;
  } else if (hasBalance === false) {
    errors = {
      ...errors,
      cashOutAccount: i18next.t('voucher.validation.inSufficientBalance'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  if (data.amount === undefined || data.amount <= 0) {
    errors = {
      ...errors,
      amount: i18next.t('voucher.validation.amountRequired'),
    };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('voucher.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};
