import React from 'react';

import AuthRequired from './AuthRequired';
import Transactions from '../pages/transaction/Transactions';
import SalesIncome from '../pages/transaction/sales/SalesIncome';
import OtherIncome from '../pages/transaction/sales/OtherIncome';
import PurchaseExpense from '../pages/transaction/purchases/PurchaseExpense';
import OtherExpense from '../pages/transaction/expenses/OtherExpense';
import PaymentInPage from '../pages/transaction/paymentInOut/PaymentIn';
import SalesReturn from '../pages/transaction/sales/SalesReturn';
import PaymentOutPage from '../pages/transaction/paymentInOut/PaymentOut';
import SalaryExpense from '../pages/transaction/expenses/SalaryPayment';
import VatExpense from '../pages/transaction/expenses/VatExpense';
import StockAdjustment from '../pages/transaction/general/StockAdjustment';
import GeneralVoucher from '../pages/transaction/general/GeneralVoucher';
import FundTransfer from '../pages/transaction/transfers/FundTransfer';
import PartnerTransfer from '../pages/transaction/transfers/PartnerTransfer';
import PurchaseReturn from '../pages/transaction/purchases/PurchaseReturn';

const rootPath = 'transaction';

const transactionRoutes = [
  {
    path: `/${rootPath}`,
    element: (
      <AuthRequired>
        <Transactions />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/sales`,
    element: (
      <AuthRequired>
        <SalesIncome />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/update-sales`,
    element: (
      <AuthRequired>
        <SalesIncome isUpdate />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/payment-in`,
    element: (
      <AuthRequired>
        <PaymentInPage />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/sales-return`,
    element: (
      <AuthRequired>
        <SalesReturn />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/incomes`,
    element: (
      <AuthRequired>
        <OtherIncome />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/update-incomes`,
    element: (
      <AuthRequired>
        <OtherIncome isUpdate />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/purchases`,
    element: (
      <AuthRequired>
        <PurchaseExpense />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/update-purchases`,
    element: (
      <AuthRequired>
        <PurchaseExpense isUpdate />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/payment-out`,
    element: (
      <AuthRequired>
        <PaymentOutPage />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/expenses`,
    element: (
      <AuthRequired>
        <OtherExpense />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/update-expenses`,
    element: (
      <AuthRequired>
        <OtherExpense isUpdate />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/purchase-return`,
    element: (
      <AuthRequired>
        <PurchaseReturn />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/salary-payment`,
    element: (
      <AuthRequired>
        <SalaryExpense />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/vat-payment`,
    element: (
      <AuthRequired>
        <VatExpense />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/stock-adjusment`,
    element: (
      <AuthRequired>
        <StockAdjustment />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/general-voucher`,
    element: (
      <AuthRequired>
        <GeneralVoucher />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/fund-transfer`,
    element: (
      <AuthRequired>
        <FundTransfer />
      </AuthRequired>
    ),
  },
  {
    path: `/${rootPath}/partner-transfer`,
    element: (
      <AuthRequired>
        <PartnerTransfer />
      </AuthRequired>
    ),
  },
];

export default transactionRoutes;
