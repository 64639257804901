import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Stack, TextField, Typography } from '@mui/material';

import { colors } from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';

const InputForm = ({
  label,
  placeHolder,
  onChangeText,
  helpText,
  isInvalid,
  defaultValue,
  size = 'medium',
  isNumberic,
  inLine,
  maxLength,
  isRequired = false,
  isDisabled = false,
  autoCapitalize = 'sentences',
  silentInvalid = false,
  multiline = false,
  inLineLabel = false,
  numberOfLines = 1,
  isFloatNo = true,
  isPassword = false,
  id = '',
  autoComplete = '',
}: any): JSX.Element => {
  const { t } = useTranslation();

  const getStyles = () => {
    let inputStyles: any = {};
    if (isRequired) {
      inputStyles = { ...inputStyles, borderColor: colors.red, borderWidth: 2 };
    }
    return inputStyles;
  };

  // console.log('input defval:', defaultValue);

  return (
    <Stack>
      <Stack py={0.4} direction={'row'} justifyContent={'space-between'}>
        {label && !inLineLabel && (
          <Box py={0.4}>
            {/* <label style={{ fontWeight: 'bold', color: colors.darker4 }}> */}
            <label>{label}</label>
          </Box>
        )}
      </Stack>

      <TextField
        id={id || label}
        name={id || label}
        autoComplete={autoComplete}
        style={getStyles()}
        sx={{
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: colors.darker3,
            backgroundColor: colors.gray100,
          },
          '.MuiOutlinedInput-notchedOutline': isRequired
            ? {
                'border-left-color': colors.red500,
                'border-left-width': '2px',
              }
            : {},
        }}
        inputProps={{ maxLength }}
        // defaultValue={defaultValue || ''}  // Use Only for initial value
        // value={defaultValue || ''} // cant keep or '' over here instead keep in main component
        value={defaultValue}
        error={isInvalid ? true : false}
        size={size}
        label={inLineLabel ? label : undefined}
        variant="outlined"
        required={isRequired}
        disabled={isDisabled}
        autoCapitalize={autoCapitalize}
        placeholder={placeHolder ? placeHolder : label}
        type={isNumberic ? 'number' : !isPassword ? 'text' : 'password'}
        multiline={multiline}
        rows={numberOfLines}
        onChange={e => onChangeText(e.target.value)}
        onInput={e => {
          if (!isFloatNo && isNumberic && maxLength) {
            var InputElement = e.target as HTMLInputElement;
            InputElement.value = Math.max(0, parseFloat(InputElement.value))
              .toString()
              .slice(0, maxLength);
          }
        }}
      />
      {isInvalid && !silentInvalid && (
        <Typography pt={0.4} fontSize={14} color={colors.red500}>
          {`${t('commonText.error')}: ${helpText}`}
        </Typography>
      )}

      {helpText && !isInvalid && (
        <Typography pt={0.4} fontSize={14}>
          {helpText}
        </Typography>
      )}
    </Stack>
  );
};

export default InputForm;
