import voucherTypes from '../../constants/defaultCodes/voucherTypes';

export const getEditScreen = (voucherType: string) => {
  switch (voucherType) {
    case voucherTypes.CASH_SALES:
      return '/transaction/update-sales';
    case voucherTypes.CREDIT_SALES:
      return '/transaction/update-sales';
    case voucherTypes.CASH_PURCHASE:
      return '/transaction/update-purchases';
    case voucherTypes.CREDIT_PURCHASE:
      return '/transaction/update-purchases';
    case voucherTypes.CASH_INCOME:
      return '/transaction/update-incomes';
    case voucherTypes.CREDIT_INCOME:
      return '/transaction/update-incomes';
    case voucherTypes.CASH_EXPENSE:
      return '/transaction/update-expenses';
    case voucherTypes.CREDIT_EXPENSE:
      return '/transaction/update-expenses';
    default:
      return 'none';
  }
};

export const entryHasPDF = (voucherType: string) => {
  const sales = [voucherTypes.CASH_SALES, voucherTypes.CREDIT_SALES];
  const purc = [voucherTypes.CASH_PURCHASE, voucherTypes.CREDIT_PURCHASE];
  const income = [voucherTypes.CASH_INCOME, voucherTypes.CREDIT_INCOME];
  const exp = [voucherTypes.CASH_EXPENSE, voucherTypes.CREDIT_EXPENSE];

  const valid = [...sales, ...purc, ...income, ...exp];
  if (valid.includes(voucherType)) {
    return true;
  }
  return false;
};
