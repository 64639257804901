import React from 'react';
import { useRouteError } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Typography, TextField, Box, Button, Link } from '@mui/material';

const notFound = require('../assets/images/404.png');

export default function Error404Page() {
  // const error: any = useRouteError();
  // console.error(error);

  const image = {
    maxWidth: 300,
    height: 'auto',
  };

  return (
    <Stack
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '75vh',
        gap: 12,
      }}>
      <img src={notFound} style={image} />

      <Stack alignItems={'center'}>
        <Typography fontWeight={'bold'} sx={{ fontSize: 28 }}>
          Page Not Found !!!
        </Typography>
        <Typography sx={{ fontSize: 20 }}>
          Oops, We couldn't find the page that you're looking for !!!
        </Typography>
        <br />
        <Link href={'/'}>Go To Home Page</Link>
      </Stack>
    </Stack>
  );
}
