import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Stack, Typography } from '@mui/material';
import { Card, CardContent, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import SelectPicker from '../../components/forms/SelectPicker';
import {
  createSubsOrderBySysAdmin,
  getActiveVerifiedUsers,
  getAllBusinesses,
  switchBusiness,
} from '../../controllers/admin/adminControllers';
import { useDispatch } from '../../store/reduxHooks';
import { refreshUserDetails } from '../../store/authSlice';
import { errorToast, successToast } from '../../constants/snackbar/snackbar';
import { getSubscriptionProducts } from '../../controllers/settings/otherSettingControllers';
import { validateSubsOrder } from '../../validations/sysAdmin/validateOrderCreate';
import InputForm from '../../components/forms/InputForm';
import { formatDate } from '../../shared/utils/dateTime';
import { paymentModes } from '../../constants/defaultCodes/otherEnums';
import { getSubscriptionDiscountedPrice } from '../../shared/utils/subscriptionPrice';
import { formatAmount } from '../../shared/utils/locale';
import { getSystemConfigs } from '../../controllers/system/systemControllers';
import { colors } from '../../assets/styles/colors';

function SystemAdmin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [formData, setData] = useState<any>({ totalBusiness: 1 });
  const [errors, setErrors] = useState<any>({});

  const subsProd = () => getSubscriptionProducts();
  const subsProdQuery = useQuery(['subscriptionProducts'], subsProd);
  const { isFetched, data: subsProducts = [] } = subsProdQuery;

  const getBusinesses = () => getAllBusinesses();
  const allBusinesses = useQuery(['allBusinesses'], getBusinesses);

  const getActiveUsers = () => getActiveVerifiedUsers();
  const allActiveUsers = useQuery(['allAVAUsers'], getActiveUsers);

  const sysConfigsQuery = useQuery(['sysConfigs'], getSystemConfigs);
  const { data: sysConfigs = {} } = sysConfigsQuery;

  const vatEnabled = sysConfigs.enableVATOnSubscription === 'true';

  const businessChange = useMutation(switchBusiness);

  const onBusinessChange = async (businessId: number) => {
    try {
      await businessChange.mutateAsync(businessId);
      await dispatch(refreshUserDetails());

      queryClient.invalidateQueries(['getDashboardReport']);
      enqueueSnackbar('Business Switch Successful', successToast);
    } catch (er) {
      // log err
    }
  };

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateSubsOrder(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const { isLoading, mutateAsync } = useMutation(createSubsOrderBySysAdmin);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validateSubsOrder(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        navigate('/', { replace: true }); // later redirect to the list of active subscribers
        enqueueSnackbar('Subscription Order Created!', successToast);
      } catch (err) {
        enqueueSnackbar('Error Creating Subscription Order', errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  // console.log('allActiveUsers?.data', allActiveUsers?.data);

  const getAllActiveUsers = () => {
    const modifiedUsers = [];

    if (allActiveUsers?.data) {
      for (const x of allActiveUsers?.data) {
        const modified = { ...x };
        const name1 = `${x.firstName} ${x.lastName} - ${x.phoneNumber}`;
        const name2 = `${x.email} - ${x.companyAccount.name}`;
        modified.name = `${name1} - ${name2}`;
        modifiedUsers.push(modified);
      }
    }
    return modifiedUsers;
  };

  const getSubsProducts = () => {
    const modifiedProds = [];

    for (const p of subsProducts) {
      const modified = { ...p };
      const name1 = `${p.name} - ${p.type} (${p.months} months)`;
      const name2 = ` - ${p.offerName} ${p.discountPercent}%`;
      modified.name = `${name1} ${name2}`;
      modifiedProds.push(modified);
    }
    return modifiedProds;
  };

  // console.log('allBusinesses?.data', allBusinesses?.data);

  const getModifiedBusiness = () => {
    const modifiedBizs = [];

    if (allBusinesses?.data) {
      for (const b of allBusinesses?.data) {
        const modified = { ...b };
        // modified.name = `${b.name} - ${b.phoneNumber}`;
        modifiedBizs.push(modified);
      }
    }
    return modifiedBizs;
  };

  const product = subsProducts.find(x => x.id === formData.product);
  const price = product?.price || 0;
  // const price = product?.price * formData.totalBusiness || 0;
  const discountPercent = product?.discountPercent || 0;
  const discountAmount = (discountPercent / 100) * price;

  const amtAfterDis1 = getSubscriptionDiscountedPrice(price, discountPercent);

  const totAmtAfterDis1 = amtAfterDis1 * formData.totalBusiness;
  const totalAmtAfterDis2 = totAmtAfterDis1 - (formData.otherDiscount || 0); // decrease extra discount given

  let taxAmount = 0;
  if (vatEnabled) {
    taxAmount = Math.ceil(0.13 * totalAmtAfterDis2);
  }
  const finalSubsAmount = totalAmtAfterDis2 + taxAmount;

  useEffect(() => {
    onFormChange('amount', finalSubsAmount);
  }, [finalSubsAmount]);

  // console.log('formData', formData);
  // console.log('errors', errors);

  return (
    <Card sx={{ paddingX: 4, minHeight: 840 }}>
      <CardContent>
        <Stack gap={4} width={300}>
          <LoadingButton
            size="large"
            variant="contained"
            loading={false}
            onClick={() => navigate('subscription-order')}>
            Subscription Order
          </LoadingButton>

          <LoadingButton
            size="large"
            variant="contained"
            loading={false}
            onClick={() => navigate('business-switch')}>
            Business Switch
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SystemAdmin;
