import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { Box, CardContent, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSelector } from '../../../store/reduxHooks';
import { productExpiryReport } from '../../../controllers/settings/inventoryControllers';
import InputForm from '../../../components/forms/InputForm';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { queryStatusWithLength } from '../../../shared/utils/queryUtils';
import { colors } from '../../../assets/styles/colors';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import { getAgingDate } from '../../../shared/utils/dateTime';

const init = { initialData: [] };

function ProductExpiryReport() {
  const { t } = useTranslation();
  const { user } = useSelector(state => state.auth.authUser);
  // const navigation = useNavigation<any>();

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');

  const reportQuery = useQuery(['prodExpiryReport'], productExpiryReport, init);
  const { data = [] } = reportQuery;

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = data.filter((product: any) => {
      const { name, currentStock } = product;

      const hasName = name?.toLowerCase().includes(text);
      const hasCurrentStk = String(currentStock)?.toLowerCase().includes(text);

      if (hasName || hasCurrentStk) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let productList = data;
  if (searchText) {
    productList = filteredItems;
  }

  return (
    <ScreenWrapper {...queryStatusWithLength([reportQuery])}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'} alignItems={'center'}>
            <Typography flex={3} sx={{ fontSize: 20 }}>
              {t('reportStack.productExpiry')} ({productList.length})
            </Typography>
            <Stack pb={0.6} flex={1}>
              <InputForm
                size="small"
                inLineLabel={true}
                label={'Search Product...'}
                onChangeText={(v: string) => onSearch(String(v))}
              />
            </Stack>
          </Stack>

          <Divider />

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Product Name</TbCellHeader>
                  <TbCellHeader>Expiry Date</TbCellHeader>
                  <TbCellHeader align="right">Expires In</TbCellHeader>
                  <TbCellHeader align="right">Current Stock</TbCellHeader>
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {productList.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>
                      {getSettingBasedDate(row.expiryDate, user)}
                    </TbCellBody>
                    <TbCellAmount sx={{ color: colors.darker3 }}>
                      {getAgingDate(row.expiryDate)} days
                    </TbCellAmount>
                    <TbCellAmount sx={{ color: colors.theme2 }}>
                      {row.currentStock} {row.primaryUnit?.name}
                    </TbCellAmount>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default ProductExpiryReport;
