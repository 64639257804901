import React, { useEffect, useState } from 'react';
// import { VStack, useToast, ScrollView, HStack, Box } from 'native-base';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Divider, Box, Stack, Typography, Container } from '@mui/material';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';

import InputForm from '../../../components/forms/InputForm';

import { getAccountTypes } from '../../../controllers/system/systemControllers';
import {
  accountInputFields1,
  accountInputFields2,
  accountSelectFields,
} from '../../../constants/formFields/settings/accountFields';
import {
  createAccount,
  getAccountCode,
} from '../../../controllers/settings/accountControllers';
import { validateAccount } from '../../../validations/settings/validateAccount';
import SelectPicker from '../../../components/forms/SelectPicker';
import DatePicker from '../../../components/datePickers/DatePicker';
import { assetsTypes } from '../../../constants/defaultCodes/accountTypeCodes';
import { colors } from '../../../assets/styles/colors';
import Button from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

const CreateAccount = (): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const typeId = formData.accountType;

  const getCodeFn = () => getAccountCode(typeId);
  const accountCode = useQuery(['getAccCode'], getCodeFn, { enabled: false });

  const accountTypes: any = useQuery(['getAccountTypes'], getAccountTypes);

  useEffect(() => {
    if (formData.accountType) {
      accountCode.refetch();
    }
  }, [formData.accountType]);

  useEffect(() => {
    if (accountCode.data) {
      setData(prev => ({ ...prev, code: String(accountCode.data) }));
    }
  }, [accountCode.data]);

  // console.log('accountCode', accountCode.data);

  const { isLoading, mutateAsync } = useMutation(createAccount);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validateAccount(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getAccounts']);
        queryClient.invalidateQueries(['getDashboardReport']);
        navigate('/settings/accounts', { replace: true });

        enqueueSnackbar(t('account.createMsg.success'), successToast);
      } catch (err) {
        // console.error('err:', err); // report to err reporting services
        enqueueSnackbar(t('account.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateAccount(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData:', formData);
  // console.log('errors:', errors);

  const accType = accountTypes.data?.find(x => x.id === formData.accountType);
  const hasOpening = assetsTypes.includes(accType?.code);
  // console.log('hasOpenign:', hasOpening);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.addAccount')}
          </Typography>
          <Divider />
          <Stack py={4} gap={3}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                {accountSelectFields.map(({ fieldName, required }) => (
                  <SelectPicker
                    key={fieldName}
                    enableSearch={false}
                    isRequired={required}
                    selectedValue={formData[fieldName]}
                    selectItems={accountTypes.data}
                    label={t(`account.label.${fieldName}`)}
                    placeHolder={t(`account.placeholder.${fieldName}`)}
                    isInvalid={fieldName in errors}
                    onValueChange={(v: string) => onFormChange(fieldName, v)}
                    helpText={fieldName in errors && errors[fieldName]}
                  />
                ))}
              </Grid>
              <Grid xs={4}>
                {accountInputFields1.map(
                  ({ required, fieldName, disabled }) => (
                    <InputForm
                      key={fieldName}
                      isRequired={required}
                      defaultValue={formData[fieldName] || ''}
                      isDisabled={disabled}
                      isNumberic={fieldName === 'code' ? true : false}
                      label={t(`account.label.${fieldName}`)}
                      placeHolder={t(`account.placeholder.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      onChangeText={(v: string) => onFormChange(fieldName, v)}
                      helpText={fieldName in errors && errors[fieldName]}
                    />
                  ),
                )}
              </Grid>
            </Grid>
            {accountInputFields2.map(({ required, fieldName, disabled }) => (
              <InputForm
                key={fieldName}
                isRequired={required}
                defaultValue={formData[fieldName]}
                editable={!disabled}
                isNumberic={fieldName === 'code' ? true : false}
                label={t(`account.label.${fieldName}`)}
                placeHolder={t(`account.placeholder.${fieldName}`)}
                isInvalid={fieldName in errors}
                onChangeText={(v: string) => onFormChange(fieldName, v)}
                helpText={fieldName in errors && errors[fieldName]}
              />
            ))}

            {hasOpening && (
              <Stack direction="row" spacing={2}>
                <Box flex="1">
                  <InputForm
                    isNumberic={true}
                    maxLength={12}
                    label={t('account.label.openingBalance')}
                    isInvalid={'openingBalance' in errors}
                    onChangeText={(v: string) =>
                      onFormChange('openingBalance', +v)
                    }
                    helpText={
                      'openingBalance' in errors && errors.openingBalance
                    }
                  />
                </Box>
                <Box flex="1" zIndex={999}>
                  <DatePicker
                    entryMinDate={true}
                    isRequired={formData.openingBalance ? true : false}
                    label={t('account.label.openingDate')}
                    value={formData.openingDate}
                    isInvalid={'openingDate' in errors}
                    helpText={'openingDate' in errors && errors.openingDate}
                    onDateChange={(v: any) => onFormChange('openingDate', v)}
                  />
                </Box>
              </Stack>
            )}
            {/* <SubmitButton {...{ onSubmit, isLoading }} /> */}
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            title="Save Account"
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default CreateAccount;
