import TableCell from '@mui/material/TableCell';

import { styled } from '@mui/material/styles';
import { colors } from '../../assets/styles/colors';

export const TbCellHeader = styled(TableCell)(() => ({
  fontSize: 15,
  paddingTop: 4,
  paddingBottom: 4,
  fontWeight: 'bold',
  color: colors.theme1,
}));

const cellPaddigTop = 8;
const cellPaddigBottom = 8;
export const TbCellBody = styled(TableCell)(() => ({
  fontSize: 15,
  paddingTop: cellPaddigTop,
  paddingBottom: cellPaddigBottom,
}));

export const TbCellAmount = styled(TableCell)(() => ({
  fontSize: 16,
  paddingTop: cellPaddigTop,
  paddingBottom: cellPaddigBottom,
  fontWeight: 'bold',
  textAlign: 'right',
}));

export const TbCellSummary = styled(TableCell)(() => ({
  fontSize: 17,
  paddingTop: cellPaddigTop,
  paddingBottom: cellPaddigBottom,
  fontWeight: 'bold',
  textAlign: 'right',
}));
