export enum UserRole {
  systemSuperAdmin = 'system-super-admin',
  systemAdmin = 'system-admin',
  companySuperAdmin = 'company-super-admin',
  companyAdmin = 'company-admin',
  accountant = 'accountant',
  salesPerson = 'sales-person',
  inventoryManager = 'inventory-manager',
  customRole = 'custom-role',
}

export const userRoles = [
  { id: UserRole.companyAdmin, name: 'Company Admin' },
  { id: UserRole.accountant, name: 'Accountant' },
  { id: UserRole.salesPerson, name: 'Sales Person' },
  { id: UserRole.inventoryManager, name: 'Inventory Manager' },
];

export const userRolesForUpdate = [
  // added else super admin will now see own role in update
  { id: UserRole.companySuperAdmin, name: 'Company Super Admin' },
  ...userRoles,
];
