import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import {
  Box,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import Button, { TextButton } from '../../../components/buttons/Button';
import { useSelector } from '../../../store/reduxHooks';
import { getEmployees } from '../../../controllers/settings/employeeControllers';
import { UserRole } from '../../../constants/defaultCodes/userRoles';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import { isCompanySuperAdmin } from '../../../shared/utils/rolesUtils';

const EmployeeListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useSelector(s => s.auth.authUser);

  const empFn = () => getEmployees({});
  const { isLoading, isError, data = [] } = useQuery(['getEmployees'], empFn);

  const getRole = employee => {
    let scheme = 'gray';
    let variant = true;
    if (!employee.isActive && employee.appUser) {
      return { scheme: 'error', variant: 'outlined', name: 'DISABLED' };
    } else if (employee.userRole === UserRole.companySuperAdmin) {
      return { scheme, variant: false, name: 'SUPER ADMIN' };
    } else if (employee.userRole === UserRole.companyAdmin) {
      return { scheme: 'info', variant: false, name: 'ADMIN' };
    } else if (employee.userRole === UserRole.accountant) {
      return { scheme, variant, name: 'ACCOUNTANT' };
    } else if (employee.userRole === UserRole.salesPerson) {
      return { scheme, variant, name: 'SALES PERSON' };
    } else if (employee.userRole === UserRole.inventoryManager) {
      return { scheme, variant, name: 'INVENTORY MANAGER' };
    } else if (!employee.appUser) {
      return { scheme, variant, name: 'EMPLOYEE' };
    }
    return {};
  };

  // console.log('data', data);

  return (
    <Card sx={{ minWidth: 275, paddingX: 4 }}>
      <CardContent>
        <Stack pb={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom>
                  {t('settingStack.employeeList')} ({data.length})
                </Typography>
              </Grid>
              {/* <Grid xs={4}>
                <InputForm
                  size="small"
                  inLineLabel={true}
                  label={'search account by name, code or type...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                />
              </Grid> */}
            </Grid>
          </Box>
          {isCompanySuperAdmin(user) && (
            <Box>
              <Button
                title="Add New Employee"
                onClick={() => navigate('/settings/employees/create')}
              />
            </Box>
          )}
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Employee Name</TbCellHeader>
                  <TbCellHeader>Phone Number</TbCellHeader>
                  {/* <TbCellHeader>Email</TbCellHeader> */}
                  <TbCellHeader>Employee Role</TbCellHeader>
                  <TbCellHeader>Created At</TbCellHeader>
                  <TbCellHeader>Last Login</TbCellHeader>
                  <TbCellHeader align="right">Status</TbCellHeader>
                  {isCompanySuperAdmin(user) && <TbCellHeader />}
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>
                      {row.firstName} {row.lastName}
                    </TbCellBody>
                    <TbCellBody>{row.phoneNumber}</TbCellBody>
                    {/* <TbCellBody>{row.email}</TbCellBody> */}

                    <TbCellBody>
                      <Chip
                        size="small"
                        label={getRole(row).name}
                        color={row.isActive ? 'success' : 'error'}
                        variant={getRole(row).variant ? 'outlined' : 'filled'}
                      />
                    </TbCellBody>

                    {/* <TableCell component="th" scope="row">
                      {row.userBusinesses?.length}
                    </TableCell> */}
                    <TbCellBody>
                      {getSettingBasedDate(row.createdAt, user)}
                    </TbCellBody>
                    <TbCellBody>
                      {getSettingBasedDate(row.lastLogin, user)}
                    </TbCellBody>
                    <TbCellBody align="right">
                      <Chip
                        size="small"
                        label={row.isActive ? 'ACTIVE' : 'IN-ACTIVE'}
                        color={row.isActive ? 'success' : 'error'}
                        variant="outlined"
                      />
                    </TbCellBody>

                    {isCompanySuperAdmin(user) && (
                      <TbCellBody align="right">
                        <TextButton
                          title="update"
                          size="sm"
                          onClick={() =>
                            navigate('/settings/employees/update', {
                              state: row,
                            })
                          }
                        />
                      </TbCellBody>
                    )}
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
};

export default EmployeeListPage;
