import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, IconButton, Stack } from '@mui/material';
import { Button as MUIButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Cancel } from '@mui/icons-material';

import SelectPicker from '../../../../components/forms/SelectPicker';
import InputForm from '../../../../components/forms/InputForm';
import {
  expenseItemSelectFields,
  incomeItemSelectFields,
} from '../../../../constants/formFields/transactions/purchaseItemFields';
import Button from '../../../../components/buttons/Button';
import { colors } from '../../../../assets/styles/colors';

function IncomeExpenseItem({
  accountItems,
  productItems,
  expenseItems,
  onFormValueChange,
  onRemoveLine,
  errors,
  getHelpText,
  voucherItem,
  itemsLength,
  isExpense,
  isReturn,
  isOtherIncome,
  enableProduct,
}: any) {
  const { t } = useTranslation();

  const { product, quantity, pricePerUnit, lineNo, account } = voucherItem;

  let selecFields = expenseItemSelectFields;
  if (isExpense) {
    selecFields = expenseItemSelectFields;
    enableProduct && (selecFields = incomeItemSelectFields);
  } else if (isOtherIncome) {
    selecFields = incomeItemSelectFields;
  }

  const getSelectItems = (fieldName: string) => {
    if (fieldName === 'account') {
      return accountItems;
    } else if (fieldName === 'product') {
      return productItems;
    } else if (fieldName === 'expenseItem') {
      const accExpenses = expenseItems.filter(x => x.accountId === account);
      if (accExpenses.length) {
        return accExpenses;
      } else {
        return expenseItems;
      }
    }
  };

  // const getHandle = (fieldName: string) => {
  //   if (fieldName === 'product') {
  //     return setHandleProduct;
  //   } else if (fieldName === 'expenseItem') {
  //     return setHandleExpItem;
  //   }
  // };

  return (
    <Stack>
      <Grid container columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
        {selecFields.map(({ fieldName, required, flex }) => (
          <Grid xs={itemsLength !== 1 ? 3.3 : 3.5}>
            <SelectPicker
              label={fieldName === 'expenseItem' ? 'expense' : fieldName}
              isRequired={required}
              placeHolder={fieldName === 'expenseItem' ? 'expense' : fieldName}
              selectedValue={voucherItem[fieldName]}
              selectItems={getSelectItems(fieldName)}
              isInvalid={`${lineNo}-${fieldName}` in errors}
              helpText={getHelpText(`${lineNo}-${fieldName}`)}
              onValueChange={(v: any) =>
                onFormValueChange(fieldName, v, lineNo)
              }
              // setHandles={getHandle(fieldName)}
            />
          </Grid>
        ))}

        <Grid xs={3}>
          <InputForm
            label={'memo/notes'}
            maxLength={128}
            placeHolder={'memo'}
            defaultValue={voucherItem.memo}
            isInvalid={`${lineNo}-memo` in errors}
            helpText={getHelpText(`${lineNo}-memo`)}
            onChangeText={(v: string) => {
              onFormValueChange('memo', v, lineNo);
            }}
          />
        </Grid>

        <Grid xs={2}>
          <InputForm
            label={'amount'}
            maxLength={12}
            isRequired={true}
            isNumberic={true}
            editable={quantity && pricePerUnit ? false : true}
            placeHolder={'amount'}
            defaultValue={String(voucherItem.amount || '')}
            isInvalid={`${lineNo}-amount` in errors}
            helpText={getHelpText(`${lineNo}-amount`)}
            onChangeText={v => {
              onFormValueChange('amount', +v, lineNo);
            }}
          />
        </Grid>

        {itemsLength !== 1 && (
          <Grid xs={0.4} pt={5.5}>
            <IconButton
              aria-label="delete"
              color="error"
              onClick={() => onRemoveLine(lineNo)}>
              <Cancel />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

export default IncomeExpenseItem;
