import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import InputForm from '../../../components/forms/InputForm';
import Button from '../../../components/buttons/Button';
import { checkAllowedSettingCount } from '../../../controllers/accounts/userAccountControllers';
import {
  createBusiness,
  updateBusiness,
} from '../../../controllers/settings/businessControllers';
import { validateBusiness } from '../../../validations/settings/validateBusiness';
import {
  businessCreateFields,
  businessSwitchFields,
  businessUpdateFields,
  businessUpdateFields1,
  businessUpdateFields2,
} from '../../../constants/formFields/settings/businessFields';
import SwitchForm from '../../../components/forms/SwitchForm';
import { useDispatch } from '../../../store/reduxHooks';
import { deleteEmptyFields } from '../../../shared/utils/objectUtils';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import { refreshUserDetails } from '../../../store/authSlice';
import SelectPicker from '../../../components/forms/SelectPicker';
import { dateFormats } from '../../../constants/defaultCodes/otherEnums';

function UpdateBusinessPage() {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { state: business } = useLocation();
  if (!business) {
    navigate('/settings/businesses');
    return null;
  }

  const [formData, setData] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>({});

  useEffect(() => {
    const { name, address, phoneNumber } = business;
    const { panNumber, description, isActive, vatNumber, onVat } = business;

    const updatedData = {
      name,
      address,
      phoneNumber,
      panNumber,
      vatNumber,
      description,
      onVat,
      isActive,
      dateFormat: business.dateFormat,
      enableInvoiceInterest: business.enableInvoiceInterest,
      enableBillInterest: business.enableBillInterest,
      showPanVatNoOnInvoice: business.showPanVatNoOnInvoice,
    };
    deleteEmptyFields(updatedData);
    setData(prev => ({ ...prev, ...updatedData }));
  }, [business]);

  const { isLoading, mutateAsync } = useMutation(updateBusiness);

  const onSubmit = async () => {
    const { isValid, errors: errorMsg } = validateBusiness(formData, errors);

    if (isValid) {
      try {
        const payload = { businessId: business.id, data: formData };
        await mutateAsync(payload);
        queryClient.invalidateQueries(['getBusinesses']);
        queryClient.invalidateQueries(['getUserBusinesses']);
        await dispatch(refreshUserDetails());

        navigate('/settings/businesses', { replace: true });
        enqueueSnackbar(t('business.updateMsg.success'), successToast);
      } catch (err) {
        // console.error('err:', err.response?.data.message);
        enqueueSnackbar(t('business.updateMsg.error'), errorToast);
      }
    } else {
      setErrors(errorMsg);
    }
  };

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateBusiness(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData Business update:', formData);
  // console.log('business errors:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.updateBusiness')}
          </Typography>
          <Divider />
          <Stack py={1} gap={3}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {businessUpdateFields1.map(({ fieldName, required }) => (
                <Grid xs={12} key={fieldName}>
                  <InputForm
                    isRequired={required}
                    defaultValue={formData[fieldName]}
                    maxLength={fieldName === 'phoneNumber' ? 10 : 128}
                    label={t(`business.label.${fieldName}`)}
                    placeHolder={t(`business.placeholder.${fieldName}`)}
                    isInvalid={fieldName in errors}
                    onChangeText={(v: string) => onFormChange(fieldName, v)}
                    helpText={fieldName in errors && errors[fieldName]}
                  />
                </Grid>
              ))}

              {businessUpdateFields2.map(
                ({ fieldName, required, numberic }) => (
                  <Grid xs={6} key={fieldName}>
                    <InputForm
                      isRequired={required}
                      defaultValue={formData[fieldName]}
                      isFloatNo={false}
                      isNumberic={numberic}
                      maxLength={fieldName === 'phoneNumber' ? 10 : 128}
                      label={t(`business.label.${fieldName}`)}
                      placeHolder={t(`business.placeholder.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      onChangeText={(v: string) => onFormChange(fieldName, v)}
                      helpText={fieldName in errors && errors[fieldName]}
                    />
                  </Grid>
                ),
              )}

              <Grid xs={6}>
                <SelectPicker
                  isRequired={true}
                  disableClearable={true}
                  selectItems={dateFormats}
                  selectedValue={formData.dateFormat}
                  label={t('business.label.dateFormat')}
                  isInvalid={'dateFormat' in errors}
                  onValueChange={(v: any) => onFormChange('dateFormat', v)}
                  helpText={'dateFormat' in errors && errors.dateFormat}
                />
              </Grid>
            </Grid>

            {businessSwitchFields.map(({ fieldName }) => (
              <SwitchForm
                key={fieldName}
                isChecked={formData[fieldName]}
                label={t(`business.label.${fieldName}-${formData[fieldName]}`)}
                onToggle={(e, v: boolean) => onFormChange(fieldName, v)}
                // helpText={t(`business.helpText.${formData[fieldName]}`)}
              />
            ))}
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Update Business"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default UpdateBusinessPage;
