import React from 'react';
import { Stack, Typography } from '@mui/material';

import { useSelector } from '../../../store/reduxHooks';
import { dateFormat } from '../../../constants/defaultCodes/otherEnums';
import { getNepaliDate } from '../../../shared/utils/nepaliDate';
import { formatToTextDate } from '../../../shared/utils/dateTime';

const OpenignBalanceAsOf = ({ fromDate, openingBalance }: any) => {
  const { user } = useSelector(state => state.auth.authUser);
  const format: dateFormat = user.business?.dateFormat || dateFormat.BS;

  let userDate: string;
  if (format === dateFormat.BS) {
    userDate = getNepaliDate(fromDate);
  } else {
    userDate = formatToTextDate(fromDate);
  }

  return (
    <Stack
      direction={'row'}
      gap={4}
      alignItems={'center'}
      justifyContent={'flex-end'}>
      <Typography
        sx={{
          fontSize: 17,
          fontStyle: 'italic',
        }}>{`Opening Balance as of ${userDate} `}</Typography>
      <Typography sx={{ fontWeight: 'bold', fontSize: 17 }}>
        {openingBalance}
      </Typography>
    </Stack>
  );
};

export default OpenignBalanceAsOf;
