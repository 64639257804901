import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { updateEmployee } from '../../../controllers/settings/employeeControllers';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import { useSelector } from '../../../store/reduxHooks';
import { checkAllowedSettingCount } from '../../../controllers/accounts/userAccountControllers';
import { validateEmployee } from '../../../validations/settings/validateEmployee';

import {
  employeeInputFields,
  employeeInputFields2,
} from '../../../constants/formFields/settings/employeeFields';
import {
  UserRole,
  userRoles,
  userRolesForUpdate,
} from '../../../constants/defaultCodes/userRoles';
import SwitchForm from '../../../components/forms/SwitchForm';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import { getUserBusinesses } from '../../../controllers/settings/businessControllers';
import { deleteEmptyFields } from '../../../shared/utils/objectUtils';
import MultiSelectPicker from '../../../components/forms/MultiSelectPicker';
import SubscriptionUpgradeNeeded from '../../../components/modals/SubscriptionUpgradeNeeded';
const initData = { initialData: { userBusinesses: [] } };

function UpdateEmployeePage() {
  const navigate = useNavigate();
  const { state: employee } = useLocation();
  if (!employee) {
    navigate('/settings/employees');
    return null;
  }
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user } = useSelector(s => s.auth.authUser);

  const init = { businesses: [], appUser: false };

  const [formData, setData] = useState<any>(init);
  const [errors, setErrors] = useState<any>({});
  const [showWarning, setShowWarning] = useState(false);
  // const { enableNonAppUserCreate } = useSelector(s => s.config.remoteConfigs);

  const subsCount = useQuery(['getSubsCount'], checkAllowedSettingCount);
  const { isSuccess, data = {} } = subsCount;

  const getBizFn = () => getUserBusinesses();
  const business = useQuery(['getBusinesses'], getBizFn, initData);

  useEffect(() => {
    if (isSuccess && !employee.appUser && formData.appUser) {
      if (!data.employee?.allowedNew) {
        setShowWarning(true);
      }
    }
  }, [formData.appUser]);

  useEffect(() => {
    const { firstName, lastName, userRole, appUser } = employee;
    const { phoneNumber, email, isActive, businesses } = employee;

    const newData = {
      id: employee.id,
      appUser,
      firstName,
      lastName,
      isActive,
      userRole,
      phoneNumber,
      email,
      businesses,
    };
    deleteEmptyFields(newData);
    setData(prev => ({ ...prev, ...newData }));
  }, [employee]);

  const { isLoading, mutateAsync } = useMutation(updateEmployee);

  const onSubmit = async () => {
    const { isValid, errors: errorMsg } = validateEmployee(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getEmployees']);
        navigate('/settings/employees');
        enqueueSnackbar(t('employee.updateMsg.success'), successToast);
      } catch (err) {
        // console.error('err:', err.response?.data?.message); // report to err reporting services
        enqueueSnackbar(t('employee.updateMsg.error'), errorToast);
      }
    } else {
      setErrors(errorMsg);
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateEmployee(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const isLoggedInUser = user.id === employee.id;

  let userRolesForSelect = userRoles;
  if (user.userRole === UserRole.companySuperAdmin && isLoggedInUser) {
    userRolesForSelect = userRolesForUpdate;
  }

  const totalAllowed = data.employee?.totalAllowed;

  // console.log('formData UP:', formData);
  // console.log('errors SP:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.updateEmployee')}
          </Typography>
          <Divider />
          <Stack py={4} gap={2}>
            <Grid xs={12}>
              <SwitchForm
                isChecked={formData.appUser}
                label={t(`employee.label.appUser-${formData.appUser}`)}
                onToggle={(e, v) => onFormChange('appUser', v)}
              />
            </Grid>

            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              {[...employeeInputFields, ...employeeInputFields2].map(
                ({ fieldName, required, disabled }) => (
                  <Grid xs={6} key={fieldName}>
                    <InputForm
                      isRequired={required}
                      isDisabled={disabled}
                      isFloatNo={false}
                      defaultValue={formData[fieldName]}
                      maxLength={fieldName === 'phoneNumber' && 10}
                      isNumberic={fieldName === 'phoneNumber' ? true : false}
                      label={t(`employee.label.${fieldName}`)}
                      placeHolder={t(`employee.placeholder.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      helpText={fieldName in errors && errors[fieldName]}
                      onChangeText={(v: string) => onFormChange(fieldName, v)}
                    />
                  </Grid>
                ),
              )}
            </Grid>

            <MultiSelectPicker
              isRequired={true}
              isDisabled={isLoggedInUser}
              selectedValue={formData.businesses}
              selectItems={business.data?.userBusinesses}
              label={
                formData.appUser
                  ? t('employee.label.businesses')
                  : t('employee.label.businesses2')
              }
              placeHolder={
                formData.appUser
                  ? t('employee.placeholder.businesses')
                  : t('employee.placeholder.businesses2')
              }
              isInvalid={'businesses' in errors}
              helpText={'businesses' in errors && errors.businesses}
              onValueChange={(v: string) => onFormChange('businesses', v)}
            />

            {formData.appUser && (
              <Box>
                <SelectPicker
                  isRequired={true}
                  disableClearable={true}
                  isDisabled={isLoggedInUser}
                  selectItems={userRolesForSelect}
                  selectedValue={formData.userRole}
                  label={t('employee.label.userRole')}
                  isInvalid={'userRole' in errors}
                  helpText={'userRole' in errors && errors.userRole}
                  onValueChange={(v: string) => onFormChange('userRole', v)}
                />
              </Box>
            )}

            <SwitchForm
              disabled={isLoggedInUser}
              isChecked={formData.isActive}
              label={t(`employee.label.isActive`)}
              onToggle={(e, v: any) => onFormChange('isActive', v)}
              helpText={t(`employee.helpText.isActive-${formData.isActive}`)}
            />
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Employee"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>

        <SubscriptionUpgradeNeeded
          {...{ showWarning, setShowWarning, totalEmployee: totalAllowed }}
        />
      </Card>
    </Container>
  );
}

export default UpdateEmployeePage;
