import { default as i18n } from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';

const advanceVoucher = voucherTypes.ADVANCE_PAYMENT;
const paymentVoucher = voucherTypes.VENDOR_RECEIPT;

export const validatePOVoucherNo = async (data, errors) => {
  const voucherType = voucherTypes.PAYMENT_OUT;

  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('fundTransfer.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateVoucherNo = async (data, errors) => {
  const voucherType = data.isAdvancePayment ? advanceVoucher : paymentVoucher;

  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('fundTransfer.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validatePaymentOut = (data, errors, hasBalance?: boolean) => {
  let isValid = true;
  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('advancePayment.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length < 3 || data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('advancePayment.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18n.t('advancePayment.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.partner) {
    errors = {
      ...errors,
      partner: i18n.t('advancePayment.validation.vendorRequired'),
    };
    isValid = false;
  } else {
    delete errors.partner;
  }

  if (!data.cashOutAccount) {
    errors = {
      ...errors,
      cashOutAccount: i18n.t('advancePayment.validation.accountRequired'),
    };
    isValid = false;
  } else if (hasBalance === false) {
    errors = {
      ...errors,
      cashOutAccount: i18n.t('voucher.validation.inSufficientBalance'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  if (!Number(data.amount) || data.amount <= 0) {
    errors = { ...errors, amount: i18n.t('form.validation.requireAmount') };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.interestPaid && data.interestPaid < 0) {
    errors = {
      ...errors,
      interestPaid: 'Interest paid cannot be negative value',
    };
    isValid = false;
  } else {
    delete errors.interestPaid;
  }

  if (data.discount && data.discount < 0) {
    errors = { ...errors, discount: 'Discount cannot be negative value' };
    isValid = false;
  } else {
    delete errors.discount;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18n.t('form.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18n.t('form.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};

export const validateAdvancePayment = (data, errors, hasBalance?: boolean) => {
  let isValid = true;
  if (data.voucherNumber === undefined) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('advancePayment.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length < 3 || data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('advancePayment.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (data.voucherDate === undefined) {
    errors = {
      ...errors,
      voucherDate: i18n.t('advancePayment.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (data.vendor === undefined) {
    errors = {
      ...errors,
      vendor: i18n.t('advancePayment.validation.vendorRequired'),
    };
    isValid = false;
  } else {
    delete errors.vendor;
  }

  if (data.isAdvancePayment && !data.cashOutAccount) {
    errors = {
      ...errors,
      cashOutAccount: i18n.t('advancePayment.validation.accountRequired'),
    };
    isValid = false;
  } else if (data.isAdvancePayment && hasBalance === false) {
    errors = {
      ...errors,
      cashOutAccount: i18n.t('voucher.validation.inSufficientBalance'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  if (!data.isAdvancePayment && !data.cashInAccount) {
    errors = {
      ...errors,
      cashInAccount: i18n.t('form.validation.cashInAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  if (!Number(data.amount) || data.amount <= 0) {
    errors = { ...errors, amount: i18n.t('form.validation.requireAmount') };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18n.t('form.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18n.t('form.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};
