import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import {
  Box,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button, { TextButton } from '../../../components/buttons/Button';
import { colors } from '../../../assets/styles/colors';
import { getExpenseItems } from '../../../controllers/settings/inventoryControllers';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { useSelector } from '../../../store/reduxHooks';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';

const init = { initialData: [] };

const ExpenseItemListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { user } = useSelector(s => s.auth.authUser);

  const result = useQuery(['getExpenseItems'], getExpenseItems, init);
  const { isFetching: isLoading, isError, data = [] } = result;

  // console.log('getExpenseItems:', data);

  return (
    <Card sx={{ minWidth: 275, paddingX: 4 }}>
      <CardContent>
        <Stack pb={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom>
                  {t('settingStack.expenseItemList')} ({data.length})
                </Typography>
              </Grid>
              {/* <Grid xs={4}>
                <InputForm
                  size="small"
                  inLineLabel={true}
                  label={'search account by name, code or type...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                />
              </Grid> */}
            </Grid>
          </Box>
          <Box>
            <Button
              title="Add Expense Item"
              onClick={() => navigate('/settings/expense-items/create')}
            />
          </Box>
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Expense Item Name</TbCellHeader>
                  <TbCellHeader> Created At</TbCellHeader>
                  <TbCellHeader align="right">Status</TbCellHeader>
                  <TbCellHeader />
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>
                      {getSettingBasedDate(row.createdAt, user)}
                    </TbCellBody>

                    <TbCellBody align="right">
                      <Chip
                        size="small"
                        label={row.isActive ? 'ACTIVE' : 'IN-ACTIVE'}
                        color={row.isActive ? 'success' : 'error'}
                        variant="outlined"
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <TextButton
                        title="update"
                        size="sm"
                        onClick={() =>
                          navigate('/settings/expense-items/update', {
                            state: row,
                          })
                        }
                      />
                    </TbCellBody>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
};

export default ExpenseItemListPage;
