import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { colors } from '../../assets/styles/colors';

const HeaderSwitch = ({
  leftEnabled,
  onToggle,
  leftText,
  rightText,
  width = 120,
}: any) => {
  const getBgColor = (isLeft: boolean) => {
    if (isLeft) {
      return leftEnabled ? colors.theme1 : colors.gray200;
    } else {
      return !leftEnabled ? colors.theme1 : colors.gray200;
    }
  };

  const getDisableStatus = (isLeft: boolean) => {
    if (isLeft) {
      return leftEnabled ? true : false;
    } else {
      return !leftEnabled ? true : false;
    }
  };

  return (
    <ButtonGroup aria-label="outlined primary button group">
      <Button
        sx={{
          width: width,
          // fontSize: 15,
          fontWeight: getDisableStatus(true) ? 'bold' : '400',
          backgroundColor: getBgColor(true),
          borderColor: getBgColor(true),
          color: !leftEnabled && colors.blueGrey500,
        }}
        variant={leftEnabled ? 'contained' : 'outlined'}
        onClick={() => onToggle(true)}>
        {leftText.toUpperCase()}
      </Button>
      <Button
        sx={{
          width: width,
          fontWeight: getDisableStatus(false) ? 'bold' : '400',
          backgroundColor: getBgColor(false),
          borderColor: getBgColor(false),
          color: leftEnabled && colors.blueGrey500,
        }}
        variant={!leftEnabled ? 'contained' : 'outlined'}
        onClick={() => onToggle(false)}>
        {rightText.toUpperCase()}
      </Button>
    </ButtonGroup>
  );
};

export default HeaderSwitch;
