import { SnackbarOrigin, VariantType } from 'notistack';

const anchorOrigin: SnackbarOrigin = {
  horizontal: 'center',
  vertical: 'bottom',
};

export const successToast = {
  variant: 'success' as VariantType,
  autoHideDuration: 2500,
  anchorOrigin,
};

export const errorToast = {
  variant: 'error' as VariantType,
  autoHideDuration: 2500,
  anchorOrigin,
};

export const warningToast = {
  variant: 'warning' as VariantType,
  autoHideDuration: 2500,
  anchorOrigin,
};
