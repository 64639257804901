import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Card, CardContent, Divider } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { useQuery } from '@tanstack/react-query';

import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import ActiveSubscription from '../subscription/components/ActiveSubscription';
import { getSubscriptionOrders } from '../../../controllers/settings/otherSettingControllers';
import { formatAmount } from '../../../shared/utils/locale';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import { useSelector } from '../../../store/reduxHooks';

function BillingInformation({ hideBilling }) {
  const { user } = useSelector(s => s.auth.authUser);

  const subsOrderFn = () => getSubscriptionOrders();
  const subscriptionOrders = useQuery(['subscriptionOrders'], subsOrderFn);
  const { isLoading, isError, data = [] } = subscriptionOrders;

  // console.log('data', data);

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1.4 }}>
        <Stack py={2} mb={4}>
          <ActiveSubscription hideBilling={hideBilling} />
        </Stack>

        <Typography sx={{ fontSize: 20, pb: 1 }}>BILLING HISTORY</Typography>
        <Divider />
        <Stack mb={2} />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table aria-label="billing">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Subscription</TbCellHeader>
                  <TbCellHeader>Months</TbCellHeader>
                  <TbCellHeader>Order#</TbCellHeader>
                  <TbCellHeader>Billing Date</TbCellHeader>
                  <TbCellHeader>Expiry Date</TbCellHeader>
                  <TbCellHeader>Biz/User</TbCellHeader>
                  <TbCellHeader align="right">Price</TbCellHeader>
                  <TbCellHeader align="right">Discount</TbCellHeader>
                  <TbCellHeader align="right">Billed Amount</TbCellHeader>
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.product.name}</TbCellBody>
                    <TbCellBody>{row.product.months}</TbCellBody>
                    <TbCellBody>{row.orderNumber}</TbCellBody>

                    <TbCellBody>
                      {getSettingBasedDate(row.createdAt, user)}
                    </TbCellBody>
                    <TbCellBody>
                      {getSettingBasedDate(row.expiryDate, user)}
                    </TbCellBody>
                    <TbCellBody>
                      {row.totalBusiness}/{row.totalUsers}
                    </TbCellBody>
                    <TbCellBody align="right">{row.productPrice}</TbCellBody>
                    <TbCellBody align="right">
                      {row.discountAmount + row.extraDiscountAmount}
                    </TbCellBody>
                    <TbCellAmount>
                      {formatAmount(row.totalPaidPrice)}
                    </TbCellAmount>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
}

export default BillingInformation;
