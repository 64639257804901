import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  createExpenseItem,
  updateExpenseItem,
} from '../../../controllers/settings/inventoryControllers';
import { validateExpenseItem } from '../../../validations/settings/validateProductItem';
import InputForm from '../../../components/forms/InputForm';
import Button from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { billableExpenseAccounts } from '../../../controllers/settings/accountControllers';
import SelectPicker from '../../../components/forms/SelectPicker';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
const initial = { initialData: [] };

const CreateExpenseItem = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { state: editExpenseItem } = useLocation();
  const isEdit = Boolean(editExpenseItem);

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const queryClient = useQueryClient();

  const accounts = useQuery(
    ['billableExpAcc'],
    billableExpenseAccounts,
    initial,
  );

  useEffect(() => {
    if (isEdit) {
      if (editExpenseItem) {
        const { accountId: account, name, isActive } = editExpenseItem;
        setData(prev => ({ ...prev, name, account, isActive }));
      }
    }
  }, [isEdit, editExpenseItem]);

  const { isLoading, mutateAsync: createExp } = useMutation(createExpenseItem);
  const { isLoading: isEditing, mutateAsync } = useMutation(updateExpenseItem);

  const onSubmit = async () => {
    const { isValid, errors: errorMsg } = validateExpenseItem(formData, errors);

    if (isValid) {
      try {
        if (!isEdit) {
          await createExp(formData);
          enqueueSnackbar(t('expenseItem.createMsg.success'), successToast);
          navigate('/settings/expense-items', { replace: true });
        } else {
          const updatePayload = { id: editExpenseItem.id, data: formData };
          await mutateAsync(updatePayload);
          enqueueSnackbar(t('expenseItem.updateMsg.success'), successToast);
          navigate('/settings/expense-items', { replace: true });
        }
        queryClient.invalidateQueries(['getExpenseItems']);
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('expenseItem.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errorMsg);
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateExpenseItem(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  // console.log('formData expitem:', formData);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {isEdit
              ? t('settingStack.updateExpenseItem')
              : t('settingStack.addExpenseItem')}
          </Typography>
          <Divider />
          <Stack py={4} spacing={4}>
            <SelectPicker
              label={t(`expenseItem.label.account`)}
              selectItems={accounts.data}
              selectedValue={formData.account}
              isInvalid={'account' in errors}
              helpText={getHelpText('account')}
              onValueChange={(v: any) => onFormChange('account', v)}
            />
            <InputForm
              isRequired={true}
              defaultValue={formData.name}
              label={t('expenseItem.label.name')}
              placeHolder={t('expenseItem.label.name')}
              isInvalid={'name' in errors}
              onChangeText={(v: string) => onFormChange('name', v)}
              helpText={'name' in errors && errors.name}
            />
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            loading={isLoading || isEditing}
            title={isEdit ? 'Update Expense Item' : 'Save Expense Item'}
            onClick={onSubmit}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default CreateExpenseItem;
