import React, { useEffect, useState } from 'react';
import { Chip, Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';

import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import DatePicker from '../../../components/datePickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { defaultAccountCodes } from '../../../constants/defaultCodes/accountDefaultCodes';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import {
  accountByDefCode,
  bankAndCashAccounts,
} from '../../../controllers/settings/accountControllers';
import { getVendors } from '../../../controllers/settings/partnerControllers';
import {
  getProductItems,
  getProductUnits,
} from '../../../controllers/settings/inventoryControllers';
import { getVendorBalance } from '../../../controllers/reports/partnerReports';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';

import Button from '../../../components/buttons/Button';
import CashOrCreditSwitch from '../components/CashOrCreditSwitch';
import ItemListHeader from '../components/ItemListHeader';
import BillOrInvoiceSummary from '../components/BillOrInvoiceSummary';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import TransactionItem from '../sales/components/TransactionItem';
import TransactionItemModal from '../sales/components/TransactionItemModal';
import AddNewLine from '../sales/components/AddNewLine';
import TransactionError from '../sales/components/TransactionError';
import { purchaseReturn } from '../../../controllers/transactions/purchasesControllers';
import {
  validateVoucherNo,
  validatePurchaseReturn,
} from '../../../validations/transactions/validatePurchaseReturn';
import ImagePicker from '../../../components/image/ImagePicker';

const defCode = defaultAccountCodes.COST_OF_GOODS_PURCHASED;

const voucherType = voucherTypes.PURCHASE_RETURN;
const initial = { initialData: [] };
const cache = { cacheTime: 0 };

const PurchaseReturn = ({ navigation, route }: any): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const now = new Date();
  const initState = { voucherDate: now, voucherItems: [], isFullPayment: true };
  const [formData, setData] = useState<any>(initState);
  const [images, setImages] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const [activeLineNo, setActiveLineNo] = useState<number>(1);
  const [vendorPayable, setVendorPayable] = useState<any>();

  const { isFullPayment, paidAmount } = formData;

  const voucherNoFn = () => getVoucherNumber(voucherType);
  const queryVNO = useQuery(['getVoucherNo', voucherType], voucherNoFn);
  const { data: voucherNumber } = queryVNO;

  const vendors = useQuery(['getVendors'], getVendors, initial);
  const bankAndCash = useQuery(['bankAndCash'], bankAndCashAccounts, initial);
  const products = useQuery(['products'], getProductItems, initial);
  const productUnits = useQuery(['productUnits'], getProductUnits, initial);
  const vendorBalanceQuery = useMutation(getVendorBalance);

  const eQuery = useQuery(['expAcc', defCode], () => accountByDefCode(defCode));
  const { data: defAccount } = eQuery;

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  const { isLoading, mutateAsync } = useMutation(purchaseReturn);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async () => {
    if (!isFullPayment && paidAmount <= 0 && formData.cashInAccount) {
      delete formData.cashInAccount;
    }

    const { isValid, errs } = validatePurchaseReturn(formData, errors);
    const { isVoucherNoValid, errss } = await validateVoucherNo(formData, errs);

    if (isValid && isVoucherNoValid) {
      try {
        if (images && images.length) {
          const imgPaylaod = { images, voucherType: 'purchase-return' };
          const image = await saveImage.mutateAsync(imgPaylaod);
          formData.image = image.id;
        }

        await mutateAsync(formData);
        queryClient.invalidateQueries(['getDashboardReport']);

        enqueueSnackbar(t('purchaseReturn.createMsg.success'), successToast);
        navigate('/journals', { replace: true });
      } catch (err) {
        // console.error('err:', err);
        let title = t('purchaseReturn.createMsg.error');
        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }
        enqueueSnackbar(title, errorToast);
      }
    } else {
      setErrors(errss);
      if (!isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    setData((prev: any) => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errs } = validatePurchaseReturn(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});

    if (name === 'vendor' && value && formData.vendor !== value) {
      const response = await vendorBalanceQuery.mutateAsync(value);
      setVendorPayable({ type: 'CASH_OUT', balance: response });
    } else if (name === 'vendor' && !value) {
      setVendorPayable(null);
    }
  };

  const onFormItemChange = (data: any, lineNo: number) => {
    const { voucherItems } = formData;
    let myItem = voucherItems.find((item: any) => item.lineNo === lineNo);

    if (!myItem) {
      const vItems = [...voucherItems, { ...data, lineNo }];
      setData(prev => ({ ...prev, voucherItems: vItems }));
      return;
    }

    const vItems = voucherItems.map((item: any) => {
      item.lineNo === lineNo && (item = { ...myItem, ...data });
      return item;
    });

    setData(prev => ({ ...prev, voucherItems: [...vItems] }));
  };

  const onAddNewLine = () => {
    let newLine = formData.voucherItems.length + 1;

    let loopBreaker = 0;
    while (true) {
      const lineNo = formData.voucherItems.find(i => i.lineNo === newLine);
      if (!lineNo) {
        break;
      }
      newLine += 1;
      loopBreaker += 1;
      if (loopBreaker >= 100) {
        break;
      }
    }
    setShowItemModal(true);
    setActiveLineNo(newLine);
  };

  const onRemoveLine = (lineNo: number) => {
    if (formData.voucherItems.length <= 1) {
      return;
    }

    const voucherItems = formData.voucherItems.filter(
      (item: any) => item.lineNo !== lineNo,
    );

    setData(p => ({ ...p, voucherItems: [...voucherItems] }));
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  // console.log('formData invoice:', formData);
  // console.log('errors invoice:', errors);

  const activeItem = formData.voucherItems.find(x => x.lineNo === activeLineNo);

  return (
    <Container>
      <Card sx={{ paddingX: 2 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('transactionStack.purchaseReturn')}
            </Typography>

            <CashOrCreditSwitch
              isFullPayment={isFullPayment}
              onToggle={v => onFormChange('isFullPayment', v)}
            />
          </Stack>
          <Divider />

          <Stack py={2} gap={2}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('purchaseReturn.label.voucherDate')}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                  value={formData.voucherDate}
                />
              </Grid>

              <Grid xs={4}>
                <InputForm
                  isRequired={true}
                  isDisabled={true}
                  defaultValue={formData.voucherNumber}
                  label={t('purchaseReturn.label.voucherNumber')}
                  placeHolder={t('purchaseReturn.label.voucherNumber')}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  defaultValue={formData.referenceId}
                  label={t('purchaseReturn.label.referenceId')}
                  isInvalid={'referenceId' in errors}
                  helpText={'referenceId' in errors && errors.referenceId}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <SelectPicker
                  isRequired={isFullPayment ? false : true}
                  label={t('purchaseReturn.label.vendor')}
                  selectedValue={formData.vendor}
                  selectItems={vendors.data}
                  isInvalid={'vendor' in errors}
                  helpText={'vendor' in errors && errors.vendor}
                  onValueChange={(v: any) => onFormChange('vendor', v)}
                  partnerBalance={vendorPayable && vendorPayable}
                />
              </Grid>
            </Grid>
            <Stack pt={1}>
              <ItemListHeader
                itemsLen={formData.voucherItems.length}
                title="Return Items"
              />
              {formData.voucherItems.map((item: any, index) => (
                <TransactionItem
                  key={item.lineNo}
                  index={index + 1}
                  voucherItem={item}
                  productItems={products.data}
                  productUnits={productUnits.data}
                  onFormValueChange={onFormItemChange}
                  onRemoveLine={onRemoveLine}
                  isPurchaseReturn
                />
              ))}
            </Stack>

            {showItemModal && (
              <TransactionItemModal
                showModal={showItemModal}
                setShowModal={setShowItemModal}
                voucherItem={activeItem || { lineNo: activeLineNo }}
                productItems={products.data || []}
                productUnits={productUnits.data || []}
                onFormValueChange={onFormItemChange}
                // setHandleProduct={setHandleProduct}
                defaultAccount={defAccount}
                isPurchaseReturn
              />
            )}

            <AddNewLine {...{ onAddNewLine, t }} />

            {errors.returnItem && (
              <TransactionError error={errors.returnItem} />
            )}

            {/* Not Handled the partial payments for return transaction */}
            {/* <PaymentAmount
              {...{ formData, onFormChange, errors, type: 'PURCHASE', t }}
            /> */}

            {(paidAmount > 0 || isFullPayment) && (
              <SelectPicker
                mode="modal"
                isRequired={paidAmount || isFullPayment ? true : false}
                selectedValue={formData.cashInAccount}
                selectItems={bankAndCash.data}
                label={t('purchaseReturn.label.cashInAccount')}
                isInvalid={'cashInAccount' in errors}
                helpText={'cashInAccount' in errors && errors.cashInAccount}
                onValueChange={(v: string) => onFormChange('cashInAccount', v)}
              />
            )}

            <BillOrInvoiceSummary
              t={t}
              voucherItems={formData.voucherItems}
              discountAmount={formData.discountAmount}
              paidAmount={formData.paidAmount}
              isTaxable={formData.isTaxable}
              isReturn
              isPurchaseReturn
            />

            <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
              <Stack flex={1}>
                <InputForm
                  inLineLabel={true}
                  defaultValue={formData.description}
                  label={t('purchaseReturn.label.description')}
                  isInvalid={'description' in errors}
                  helpText={'description' in errors && errors.description}
                  onChangeText={(v: string) => onFormChange('description', v)}
                />
              </Stack>
              <Stack pt={0.1}>
                <ImagePicker {...{ images, setImages }} />
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title={'Save Purchase Return'}
            onClick={onSubmit}
            loading={isLoading || saveImage.isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default PurchaseReturn;
