import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import InputForm from '../../../../components/forms/InputForm';

import { useDispatch } from '../../../../store/reduxHooks';
import { validateLoginCode } from '../../../../validations/settings/validateLoginCode';
import { useMutation } from '@tanstack/react-query';
import { userLoginCode } from '../../../../controllers/accounts/userAccountControllers';
import { refreshUserDetails } from '../../../../store/authSlice';
import {
  errorToast,
  successToast,
} from '../../../../constants/snackbar/snackbar';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

function SetLoginCodeModal({ loginCode, modalVisible, onModalClose }: any) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({ loginCode });
  const [errors, setErrors] = useState<any>({});

  const onFormChange = (name: string, value: any) => {
    setData((prev: any) => ({ ...prev, [name]: value }));

    const { errs } = validateLoginCode({ ...formData, [name]: value }, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const { isLoading, mutateAsync } = useMutation(userLoginCode);

  const onSubmit = async () => {
    const { isValid, errs } = validateLoginCode(formData, errors);
    if (isValid) {
      try {
        loginCode && (formData.isUpdate = true);
        await mutateAsync(formData);
        enqueueSnackbar('Login Code Updated !!', successToast);
        await dispatch(refreshUserDetails());

        onModalClose();
      } catch (err) {
        // console.error('err:', err?.response?.data.message);
        enqueueSnackbar('Error, adding login code !!', errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  // console.log('formData', formData);
  // console.log('errors', errors);

  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
          {loginCode ? 'Update Login Code' : 'Set Login Code'}
        </Typography>
        <Divider />

        <Stack gap={2} py={2}>
          {loginCode && (
            <InputForm
              maxLength={4}
              isFloatNo={false}
              isRequired={true}
              isNumberic={true}
              label={'Old Login Code'}
              isInvalid={'oldCode' in errors}
              helpText={'oldCode' in errors && errors.oldCode}
              onChangeText={(v: string) => onFormChange('oldCode', v)}
            />
          )}

          <InputForm
            maxLength={4}
            isFloatNo={false}
            isRequired={true}
            isNumberic={true}
            label={'Login Code'}
            isInvalid={'newCode1' in errors}
            helpText={'newCode1' in errors && errors.newCode1}
            onChangeText={(v: string) => onFormChange('newCode1', v)}
          />
          <InputForm
            maxLength={4}
            isFloatNo={false}
            isRequired={true}
            isNumberic={true}
            // defaultValue={formData.referenceId}
            label={'Confirm Login Code'}
            isInvalid={'newCode2' in errors}
            helpText={'newCode2' in errors && errors.newCode2}
            onChangeText={(v: string) => onFormChange('newCode2', v)}
          />
        </Stack>

        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onModalClose}>
            Close
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="large"
            onClick={onSubmit}>
            Submit
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default SetLoginCodeModal;
