import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { getSubscriptionDiscountedPrice } from '../../../../shared/utils/subscriptionPrice';
import { colors } from '../../../../assets/styles/colors';
import {
  formatAmount,
  formattedAmountDashboard,
} from '../../../../shared/utils/locale';

function SubscriptionCard({
  product,
  vatEnabled,
  lookups,
  isAnnualActive,
  setShowSalesContact,
}) {
  const price = product.price;
  const discountPercent = product.discountPercent;
  const subsAmount = getSubscriptionDiscountedPrice(price, discountPercent);
  const discountedAmount = subsAmount * 1;

  let taxAmount = 0;
  if (vatEnabled) {
    taxAmount = Math.ceil(0.13 * discountedAmount);
  }
  const finalSubsAmount = discountedAmount + taxAmount;

  const savedPercentYearly = (yearly, monthly) => {
    const totalMonthly = monthly * 12;
    const saved = 100 - Math.ceil((yearly / totalMonthly) * 100);
    return `Save ${saved} %`;
  };

  const getSubscriptionName = () => {
    const name1 = product.type.split('_').join(' ');
    const name2 = product.name.split(' ')[1];
    return `${name1} ${name2}`.toUpperCase();
  };

  return (
    <Box sx={{ boxShadow: 8, borderRadius: 2, pb: 2 }}>
      <Stack
        py={2}
        pb={4}
        sx={{
          position: 'relative',
          backgroundColor: colors.white2,
          borderRadius: 2,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        alignItems={'center'}>
        <Typography
          mb={4}
          // borderBottom={1}
          sx={{ fontSize: 20, fontWeight: 'bold', color: colors.darker2 }}>
          {product.name.toUpperCase()}
          {/* {getSubscriptionName()} */}
        </Typography>

        {product.offerId > 0 && (
          <Stack>
            <Typography
              sx={{
                py: 0.6,
                fontSize: 20,
                textDecorationLine: 'line-through',
                color: colors.darker2,
              }}>
              {product.price} NPR
            </Typography>

            <Typography
              sx={{
                p: 0.6,
                position: 'absolute',
                right: 0,
                fontSize: 14,
                lineHeight: 1,
                boxShadow: 2,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                fontWeight: 'bold',
                backgroundColor: 'yellow',
                textAlign: 'center',
              }}>
              {`${product.discountPercent}% OFF`}
              <br />
              {product.offerName}
            </Typography>
          </Stack>
        )}

        <Stack
          py={1.4}
          pb={!product.offerId ? 2.4 : 1.4}
          direction="row"
          alignItems={'flex-end'}
          gap={0.4}>
          <Typography
            sx={{
              fontSize: 48,
              fontWeight: 'bold',
              color: colors.darker,
              lineHeight: 1,
            }}>
            {product.offerId
              ? discountedAmount
              : formattedAmountDashboard(discountedAmount)}
          </Typography>
          <Typography sx={{ fontSize: 14, color: colors.darker, pb: 0.6 }}>
            NPR
          </Typography>

          {/* {!product.offerId && isAnnualActive && (
            <Typography
              sx={{
                p: 0.6,
                position: 'absolute',
                right: 0,
                mb: 2,
                fontSize: 14,
                lineHeight: 1,
                boxShadow: 2,
                borderTopLeftRadius: 5,
                borderBottomLeftRadius: 5,
                fontWeight: 'bold',
                backgroundColor: 'yellow',
                textAlign: 'center',
              }}>
              {savedPercentYearly(product.price, product.newPrice)}
            </Typography>
          )} */}
        </Stack>

        {vatEnabled && (
          <Typography sx={{ fontSize: 20, color: colors.darker }}>
            +13% VAT = {finalSubsAmount}
          </Typography>
        )}
        <Typography sx={{ fontSize: 20, color: colors.darker3 }}>
          per {isAnnualActive ? 'year' : 'quarter'}, per business
        </Typography>
      </Stack>
      <Stack pt={2} px={2} gap={2}>
        <Stack gap={1}>
          {lookups?.map((lookup, index) => (
            <Stack
              key={lookup.id}
              sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <CheckCircleIcon sx={{ color: colors.green600, fontSize: 20 }} />
              <Typography sx={{ fontWeight: index === 0 ? 'bold' : 'normal' }}>
                {lookup.text}
              </Typography>
            </Stack>
          ))}
        </Stack>

        <Stack flex="1" pt={1}>
          <Button
            // disabled={activeSubsProduct === product.type ? true : false}
            color="primary"
            variant="contained"
            size="large"
            sx={{ py: 1.6, fontWeight: 'bold' }}
            onClick={() => setShowSalesContact(true)}>
            BUY {product.name.split(' ')[0]} Plan
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default SubscriptionCard;
