// EDIT AT
// https://jsfiddle.net/

// import { dateFormat } from '../constants/defaultCodes/otherEnums';
// import { formatDate } from '../shared/utils/dateTime';
import { formatAmount } from '../shared/utils/locale';

export const partnerLedgerDashTemplate = (data: any) => {
  const { partnerLedgers, user, createdAt } = data;
  const { allVendors, allCustomers, totalBalance } = data;

  // const userDateFormat = user.business.dateFormat;
  const businessName = user.business.name;
  const phoneNumber = user.business.phoneNumber || user.phoneNumber;
  const businessAddress = user.business.address;

  let reportTitle = 'Partner Ledger';
  if (allCustomers) {
    reportTitle = 'Partner (Customer) Ledger';
  } else if (allVendors) {
    reportTitle = 'Partner (Vendor) Ledger';
  }

  // const { normalAmountSum, partnerLedgers } = ledgerData;
  // const { openingBalanceSum, closingBalanceSum } = ledgerData;

  const getPartnerElem = (item: any) => {
    const htmlElem = `<tr class="sub-heading">
    <td>${item.code} ${item.name} ${
      item.phoneNumber ? `(${item.phoneNumber})` : ''
    }</td>
    </tr>`;
    return htmlElem;
  };

  const getVoucherElem = (item: any) => {
    const htmlElem = `<tr class="item">
    <td>${item.account.name} </td>
    <td class="text-right">${formatAmount(item.closingBalance)}</td>
    </tr>`;
    return htmlElem;
  };

  let voucherItemsElem = '';
  for (const partnerItem of partnerLedgers) {
    voucherItemsElem += getPartnerElem(partnerItem.partner);
    for (const vItem of partnerItem.accountLedgers) {
      voucherItemsElem += getVoucherElem(vItem);
    }
    // voucherItemsElem += getPartnerSumElem(partnerItem.partner);
  }

  const template = `<!DOCTYPE html>
  <html>
    <head>
      <meta charset="utf-8" />
      <title>A simple, clean, and responsive HTML invoice template</title>

      <style>
          @page {
              size: A4;
              margin: 64px;
              margin-left: 32px;
              margin-right: 32px;
          }

        .main {
          margin: 16px;
          margin-left: 36px;
          margin-right: 36px;
        }

        .box table {
          width: 100%;
          line-height: inherit;
          text-align: left;
        }

        .box table td {
          padding: 5px;
          vertical-align: top;
        }

        .box table tr td:nth-child(2) {
          text-align: right;
        }

        .box table tr.top table td {
          padding-bottom: 20px;
        }

        .box table tr.top table td.title {
          font-size: 45px;
          line-height: 45px;
          color: #333;
        }

        .box table tr.heading td {
          /* font-size: 18px; */
          background: #AFAFAF;
          border-bottom: 1px solid #ddd;
          font-weight: bold;
          padding-left: 32px;
        }

        .box table tr.item td {
          font-size: 18px;
          border-bottom: 1px solid #eee;
        }

        .box table tr.sub-heading td {
          font-size: 18px;
          border-bottom: 1px solid #eee;
          padding-top: 8px;
          font-weight: bold;
        }

        .box table tr.summary td {
          font-size: 18px;
          border-bottom: 1px solid #eee;
          font-weight: bold;
          background: #DFDFDF;
          text-align: right;
        }


        .box table tr.total-summary td {
          font-size: 20px;
          border-bottom: 1px solid #eee;
          font-weight: bold;
          background: #BFBFBF;
          text-align: right;
        }

        .box table tr.item.last td {
          border-bottom: none;
        }

        .box table tr.total td:nth-child(2) {
          border-top: 2px solid #eee;
          font-weight: bold;
        }

        .footer {
          bottom: 0px;
          left: 16px;
          right: 16px;
          margin-top: 16px;
          padding: 8px 0 16px 2px;
          border-top: 1px solid #eee;
          border-top-color: #AFAFAF;
          font-weight: bold;
        }

        @media only screen and (max-width: 600px) {
          .box table tr.top table td {
            width: 100%;
            display: block;
            text-align: center;
          }

          .box table tr.information table td {
            width: 100%;
            display: block;
            text-align: center;
          }
        }

        .text-right {
          text-align: right;
        }

        .text-center {
          text-align: center;
        }

        .titleText {
            font-size: 20px;
            font-weight: bold;
        }
        .companyName {
            font-size: 24px;
            font-weight: bold;
        }
        .phoneNo {
            font-size: 18px;
            font-weight: medium;
        }

        .italic {
          font-style: italic;
      }


      </style>
    </head>

    <body class="main">
      <div class="box">
        <table cellpadding="0" cellspacing="0">
          <tr class="top">
            <td colspan="2">
              <table>
                <tr>
                  <td>
                    <span class="companyName">${businessName}</span><br />
                    ${
                      businessAddress
                        ? `<span class="phoneNo">${businessAddress}</span><br />`
                        : ''
                    }
                    <span class="phoneNo">${phoneNumber}</span><br /><br />
                  </td>

                  <td>
                    <span class="titleText">${reportTitle}</span><br />
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>

        <table>
          <tr class="heading">
            <td>Account</td>
            <td class="text-right">Due Balance</td>
          </tr>
          ${voucherItemsElem}
          <tr class="total-summary">
            <td class="text-right">Total Due Balance</td>
            <td class="text-right">${formatAmount(totalBalance)}</td>
          </tr>
        </table>
      </div>

      <div class="footer">
        <span>Created at: ${createdAt}</span><br />
        <span>karodapp.com</span>
      </div>
    </body>
  </html>`;
  return template;
};
