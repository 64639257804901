import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';

import { createEmployee } from '../../../controllers/settings/employeeControllers';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import { checkAllowedSettingCount } from '../../../controllers/accounts/userAccountControllers';
import { validateEmployee } from '../../../validations/settings/validateEmployee';
import { useNavigate } from 'react-router-dom';
import {
  employeeInputFields,
  employeeInputFields2,
} from '../../../constants/formFields/settings/employeeFields';
import { userRoles } from '../../../constants/defaultCodes/userRoles';
import SwitchForm from '../../../components/forms/SwitchForm';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import { getUserBusinesses } from '../../../controllers/settings/businessControllers';
import MultiSelectPicker from '../../../components/forms/MultiSelectPicker';
import SubscriptionUpgradeNeeded from '../../../components/modals/SubscriptionUpgradeNeeded';
import { colors } from '../../../assets/styles/colors';

const initData = { initialData: { userBusinesses: [] } };

function CreateEmployeePage() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const init = { businesses: [], appUser: false };

  const [formData, setData] = useState<any>(init);
  const [errors, setErrors] = useState<any>({});
  const [showWarning, setShowWarning] = useState(false);

  const subsCount = useQuery(['getSubsCount'], checkAllowedSettingCount);
  const { isSuccess, data = {} } = subsCount;

  const getBizFn = () => getUserBusinesses();
  const business = useQuery(['getBusinesses'], getBizFn, initData);

  const { isLoading, mutateAsync } = useMutation(createEmployee);

  useEffect(() => {
    if (isSuccess && formData.appUser) {
      if (!data.employee?.allowedNew) {
        setShowWarning(true);
      }
    }
  }, [formData.appUser]);

  const onSubmit = async () => {
    const { isValid, errors: errorMsg } = validateEmployee(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getSubsCount']);
        queryClient.invalidateQueries(['getEmployees']);
        navigate('/settings/employees');
        enqueueSnackbar(t('employee.createMsg.success'), successToast);

        // console.log('employee:', data);
      } catch (err) {
        // console.error('err:', err);
        enqueueSnackbar(t('employee.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errorMsg);
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateEmployee(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData SP:', formData);
  // console.log('errors SP:', errors);

  const totalAllowed = data.employee?.totalAllowed;

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.addEmployee')}
          </Typography>
          <Divider />
          <Stack py={4} gap={2}>
            <Grid xs={12}>
              <SwitchForm
                isChecked={formData.appUser}
                label={t(`employee.label.appUser-${formData.appUser}`)}
                onToggle={(e, v) => onFormChange('appUser', v)}
              />
            </Grid>

            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 2 }}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              {[...employeeInputFields, ...employeeInputFields2].map(
                ({ fieldName, required }) => (
                  <Grid xs={6} key={fieldName}>
                    <InputForm
                      isRequired={
                        fieldName === 'email' && !formData.appUser
                          ? false
                          : true
                      }
                      defaultValue={formData[fieldName]}
                      isFloatNo={false}
                      maxLength={fieldName === 'phoneNumber' && 10}
                      isNumberic={fieldName === 'phoneNumber' ? true : false}
                      label={t(`employee.label.${fieldName}`)}
                      placeHolder={t(`employee.placeholder.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      helpText={fieldName in errors && errors[fieldName]}
                      onChangeText={(v: string) => onFormChange(fieldName, v)}
                    />
                  </Grid>
                ),
              )}
            </Grid>

            <MultiSelectPicker
              isRequired={true}
              selectedValue={formData.businesses}
              selectItems={business.data?.userBusinesses}
              label={
                formData.appUser
                  ? t('employee.label.businesses')
                  : t('employee.label.businesses2')
              }
              placeHolder={
                formData.appUser
                  ? t('employee.placeholder.businesses')
                  : t('employee.placeholder.businesses2')
              }
              isInvalid={'businesses' in errors}
              helpText={'businesses' in errors && errors.businesses}
              onValueChange={(v: string) => onFormChange('businesses', v)}
            />

            {formData.appUser && (
              <Box>
                <SelectPicker
                  mode="modal"
                  isRequired={true}
                  selectItems={userRoles}
                  selectedValue={formData.userRole}
                  label={t('employee.label.userRole')}
                  isInvalid={'userRole' in errors}
                  helpText={'userRole' in errors && errors.userRole}
                  onValueChange={(v: string) => onFormChange('userRole', v)}
                />
              </Box>
            )}

            {formData.appUser && (
              <Typography
                bgcolor={colors.gray200}
                sx={{ fontSize: 15, my: 1, p: 0.4, fontStyle: 'italic' }}>
                {t('employee.roleMessage')}
                <Typography sx={{ fontSize: 15, fontWeight: 'bold' }}>
                  {t('employee.passwordMessage')}
                </Typography>
              </Typography>
            )}
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Employee"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>

        <SubscriptionUpgradeNeeded
          {...{ showWarning, setShowWarning, totalEmployee: totalAllowed }}
        />
      </Card>
    </Container>
  );
}

export default CreateEmployeePage;
