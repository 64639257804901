import i18next from 'i18next';

export const settings = () => {
  return [
    {
      id: 21,
      name: i18next.t('setting.accounting.title'),
      addText: 'Add Account',
      viewText: 'Accounts',
      addNavigateTo: 'addAccount',
      viewNavigateTo: 'accountsList',
      iconName: 'money-bill',
      items: [
        {
          id: 'accounts',
          name: i18next.t('setting.accounting.account'),
          addNavigateTo: 'accounts/create',
          viewNavigateTo: 'accountsList',
          navigateTo: 'accounts',
          icon: 'money-bill',
        },
        {
          name: i18next.t('setting.accounting.partner'),
          addNavigateTo: 'partners/create',
          viewNavigateTo: 'partnersList',
          navigateTo: 'partners',
          icon: 'users',
        },
        // {
        //   name: i18next.t('setting.accounting.lnPartner'),
        //   addNavigateTo: 'addLoanPartner',
        //   viewNavigateTo: 'loanPartnersList',
        //   icon: 'user-tie',
        // },
        {
          name: i18next.t('setting.accounting.partyCategory'),
          addNavigateTo: 'partner-categories/create',
          // addNavigateTo: 'addPartnerCategory',
          viewNavigateTo: 'partnerCategories',
          navigateTo: 'partner-categories',
          icon: 'th-list',
          isLast: true,
        },
      ],
    },
    {
      id: 22,
      name: i18next.t('setting.product.title'),
      addText: 'Add Product',
      viewText: 'Product Items',
      addNavigateTo: 'createProductItem',
      viewNavigateTo: 'productItemList',
      iconName: 'check-circle',
      items: [
        {
          name: i18next.t('setting.product.product'),
          addNavigateTo: 'products/create',
          viewNavigateTo: 'productItemList',
          navigateTo: 'products',
          icon: 'store',
        },
        {
          name: i18next.t('setting.product.productUnit'),
          addNavigateTo: 'product-units/create',
          viewNavigateTo: 'createProductUnit',
          navigateTo: 'product-units',
          icon: 'compass',
        },
        {
          name: i18next.t('setting.product.prodCategory'),
          viewNavigateTo: 'product-categories/create',
          addNavigateTo: 'product-categories/create',
          navigateTo: 'product-categories',
          icon: 'th-list',
        },
        {
          name: i18next.t('setting.product.expenseItem'),
          addNavigateTo: 'expense-items/create',
          viewNavigateTo: 'expenseItemList',
          navigateTo: 'expense-items',
          icon: 'cart-plus',
          isLast: true,
        },
      ],
    },
    {
      id: 11,
      name: i18next.t('setting.company.title'),
      addText: 'Add Business',
      viewText: 'Businesses',
      addNavigateTo: 'addBusiness',
      viewNavigateTo: 'businessesList',
      iconName: 'building',
      items: [
        {
          id: 'business',
          name: i18next.t('setting.company.business'),
          addNavigateTo: 'businesses/create',
          viewNavigateTo: 'businessesList',
          navigateTo: 'businesses',
          icon: 'building',
        },
        {
          id: 'employee',
          name: i18next.t('setting.company.employee'),
          addNavigateTo: 'employees/create',
          viewNavigateTo: 'employeesList',
          navigateTo: 'employees',
          icon: 'user',
        },
        {
          name: i18next.t('setting.userSetting.userSetting'),
          addNavigateTo: 'user-settings',
          navigateTo: 'user-settings',
          icon: 'user-cog',
          isLast: true,
        },
      ],
    },
    // {
    //   id: 32,
    //   name: i18next.t('setting.userSetting.title'),
    //   items: [
    //     {
    //       name: i18next.t('setting.userSetting.userSetting'),
    //       viewNavigateTo: 'userSettings',
    //       icon: 'user-cog',
    //       isLast: true,
    //     },
    //   ],
    // },
  ];
};
