import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import {
  CardContent,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { Delete } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import InputForm from '../../../components/forms/InputForm';
import {
  deletePartner,
  getPartners,
} from '../../../controllers/settings/partnerControllers';
import Button, { TextButton } from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import SelectPicker from '../../../components/forms/SelectPicker';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import { colors } from '../../../assets/styles/colors';
import PartnerDeleteWarning from '../../../components/modals/PartnerDeleteWarning';
import { checkPartnerTxn } from '../../../controllers/transactions/transactionControllers';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

const PartnerListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [vendorOnly, setVendorOnly] = useState<boolean>(false);
  const [customerOnly, setCustomerOnly] = useState<boolean>(false);
  const [partnerType, setPartnerType] = useState<number>(1);
  const [showDeleteWarning, setDeleteWarning] = useState(false);
  const [hasTxns, sethasTxns] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState({});

  const partyFn = () => getPartners({ withOpeningBalance: true });
  const partnersQuery = useQuery(['getPartners'], partyFn);
  const { isLoading, isError, data = [] } = partnersQuery;

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = data.filter((partner: any) => {
      const { name, code, phoneNumber, address } = partner;

      const hasName = name?.toLowerCase().includes(text);
      const hasCode = code?.toLowerCase().includes(text);
      const hasPhone = phoneNumber?.toLowerCase().includes(text);
      const hasAddress = address?.toLowerCase().includes(text);

      if (hasName || hasCode || hasPhone || hasAddress) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  const vendorsOnly = () => {
    setVendorOnly(true);
    setCustomerOnly(false);
    setFilteredItems(data.filter(x => x.isVendor === true));
  };

  const customersOnly = () => {
    setCustomerOnly(true);
    setVendorOnly(false);
    setFilteredItems(data.filter(x => x.isCustomer === true));
  };

  const onChangePartnerType = (id: number) => {
    setPartnerType(id);
    if (id == 2) {
      setFilteredItems(data.filter(x => x.isCustomer === true));
    } else if (id == 3) {
      setFilteredItems(data.filter(x => x.isVendor === true));
    }
  };
  // console.log('partnerType:', partnerType);

  let partnerList = data;
  if (searchText || partnerType == 2 || partnerType == 3) {
    partnerList = filteredItems;
  }

  const selectOptions = [
    { id: 1, name: 'All Partners' },
    { id: 2, name: 'Customers' },
    { id: 3, name: 'Vendors' },
  ];

  const { mutateAsync } = useMutation(checkPartnerTxn);

  const onPartnerDeletePress = async (partner: any) => {
    setSelectedPartner(partner);
    const hasTransactions = await mutateAsync(partner.id);
    setDeleteWarning(true);
    sethasTxns(hasTransactions);
  };

  const delPartnerAsync = useMutation(deletePartner);
  const onConfirmDeletion = async (partner: any) => {
    try {
      if (!hasTxns) {
        await delPartnerAsync.mutateAsync(partner.id);
        queryClient.invalidateQueries(['getPartners']);
        queryClient.invalidateQueries(['getDashboardReport']);

        enqueueSnackbar(t('partner.deleteMsg.success'), successToast);
        setDeleteWarning(false);
      }
    } catch (err) {
      enqueueSnackbar(t('partner.deleteMsg.error'), errorToast);
    } finally {
      setDeleteWarning(false);
    }
  };

  // console.log('data partners:', data);

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent>
        <Stack pb={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={6}>
                <Typography sx={{ fontSize: 20 }}>
                  {t('settingStack.partnerList')} ({partnerList.length})
                </Typography>
              </Grid>
              <Grid xs={2}>
                <SelectPicker
                  height={32}
                  size="small"
                  inLineLabel={true}
                  disableClearable={true}
                  selectedValue={partnerType}
                  selectItems={selectOptions}
                  label={'partner type'}
                  onValueChange={(v: number) => onChangePartnerType(v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  size="small"
                  inLineLabel={true}
                  label={'search partner by name, code, phone or address...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              title="Add New Partner"
              onClick={() => navigate('/settings/partners/create')}
            />
          </Box>
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Partner Code</TbCellHeader>
                  <TbCellHeader>Partner Name</TbCellHeader>
                  <TbCellHeader>Phone Number</TbCellHeader>
                  <TbCellHeader>Address</TbCellHeader>
                  <TbCellHeader>Type</TbCellHeader>
                  <TbCellHeader align="right">Status</TbCellHeader>
                  <TbCellHeader />
                  <TbCellHeader />
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {partnerList.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.code}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>{row.phoneNumber}</TbCellBody>
                    <TbCellBody>{row.address}</TbCellBody>
                    <TbCellBody>
                      <Chip
                        size="small"
                        label={row.isCustomer ? 'customer' : 'vendor'}
                        color={row.isCustomer ? 'info' : 'warning'}
                        variant="outlined"
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <Chip
                        size="small"
                        label={row.isActive ? 'ACTIVE' : 'IN-ACTIVE'}
                        color={row.isActive ? 'success' : 'error'}
                        variant="outlined"
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <TextButton
                        title="update"
                        size="sm"
                        onClick={() =>
                          navigate('/settings/partners/update', { state: row })
                        }
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <IconButton
                        sx={{ padding: 0.6 }}
                        onClick={() => onPartnerDeletePress(row)}>
                        <Delete sx={{ fontSize: 20, color: colors.red500 }} />
                      </IconButton>
                    </TbCellBody>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>

        <PartnerDeleteWarning
          isLoading={delPartnerAsync.isLoading}
          hasTxns={hasTxns}
          partner={selectedPartner}
          modalVisible={showDeleteWarning}
          onConfirmDeletion={onConfirmDeletion}
          onModalClose={() => {
            setDeleteWarning(false);
          }}
        />
      </CardContent>
    </Card>
  );
};

export default PartnerListPage;
