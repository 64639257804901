import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import {
  Box,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import Button, { TextButton } from '../../../components/buttons/Button';
import { colors } from '../../../assets/styles/colors';
import { useDispatch, useSelector } from '../../../store/reduxHooks';
import { getUserBusinesses } from '../../../controllers/settings/businessControllers';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import { isCompanySuperAdmin } from '../../../shared/utils/rolesUtils';

const BusinessListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { authUser } = useSelector(s => s.auth);
  // const dispatch = useDispatch();
  // const queryClient = useQueryClient();

  const getBizFn = () => getUserBusinesses();
  const bizQuery = useQuery(['getUserBusinesses'], getBizFn);
  const { isLoading, isError, data = { userBusinesses: [] } } = bizQuery;

  // console.log('data', data);

  return (
    <Card sx={{ minWidth: 275, paddingX: 4 }}>
      <CardContent>
        <Stack pb={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom>
                  {t('settingStack.businessList')} ({data.userBusinesses.length}
                  )
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {isCompanySuperAdmin(authUser.user) && (
            <Box>
              <Button
                title="Add New Business"
                onClick={() => navigate('/settings/businesses/create')}
              />
            </Box>
          )}
        </Stack>

        <Divider />

        <ScreenWrapper
          {...{ isLoading, isError, isEmpty: !data.userBusinesses.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Business Name</TbCellHeader>
                  <TbCellHeader>Phone Number</TbCellHeader>
                  <TbCellHeader>Address</TbCellHeader>
                  <TbCellHeader>Created At</TbCellHeader>
                  <TbCellHeader>Status</TbCellHeader>
                  {isCompanySuperAdmin(authUser.user) && <TbCellHeader />}
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {data.userBusinesses.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>{row.phoneNumber}</TbCellBody>
                    <TbCellBody>{row.address}</TbCellBody>

                    <TbCellBody>
                      {getSettingBasedDate(row.createdAt, authUser)}
                    </TbCellBody>
                    <TbCellBody>
                      <Chip
                        size="small"
                        label={row.isActive ? 'ACTIVE' : 'IN-ACTIVE'}
                        color={row.isActive ? 'success' : 'error'}
                        variant="outlined"
                      />
                    </TbCellBody>

                    {isCompanySuperAdmin(authUser.user) && (
                      <TbCellBody align="right">
                        <TextButton
                          title="update"
                          size="sm"
                          onClick={() =>
                            navigate('/settings/businesses/update', {
                              state: row,
                            })
                          }
                        />
                      </TbCellBody>
                    )}
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
};

export default BusinessListPage;
