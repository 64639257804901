import React, { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { mainListItems } from './listItems';
import DrawerStyled from './DrawerStyled';
import {
  checkAllowedSettingCount,
  updateActiveBusiness,
} from '../../controllers/accounts/userAccountControllers';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserBusinesses } from '../../controllers/settings/businessControllers';
import { useDispatch, useSelector } from '../../store/reduxHooks';
import { refreshUserDetails } from '../../store/authSlice';
import { errorToast, successToast } from '../../constants/snackbar/snackbar';
import { colors } from '../../assets/styles/colors';

const MainDrawer = ({ open, handleDrawerToggle }: any) => {
  const { authUser } = useSelector(s => s.auth);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const subsCount = useQuery(['getSubsCount'], checkAllowedSettingCount);
  const allowedBusiness = subsCount.data?.business?.totalAllowed;

  const getBizFn = () => getUserBusinesses();
  const bizQuery = useQuery(['getUserBusinesses'], getBizFn);
  const { isLoading, isFetched, data = { userBusinesses: [] } } = bizQuery;

  const activeBiz = authUser.user?.businessId;
  const { mutateAsync } = useMutation(updateActiveBusiness);

  const onBusinessChange = async (value: number) => {
    if (value) {
      const biz = getAllowedBusinessSelection().find(x => x.id === value);
      try {
        await mutateAsync({ business: value });
        queryClient.clear();
        await dispatch(refreshUserDetails());
        queryClient.invalidateQueries(['getDashboardReport']);
        enqueueSnackbar(`Active Business Updated to ${biz.name}`, successToast);
        navigate('/');
      } catch (err) {
        // console.log('err', err);
        enqueueSnackbar(`Error switching Business`, errorToast);
      }
    }
  };

  const getAllowedBusinessSelection = () => {
    const businesses: any = [];

    data.userBusinesses.map((biz, index) => {
      if (index < allowedBusiness && biz.isActive) {
        businesses.push(biz);
      }
    });

    return businesses;
  };

  return (
    <DrawerStyled variant="permanent" open={open}>
      <Toolbar
        variant="dense"
        sx={{
          display: 'flex',
          alignItems: 'center',
          // justifyContent: 'space-between',
          justifyContent: 'flex-end',
          px: [1],
        }}>
        {/* {open && (
          <Typography ml={2} sx={{ fontSize: 13 }}>
            KAROD - DIGITAL KHATA
          </Typography>
        )} */}
        {/* Disabled the drawer collapser */}
        <IconButton onClick={handleDrawerToggle}>
          <ChevronLeftIcon sx={{ fontSize: 26 }} />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav" sx={{ paddingY: 0 }}>
        {mainListItems(authUser)}
      </List>

      {open && isFetched && subsCount.isFetched && (
        <Stack flex={1} p={3} justifyContent={'flex-end'}>
          <Typography sx={{ fontSize: 14, color: colors.blueGrey700 }}>
            {t('navBar.activeBiz')}
          </Typography>
          <Select
            // size="small"
            variant="standard"
            labelId="activebiz-label"
            id="activebiz"
            value={activeBiz || ''}
            onChange={(e: any) => onBusinessChange(e.target.value)}>
            {getAllowedBusinessSelection().map((biz: any) => (
              <MenuItem key={biz.id} value={biz.id}>
                {biz.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    </DrawerStyled>
  );
};

export default MainDrawer;
