import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Card from '@mui/material/Card';
import {
  CardContent,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';

import InputForm from '../../../components/forms/InputForm';
import {
  deleteProductItem,
  getProductItems,
} from '../../../controllers/settings/inventoryControllers';
import { useNavigate } from 'react-router-dom';
import Button, { TextButton } from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import ProductItemDeleteWarning from '../../../components/modals/ProductItemDeleteWarning';
import { checkProductTxn } from '../../../controllers/transactions/transactionControllers';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import { colors } from '../../../assets/styles/colors';

const ProductItemListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [showBulkUpload, setShowBulkUpload] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  // const [showQRCode, setShowQRCode] = useState<boolean>(false);
  const [showDeleteWarning, setDeleteWarning] = useState(false);
  const [hasTxns, sethasTxns] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState({});

  const result = useQuery(['getProductItems'], getProductItems);
  const { isFetching: isLoading, isError, data = [] } = result;

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = data.filter((product: any) => {
      const { name, code } = product;
      const categoryName = product.category?.name;

      const hasName = name?.toLowerCase().includes(text);
      const hasCode = String(code)?.toLowerCase().includes(text);
      const hasCatName = categoryName?.toLowerCase().includes(text);

      if (hasName || hasCode || hasCatName) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let productList = data;
  if (searchText) {
    productList = filteredItems;
  }

  const { mutateAsync } = useMutation(checkProductTxn);

  const onProductDelete = async productItem => {
    setSelectedProduct(productItem);
    const hasTransactions = await mutateAsync(productItem.id);

    setDeleteWarning(true);
    sethasTxns(hasTransactions);
  };

  const delProductAsync = useMutation(deleteProductItem);
  const onConfirmDeletion = async (productItem: any) => {
    try {
      if (!hasTxns) {
        await delProductAsync.mutateAsync(productItem.id);
        queryClient.invalidateQueries(['getProductItems']);
        queryClient.invalidateQueries(['getDashboardReport']);

        enqueueSnackbar(t('productItem.deleteMsg.success'), successToast);
        setDeleteWarning(false);
      }
    } catch (err) {
      enqueueSnackbar(t('productItem.deleteMsg.error'), errorToast);
    } finally {
      setDeleteWarning(false);
    }
  };

  // console.log('data', data);

  return (
    <Card sx={{ minWidth: 275, paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1 }}>
        <Stack pb={1} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom>
                  {t('settingStack.productList')} ({productList.length})
                </Typography>
              </Grid>
              <Grid xs={4} pb={0.6}>
                <InputForm
                  size="small"
                  inLineLabel={true}
                  label={'search by name, category or code...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              size="md"
              title="Add New Product"
              onClick={() => navigate('/settings/products/create')}
            />
          </Box>
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Code</TbCellHeader>
                  <TbCellHeader>Product Name</TbCellHeader>
                  <TbCellHeader>Category</TbCellHeader>
                  <TbCellHeader>Product Type</TbCellHeader>
                  <TbCellHeader>Purchase Price</TbCellHeader>
                  <TbCellHeader>Selling Price</TbCellHeader>
                  <TbCellHeader align="right">Status</TbCellHeader>
                  <TbCellHeader />
                  <TbCellHeader />
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {productList.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.code}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>{row.category?.name}</TbCellBody>
                    <TbCellBody>{row.productType}</TbCellBody>
                    <TbCellBody>{row.purchasePrice}</TbCellBody>
                    <TbCellBody>{row.sellingPrice}</TbCellBody>
                    <TbCellBody align="right">
                      <Chip
                        size="small"
                        label={row.isActive ? 'ACTIVE' : 'IN-ACTIVE'}
                        color={row.isActive ? 'success' : 'error'}
                        variant="outlined"
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <TextButton
                        title="update"
                        size="sm"
                        onClick={() =>
                          navigate('/settings/products/update', { state: row })
                        }
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <IconButton
                        sx={{ padding: 0.6 }}
                        onClick={() => onProductDelete(row)}>
                        <Delete sx={{ fontSize: 20, color: colors.red500 }} />
                      </IconButton>
                    </TbCellBody>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>

        <ProductItemDeleteWarning
          isLoading={delProductAsync.isLoading}
          hasTxns={hasTxns}
          product={selectedProduct}
          modalVisible={showDeleteWarning}
          onConfirmDeletion={onConfirmDeletion}
          onModalClose={() => {
            setDeleteWarning(false);
          }}
        />
      </CardContent>
    </Card>
  );
};

export default ProductItemListPage;
