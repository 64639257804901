import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';

import TransactionActionMenu from './TransactionActionMenu';
import {
  entryHasPDF,
  getEditScreen,
} from '../../../shared/utils/getEditScreen';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import {
  customerVouchers,
  loanPartnerVouchers,
  paymentInOutVouchers,
  salesPurchaseVouchers,
  vendorVouchers,
  voucherTypes,
} from '../../../constants/defaultCodes/voucherTypes';
import { getSettingBasedDate } from '../../../shared/utils/getSettingBasedDate';
import { useSelector } from '../../../store/reduxHooks';
import { TableRowStyled } from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
} from '../../../components/styled/TableCellStyled';
import TransactionDetailModal from './TransactionDetailModal';
import { useNavigate } from 'react-router-dom';
import { OutlinedButton, TextButton } from '../../../components/buttons/Button';

const TxnEntriesTableItem = ({
  voucher,
  index,
  isReportScreen,
  refetchVouchers,
}: any) => {
  const { user } = useSelector(state => state.auth.authUser);
  const navigate = useNavigate();
  // const { t } = useTranslation();

  const [showDetail, setShowDetail] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const voucherId = voucher.id;

  // console.log('vouchersToDelete:', toDelete.data);
  // console.log('voucher isPaid:', voucher.isPaid);
  // console.log('voucher::', voucher);

  // const getReturnScreen = () => {
  //   if (type === voucherTypes.CREDIT_SALES) {
  //     return 'salesReturn';
  //   } else if (type === voucherTypes.CREDIT_PURCHASE) {
  //     return 'purchaseReturn';
  //   }
  // };
  const type = voucher.voucherType.code;

  const editScreen = getEditScreen(type);

  // console.log('type', voucher.otherInfo?.paidAmount);

  const getPartner = () => {
    if (customerVouchers.includes(type)) {
      const vItem = voucher.voucherItems.find(x => x.customer);
      return vItem?.customer?.name;
    } else if (vendorVouchers.includes(type)) {
      const vItem = voucher.voucherItems.find(x => x.vendor);
      return vItem?.vendor?.name;
    } else if (loanPartnerVouchers.includes(type)) {
      const vItem = voucher.voucherItems.find(x => x.loanPartner);
      return vItem?.loanPartner?.name;
    } else if (voucherTypes.ACCRUED_INTEREST === type) {
      const itm1 = voucher.voucherItems.find(x => x.customer);
      const itm2 = voucher.voucherItems.find(x => x.vendor);
      const itm3 = voucher.voucherItems.find(x => x.loanPartner);

      const name = itm1?.customer?.name || itm2?.vendor?.name;
      return name || itm3?.loanPartner?.name;
      /** HANDLED LIKE THIS BECAUSE, BOTH VENDOR & CUSTOMER WONT BE TAGGED IN PAYMENT IN/OUT VOUCHERS */
    } else if (paymentInOutVouchers.includes(type)) {
      const itm1 = voucher.voucherItems.find(x => x.customer);
      const itm2 = voucher.voucherItems.find(x => x.vendor);

      return itm1?.customer?.name || itm2?.vendor?.name;
    }
  };

  // console.log('voucher ID:', voucher.id);

  const userVoucherDate = getSettingBasedDate(voucher.voucherDate, user);
  return (
    <TableRowStyled key={voucher.id}>
      <TbCellBody sx={{ maxWidth: 20 }}>{index}</TbCellBody>
      <TbCellBody>{getSettingBasedDate(voucher.voucherDate, user)}</TbCellBody>

      <TbCellBody>
        <Chip
          size="small"
          label={voucher.voucherType.code.split('_').join(' ')}
          color="default"
        />
      </TbCellBody>
      <TbCellBody>{getPartner()}</TbCellBody>
      <TbCellBody>{voucher.referenceId}</TbCellBody>
      <TbCellBody>{voucher.voucherNumber}</TbCellBody>
      <TbCellBody>
        <Chip
          size="small"
          variant="outlined"
          label={`${voucher.aging} ${voucher.aging <= 1 ? 'day' : 'days'}`}
          color={voucher.aging <= 30 ? 'primary' : 'error'}
          sx={{
            backgroundColor:
              voucher.aging <= 30 ? colors.info100 : colors.red100,
          }}
        />
      </TbCellBody>

      <TbCellAmount
        align="right"
        style={{
          fontWeight: 'bold',
          textDecorationLine: voucher.isCancelled && 'line-through',
        }}>
        {formatAmount(voucher.txnAmount || voucher.debitSum)}

        {((voucher.isCredit && voucher.otherInfo?.paidAmount) ||
          voucher?.isCancelled) && <br />}

        {voucher.isCredit && voucher.otherInfo?.paidAmount && (
          <Chip
            sx={{
              marginLeft: 1,
              backgroundColor: colors.green100,
              mr: voucher?.isCancelled ? 0.4 : 0,
            }}
            size="small"
            variant="outlined"
            label={`PAID: ${voucher.otherInfo?.paidAmount}`}
            color="success"
          />
        )}

        {voucher?.isCancelled && (
          <Chip
            sx={{ backgroundColor: colors.red100 }}
            size="small"
            variant="outlined"
            label={'cancelled'}
            color="error"
          />
        )}
      </TbCellAmount>

      <TbCellBody align="right" sx={{ maxWidth: 60, zIndex: -10 }}>
        <Button
          onClick={() => setShowDetail(true)}
          variant="text"
          size="small"
          sx={{ fontWeight: '500', zIndex: 0 }}>
          View
        </Button>
      </TbCellBody>

      <TbCellBody align="right" sx={{ maxWidth: 46 }}>
        <Tooltip title="Action Menu">
          <IconButton
            id="action-button"
            onClick={e => setAnchorElUser(e.currentTarget)}
            size="small">
            <MoreVertRoundedIcon />
          </IconButton>
        </Tooltip>
      </TbCellBody>

      <TransactionActionMenu
        anchorEl={anchorElUser}
        setAnchorEl={setAnchorElUser}
        voucher={voucher}
        refetchVouchers={refetchVouchers}
        userVoucherDate={userVoucherDate}
        onEditVoucher={() => {
          navigate(editScreen, { state: voucherId });
        }}
      />

      {showDetail && (
        <TransactionDetailModal
          showModal={showDetail}
          onClose={() => setShowDetail(false)}
          voucherId={voucherId}
        />
      )}
    </TableRowStyled>
  );
};

export default TxnEntriesTableItem;
