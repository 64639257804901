export enum ProductItemType {
  product = 'product',
  service = 'service',
}

export const productItemTypes = [
  { id: ProductItemType.product, name: 'Product' },
  { id: ProductItemType.service, name: 'Service' },
];

export enum DiscountType {
  percent = 'percent',
  amount = 'amount',
}

export const discountTypes = [
  { id: DiscountType.percent, name: 'Percent' },
  { id: DiscountType.amount, name: 'Amount' },
];
