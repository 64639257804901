import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';

import { getProductUnits } from '../../../controllers/settings/inventoryControllers';
import { productStockMovement } from '../../../controllers/reports/reportControllers';
import StockMovementTable from './StockMovementTable';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';

const init = { initialData: [] };
const initDt = { initialData: { productItem: {}, productStockItems: [] } };

const ProductStockDetail = ({ productItem, onCloseDetail }): JSX.Element => {
  // const { state: productItem } = useLocation();
  // if (!partner) {
  //   navigate('/settings/partners');
  //   return null;
  // }

  const { id: sId, name, primaryUnitId, secondaryUnitId } = productItem;

  // const productUnits = useQuery(['productUnits'], getProductUnits, init);

  const filter = { product: sId, fromDetailScreen: true };
  const stockMovementFn = () => productStockMovement(filter);
  const stockMovement = useQuery(['stockMovement'], stockMovementFn, initDt);
  const { isLoading, isError, data } = stockMovement;
  // const isLoading = true;

  let prodUnits: any = [];
  if (primaryUnitId) {
    const primary = { id: primaryUnitId, name: productItem.primaryUnit.name };
    prodUnits.push(primary);
  }
  if (secondaryUnitId) {
    const sec = { id: secondaryUnitId, name: productItem.secondaryUnit.name };
    prodUnits.push(sec);
  }

  return (
    <>
      <Stack>
        {/* <View style={{ flex: 1 }}>
            <Heading fontSize="xl">
              {name} ({data && data.productStockItems.length})
            </Heading>
            {productItem.code && (
              <Text
                style={{
                  fontSize: 15,
                  color: colors.darker,
                  fontWeight: '500',
                }}>
                {`code: ${productItem.code}`}
              </Text>
            )}
          </View> */}

        {/* <Button
            onPress={() => navigation.navigate('stockAdjustment')}
            bg={colors.theme1}
            size="sm"
            _text={{ fontSize: 13 }}>
            adjustment
          </Button> */}
      </Stack>
      <ScreenWrapper {...{ isLoading, isError }}>
        <StockMovementTable
          stockMovement={stockMovement}
          product={productItem}
          fromDetailScreen={true}
          onCloseDetail={onCloseDetail}
        />
      </ScreenWrapper>
    </>
  );
};

export default ProductStockDetail;
