import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { ClickAwayListener, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

import { colors } from '../../assets/styles/colors';
import { LocalizationProvider } from '@mui/x-date-pickers';

const ADDatePicker = ({
  label,
  value,
  onDateChange,
  helpText,
  height = 40,
  minDate,
  isInvalid = false,
  isRequired = true,
  enableClear = false,
  selectPeriod,
  maxDateLimit,
  inLineLabel = false,
  size = 'medium',
}: any): JSX.Element => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const getStyles = () => {
    let inputStyles: any = {};
    if (isRequired) {
      inputStyles = { ...inputStyles, borderColor: colors.red, borderWidth: 2 };
    }
    return inputStyles;
  };

  const onDateValueChange = (newValue: Dayjs) => {
    const dayValue = newValue['$d'];
    onDateChange(dayValue);
  };

  const onKeyDown = e => {
    e.preventDefault();
  };

  return (
    <Stack>
      <Stack py={0.4} direction={'row'} justifyContent={'space-between'}>
        {label && !inLineLabel && (
          <Box py={0.4}>
            <label>{label}</label>
          </Box>
        )}
      </Stack>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          // open={open}
          // onOpen={() => setOpen(true)}
          // onClose={() => setOpen(false)}
          minDate={dayjs(minDate)}
          format="MMM DD, YYYY"
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: colors.darker3,
              backgroundColor: colors.gray100,
            },
            '.MuiOutlinedInput-notchedOutline': isRequired
              ? {
                  'border-left-color': colors.red500,
                  'border-left-width': '2px',
                }
              : {},
          }}
          // defaultValue={dayjs('2022-04-17')}
          slotProps={{
            textField: {
              size,
              disabled: true,
              'aria-readonly': true,
              // onKeyDown: onKeyDown,
              // onClick: e => setOpen(true),
            },
            field: { clearable: false },
          }}
          label={inLineLabel ? label : undefined}
          value={value ? dayjs(value) : ''}
          onChange={(newValue: any) => onDateValueChange(newValue)}
        />
      </LocalizationProvider>

      {isInvalid && (
        <Typography pt={0.4} fontSize={14} color={colors.red500}>
          {`${t('commonText.error')}: ${helpText}`}
        </Typography>
      )}

      {helpText && !isInvalid && (
        <Typography pt={0.4} fontSize={14}>
          {helpText}
        </Typography>
      )}
    </Stack>
  );
};

export default ADDatePicker;
