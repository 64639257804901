import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useSnackbar } from 'notistack';

import {
  signInWithPhoneNumber,
  confirmVerificationCode,
  userPasswordReset,
} from '../../store/authSlice';
import { useDispatch, useSelector } from '../../store/reduxHooks';
import { validatePasswordResetData } from '../../validations/auth/validateUserRegistration';
import { colors } from '../../assets/styles/colors';
import InputForm from '../../components/forms/InputForm';

import { checkUserExistance } from '../../controllers/accounts/userAccountControllers';
import { useMutation } from '@tanstack/react-query';
import OTPCodeInput from './components/OTPCodeInput';
import { OTPUsage } from '../../constants/defaultCodes/authAndAccounts';

import { TextButton } from '../../components/buttons/Button';
import { passwordResetFields } from '../../constants/formFields/auth/registrationFields';
import { errorToast, successToast } from '../../constants/snackbar/snackbar';
import { LoadingButton } from '@mui/lab';

const ResetPassword = ({ navigation }: any): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [userNotFound, setUserNotFound] = useState<boolean>(false);
  // console.log('phoneNumber:', phoneNumber);
  // console.log('code:', code);

  const authSlice = useSelector(s => s.auth);
  const { otpSent, requestingCode, confirmingCode } = authSlice;
  const { codeVerified, invalidCode } = authSlice;

  const checkUser = useMutation(checkUserExistance);

  async function onSubmitPhoneNumber() {
    if (phoneNumber) {
      const checkPayload = { phoneNumber, fromPasswordReset: true };
      const userExist = await checkUser.mutateAsync(checkPayload);
      // console.log('userExist', userExist);

      if (userExist) {
        const otpUsage = OTPUsage.PASSWORD_RESET;
        const payload = { countryCode: '+977', phoneNumber, otpUsage };
        await dispatch(signInWithPhoneNumber(payload));
      } else {
        setUserNotFound(true);
      }
    }
  }

  async function onSubmitVerificationCode() {
    if (code) {
      try {
        const payload = { countryCode: '+977', phoneNumber, otp: code };
        await dispatch(confirmVerificationCode(payload));
      } catch (error) {
        // console.log('Invalid code:', error);
        enqueueSnackbar('Invalid code:', errorToast);
      }
    }
  }

  const onFormValueChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validatePasswordResetData(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const getHelpTypography = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const onResetPassword = async () => {
    setIsResetting(true);
    const { isValid, errors: errs } = validatePasswordResetData(
      formData,
      errors,
    );

    if (isValid) {
      try {
        const payload = { phoneNumber, password: formData.password };
        const response = await dispatch(userPasswordReset(payload));

        if (response.meta.requestStatus === 'fulfilled') {
          navigate('/');
          enqueueSnackbar('Password reset successful', successToast);
        } else if (response.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Error reseting password!', errorToast);
        }
      } catch (err) {
        enqueueSnackbar('Error reseting password!', errorToast);
      } finally {
        setIsResetting(false);
      }
    } else {
      setErrors(errs);
      // console.log('Validation Failed');
    }
  };

  const renderPhoneNumberInput = () => {
    return (
      <Stack gap={4}>
        <Stack alignItems={'center'}>
          <Avatar sx={{ m: 1, bgcolor: colors.theme1 }}>
            <LockResetIcon />
          </Avatar>
          <Typography sx={{ fontSize: 20 }}>Password Reset</Typography>
        </Stack>

        <Stack gap={2}>
          <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
            Enter mobile number to reset your password!
          </Typography>
          <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
            आफ्नो पासवर्ड रिसेट गर्न मोबाइल नम्बर राख्नुहोस्!
          </Typography>
        </Stack>

        <InputForm
          maxLength={10}
          isRequired={true}
          isNumberic={true}
          isFloatNo={false}
          placeHolder={t(
            'userRegistration.placeholder.phoneNumber',
          ).toLowerCase()}
          onChangeText={(v: string) => {
            setPhoneNumber(v);
            setUserNotFound(false);
          }}
        />

        {userNotFound && (
          <Typography
            sx={{ color: colors.red500, fontWeight: 'bold', fontSize: 17 }}
            align="center">
            {`User ${phoneNumber} not found!`}
          </Typography>
        )}

        <LoadingButton
          size="large"
          variant="contained"
          disabled={phoneNumber.length !== 10 ? true : false}
          loading={requestingCode}
          onClick={() => onSubmitPhoneNumber()}>
          Get Code
        </LoadingButton>

        <Stack alignItems={'center'}>
          <Typography>I have my password</Typography>
          <TextButton
            color={colors.theme2}
            onClick={() => navigate('/login')}
            title="LOGIN/लगइन"
          />
        </Stack>
      </Stack>
    );
  };

  const renderPasswordInput = () => {
    return (
      <Stack gap={4} my={2}>
        <Stack>
          <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
            Enter your new password!
          </Typography>
          <Typography sx={{ fontSize: 18 }}>
            आफ्नो नयाँ पासवर्ड राख्नुहोस्!
          </Typography>
        </Stack>

        <Stack gap={2}>
          {passwordResetFields.map(({ fieldName }) => (
            <Stack flex={1} key={fieldName}>
              <Box py={0.8}>
                <label>{t(`userRegistration.label.${fieldName}`)}</label>
              </Box>
              <TextField
                required
                type="password"
                autoComplete="current-password"
                // type={showPassword ? 'Typography' : 'password'}
                placeholder={t(`userRegistration.label.${fieldName}`)}
                // error={'password' in errors}
                // helperText={'password' in errors && errors['password']}
                onChange={(e: any) =>
                  onFormValueChange(fieldName, e.target.value)
                }
                // InputRightElement={
                //   <Pressable onPress={() => setShowPassword(!showPassword)}>
                //     <Stack mr="1">
                //       <Icon
                //         name={showPassword ? 'eye' : 'eye-slash'}
                //         size={12}
                //       />
                //     </Stack>
                //   </Pressable>
                // }
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    'border-left-color': colors.red500,
                    'border-left-width': '2px',
                  },
                }}
              />

              {fieldName in errors && (
                <Typography pt={0.4} fontSize={14} color={colors.red500}>
                  {`Error: ${getHelpTypography(fieldName)}`}
                </Typography>
              )}
            </Stack>
          ))}
        </Stack>

        <LoadingButton
          size="large"
          variant="contained"
          loading={isResetting}
          onClick={() => onResetPassword()}>
          Reset Password
        </LoadingButton>
      </Stack>
    );
  };

  const renderRightScreen = () => {
    if (otpSent && codeVerified) {
      // if (true) {
      return renderPasswordInput();
    } else if (otpSent && !codeVerified) {
      // } else if (true) {
      return (
        <OTPCodeInput
          code={code}
          setCode={setCode}
          phoneNumber={phoneNumber}
          onSubmit={() => onSubmitVerificationCode()}
        />
      );
    } else {
      return renderPhoneNumberInput();
    }
  };

  // console.log('formData', formData);

  const containerStyle = {
    minHeight: '80vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const boxStyle = {
    gap: 2,
    margin: 2,
    padding: 4,
    boxShadow: 8,
    minWidth: 480,
    borderRadius: 2,
    display: 'flex',
    flexDirection: 'column',
  };

  return (
    <Container sx={containerStyle} maxWidth={'xs'}>
      <Box sx={boxStyle}>{renderRightScreen()}</Box>
    </Container>
  );
};

export default ResetPassword;
