export const employeeInputFields = [
  { fieldName: 'firstName', required: true, disabled: false },
  { fieldName: 'lastName', required: true, disabled: false },
];

export const employeeInputFields2 = [
  { fieldName: 'phoneNumber', required: true, disabled: true },
  { fieldName: 'email', required: true, disabled: true },
];

export const employeeInputFields3 = [
  { fieldName: 'password', required: true, disabled: false },
  { fieldName: 'password2', required: true, disabled: false },
];

export const employeeUpdateSwitchFields = [
  // { fieldName: 'isEmployee', required: false },
  // { fieldName: 'isOwner', required: false },
  // { fieldName: 'isCompanyAdmin', required: false, disabled: true },
  { fieldName: 'isActive', required: false, disabled: false },
];
