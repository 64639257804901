import { default as i18n } from 'i18next';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';

const advanceVoucher = voucherTypes.ADVANCE_RECEIVE;
const paymentVoucher = voucherTypes.CUSTOMER_PAYMENT;

export const validatePIVoucherNo = async (data, errors) => {
  const voucherType = voucherTypes.PAYMENT_IN;

  const newVoucherNumber = await getVoucherNumber(voucherType);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('form.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateVoucherNo = async (data, errors) => {
  const voucherType = data.isAdvanceReceive ? advanceVoucher : paymentVoucher;

  const newVoucherNumber = await getVoucherNumber(voucherType);

  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('form.validation.voucherNumberExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validatePaymentIn = (data, errors) => {
  let isValid = true;

  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('form.validation.requireVoucherNo'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('form.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18n.t('form.validation.requireVoucherDate'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.partner) {
    errors = { ...errors, partner: i18n.t('form.validation.requireCustomer') };
    isValid = false;
  } else {
    delete errors.partner;
  }

  if (!data.cashInAccount) {
    errors = {
      ...errors,
      cashInAccount: i18n.t('form.validation.cashInAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  if (!Number(data.amount) || data.amount <= 0) {
    errors = { ...errors, amount: i18n.t('form.validation.requireAmount') };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.interestReceived && data.interestReceived < 0) {
    errors = {
      ...errors,
      interestReceived: 'Interest received cannot be negative value',
    };
    isValid = false;
  } else {
    delete errors.interestReceived;
  }

  if (data.discount && data.discount < 0) {
    errors = { ...errors, discount: 'Discount cannot be negative value' };
    isValid = false;
  } else {
    delete errors.discount;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18n.t('form.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18n.t('form.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};

export const validateAdvanceReceive = (data, errors) => {
  let isValid = true;

  if (!data.voucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('form.validation.requireVoucherNo'),
    };
    isValid = false;
  } else if (data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18n.t('form.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (!data.voucherDate) {
    errors = {
      ...errors,
      voucherDate: i18n.t('form.validation.requireVoucherDate'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (!data.customer) {
    errors = { ...errors, customer: i18n.t('form.validation.requireCustomer') };
    isValid = false;
  } else {
    delete errors.customer;
  }

  if (data.isAdvanceReceive && !data.cashInAccount) {
    errors = {
      ...errors,
      cashInAccount: i18n.t('form.validation.cashInAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashInAccount;
  }

  if (!data.isAdvanceReceive && !data.cashOutAccount) {
    errors = {
      ...errors,
      cashOutAccount: i18n.t('form.validation.cashOutAccountReq'),
    };
    isValid = false;
  } else {
    delete errors.cashOutAccount;
  }

  if (!Number(data.amount) || data.amount <= 0) {
    errors = { ...errors, amount: i18n.t('form.validation.requireAmount') };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (data.description && data.description.length > 256) {
    errors = {
      ...errors,
      description: i18n.t('form.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.referenceId && data.referenceId.length > 32) {
    errors = {
      ...errors,
      referenceId: i18n.t('form.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  return { isValid, errs: errors };
};
