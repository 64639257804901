import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import {
  checkUniqueProductCode,
  createProductItem,
  createProductUnit,
  generateProductCode,
  getProdCategories,
  getProductUnits,
  updateProductItem,
} from '../../../controllers/settings/inventoryControllers';
import {
  validateProductItem,
  validateProductUnit,
} from '../../../validations/settings/validateProductItem';
import InputForm from '../../../components/forms/InputForm';
import Button from '../../../components/buttons/Button';
import {
  ProductItemType,
  discountTypes,
} from '../../../constants/defaultCodes/producttemTypes';
import { deleteEmptyFields } from '../../../shared/utils/objectUtils';
import {
  productInputFields1,
  productInputFields2,
  productInputFields3,
  productInputFields4,
  productInputFields5,
  productSelectFields,
} from '../../../constants/formFields/settings/productFields';
import SelectPicker from '../../../components/forms/SelectPicker';
import { colors } from '../../../assets/styles/colors';
import DatePicker from '../../../components/datePickers/DatePicker';
import SwitchForm from '../../../components/forms/SwitchForm';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import HeaderSwitch from '../../../components/switch/HeaderSwitch';
import ProdctSectionTitle from './components/ProductSectionTitle';
import ProductAccordianSummary from './components/ProdAccordianSummary';
import { productNavTitle } from '../../../shared/utils/transactionUtils';

const initial = { initialData: [] };

const CreateProductItemPage = (): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { state: editProductItem } = useLocation();

  const location = useLocation();
  const paths = location.pathname.split('/').filter(x => x);
  if (paths[paths.length - 1] === 'update' && !editProductItem) {
    navigate('/settings/products');
    return null;
  }

  const init = { productType: ProductItemType.product };
  // const initState = productName ? { ...init, name: productName } : init;
  const [formData, setData] = useState<any>(init);
  const [errors, setErrors] = useState<any>({});
  const [handleCategory, setHandleCategory] = useState<any>({ show: false });
  // const [handleProdUnit, setHandleProdUnit] = useState<any>({ show: false });
  const { productType } = formData;

  const categories = useQuery(['prodCategories'], getProdCategories, initial);
  const productUnits = useQuery(['productUnits'], getProductUnits, initial);
  const prodCodeQuery = useQuery(['genProdCode'], generateProductCode, {
    refetchInterval: 0,
  });
  const { data: prodCode, isFetched: codeFetched } = prodCodeQuery;

  const isOBEditable = editProductItem?.isOBEditable;
  const isEdit = Boolean(editProductItem);

  useEffect(() => {
    if (prodCode && !isEdit && !formData.code) {
      setData((prev: any) => ({ ...prev, code: prodCode }));
    }
  }, [codeFetched]);

  useEffect(() => {
    if (isEdit) {
      if (editProductItem) {
        const prod: any = editProductItem;
        const { manufacturedDate, expiryDate, inventoryDate } = editProductItem;
        const { name, productType: prodType, isActive } = editProductItem;
        const prodData: any = { name, productType: prodType, isActive };

        // console.log('prod.openingStock', prod.openingStock);

        prodData.category = prod.categoryId;
        prodData.code = prod.code;
        prodData.primaryUnit = prod.primaryUnitId;
        prodData.secondaryUnit = prod.secondaryUnitId;

        prodData.primaryToSecScale = prod.primaryToSecScale;
        prodData.purchasePrice = prod.purchasePrice;
        prodData.sellingPrice = prod.sellingPrice;
        prodData.salesDiscount = prod.salesDiscount;
        prodData.discountType = prod.discountType;

        prodData.minStockQuantity = prod.minStockQuantity;
        prodData.productLocation = prod.productLocation;
        manufacturedDate &&
          (prodData.manufacturedDate = new Date(manufacturedDate));
        expiryDate && (prodData.expiryDate = new Date(expiryDate));
        prodData.batchNumber = prod.batchNumber;
        prodData.description = prod.description;

        // // OPENING STOCK RELATED
        prodData.openingStock = String(prod.openingStock || 0);
        prodData.pricePerUnit = prod.pricePerUnit;
        inventoryDate && (prodData.inventoryDate = new Date(inventoryDate));

        deleteEmptyFields(prodData);
        setData(prev => ({ ...prev, ...prodData }));
      }
    }
  }, [isEdit, editProductItem]);

  const { isLoading, mutateAsync } = useMutation(createProductItem);
  const editProduct = useMutation(updateProductItem);
  const uniquProdCode = useMutation(checkUniqueProductCode);

  const onSubmit = async () => {
    const { isValid, errors: errorMsg } = validateProductItem(formData, errors);

    let uniqueProdCode = true;
    if (isValid && !isEdit) {
      uniqueProdCode = await uniquProdCode.mutateAsync(formData.code);
      !uniqueProdCode && (errorMsg.code = 'Product Code already exist');
    }

    if (isValid && uniqueProdCode) {
      try {
        if (!isEdit) {
          await mutateAsync(formData);
          queryClient.invalidateQueries(['getProductItems']);
          queryClient.invalidateQueries(['products']);
          // toast.show({ title: t('productItem.updateMsg.success'), duration });

          navigate('/settings/products', { replace: true });
          enqueueSnackbar(t('productItem.createMsg.success'), successToast);

          // if (productName || setHandleProduct) {
          //   setHandleProduct(prev => ({ ...prev, show: false }));
          // } else {
          //   navigation.goBack();
          // }
        } else {
          // console.log('edititng...');

          const updatePayload = { id: editProductItem.id, data: formData };
          await editProduct.mutateAsync(updatePayload);
          queryClient.invalidateQueries(['getProductItems']);
          queryClient.invalidateQueries(['products']);

          navigate('/settings/products', { replace: true });
          enqueueSnackbar(t('productItem.updateMsg.success'), successToast);
        }
        queryClient.invalidateQueries(['getDashboardReport']);
      } catch (err) {
        // console.error('err:', err);
        if (isEdit) {
          enqueueSnackbar(t('productItem.updateMsg.error'), errorToast);
        } else {
          enqueueSnackbar(t('productItem.createMsg.error'), errorToast);
        }
      }
    } else {
      setErrors(errorMsg);
    }
  };

  const onFormChange = (name: string, value: any) => {
    if (name === 'isProduct') {
      const prodItemType = ProductItemType.service;
      value && setData((p: any) => ({ ...p, ...init }));
      !value && setData((p: any) => ({ ...p, productType: prodItemType }));
    } else {
      if (name === 'primaryUnit') {
        const secondaryUnit = null;
        setData((prev: any) => ({ ...prev, [name]: value, secondaryUnit }));
      } else {
        setData((prev: any) => ({ ...prev, [name]: value }));
      }
    }

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateProductItem(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const getSelectItems = (fieldName: string) => {
    if (fieldName === 'productUnit') {
      return productUnits.data;
    } else if (fieldName === 'category') {
      return categories.data;
    }
  };

  const getStockDefaultValue = (fieldName: string) => {
    if (fieldName === 'pricePerUnit' && formData.openingStock) {
      if (isEdit) {
        return formData.pricePerUnit || formData.purchasePrice;
      } else {
        return formData.purchasePrice;
      }
    } else {
      return String(formData[fieldName] || '');
    }
  };

  const getProdUnits = (fieldName: string) => {
    if (fieldName === 'primaryUnit') {
      return productUnits.data;
    } else if (fieldName === 'secondaryUnit') {
      const { primaryUnit } = formData;
      const left = productUnits.data?.filter(x => x.id !== primaryUnit);
      return left;
    }
  };

  // console.log('formData productItem:', formData);
  // console.log(' errors:', errors);

  const { primaryUnit, secondaryUnit } = formData;
  const isProd = productType === ProductItemType.product;

  return (
    <Container>
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20, alignContent: 'center' }}>
              {productNavTitle(isEdit, isProd)}
            </Typography>

            <HeaderSwitch
              leftEnabled={productType === ProductItemType.product}
              leftText="Product"
              rightText="Service"
              onToggle={v => onFormChange('isProduct', v)}
            />
          </Stack>

          <Divider />
          <Stack py={1} gap={2}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {productInputFields1.map(({ fieldName, isCode }) => (
                <Grid xs={isCode ? 6 : 12} key={fieldName}>
                  <InputForm
                    isRequired={true}
                    isNumberic={isCode ? true : false}
                    isDisabled={isCode && isEdit ? true : false}
                    defaultValue={formData[fieldName]}
                    label={t(`productItem.label.${fieldName}-${isProd}`)}
                    placeHolder={t(`productItem.label.${fieldName}-${isProd}`)}
                    isInvalid={fieldName in errors}
                    helpText={fieldName in errors && errors[fieldName]}
                    onChangeText={(v: string) => onFormChange(fieldName, v)}
                  />
                </Grid>
              ))}
              {productSelectFields.map(({ fieldName, isCat, isSec }) => (
                <Grid xs={6} key={fieldName}>
                  <SelectPicker
                    enableClear={true}
                    enableSearch={true}
                    addItemText="Unit"
                    isDisabled={isSec && !formData.primaryUnit ? true : false}
                    selectedValue={formData[fieldName]}
                    selectItems={
                      isCat ? categories.data : getProdUnits(fieldName)
                    }
                    label={t(`productItem.label.${fieldName}`)}
                    isInvalid={fieldName in errors}
                    helpText={fieldName in errors && errors[fieldName]}
                    onValueChange={(v: string) => onFormChange(fieldName, v)}
                    // setHandles={isCat ? setHandleCategory : setHandleProdUnit}
                  />
                </Grid>
              ))}

              {primaryUnit && secondaryUnit && (
                <Stack
                  direction={'row'}
                  mx={1.6}
                  px={2}
                  flex={1}
                  borderRadius={1}
                  alignItems={'center'}
                  bgcolor={colors.info100}>
                  <Typography>
                    1 {productUnits?.data.find(x => x.id === primaryUnit)?.name}
                    =
                  </Typography>
                  <Grid xs={3} pb={2}>
                    <InputForm
                      size={'small'}
                      isRequired={true}
                      isNumberic={true}
                      placeHolder={'Example: 12'}
                      defaultValue={String(formData.primaryToSecScale || '')}
                      isInvalid={'primaryToSecScale' in errors}
                      helpText={errors.primaryToSecScale}
                      onChangeText={(v: string) =>
                        onFormChange('primaryToSecScale', +v)
                      }
                    />
                  </Grid>
                  <Typography>
                    {productUnits?.data.find(x => x.id === secondaryUnit)?.name}
                  </Typography>
                </Stack>
              )}
            </Grid>

            <ProdctSectionTitle title={t('productItem.title1')} />

            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              {productInputFields2(productType).map(
                ({ required, fieldName, isSell }: any) => (
                  <Grid xs={!isProd && isSell ? 12 : 6} key={fieldName}>
                    <InputForm
                      isRequired={required}
                      isNumberic={true}
                      defaultValue={String(formData[fieldName] || '')}
                      label={t(`productItem.label.${fieldName}`)}
                      placeHolder={t(`productItem.label.${fieldName}`)}
                      isInvalid={fieldName in errors}
                      onChangeText={(v: any) => onFormChange(fieldName, +v)}
                      helpText={fieldName in errors && errors[fieldName]}
                    />
                  </Grid>
                ),
              )}

              <Grid xs={6}>
                <SelectPicker
                  isDisabled={formData.salesDiscount ? false : true}
                  isRequired={formData.salesDiscount ? true : false}
                  selectedValue={formData.discountType}
                  selectItems={discountTypes}
                  label={t('productItem.label.discountType')}
                  placeHolder={t('productItem.label.discountType')}
                  isInvalid={'discountType' in errors}
                  helpText={'discountType' in errors && errors.discountType}
                  onValueChange={(v: string) => onFormChange('discountType', v)}
                />
              </Grid>
            </Grid>

            {isProd && (
              <div>
                <Accordion>
                  <ProductAccordianSummary title={t('productItem.title2')} />
                  <AccordionDetails>
                    <Grid
                      container
                      rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      {((isEdit && isOBEditable) || !isEdit) && (
                        <>
                          <Grid xs={6}>
                            <InputForm
                              isNumberic={true}
                              defaultValue={getStockDefaultValue(
                                'openingStock',
                              )}
                              label={t(`productItem.label.openingStock`)}
                              placeHolder={t(`productItem.label.openingStock`)}
                              isInvalid={'openingStock' in errors}
                              helpText={errors.openingStock}
                              onChangeText={(v: string) =>
                                onFormChange('openingStock', v)
                              }
                            />
                          </Grid>
                          <Grid xs={6}>
                            <DatePicker
                              isRequired={formData.openingStock ? true : false}
                              label={t('productItem.label.inventoryDate')}
                              value={formData.inventoryDate}
                              isInvalid={'inventoryDate' in errors}
                              helpText={errors.inventoryDate}
                              onDateChange={(v: any) =>
                                onFormChange('inventoryDate', v)
                              }
                            />
                          </Grid>
                          <Grid xs={6}>
                            <InputForm
                              isNumberic={true}
                              defaultValue={getStockDefaultValue(
                                'pricePerUnit',
                              )}
                              label={t(`productItem.label.pricePerUnit`)}
                              placeHolder={t(`productItem.label.pricePerUnit`)}
                              isDisabled={true}
                              isInvalid={'pricePerUnit' in errors}
                              helpText={errors.pricePerUnit}
                              onChangeText={(v: string) =>
                                onFormChange('pricePerUnit', v)
                              }
                            />
                          </Grid>
                        </>
                      )}

                      {productInputFields3.map(({ fieldName, numeric }) => (
                        <Grid xs={6} key={fieldName}>
                          <InputForm
                            isNumberic={numeric}
                            defaultValue={String(formData[fieldName] || '')}
                            label={t(`productItem.label.${fieldName}`)}
                            placeHolder={t(`productItem.label.${fieldName}`)}
                            isInvalid={fieldName in errors}
                            helpText={fieldName in errors && errors[fieldName]}
                            onChangeText={(v: string) =>
                              onFormChange(fieldName, v)
                            }
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
            )}

            <div>
              <Accordion>
                <ProductAccordianSummary title={t('productItem.title3')} />
                <AccordionDetails>
                  <Grid
                    container
                    rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {isProd &&
                      productInputFields4.map(({ fieldName }) => (
                        <Grid xs={6} key={fieldName}>
                          <DatePicker
                            isRequired={false}
                            label={t(`productItem.label.${fieldName}`)}
                            value={formData[fieldName]}
                            onDateChange={(v: any) =>
                              onFormChange(fieldName, v)
                            }
                          />
                        </Grid>
                      ))}

                    {productInputFields5(isProd).map(
                      ({ fieldName, isDesc }) => (
                        <Grid key={fieldName} xs={!isProd && isDesc ? 12 : 6}>
                          <InputForm
                            defaultValue={formData[fieldName]}
                            label={t(`productItem.label.${fieldName}`)}
                            placeHolder={t(`productItem.label.${fieldName}`)}
                            isInvalid={fieldName in errors}
                            onChangeText={(v: string) =>
                              onFormChange(fieldName, v)
                            }
                            helpText={fieldName in errors && errors[fieldName]}
                          />
                        </Grid>
                      ),
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </div>

            <Stack p={1}>
              {isEdit && (
                <SwitchForm
                  isChecked={formData.isActive}
                  label={t(`productItem.label.isActive-${formData.isActive}`)}
                  onToggle={(e, v: boolean) => onFormChange('isActive', v)}
                  helpText={'isActive' in errors && errors.isActive}
                />
              )}
            </Stack>
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            title={
              isEdit
                ? `Update ${isProd ? 'Product Item' : 'Service'}`
                : `Save ${isProd ? 'Product Item' : 'Service'}`
            }
            onClick={onSubmit}
            loading={isLoading || editProduct.isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default CreateProductItemPage;
