// import { EntryType } from '../../constants/defaultCodes/voucherEntryTypes';
// import voucherTypes from '../../constants/defaultCodes/voucherTypes';

// export const isCreditVoucher = (voucherType: string) => {
//   const { creditSales, creditPurchase, creditExpense, lendLoan } = EntryType;
//   const credits = [creditSales, creditPurchase, creditExpense, lendLoan];
//   credits.push(EntryType.borrowLoan);

//   if (credits.includes(voucherType)) {
//     return true;
//   }
//   return false;
// };

// export const isReturnVoucher = (voucherType: string) => {
//   const { SALES_RETURN, PURCHASE_RETURN, EXPENSE_RETURN } = voucherTypes;
//   const returns = [SALES_RETURN, PURCHASE_RETURN, EXPENSE_RETURN];

//   if (returns.includes(voucherType)) {
//     return true;
//   }
//   return false;
// };

export const getRefNoFromVoucherNo = (voucherNumber: string) => {
  return voucherNumber.split('-')[2];
};
