import React, { useEffect, useState } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getVoucherNumber,
  uploadVoucherImage,
} from '../../../controllers/transactions/transactionControllers';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import Button from '../../../components/buttons/Button';

import {
  validatePTVoucherNo,
  validatePartnerTransfer,
} from '../../../validations/transactions/validateTransfer';
import { partnerTransfer } from '../../../controllers/transactions/transferControllers';
import {
  getCustomers,
  getVendors,
} from '../../../controllers/settings/partnerControllers';
import {
  getCustomerBalance,
  getVendorBalance,
} from '../../../controllers/reports/partnerReports';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import ImagePicker from '../../../components/image/ImagePicker';

const voucherType = voucherTypes.PARTNER_TRANSFER;
const initial = { initialData: [] };

function PartnerTransfer() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [formData, setData] = useState<any>({ voucherDate: new Date() });
  const [images, setImages] = useState<any>([]);
  const [errors, setErrors] = useState<any>({});

  const { data: voucherNumber } = useQuery(['getVoucherNo', voucherType], () =>
    getVoucherNumber(voucherType),
  );

  const vendors = useQuery(['getVendors'], getVendors, initial);
  const customers = useQuery(['getCustomers'], getCustomers, initial);
  const customerBalanceQuery = useMutation(getCustomerBalance);
  const vendorBalanceQuery = useMutation(getVendorBalance);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  const { isLoading, mutateAsync } = useMutation(partnerTransfer);
  const saveImage = useMutation(uploadVoucherImage);

  const onSubmit = async () => {
    const { isValid, errs } = validatePartnerTransfer(formData, errors);
    const { isVoucherNoValid } = await validatePTVoucherNo(formData, errs);

    if (isValid && isVoucherNoValid) {
      try {
        if (images && images.length) {
          const imgPaylaod = { images, voucherType: 'transfers' };
          const image = await saveImage.mutateAsync(imgPaylaod);
          formData.image = image.id;
        }
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getDashboardReport']);

        navigate('/journals', { replace: true });
        enqueueSnackbar(t('partnerTransfer.createMsg.success'), successToast);
      } catch (err) {
        let title = t('partnerTransfer.createMsg.error');
        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }
        enqueueSnackbar(title, errorToast);
      }
    } else {
      setErrors(errs);
      if (!isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = async (name: string, value: any) => {
    let payload = { [name]: value };

    if (name === 'fromPartner' && formData.fromPartner !== value) {
      const response = await customerBalanceQuery.mutateAsync(value);
      payload = { ...payload, customerBalance: String(response) };
      setData(prev => ({ ...prev, ...payload }));
    } else if (name === 'toPartner' && formData.toPartner !== value) {
      const response = await vendorBalanceQuery.mutateAsync(value);
      payload = { ...payload, vendorBalance: String(response) };
      setData(prev => ({ ...prev, ...payload }));
    } else {
      setData(prev => ({ ...prev, [name]: value }));
    }

    const updatedData = { ...formData, ...payload };
    const { errs } = validatePartnerTransfer(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const getSelectItems = (fieldName: string) => {
    if (fieldName === 'fromPartner') {
      return customers.data;
    } else if (fieldName === 'toPartner') {
      return vendors.data;
    }
  };

  const getPartnerBalance = (fieldName: string) => {
    const { customerBalance, vendorBalance } = formData;
    if (fieldName === 'fromPartner' && customerBalance) {
      return { type: 'CASH_IN', balance: customerBalance };
    } else if (fieldName === 'toPartner' && vendorBalance) {
      return { type: 'CASH_OUT', balance: vendorBalance };
    }
  };

  // console.log('formData pay in:', formData);
  // console.log('errors:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('transactionStack.p2pTransfer')}
          </Typography>
          <Divider />
          <Stack py={2} spacing={4}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('partnerTransfer.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('partnerTransfer.label.voucherNumber')}
                  isDisabled={true}
                  isRequired={true}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  defaultValue={formData.voucherNumber}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('partnerTransfer.label.referenceId')}
                  defaultValue={formData.referenceId}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`partnerTransfer.label.fromPartner`)}
                  selectItems={getSelectItems('fromPartner')}
                  selectedValue={formData.fromPartner}
                  isInvalid={'fromPartner' in errors}
                  helpText={getHelpText('fromPartner')}
                  onValueChange={(v: any) => onFormChange('fromPartner', v)}
                  partnerBalance={getPartnerBalance('fromPartner')}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`partnerTransfer.label.toPartner`)}
                  selectItems={getSelectItems('toPartner')}
                  selectedValue={formData.toPartner}
                  isInvalid={'toPartner' in errors}
                  helpText={getHelpText('toPartner')}
                  onValueChange={(v: any) => onFormChange('toPartner', v)}
                  partnerBalance={getPartnerBalance('toPartner')}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <InputForm
                  isRequired={true}
                  isNumberic={true}
                  defaultValue={formData.amount}
                  isInvalid={'amount' in errors}
                  helpText={getHelpText('amount')}
                  label={t('partnerTransfer.label.amount')}
                  onChangeText={(v: string) => onFormChange('amount', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
                  <Stack flex={1}>
                    <InputForm
                      inLineLabel={true}
                      defaultValue={formData.description}
                      label={t('partnerTransfer.label.description')}
                      isInvalid={'description' in errors}
                      helpText={'description' in errors && errors.description}
                      onChangeText={(v: string) =>
                        onFormChange('description', v)
                      }
                    />
                  </Stack>
                  <Stack pt={0.1}>
                    <ImagePicker {...{ images, setImages }} />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Partner Transfer"
            onClick={onSubmit}
            loading={isLoading || saveImage.isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default PartnerTransfer;
