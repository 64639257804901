// import Config from 'react-native-config';
// import { Platform } from 'react-native';
// import ReactNativeBlobUtil from 'react-native-blob-util';

import {
  productCategoryUrl,
  productItemUrl,
  expenseItemUrl,
  productUnitUrl,
} from '../endPoints';
import { store } from '../../store/store';

const v1 = (path: string) => `/v1${path}`;

const httpClient = () => {
  return store.getState().http.client;
};

export const generateProductCode = async () => {
  const path = v1('/inventory/product-code/');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const checkUniqueProductCode = async (code: string) => {
  const path = v1(`/inventory/unique-product-code/${code}`);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const createProductCategory = async (data: any) => {
  const path = productCategoryUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getProdCategories = async () => {
  const path = productCategoryUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getProductItems = async (queries: any) => {
  const path = productItemUrl();

  const response = await httpClient().get<any>(path, queries);
  return response.data;
};

export const createProductItem = async (data: any) => {
  const path = productItemUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updateProductItem = async (data: any) => {
  const path = v1(`/inventory/product-items/${data.id}`);

  const response = await httpClient().patch<any>(path, data.data);
  return response.data;
};

export const deleteProductItem = async (productId: number) => {
  const path = v1(`/inventory/product-items/${productId}`);

  const response = await httpClient().delete<any>(path);
  return response.data;
};

export const getExpenseItems = async (queries: any) => {
  const path = expenseItemUrl();

  const response = await httpClient().get<any>(path, queries);
  return response.data;
};

export const createExpenseItem = async (data: any) => {
  const path = expenseItemUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updateExpenseItem = async (data: any) => {
  const path = v1(`/inventory/expense-items/${data.id}`);

  const response = await httpClient().patch<any>(path, data.data);
  return response.data;
};

export const createProductUnit = async (data: any) => {
  const path = productUnitUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getProductUnits = async () => {
  const path = productUnitUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const lowStockReport = async () => {
  const path = v1('/inventory/low-stock-report');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const productExpiryReport = async () => {
  const path = v1('/inventory/product-expiry-report');

  const response = await httpClient().get<any>(path);
  return response.data;
};

// export const uploadProductFile = async (data: any) => {
//   const DOMAIN = Config.API_BASE_URL;
//   const uploadURL = `${DOMAIN}/v1/inventory/bulk-product-upload`;
//   // console.log('files', files);
//   console.log('validationOnly', data.validationOnly);

//   const authtoken = store.getState().auth.authUser?.accessToken;
//   const file = data.files[0];

//   const headers = {
//     Authorization: `Bearer ${authtoken}`,
//     'Content-Type': 'multipart/form-data',
//   };

//   const formData = [
//     { name: 'validationOnly', data: String(data.validationOnly) },
//   ];

//   const productItemsFile = {
//     name: 'productItemsFile',
//     filename: file.name,
//     type: file.type,
//     data: ReactNativeBlobUtil.wrap(file.uri),
//   };

//   formData.push(productItemsFile);

//   const res = await ReactNativeBlobUtil.fetch(
//     'POST',
//     uploadURL,
//     headers,
//     formData,
//   );

//   return JSON.parse(res.data);
// };
