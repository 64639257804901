import * as React from 'react';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';

import {
  Close,
  Delete,
  Edit,
  PictureAsPdfRounded,
  Replay,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import voucherTypes, {
  salesPurchaseVouchers,
} from '../../../constants/defaultCodes/voucherTypes';
import {
  entryHasPDF,
  getEditScreen,
} from '../../../shared/utils/getEditScreen';
import {
  deleteVoucher,
  getVouchersToDelete,
  updateTxnCancelStatus,
} from '../../../controllers/transactions/voucherControllers';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import VoucherDeleteWarningModal from './VoucherDeleteWarningModal';
import TxnCancelConfirmModal from './TxnCancelConfirmModal';

export default function TransactionActionMenu({
  anchorEl,
  setAnchorEl,
  voucher,
  refetchVouchers,
  userVoucherDate,
  onEditVoucher,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const [showDeleteWarning, setDeleteWarning] = React.useState(false);
  const [showCancelWarning, setShowCancelWarning] = React.useState(false);

  const type = voucher.voucherType.code;

  const isCancellableTxn = () => {
    if (salesPurchaseVouchers.includes(type)) {
      return true;
    }
    return false;
  };

  const isDeletableTxn = () => {
    if (voucherTypes.ACCRUED_INTEREST === type) {
      return false;
    }
    return true;
  };
  const editScreen = getEditScreen(type);
  const hasPDF = entryHasPDF(type);

  const voucherId = voucher.id;
  const voucherNo = voucher.referenceId || voucher.voucherNumber;

  const queryFunc = () => getVouchersToDelete(voucherId);
  const toDelete = useQuery(['toDelete', voucherId], queryFunc, {
    enabled: false,
    initialData: [],
  });

  const { isLoading, mutateAsync } = useMutation(deleteVoucher);

  const onConfirmDeletion = async () => {
    try {
      await mutateAsync(voucherId);
      queryClient.invalidateQueries(['vouchers']);
      queryClient.invalidateQueries(['getDashboardReport']);
      refetchVouchers();

      const msg = t('voucherDeletionWarning.message.success');
      enqueueSnackbar(msg, successToast);
    } catch (err) {
      // console.log('err:', err);
      enqueueSnackbar(t('voucherDeletionWarning.message.error'), errorToast);
    } finally {
      setDeleteWarning(false);
    }
  };

  const onVoucherDeletePress = () => {
    setAnchorEl(null);
    toDelete.refetch();
    setDeleteWarning(true);
  };

  const onVoucherCancelPress = () => {
    setAnchorEl(null);
    setShowCancelWarning(true);
  };

  const updateCancellation = useMutation(updateTxnCancelStatus);

  const onCancellationUpdate = async () => {
    try {
      await updateCancellation.mutateAsync(voucherId);
      queryClient.invalidateQueries(['vouchers']);
      queryClient.invalidateQueries(['getDashboardReport']);
      refetchVouchers();

      if (voucher.isCancelled) {
        const msg = t('voucherCancelWarning.message.success2');
        enqueueSnackbar(msg, successToast);
      } else {
        const msg = t('voucherCancelWarning.message.success1');
        enqueueSnackbar(msg, successToast);
      }
    } catch (err) {
      if (voucher.isCancelled) {
        const msg = t('voucherCancelWarning.message.error2');
        enqueueSnackbar(msg, errorToast);
      } else {
        const msg = t('voucherCancelWarning.message.error1');
        enqueueSnackbar(msg, errorToast);
      }
    } finally {
      setShowCancelWarning(false);
    }
  };

  return (
    <Menu
      sx={{ mt: '32px' }}
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}>
      {isDeletableTxn() && (
        <MenuItem onClick={onVoucherDeletePress} sx={{ py: 2 }}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          {t('voucherEntries.action.remove')}
        </MenuItem>
      )}

      {/* {isCancellableTxn() &&
        (voucher.isCancelled ? (
          <MenuItem onClick={onVoucherCancelPress} sx={{ py: 2 }}>
            <ListItemIcon>
              <Replay fontSize="small" />
            </ListItemIcon>
            {t('voucherEntries.action.revokeCancel')}
          </MenuItem>
        ) : (
          <MenuItem onClick={onVoucherCancelPress} sx={{ py: 2 }}>
            <ListItemIcon>
              <Close fontSize="small" />
            </ListItemIcon>
            {t('voucherEntries.action.cancel')}
          </MenuItem>
        ))} */}

      {editScreen !== 'none' && (
        <MenuItem onClick={onEditVoucher} sx={{ py: 2 }}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          {t('voucherEntries.action.update')}
        </MenuItem>
      )}

      {/* {hasPDF && (
        <MenuItem onClick={onClose} sx={{ py: 2 }}>
          <ListItemIcon>
            <PictureAsPdfRounded fontSize="small" />
          </ListItemIcon>
          {t('voucherEntries.action.pdf')}
        </MenuItem>
      )} */}

      {showDeleteWarning && (
        <VoucherDeleteWarningModal
          isLoading={isLoading}
          showModal={showDeleteWarning}
          setShowModal={setDeleteWarning}
          onConfirmDeletion={onConfirmDeletion}
          vouchersToDelete={toDelete.data}
        />
      )}

      {showCancelWarning && (
        <TxnCancelConfirmModal
          userVoucherDate={userVoucherDate}
          voucher={voucher}
          onCancellationUpdate={onCancellationUpdate}
          showModal={showCancelWarning}
          setShowModal={setShowCancelWarning}
          isLoading={updateCancellation.isLoading}
        />
      )}
    </Menu>
  );
}
