import i18next from 'i18next';
import { normalBalance } from './../../constants/defaultCodes/accountTypeCodes';

import { getVoucherNumber } from '../../controllers/transactions/transactionControllers';
import voucherTypes from '../../constants/defaultCodes/voucherTypes';

export const validateVoucherNo = async (data: any, errors: any) => {
  const newVoucherNumber = await getVoucherNumber(voucherTypes.GENERAL_VOUCHER);
  let isVoucherNoValid = true;

  if (data.voucherNumber !== newVoucherNumber) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoExist'),
    };
    isVoucherNoValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isVoucherNoValid, errss: errors };
};

export const validateJournal = (
  data: any,
  errors: any,
  hasBalance?: boolean,
) => {
  let isValid = true;
  if (data.voucherNumber === undefined) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoRequired'),
    };
    isValid = false;
  } else if (data.voucherNumber.length < 3 || data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: i18next.t('voucher.validation.voucherNoLength'),
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  if (
    data.description &&
    (data.description.length < 2 || data.description.length > 256)
  ) {
    errors = {
      ...errors,
      description: i18next.t('voucher.validation.descriptionLength'),
    };
    isValid = false;
  } else {
    delete errors.description;
  }

  if (data.voucherDate === undefined) {
    errors = {
      ...errors,
      voucherDate: i18next.t('voucher.validation.voucherDateRequired'),
    };
    isValid = false;
  } else {
    delete errors.voucherDate;
  }

  if (data.referenceId && data.referenceId > 32) {
    errors = {
      ...errors,
      referenceId: i18next.t('voucher.validation.referenceIdLength'),
    };
    isValid = false;
  } else {
    delete errors.referenceId;
  }

  if (data.customerRequired && !data.customer) {
    errors = {
      ...errors,
      customer: i18next.t('generalVoucher.validation.customerRequired'),
    };
    isValid = false;
  } else {
    delete errors.customer;
  }

  if (data.vendorRequired && !data.vendor) {
    errors = {
      ...errors,
      vendor: i18next.t('generalVoucher.validation.vendorRequired'),
    };
    isValid = false;
  } else {
    delete errors.vendor;
  }

  const drSum = data.voucherItems.reduce((sum, i) => sum + i.debit, 0);
  const crSum = data.voucherItems.reduce((sum, i) => sum + i.credit, 0);

  // console.log('data.voucherItems', data.voucherItems);
  // console.log('drSum', drSum);
  // console.log('crSum', crSum);

  if (!drSum && !crSum) {
    errors = {
      ...errors,
      drCrBoth0: i18next.t('generalVoucher.validation.drCrBoth0'),
    };
    isValid = false;
  } else {
    delete errors.drCrBoth0;
  }

  if (drSum !== crSum) {
    errors = {
      ...errors,
      sumNotEqual: i18next.t('generalVoucher.validation.sumNotEqual'),
    };
    isValid = false;
  } else {
    delete errors.sumNotEqual;
  }

  if (hasBalance === false) {
    errors = {
      ...errors,
      sumNotEqual: i18next.t('voucher.validation.inSufficientBalance'),
    };
    isValid = false;
  } else {
    delete errors.sumNotEqual;
  }

  data.voucherItems.forEach((voucherItem: any) => {
    const accountField = `${voucherItem.lineNo}-account`;

    if (voucherItem.account === undefined) {
      errors = {
        ...errors,
        [accountField]: i18next.t('generalVoucher.validation.accountRequired'),
      };
      isValid = false;
    } else {
      delete errors[accountField];
    }

    if (voucherItem.debit && voucherItem.credit) {
      errors = {
        ...errors,
        drAndCrAdded: i18next.t('generalVoucher.validation.bothDrAndCrAdded'),
      };
      isValid = false;
    } else {
      delete errors.drAndCrAdded;
    }
  });

  return { isValid, errs: errors };
};
