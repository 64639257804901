import { store } from '../../store/store';
import {
  getPurchasesForReturnUrl,
  getBoughtItemsUrl,
  getPurchaseReturnUrl,
  vendorReceiptUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};
export const getPurchasesForReturn = async (query: any) => {
  const path = getPurchasesForReturnUrl();
  const response = await httpClient().get<any>(path, query);
  return response.data;
};

export const getBoughtItems = async (voucherId: string) => {
  const path = getBoughtItemsUrl(voucherId);

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const purchaseReturn = async (data: any) => {
  const path = getPurchaseReturnUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const vendorReceipt = async (data: any) => {
  const path = vendorReceiptUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const savePurchaseImageold = async data => {
  // const image = data.images[0];
  // const path = purchaseImageUrl();
  // const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  // const headers = {
  //   'content-type': 'multipart/form-data',
  //   accept: 'application/json',
  // };
  // const body = new FormData();
  // body.append(
  //   'image',
  //   JSON.stringify({
  //     uri: image.uri,
  //     type: 'image/jpeg',
  //     name: image.fileName,
  //   }),
  // );
  // const formData = createFormData(data.images, { voucherId: data.voucherId });
  // const formData = createFormData(data.images[0]);
  // console.log('formData', formData);
  // console.log('formData body:', body);
  // const response = await httpClient().post<any>(path, body, config);
  // return response.data;
};
