import React from 'react';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const EmptyTable = ({ message }: any): JSX.Element => {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={9} sx={{ fontWeight: 'bold', height: 240 }}>
          <Typography
            sx={{ fontSize: 18, fontWeight: 'bold' }}
            textAlign={'center'}>
            {message ? message : 'No records found!'}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default EmptyTable;
