import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Card, CardContent, Divider, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import OutboundIcon from '@mui/icons-material/Outbound';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import SwapHorizontalCircleRoundedIcon from '@mui/icons-material/SwapHorizontalCircleRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';

import { shortcutNavItems } from '../../constants/cards/transactionCards';
import { colors } from '../../assets/styles/colors';
import TransactionCard from './components/TransactionCard';

export default function Transactions() {
  const navigate = useNavigate();

  const boxStyle = {
    width: 180,
    height: 100,
    boxShadow: 4,
    borderRadius: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: colors.info50,
    '&:hover': {
      bgcolor: colors.gray300,
      cursor: 'pointer',
      color: colors.darker3,
    },
  };

  const typoStyle: any = {
    fontSize: 15,
    color: colors.theme1,
    '&:hover': {
      color: colors.darker3,
    },
    // fontWeight: '600',
  };

  const sectionItemStyle = (nav: string) => {
    const cardStyle: any = { ...boxStyle };
    const textStyle: any = { ...typoStyle };
    const iconStyle: any = { color: colors.theme1 };

    const isGrn = nav === 'receiveInvoicePayment' || nav === 'sales';
    // if (nav === 'receiveInvoicePayment' || nav === 'creditSales') {
    if (nav === 'payment-in' || isGrn) {
      // if (nav === 'receiveInvoicePayment') {
      cardStyle.backgroundColor = colors.green100;
      textStyle.color = colors.green900;
      iconStyle.color = colors.green900;
      return { cardStyle, textStyle, iconStyle };
      // } else if (nav === 'billPayment') {
    } else if (nav === 'payment-out' || nav === 'billPayment') {
      cardStyle.backgroundColor = colors.red100;
      textStyle.color = colors.red700;
      iconStyle.color = colors.red700;
      return { cardStyle, textStyle, iconStyle };
    } else if (nav === 'purchases') {
      cardStyle.backgroundColor = colors.blueGrey200;
      textStyle.color = colors.blueGrey900;
      iconStyle.color = colors.blueGrey900;
      return { cardStyle, textStyle, iconStyle };
    } else {
      return { cardStyle, textStyle, iconStyle };
    }
  };

  const getTxnIcon = (iconName: string, color) => {
    const style = { fontSize: 24, color };
    if (iconName === 'hand-holding-usd') {
      return <MonetizationOnRoundedIcon sx={style} />;
    } else if (iconName === 'arrow-circle-down') {
      return <ExpandCircleDownIcon sx={style} />;
    } else if (iconName === 'reply') {
      return <ReplayCircleFilledIcon sx={style} />;
    } else if (iconName === 'money-bill-alt') {
      return <PriceChangeIcon sx={style} />;
    } else if (iconName === 'shopping-cart') {
      return <ShoppingCartIcon sx={style} />;
    } else if (iconName === 'arrow-circle-up') {
      return <OutboundIcon sx={style} />;
    } else if (iconName === 'cart-plus') {
      return <LocalMallIcon sx={style} />;
    } else if (iconName === 'people-arrows') {
      return <ContactsRoundedIcon sx={style} />;
    } else if (iconName === 'exchange-alt') {
      return <SwapHorizontalCircleRoundedIcon sx={style} />;
    } else if (iconName === 'equals') {
      return <DnsRoundedIcon sx={style} />;
    }
  };

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent>
        <Stack gap={2}>
          {shortcutNavItems().map((section, index) => (
            <Stack key={index}>
              <Typography
                sx={{ fontSize: 18 }}
                color="text.primary"
                gutterBottom>
                {section.sectionTitle}
              </Typography>

              <Stack direction={'row'} py={1} pb={2} spacing={4}>
                {section.sectionItems.map((item, index2) => (
                  <Box
                    key={index2}
                    sx={sectionItemStyle(item.nav).cardStyle}
                    onClick={() => navigate(item.nav)}>
                    <Stack alignItems={'center'} gap={2}>
                      {getTxnIcon(
                        item.icon,
                        sectionItemStyle(item.nav).iconStyle.color,
                      )}
                      <Typography
                        textAlign={'center'}
                        fontWeight={'bold'}
                        px={2}
                        sx={sectionItemStyle(item.nav).textStyle}>
                        {item.name.toUpperCase()}
                      </Typography>
                    </Stack>
                  </Box>
                ))}
              </Stack>
              <Divider />
            </Stack>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
