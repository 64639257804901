import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { Chip, Divider, IconButton, Stack, Typography } from '@mui/material';

import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import { getProductItems } from '../../../controllers/settings/inventoryControllers';
import { productStockMovement } from '../../../controllers/reports/reportControllers';
import { validateProdStatementFilters } from '../../../validations/reports/validateProdStatementFilters';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { useSelector } from '../../../store/reduxHooks';
import StockMovementTable from './StockMovementTable';

const initial = { initialData: [] };

function ProductStockMovement() {
  const { t } = useTranslation();
  const { user } = useSelector(s => s.auth.authUser);

  const initalState: any = { ...initialDate, toDate: new Date() };
  const [filters, setFilters] = useState<any>(initalState);
  const [errors, setErrors] = React.useState({});

  const [showFilters, setShowFilter] = React.useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  const products = useQuery(['getProducts'], getProductItems, initial);
  const stockMovementFn = () => productStockMovement(filters);
  const stockMovement = useQuery(['stockMovement'], stockMovementFn, {
    enabled: false,
  });

  const onSubmit = async () => {
    const { isValid, errs } = validateProdStatementFilters(filters, errors);

    if (isValid) {
      stockMovement.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onFilterChange = (name: string, value: any) => {
    setFilters(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...filters, [name]: value };
    const { errs } = validateProdStatementFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };

  if (stockMovement.isSuccess && !showFilters) {
    const product = products.data.find(x => x.id === filters.product);
    return (
      <StockMovementTable
        {...{ stockMovement, filters, setShowFilter, product }}
      />
    );
  }

  return (
    <ScreenWrapper isError={products.isError}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.stockMovement')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid
            py={2}
            pb={3}
            container
            rowSpacing={{ xs: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>
            <Grid xs={12}>
              <SelectPicker
                isRequired={true}
                isDisabled={filters.allProducts ? true : false}
                label={t('productStatement.label.product')}
                selectedValue={filters.product}
                selectItems={products.data}
                isInvalid={'product' in errors}
                helpText={'product' in errors && errors.product}
                onValueChange={(v: string) => onFilterChange('product', v)}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Product Movements'}
              onClick={onSubmit}
              loading={stockMovement.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default ProductStockMovement;
