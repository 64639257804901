/* NEW ENUMS VALUES SHOULD ALWAYS BE ADDED TO END ONLY  */
export enum defaultAccountCodes {
  CASH_ON_HAND = 'CASH_ON_HAND',
  ACCOUNT_PAYABLE = 'ACCOUNT_PAYABLE', // make account payable
  BORROWED_PAYABLE = 'BORROWED_PAYABLE',
  INTEREST_PAYABLE = 'INTEREST_PAYABLE',
  ACCOUNT_RECEIVABLE = 'ACCOUNT_RECEIVABLE', // make account receivable
  LENT_RECEIVABLE = 'LENT_RECEIVABLE',
  SALARY_EXPENSE = 'SALARY_EXPENSE',
  SALES_DISCOUNT = 'SALES_DISCOUNT',
  PURCHASE_DISCOUNT = 'PURCHASE_DISCOUNT',
  SALES_SERVICE_CHARGE = 'SALES_SERVICE_CHARGE',
  PURCHASE_SERVICE_CHARGE = 'PURCHASE_SERVICE_CHARGE',
  VAT_ON_SALES = 'VAT_ON_SALES',
  VAT_EXPENSES = 'VAT_EXPENSES',
  INTEREST_EXPENSES = 'INTEREST_EXPENSES',
  CURRENT_YEAR_EARNINGS = 'CURRENT_YEAR_EARNINGS',
  INCOME_FROM_SALES = 'INCOME_FROM_SALES',
  COST_OF_GOODS_PURCHASED = 'COST_OF_GOODS_PURCHASED',
  CHEQUE_ACCOUNT = 'CHEQUE_ACCOUNT',
  LOAN_PAYABLE = 'LOAN_PAYABLE',
  EARNED_INTEREST = 'EARNED_INTEREST',
  INTEREST_RECEIVABLE = 'INTEREST_RECEIVABLE',
  SHARE_CAPITAL = 'SHARE_CAPITAL',
  RETAINED_EARNINGS = 'RETAINED_EARNINGS',
  DIVIDENDS_PAYABLE = 'DIVIDENDS_PAYABLE',
  CURRENT_STOCK_VALUE = 'CURRENT_STOCK_VALUE',
}
