export const deleteEmptyFields = (data: any) => {
  for (const key in data) {
    if (
      data.hasOwnProperty(key) &&
      (data[key] === null || data[key] === undefined)
    ) {
      delete data[key];
    }
  }
};
