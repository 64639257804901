import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import PeriodSelect, {
  initialDate,
  LAST_7_DAYS,
} from '../../../components/forms/PeriodSelect';
import { useSelector } from '../../../store/reduxHooks';
import { getProductItems } from '../../../controllers/settings/inventoryControllers';
import { salesReportAPI } from '../../../controllers/reports/reportControllers';
import { validateProdStatementFilters } from '../../../validations/reports/validateProdStatementFilters';
import SelectPicker from '../../../components/forms/SelectPicker';
import SwitchForm from '../../../components/forms/SwitchForm';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import Button from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import { salesReportTitles } from '../../../constants/tableTitles/reportTables';
import DateRangeCard from '../../../components/datePickers/DateRangeCard';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../../components/styled/TableCellStyled';

const initial = { initialData: [] };

function SalesReport() {
  const { t } = useTranslation();
  const { user } = useSelector(s => s.auth.authUser);

  const toDate = new Date();
  const initSt: any = { ...initialDate, toDate, allProducts: false };
  const [filters, setFilters] = useState<any>({ ...initSt });
  const [errors, setErrors] = useState<any>({});
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [showFilters, setShowFilter] = useState(true);
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);

  const products = useQuery(['getProducts'], getProductItems, initial);
  const salesFn = () => salesReportAPI(filters);
  const salesReport = useQuery(['salesReport'], salesFn, { enabled: false });

  const onSubmit = async () => {
    const { isValid, errs } = validateProdStatementFilters(filters, errors);

    if (isValid) {
      salesReport.refetch();
      setShowFilter(false);
    } else {
      setErrors(errs);
    }
  };

  const onFilterChange = (name: string, value: any) => {
    setFilters(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...filters, [name]: value };
    const { errs } = validateProdStatementFilters(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  const getAmount = (amount: string) => {
    if (!amount) return null;
    return formatAmount(amount);
  };

  const onCreatePDF = async () => {};

  // console.log('salesReport', salesReport.data);
  // console.log('filtersL:', filters);

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };
  const tbCell = { fontWeight: 'bold', textAlign: 'right' };
  const pickerSize = 'small';

  if (salesReport.isSuccess && !showFilters) {
    return (
      <Card sx={{ paddingX: 4 }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack pb={1} direction={'row'}>
            <Stack flex={1} alignItems={'flex-start'} justifyContent={'center'}>
              <IconButton
                onClick={() => setShowFilter(true)}
                sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
                <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
              </IconButton>
            </Stack>
            <Stack flex={1}>
              <Typography sx={{ fontSize: 20 }} textAlign={'center'}>
                {t('reportStack.sales')}
              </Typography>
              <DateRangeCard {...{ filters }} />
            </Stack>
            <Stack flex={1} />
          </Stack>
          <Divider />

          <ScreenWrapper
            isLoading={products.isLoading}
            isError={products.isError}
            isEmpty={!salesReport.data?.salesReport.length}>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                    <TbCellHeader>Product Name</TbCellHeader>
                    <TbCellHeader align="right">Sales Income </TbCellHeader>
                    <TbCellHeader align="right">Other Income </TbCellHeader>
                    <TbCellHeader align="right">Discount Amount </TbCellHeader>
                    <TbCellHeader align="right">VAT Amount </TbCellHeader>
                    <TbCellHeader align="right">Total Amount</TbCellHeader>
                  </TbRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {salesReport.data?.salesReport.map((row, index) => (
                    <TableRowStyled
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { bgcolor: colors.gray100 },
                      }}>
                      <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                      <TbCellBody>{row.name}</TbCellBody>
                      <TbCellAmount sx={{ color: colors.darker3 }}>
                        {getAmount(row.salesAmount)}
                      </TbCellAmount>
                      <TbCellAmount sx={{ color: colors.darker3 }}>
                        {getAmount(row.incomeAmount)}
                      </TbCellAmount>
                      <TbCellAmount sx={{ color: colors.darker3 }}>
                        {getAmount(row.discountAmount)}
                      </TbCellAmount>
                      <TbCellAmount sx={{ color: colors.darker3 }}>
                        {getAmount(row.vatAmount)}
                      </TbCellAmount>
                      <TbCellAmount>
                        {getAmount(row.netSalesAmount)}
                      </TbCellAmount>
                    </TableRowStyled>
                  ))}
                </TableBody>
                <TableHead sx={{ backgroundColor: colors.gray200 }}>
                  <TableRow>
                    <TableCell />
                    <TbCellSummary sx={{ fontStyle: 'italic' }}>
                      Total Balance
                    </TbCellSummary>
                    <TbCellSummary>
                      {getAmount(salesReport.data?.totalSalesAmount)}
                    </TbCellSummary>
                    <TbCellSummary>
                      {getAmount(salesReport.data?.totalIncomeAmount)}
                    </TbCellSummary>
                    <TbCellSummary>
                      {getAmount(salesReport.data?.totalDiscountAmount)}
                    </TbCellSummary>
                    <TbCellSummary>
                      {getAmount(salesReport.data?.totalVatAmount)}
                    </TbCellSummary>
                    <TbCellSummary sx={{ fontSize: 18 }}>
                      {getAmount(salesReport.data?.totalNetAmount)}
                    </TbCellSummary>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </ScreenWrapper>
        </CardContent>
      </Card>
    );
  }

  return (
    <ScreenWrapper isError={products.isError}>
      <Card sx={{ paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
              {t('reportStack.sales')} Filters
            </Typography>
          </Stack>
          <Divider />

          <Grid
            py={2}
            pb={3}
            container
            rowSpacing={{ xs: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                pickerSize="medium"
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>
            <Grid xs={12}>
              <SwitchForm
                label={'All Products ?'}
                isChecked={filters.allProducts}
                onToggle={(e, v: boolean) => onFilterChange('allProducts', v)}
              />
            </Grid>
            <Grid xs={12}>
              <SelectPicker
                isRequired={true}
                isDisabled={filters.allProducts ? true : false}
                label={t('productStatement.label.product')}
                selectedValue={filters.product}
                selectItems={products.data}
                isInvalid={'product' in errors}
                helpText={'product' in errors && errors.product}
                onValueChange={(v: string) => onFilterChange('product', v)}
              />
            </Grid>
          </Grid>

          <Divider />

          <Stack direction="row" justifyContent={'flex-end'} paddingTop={2}>
            <Button
              title={'Get Sales Report'}
              onClick={onSubmit}
              loading={salesReport.isFetching}
            />
          </Stack>
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default SalesReport;
