import React, { useState } from 'react';
import Box from '@mui/material/Box';
import {
  ClickAwayListener,
  Menu,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../assets/styles/colors';
import NepaliDatePicker from '../calendar/NepaliDatePicker';
import { getNepaliDate } from '../../shared/utils/nepaliDate';

function BSDatePicker({
  label,
  value,
  onDateChange,
  helpText,
  minDate,
  isInvalid = false,
  isDisabled = true,
  isRequired = true,
  inLineLabel = false,
  size = 'medium',
}: any) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const finalBSDate = value ? getNepaliDate(value) : '';

  const getStyles = () => {
    let inputStyles: any = {};
    if (isRequired) {
      inputStyles = { ...inputStyles, borderColor: colors.red, borderWidth: 2 };
    }
    return inputStyles;
  };

  // console.log('showModal:', showModal);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Stack>
        <Stack py={0.4} direction={'row'} justifyContent={'space-between'}>
          {label && !inLineLabel && (
            <Box py={0.4}>
              <label>{label}</label>
            </Box>
          )}
        </Stack>

        <TextField
          id="outlined-basic"
          style={getStyles()}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: colors.darker3,
              backgroundColor: colors.gray100,
            },
            '.MuiOutlinedInput-notchedOutline': isRequired
              ? {
                  'border-left-color': colors.red500,
                  'border-left-width': '2px',
                }
              : {},
          }}
          size={size}
          value={finalBSDate}
          error={isInvalid ? true : false}
          label={inLineLabel ? label : undefined}
          variant="outlined"
          required={isRequired}
          // disabled={isDisabled}
          placeholder={'Select Date (BS)'}
          // defaultValue={String( || '')}
          // onChange={e => onDateChange(e.target.value)}
          // onClick={() => setShowModal(true)}
          onClick={handleClick}
          // inputProps={{ onClick: () => setShowModal(true) }}
        />

        {isInvalid && (
          <Typography pt={0.4} fontSize={14} color={colors.red500}>
            {`${t('commonText.error')}: ${helpText}`}
          </Typography>
        )}

        {helpText && !isInvalid && (
          <Typography pt={0.4} fontSize={14}>
            {helpText}
          </Typography>
        )}

        <Menu
          id="bs-date-picker"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setAnchorEl(null)}>
          <NepaliDatePicker
            onModalClose={() => setAnchorEl(null)}
            onDateChange={onDateChange}
            defaultValue={value}
            minDate={minDate}
          />
        </Menu>
      </Stack>
    </ClickAwayListener>
  );
}

export default BSDatePicker;
