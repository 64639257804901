import React from 'react';
import tw from 'twin.macro';
import { css } from 'styled-components/macro'; //eslint-disable-line

import { ReactComponent as BriefcaseIcon } from 'feather-icons/dist/icons/briefcase.svg';
import { ReactComponent as MoneyIcon } from 'feather-icons/dist/icons/dollar-sign.svg';

import AnimationRevealPage from './helpers/AnimationRevealPage';
import Hero from './components/Hero.jsx';
import Features from './components/features/Features';
import MainFeature from './components/features/MainFeature.jsx';
import MainFeature2 from './components/features/MainFeature2.jsx';
import FeatureWithSteps from './components/features/FeatureWithSteps.jsx';
import Pricing from './components/pricing/Pricing.jsx';
import Testimonial from './components/testimonials/Testimonial.jsx';
import FAQ from './components/faqs/FAQ.jsx';
import GetStarted from './components/cta/GetStarted';
import Footer from './components/footers/Footer.jsx';
import heroScreenshotImageSrc from './assets/images/karodillus1.svg';
import macHeroScreenshotImageSrc from './assets/images/karodillus2.svg';
import prototypeIllustrationImageSrc from './assets/images/karodillus3.svg';

const LandingPage = () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
    <div>
      <Hero roundedHeaderButton={true} />
      <Features
          subheading={<Subheading>Features</Subheading>}
          heading={
            <>
              We have Amazing <HighlightedText>Features.</HighlightedText>
            </>
          }
        />

      <MainFeature
        subheading={<Subheading>Transaction & Stock Management</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>20+ Accounting Reports</Subheading>}
        heading={
          <>
            To Visualize your <HighlightedText>business goals.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <MainFeature2
        subheading={<Subheading>Multi User & Multi Business Support</Subheading>}
        heading={
          <>
            Single Account{' '}
            <HighlightedText>Multiple Use.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: 'Multi Business Support',
            description:
              'We promise to offer you the best rate we can - at par with the industry standard.',
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: 'Multi User Support',
            description:
              'We assure you that our templates are designed and created by professional designers.',
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      />
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
            heading: 'Amazing User Experience',
            quote:
              "Excellent accounting application! It seamlessly combines accounting, stock management, and detailed reporting features. Managing finances and inventory has never been easier. The comprehensive reports provide valuable insights for better decision-making. Highly recommended.",
            customerName: 'Kshitiz Shrestha',
            customerTitle: 'Shrestha Fancy, Proprietor',
          },
          {
            stars: 5,
            profileImageSrc:
              'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80',
            heading: 'Amazing User Experience',
            quote:
              "Impressive financial record-keeping app! It efficiently handles stock management and offers detailed report viewing capabilities. Keeping track of finances and inventory has become hassle-free. The app's insightful reports empower smarter business decisions. A must-have tool for any business seeking seamless financial management.",
            customerName: 'Sabin Silwal',
            customerTitle: 'Manakamana Traders, Owner',
          },
        ]}
      /> */}

<FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              You have <HighlightedText>Questions ?</HighlightedText>
            </>
          }
          faqs={[
            {
              question: "I'd like to know more",
              answer:
                'KAROD app is a comprehensive financial record-keeping and stock management system designed specifically for small and medium-sized businesses, providing efficient tools to manage finances and control inventory.',
            },
            {
              question:
                'Do you support multiple users & multiple businesses in single account ?',
              answer:
                'Yes, our platform fully supports multi-user and multi-business features. You can easily add multiple users to collaborate on the platform, and seamlessly manage multiple businesses within a single account for enhanced efficiency and productivity.',
            },
            {
              question: 'What kind of payment methods do you accept ?',
              answer:
                'We accept various payment methods, including bank transfer, phone pay, eSewa, Khalti, and IME Pay. Choose the option that best suits your convenience..',
            },
            {
              question:
                'Is there a free trial available?',
              answer:
                'Yes, there is a 15-days free trial available. You can try it out and explore its features before making a purchase',
            },
            {
              question: 'What customer support options are available?',
              answer:
                'Our customer support options include phone call, Viber call/message, WhatsApp call/message, and Messenger chat.',
            },
            {
              question: 'Is Karod app IRD certified software ?',
              answer:
                "No, Karod app isn't IRD certified software so invoices can't be used for official billing",
            },
          ]}
        />
      {/* <GetStarted /> */}
      {/* <Footer /> */}
    </div>
    </AnimationRevealPage>
  );
};

export default LandingPage;
