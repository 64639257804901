import React from 'react';
import { Box, Typography } from '@mui/material';
// import { colors } from '../../assets/styles/colors';

const EmptyScreen = (): JSX.Element => {
  // console.log('yes was empty...');
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FFF',
        minHeight: 480,
      }}>
      <Typography sx={{ fontSize: 22 }} color="text.primary">
        No records found!
      </Typography>
    </Box>
  );
};

export default EmptyScreen;
