import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

import { useSelector } from '../../../store/reduxHooks';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';
import { voucherTypesList } from '../../../constants/defaultCodes/voucherTypes';
import PeriodSelect from '../../../components/forms/PeriodSelect';
import SelectPicker from '../../../components/forms/SelectPicker';
import InputForm from '../../../components/forms/InputForm';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const TransactionFilterModal = ({
  isLoading,
  onSubmit,
  filters,
  selectedPeriod,
  onDateChange,
  modalVisible,
  onModalClose,
  errors,
  onFilterChange,
}: any): JSX.Element => {
  const { user } = useSelector(state => state.auth.authUser);
  const { t } = useTranslation();

  voucherTypesList.map(x => (x.name = x.name.split('_').join(' ')));

  const dateRange = { fromDate: filters.fromDate, toDate: filters.toDate };

  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={2}>
          <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
            {t('voucherEntries.filter.filterTitle')}
          </Typography>
        </Stack>
        <Divider />

        <Stack py={2} gap={2}>
          <Grid
            container
            rowSpacing={{ xs: 3 }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={12}>
              <PeriodSelect
                horizontal={false}
                inLineLabel={false}
                dateRange={dateRange}
                selectedPeriod={selectedPeriod}
                onDateChange={onDateChange}
              />
            </Grid>

            <Grid xs={12}>
              <SelectPicker
                selectedValue={filters.voucherType}
                selectItems={voucherTypesList}
                label={t('voucherEntries.label.voucherType')}
                placeHolder={t('voucherEntries.placeholder.voucherType')}
                isInvalid={'voucherType' in errors}
                helpText={'voucherType' in errors && errors.voucherType}
                onValueChange={(v: string) => onFilterChange('voucherType', v)}
              />
            </Grid>
            <Grid xs={12}>
              <InputForm
                defaultValue={filters.voucherNumber}
                label={t('voucherEntries.label.voucherNumber')}
                placeHolder={t('voucherEntries.placeholder.voucherNumber')}
                isInvalid={'voucherNumber' in errors}
                onChangeText={(v: string) => onFilterChange('voucherNumber', v)}
                helpText={'voucherNumber' in errors && errors.voucherNumber}
              />
            </Grid>
          </Grid>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onModalClose}>
            {t('voucherEntries.filter.cancelBtn')}
          </Button>
          <Button variant="contained" size="large" onClick={onSubmit}>
            {t('voucherEntries.filter.applyBtn')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TransactionFilterModal;
