const accountTypes = {
  // ASSETS
  BANK_AND_CASH: 'BANK_AND_CASH',
  RECEIVABLE: 'RECEIVABLE',
  CURRENT_ASSETS: 'CURRENT_ASSETS',
  NON_CURRENT_ASSETS: 'NON_CURRENT_ASSETS',
  FIXED_ASSETS: 'FIXED_ASSETS',
  PRE_PAYMENTS: 'PRE_PAYMENTS',

  // LIABILITY
  PAYABLE: 'PAYABLE',
  NON_CURRENT_LIABILITIES: 'NON_CURRENT_LIABILITIES',
  CURRENT_LIABILITIES: 'CURRENT_LIABILITIES',

  // REVENUE
  INCOME: 'INCOME',

  // EXPENSES
  EXPENSES: 'EXPENSES',
  DIRECT_COSTS: 'DIRECT_COSTS',
  DEPRECIATION: 'DEPRECIATION',

  EQUITY: 'EQUITY',
  CURRENT_YEAR_EARNINGS: 'CURRENT_YEAR_EARNINGS',
};

export default accountTypes;

export const assetsTypesBasic = [
  accountTypes.BANK_AND_CASH,
  accountTypes.CURRENT_ASSETS,
  accountTypes.NON_CURRENT_ASSETS,
  accountTypes.FIXED_ASSETS,
  accountTypes.PRE_PAYMENTS,
];

export const assetsTypes = [...assetsTypesBasic, accountTypes.RECEIVABLE];

export const liabilitiesTypes = [
  accountTypes.PAYABLE,
  accountTypes.NON_CURRENT_LIABILITIES,
  accountTypes.CURRENT_LIABILITIES,
];

export const expensesTypes = [
  accountTypes.EXPENSES,
  accountTypes.DIRECT_COSTS,
  accountTypes.DEPRECIATION,
];

export const incomeTypes = [accountTypes.INCOME];
export const equityTypes = [accountTypes.EQUITY];

export const normalBalance = (code: string) => {
  let isDrBalance = false;
  let isCrBalance = false;

  const drAccounts = [...assetsTypes, ...expensesTypes];
  const crAccounts = [...incomeTypes, ...liabilitiesTypes, ...equityTypes];

  // const isCYES = code === accountTypes.CURRENT_YEAR_EARNINGS;

  // if (!drAccounts.includes(code) && !crAccounts.includes(code) && !isCYES) {
  //   console.log('invalid account type code');
  // }

  if (drAccounts.includes(code)) {
    isDrBalance = true;
  } else if (crAccounts.includes(code)) {
    isCrBalance = true;
  }

  return { isDrBalance, isCrBalance };
};
