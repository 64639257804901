import React from 'react';

import AuthRequired from './AuthRequired';
import Reports from '../pages/reports/Reports';
import BalanceSheet from '../pages/reports/balanceSheet/BalanceSheet';
import CashBook from '../pages/reports/cashBook/CashBook';
import DayBookReport from '../pages/reports/dayBook/DayBook';
import GeneralLedger from '../pages/reports/generalLedger/GeneralLedger';
import IncomeStatement from '../pages/reports/incomeStatement/IncomeStatement';
import PartnerLedger from '../pages/reports/partnerLedger/PartnerLedger';
import TrialBalanceReport from '../pages/reports/trialbalance/TrialBalance';
import SalesReport from '../pages/reports/productReport/SalesReport';
import PurchaseReport from '../pages/reports/productReport/PurchaseReport';
import ProductStockReport from '../pages/reports/productReport/ProductStock';
import ProductStockMovement from '../pages/reports/productReport/StockMovement';
import LowStockReport from '../pages/reports/productReport/LowStockReport';
import ProductExpiryReport from '../pages/reports/productReport/ProductExpiryReport';
import StaffSalaryExpense from '../pages/reports/expenseReport/StaffSalaryExpense';
import ExpensesOnItems from '../pages/reports/expenseReport/ExpensesOnItems';
import PartnerProductLedger from '../pages/reports/partnerLedger/PartnerProductLedger';

const reportRoutes = [
  {
    path: '/reports',
    element: (
      <AuthRequired>
        <Reports />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/balance-sheet',
    element: (
      <AuthRequired>
        <BalanceSheet />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/cash-book',
    element: (
      <AuthRequired>
        <CashBook />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/day-book',
    element: (
      <AuthRequired>
        <DayBookReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/general-ledger',
    element: (
      <AuthRequired>
        <GeneralLedger />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/profit-loss-statement',
    element: (
      <AuthRequired>
        <IncomeStatement />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/partner-ledger',
    element: (
      <AuthRequired>
        <PartnerLedger />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/trial-balance',
    element: (
      <AuthRequired>
        <TrialBalanceReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/sales-report',
    element: (
      <AuthRequired>
        <SalesReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/purchase-report',
    element: (
      <AuthRequired>
        <PurchaseReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/product-inventory',
    element: (
      <AuthRequired>
        <ProductStockReport />
      </AuthRequired>
    ),
  },
  {
    path: '/product-inventory',
    element: (
      <AuthRequired>
        <ProductStockReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/stock-movement',
    element: (
      <AuthRequired>
        <ProductStockMovement />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/low-stock-report',
    element: (
      <AuthRequired>
        <LowStockReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/product-expiry-report',
    element: (
      <AuthRequired>
        <ProductExpiryReport />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/expenses-on-items',
    element: (
      <AuthRequired>
        <ExpensesOnItems />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/salary-expenses',
    element: (
      <AuthRequired>
        <StaffSalaryExpense />
      </AuthRequired>
    ),
  },
  {
    path: '/reports/partner-product-ledger',
    element: (
      <AuthRequired>
        <PartnerProductLedger />
      </AuthRequired>
    ),
  },
];

export default reportRoutes;
