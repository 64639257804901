import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Deposits from './Deposits';
import { useSelector } from '../../store/reduxHooks';
import { getDashboardReport } from '../../controllers/reports/reportControllers';
import { useQuery } from '@tanstack/react-query';
import { getCurrentTime } from '../../controllers/system/systemControllers';
import { getCurrentAccountingPeriod } from '../../controllers/settings/otherSettingControllers';
import { generateDashboardData } from './utils/dashboardData';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import DashboardCard from './components/ReportCard';
// import RevVsExpenseChart from './components/RevVsExpenseChart';
// import ReceivableVsPayableChart from './components/ReceivableVsPayableChart ';
// import DashboardPieChart from './components/DashboardPieChart';
// import TwoLevelPieChart from './components/TwoLevelPieChart';
import ExpiredSubscriptionWarning from '../../components/modals/ExpiredSubscriptionWarning';
import DashboardQuickLinks from './components/DashboardQuickLinks';
import { dashboardQuickLinks } from './utils/quickLinks';
import { useTranslation } from 'react-i18next';

function Dashboard() {
  const { t } = useTranslation();

  const { companyAccount } = useSelector(state => state.auth.authUser);
  const [showWarning, setShowWarning] = useState(false);

  const { authUser } = useSelector(s => s.auth);
  const activeBusiness = authUser.user?.business.name;

  /* DONT ADD ENABLED: FALSE HERE, OTHER WISE INVALIDATE REPORT WILL NOT WORK */
  const dashboardFn = () => getDashboardReport();
  const dashboardQuery = useQuery(['getDashboardReport'], dashboardFn);
  const { isLoading, isSuccess, isError, data = {} } = dashboardQuery;

  const currentTimeQuery = useQuery(['currentTime'], getCurrentTime);
  const { data: currentTime, isSuccess: success } = currentTimeQuery;

  const currentPeriod = useQuery(['currentPeriod'], getCurrentAccountingPeriod);

  const dashboardReports = generateDashboardData(data);

  useEffect(() => {
    const expiryDate = new Date(companyAccount.activeSubscription.expiryDate);
    const nowFromServer = new Date(currentTime);

    if (nowFromServer > expiryDate) {
      setShowWarning(true);
    }
  }, [success]);

  return (
    <ScreenWrapper
      {...{
        isLoading: isLoading || currentPeriod.isLoading,
        isError,
        isSuccess,
        isEmpty: !data,
      }}>
      <Card sx={{ paddingX: { lg: 2, md: 1, sm: 0 } }}>
        <CardContent sx={{ paddingY: 1 }}>
          <Stack gap={2}>
            <Stack>
              <Typography sx={{ fontSize: 18 }}>
                {t('dashboard.quickLinks.title')}
              </Typography>

              <Divider />
              <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
                {dashboardQuickLinks().map((quickLink, index) => (
                  <Grid xs={6} sm={4} md={4} lg={2} key={index}>
                    <DashboardQuickLinks quickLink={quickLink} />
                  </Grid>
                ))}
              </Grid>
            </Stack>

            <Stack>
              <Typography sx={{ fontSize: 18 }}>
                {t('dashboard.accBalances.title')}
              </Typography>

              <Divider />
              {dashboardReports.map((item, index) => (
                <Grid
                  key={index}
                  container
                  columnSpacing={{ xs: 1, sm: 1, md: 4, lg: 4 }}>
                  {item.rows?.map((itm, indx) => (
                    <Grid key={indx} xs={12} sm={6} md={6} lg={3}>
                      <DashboardCard
                        key={itm.id}
                        {...itm}
                        currentPeriod={currentPeriod?.data}
                      />
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Stack>
          </Stack>

          {/* <Divider /> */}

          {/* <Grid container spacing={3} mt={4}>
            <Grid xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 280,
                }}>
                <ReceivableVsPayableChart />
              </Paper>
            </Grid>
            <Grid xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 280,
                }}>
                <RevVsExpenseChart />
              </Paper>
            </Grid>
            <Grid xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}>
                <Deposits />
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3} mt={4}>
            <Grid xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 280,
                }}>
                <DashboardPieChart />
              </Paper>
            </Grid>
            <Grid xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 280,
                }}>
                <TwoLevelPieChart />
              </Paper>
            </Grid>
            <Grid xs={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}>
                <Deposits />
              </Paper>
            </Grid>
          </Grid> */}

          <ExpiredSubscriptionWarning {...{ showWarning, setShowWarning }} />
        </CardContent>
      </Card>
    </ScreenWrapper>
  );
}

export default Dashboard;
