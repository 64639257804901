import { ProductItemType } from '../../defaultCodes/producttemTypes';

// export const productInputFieldsForModal = [
//   { fieldName: 'name', required: true },
//   { fieldName: 'description', required: false },
// ];

export const productSelectFields = [
  { fieldName: 'category', isCat: true },
  { fieldName: 'primaryUnit', isPrime: true },
  { fieldName: 'secondaryUnit', isSec: true },
];

export const productInputFields1 = [
  { fieldName: 'name', isCode: false },
  { fieldName: 'code', isCode: true },
];

export const productInputFields2 = (productType: string) => {
  const items: any = [
    { fieldName: 'sellingPrice', required: true, isSell: true },
    { fieldName: 'salesDiscount', required: false },
  ];

  if (productType === ProductItemType.product) {
    items.splice(0, 0, { fieldName: 'purchasePrice', required: true });
  }
  return items;
};

export const productInputFields3 = [
  { fieldName: 'minStockQuantity', numeric: true },
  { fieldName: 'productLocation', numeric: false },
];

export const productInputFields4 = [
  { fieldName: 'manufacturedDate', required: false },
  { fieldName: 'expiryDate', required: false },
];

export const productInputFields5 = (isProd: boolean) => {
  const items: any = [{ fieldName: 'description', isDesc: true }];

  if (isProd) {
    items.splice(0, 0, { fieldName: 'batchNumber', isDesc: false });
  }
  return items;
};
