import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Stack, Typography } from '@mui/material';
import { Card, CardContent, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

import SelectPicker from '../../components/forms/SelectPicker';
import {
  createSubsOrderBySysAdmin,
  getActiveVerifiedUsers,
} from '../../controllers/admin/adminControllers';
import { useDispatch } from '../../store/reduxHooks';
import { errorToast, successToast } from '../../constants/snackbar/snackbar';
import { getSubscriptionProducts } from '../../controllers/settings/otherSettingControllers';
import { validateSubsOrder } from '../../validations/sysAdmin/validateOrderCreate';
import InputForm from '../../components/forms/InputForm';
import { paymentModes } from '../../constants/defaultCodes/otherEnums';
import { getSubscriptionDiscountedPrice } from '../../shared/utils/subscriptionPrice';
import { formatAmount } from '../../shared/utils/locale';
import { getSystemConfigs } from '../../controllers/system/systemControllers';
import { colors } from '../../assets/styles/colors';

function SubscriptionOrderCreate() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({ totalBusiness: 1 });
  const [errors, setErrors] = useState<any>({});

  const subsProd = () => getSubscriptionProducts();
  const subsProdQuery = useQuery(['subscriptionProducts'], subsProd);
  const { isFetched, data: subsProducts = [] } = subsProdQuery;

  const getActiveUsers = () => getActiveVerifiedUsers();
  const allActiveUsers = useQuery(['allAVAUsers'], getActiveUsers);

  const sysConfigsQuery = useQuery(['sysConfigs'], getSystemConfigs);
  const { data: sysConfigs = {} } = sysConfigsQuery;

  const vatEnabled = sysConfigs.enableVATOnSubscription === 'true';

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateSubsOrder(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const { isLoading, mutateAsync } = useMutation(createSubsOrderBySysAdmin);

  const onSubmit = async () => {
    const { isValid, errors: errs } = validateSubsOrder(formData, errors);

    if (isValid) {
      try {
        await mutateAsync(formData);
        navigate('/', { replace: true }); // later redirect to the list of active subscribers
        enqueueSnackbar('Subscription Order Created!', successToast);
      } catch (err) {
        enqueueSnackbar('Error Creating Subscription Order', errorToast);
      }
    } else {
      setErrors(errs);
    }
  };

  const getAllActiveUsers = () => {
    const modifiedUsers = [];

    if (allActiveUsers?.data) {
      for (const x of allActiveUsers?.data) {
        const modified = { ...x };
        const name1 = `${x.firstName} ${x.lastName} - ${x.phoneNumber}`;
        const name2 = `${x.email} - ${x.companyAccount.name}`;
        modified.name = `${name1} - ${name2}`;
        modifiedUsers.push(modified);
      }
    }
    return modifiedUsers;
  };

  const getSubsProducts = () => {
    const modifiedProds = [];

    for (const p of subsProducts) {
      const modified = { ...p };
      const name1 = `${p.name} - ${p.type} (${p.months} months)`;
      const name2 = ` - ${p.offerName} ${p.discountPercent}%`;
      modified.name = `${name1} ${name2}`;
      modifiedProds.push(modified);
    }
    return modifiedProds;
  };

  const product = subsProducts.find(x => x.id === formData.product);
  const price = product?.price || 0;
  // const price = product?.price * formData.totalBusiness || 0;
  const discountPercent = product?.discountPercent || 0;
  const discountAmount = (discountPercent / 100) * price;

  const amtAfterDis1 = getSubscriptionDiscountedPrice(price, discountPercent);

  const totAmtAfterDis1 = amtAfterDis1 * formData.totalBusiness;
  const totalAmtAfterDis2 = totAmtAfterDis1 - (formData.otherDiscount || 0); // decrease extra discount given

  let taxAmount = 0;
  if (vatEnabled) {
    taxAmount = Math.ceil(0.13 * totalAmtAfterDis2);
  }
  const finalSubsAmount = totalAmtAfterDis2 + taxAmount;

  useEffect(() => {
    onFormChange('amount', finalSubsAmount);
    onFormChange('totAmtAfterDis1', totAmtAfterDis1);
  }, [finalSubsAmount]);

  // console.log('formData', formData);
  // console.log('errors', errors);

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent>
        <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
          Create Subscription Order{' '}
        </Typography>
        <Divider />

        <Stack py={2} gap={3}>
          <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 4 }}>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <SelectPicker
                isRequired={true}
                label={'Select User (Ordered By)'}
                selectedValue={formData.orderedBy}
                selectItems={getAllActiveUsers()}
                isInvalid={'orderedBy' in errors}
                helpText={'orderedBy' in errors && errors.orderedBy}
                onValueChange={(v: any) => onFormChange('orderedBy', v)}
              />
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={6}>
              <SelectPicker
                isRequired={true}
                label={'Select Product'}
                selectedValue={formData.product}
                selectItems={getSubsProducts()}
                isInvalid={'product' in errors}
                helpText={'product' in errors && errors.product}
                onValueChange={(v: any) => onFormChange('product', v)}
              />
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={6}>
              <InputForm
                isRequired={true}
                isNumberic={true}
                isFloatNo={false}
                maxLength={2}
                defaultValue={formData.totalBusiness}
                label={'Total Business Subscribed'}
                isInvalid={'totalBusiness' in errors}
                onChangeText={(v: number) => onFormChange('totalBusiness', +v)}
                helpText={errors.totalBusiness}
              />
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={6}>
              <InputForm
                isNumberic={true}
                isFloatNo={false}
                maxLength={5}
                label={'Other Discount Amount (if given)'}
                isInvalid={'otherDiscount' in errors}
                onChangeText={(v: number) => onFormChange('otherDiscount', +v)}
                helpText={errors.otherDiscount}
              />
            </Grid>
          </Grid>
          <Stack direction={'row'} gap={4}>
            <Stack flex={1}>
              <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 4 }}>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <SelectPicker
                    isRequired={true}
                    label={'Select Payment Mode'}
                    selectedValue={formData.mode}
                    selectItems={paymentModes}
                    isInvalid={'mode' in errors}
                    helpText={'mode' in errors && errors.mode}
                    onValueChange={(v: any) => onFormChange('mode', v)}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12}>
                  <InputForm
                    isRequired={true}
                    isNumberic={true}
                    isDisabled={true}
                    defaultValue={formData.amount}
                    label={'Total Amount Paid'}
                    isInvalid={'amount' in errors}
                    onChangeText={(v: number) => onFormChange('amount', +v)}
                    helpText={errors.amount}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Stack flex={1}>
              <Stack
                p={2}
                py={1}
                mt={1}
                bgcolor={colors.green100}
                borderRadius={2}
                boxShadow={4}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  BILL SUMMARY
                </Typography>
                <Divider sx={{ my: 0.6 }} />

                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: 18 }}>
                    Original Price ({price} * {formData.totalBusiness})
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: colors.darker3,
                    }}>
                    {formatAmount(price * formData.totalBusiness)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: 18 }}>
                    Discount Offer ({discountPercent}%)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: colors.darker3,
                    }}>
                    {formatAmount(discountAmount * formData.totalBusiness)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: 18 }}>
                    Other Discount Amount
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: colors.darker3,
                    }}>
                    {formatAmount(formData.otherDiscount || 0)}
                  </Typography>
                </Stack>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontSize: 18 }}>VAT Amount</Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 'bold',
                      color: colors.darker3,
                    }}>
                    {formatAmount(taxAmount)}
                  </Typography>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                    Final Bill Amount
                  </Typography>
                  <Typography sx={{ fontSize: 22, fontWeight: 'bold' }}>
                    {formatAmount(finalSubsAmount)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        <Stack mt={3} direction="row" justifyContent={'flex-end'}>
          <LoadingButton
            size="large"
            variant="contained"
            loading={isLoading}
            onClick={onSubmit}>
            Create Subscription Order
          </LoadingButton>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default SubscriptionOrderCreate;
