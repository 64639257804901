import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, Stack } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from 'feather-icons/dist/icons/chevron-right.svg';

import { colors } from '../../../assets/styles/colors';

export default function ReportCard({ title, items }) {
  const navigate = useNavigate();

  const boxStyle = {
    width: '100%',
    padding: 1,
    paddingY: 1.7,
    borderRadius: 1,
    borderBottomWidth: 1,
    '&:hover': {
      bgcolor: colors.gray100,
      cursor: 'pointer',
    },
  };

  return (
    <Card sx={{ minHeight: { md: 560, lg: 500 } }}>
      <CardContent>
        <Typography color={colors.theme1} mb={1} pl={1} fontWeight={'bold'}>
          {title.toUpperCase()}
        </Typography>

        <Stack
          mt={1}
          alignItems={'flex-start'}
          divider={<Divider orientation="vertical" flexItem />}>
          {items.map((item, index) => (
            <Box
              sx={{ ...boxStyle, borderBottomWidth: item.isLast ? 0 : 1 }}
              key={index}
              onClick={() => navigate(item.navigateTo)}>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}>
                <Stack>
                  <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, mt: -0.4, color: colors.blueGrey700 }}>
                    {item.helpText}
                  </Typography>
                </Stack>
                <ChevronRightIcon />
              </Stack>
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
