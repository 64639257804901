import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardContent,
  Container,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';

import {
  createProductUnit,
  getProductUnits,
} from '../../../controllers/settings/inventoryControllers';
import { validateProductUnit } from '../../../validations/settings/validateProductItem';
import InputForm from '../../../components/forms/InputForm';
import Button from '../../../components/buttons/Button';
import { useNavigate } from 'react-router-dom';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
const initial = { initialData: [] };

const CreateProductUnitPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  // const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const unitsQuery = useQuery(['productUnits'], getProductUnits, initial);
  const { data: productUnits } = unitsQuery;
  const { isLoading, mutateAsync } = useMutation(createProductUnit);

  const onSubmit = async () => {
    const validity = validateProductUnit(formData, errors, productUnits);
    const { isValid, errors: errorMsg } = validity;

    if (isValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['productUnits']);
        enqueueSnackbar(t('productUnit.createMsg.success'), successToast);
        navigate('/settings/product-units', { replace: true });
      } catch (err) {
        // console.error('err:', err); // report to err reporting services
        enqueueSnackbar(t('productUnit.createMsg.error'), errorToast);
      }
    } else {
      setErrors(errorMsg);
      // console.log('Validation Failed');
    }
  };

  const onFormChange = (name: string, value: string) => {
    setData(prev => ({ ...prev, [name]: value }));

    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateProductUnit(
      updatedData,
      errors,
      productUnits,
    );
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  // console.log('formData Business:', formData);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.createProductUnit')}
          </Typography>
          <Divider />
          <Stack py={4} spacing={4}>
            <InputForm
              isRequired={true}
              maxLength={3}
              defaultValue={formData.code}
              label={t('productUnit.label.code')}
              placeHolder={t('productUnit.label.code')}
              isInvalid={'code' in errors}
              helpText={'code' in errors && errors.code}
              onChangeText={(v: string) => {
                onFormChange('code', v);
              }}
            />
            <InputForm
              isRequired={true}
              defaultValue={formData.name}
              label={t('productUnit.label.name')}
              placeHolder={t('productUnit.label.name')}
              isInvalid={'name' in errors}
              onChangeText={(v: string) => onFormChange('name', v)}
              helpText={'name' in errors && errors.name}
            />
          </Stack>
        </CardContent>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} padding={2}>
          <Button
            title="Save Product Unit"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default CreateProductUnitPage;
