import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { bankAndCashAccounts } from '../../../controllers/settings/accountControllers';
import { getEmployees } from '../../../controllers/settings/employeeControllers';
import { getVoucherNumber } from '../../../controllers/transactions/transactionControllers';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import {
  validateSalaryPayment,
  validateVoucherNo,
} from '../../../validations/transactions/validateSalayrPayment';
import { bankAndCashBalanceCheck } from '../../../controllers/reports/reportControllers';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import { salaryPayment } from '../../../controllers/transactions/cashTransactionControllers';
import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

const voucherType = voucherTypes.SALARY_PAYMENT;
const initial = { initialData: [] };

function SalaryExpense() {
  // const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [formData, setData] = React.useState<any>({ voucherDate: new Date() });
  const [errors, setErrors] = React.useState<any>({});

  const accounts = useQuery(['bankAndCashAcc'], bankAndCashAccounts, initial);

  const employeesFn = () => getEmployees({ isActive: true });
  const employees = useQuery(['getEmployees'], employeesFn, initial);

  const { data: voucherNumber } = useQuery(['getVoucherNo', voucherType], () =>
    getVoucherNumber(voucherType),
  );

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber]);

  const { isLoading, mutateAsync } = useMutation(salaryPayment);

  const onSubmit = async () => {
    const { amount, cashOutAccount } = formData;

    const { isValid: isInitialValid } = validateSalaryPayment(formData, errors);

    let hasBal = true;
    if (isInitialValid) {
      hasBal = await bankAndCashBalanceCheck(cashOutAccount, amount);
    }

    const { isValid, errs } = validateSalaryPayment(formData, errors, hasBal);
    const { isVoucherNoValid, errss } = await validateVoucherNo(formData, errs);

    if (isValid && isVoucherNoValid && hasBal) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getDashboardReport']);

        navigate('/journals', { replace: true });
        enqueueSnackbar(t('salaryPayment.createMsg.success'), successToast);
      } catch (err) {
        let title = t('salaryPayment.createMsg.error');
        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }
        enqueueSnackbar(title, errorToast);
      }
    } else {
      setErrors(errss);
      if (!isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = (name: string, value: any) => {
    const updatedData = { ...formData, [name]: value };
    setData(updatedData);

    const { errs } = validateSalaryPayment(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const getSelectItems = (selectType: string) => {
    if (selectType === 'employee') {
      return employees.data;
    }
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  // console.log('formData SP:', formData);
  // console.log('errors SP:', errors);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('transactionStack.salaryPayment')}
          </Typography>
          <Divider />
          <Stack py={2} spacing={4}>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('salaryPayment.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('salaryPayment.label.voucherNumber')}
                  isDisabled={true}
                  isRequired={true}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                  defaultValue={formData.voucherNumber}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  label={t('salaryPayment.label.referenceId')}
                  defaultValue={formData.referenceId}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`salaryPayment.label.employee`)}
                  selectItems={getSelectItems('employee')}
                  selectedValue={formData.employee}
                  isInvalid={'employee' in errors}
                  helpText={getHelpText('employee')}
                  onValueChange={(v: any) => onFormChange('employee', v)}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  label={t(`salaryPayment.label.cashOutAccount`)}
                  selectItems={accounts.data}
                  selectedValue={formData.cashOutAccount}
                  isInvalid={'cashOutAccount' in errors}
                  helpText={getHelpText('cashOutAccount')}
                  onValueChange={(v: any) => onFormChange('cashOutAccount', v)}
                />
              </Grid>
            </Grid>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={6}>
                <InputForm
                  isRequired={true}
                  isNumberic={true}
                  defaultValue={formData.amount}
                  isInvalid={'amount' in errors}
                  helpText={getHelpText('amount')}
                  label={t('salaryPayment.label.amount')}
                  onChangeText={(v: string) => onFormChange('amount', v)}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={12}>
                <InputForm
                  label={t('salaryPayment.label.description')}
                  defaultValue={formData.description}
                  isInvalid={'description' in errors}
                  helpText={getHelpText('description')}
                  onChangeText={(v: string) => onFormChange('description', v)}
                />
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            title="Save Salary Payment"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default SalaryExpense;
