import { store } from '../../store/store';
import {
  employeeUrl,
  updateEmloyeeUrl,
  activateEmployeeUrl,
  deActivateEmployeeUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};
export const getEmployees = async (data: any) => {
  const path = employeeUrl();
  const response = await httpClient().get<any>(path, data);

  const employees = response.data.map(e => {
    e.name = `${e.firstName} ${e.lastName}`;
    return e;
  });
  return employees;
};

// Only for Owners
export const createEmployee = async data => {
  const path = employeeUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const updateEmployee = async (data: any) => {
  const path = employeeUrl();

  const response = await httpClient().patch<any>(path, data);
  return response.data;
};

export const activateEmployeeAPI = async (employeeId: number) => {
  const path = activateEmployeeUrl(employeeId);

  const response = await httpClient().patch<any>(path);
  return response.data;
};

export const deActivateEmployeeAPI = async (employeeId: number) => {
  const path = deActivateEmployeeUrl(employeeId);

  const response = await httpClient().patch<any>(path);
  return response.data;
};
