export enum DashboardCardType {
  bankAndCash = 'bankAndCash',
  netProfit = 'netProfit',
  revenue = 'revenue',
  expense = 'expense',
  receivable = 'receivable',
  payable = 'payable',
  otherAsset = 'otherAsset',
  stockValue = 'stockValue',
}
