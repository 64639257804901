import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import {
  registrationInputFields1,
  registrationInputFields2,
  registrationInputFields3,
  passwordResetFields,
} from '../../constants/formFields/auth/registrationFields';
import InputForm from '../../components/forms/InputForm';
import { validateRegistration } from '../../validations/auth/validateUserRegistration';
import {
  checkUserExistance,
  userRegistrationAPI,
} from '../../controllers/accounts/userAccountControllers';
import { useDispatch, useSelector } from '../../store/reduxHooks';
import {
  confirmVerificationCode,
  endUserRegistration,
  refreshUserDetails,
  signInWithPhoneNumber,
  startUserRegistration,
  userLogin,
} from '../../store/authSlice';
import { colors } from '../../assets/styles/colors';
import SelectPicker from '../../components/forms/SelectPicker';
import { countryCodes } from '../../constants/defaultCodes/countryCodes';
import { useMutation } from '@tanstack/react-query';
// import { LinkButton } from '../../components/buttons/LinkButton';
// import { Button } from '../../components/buttons/Button';
import OTPCodeInput from './components/OTPCodeInput';
import {
  OTPUsage,
  RegisteredFrom,
  localeOptions,
} from '../../constants/defaultCodes/authAndAccounts';
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { TextButton } from '../../components/buttons/Button';
import { errorToast, successToast } from '../../constants/snackbar/snackbar';
import { LoadingButton } from '@mui/lab';
import { updatePhoneVerification } from '../../controllers/settings/accountControllers';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const UserRegistration = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  // const toast = useToast();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const lng = JSON.parse(localStorage.getItem('language'));
  const registeredFrom = RegisteredFrom.web;

  // const idss = Math.ceil(Math.random() * 100);
  // const testInitial = {
  //   registeredFrom,
  //   countryCode: '+977',
  //   companyName: `David Enterprises ${idss}`,
  //   email: `david${idss}@karod.com`,
  //   firstName: 'David',
  //   lastName: 'Sharma',
  //   phoneNumber: `98490000${idss}`,
  //   password: 'admin9090',
  //   password2: 'admin9090',
  // };

  // const [formData, setData] = useState<any>(testInitial);
  const init = { countryCode: '+977', registeredFrom, locale: '' };
  const [formData, setData] = useState<any>({ ...init });
  const [errors, setErrors] = useState<any>({});
  const [code, setCode] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [regInProgress, setRegInProgress] = useState(false);
  const [userExist, setUserExist] = useState('');

  const onFormChange = (name: string, value: string) => {
    if (name === 'locale' && (value === 'en' || value === 'np')) {
      i18n.changeLanguage(value);
      localStorage.setItem('language', JSON.stringify(value));
    }
    setData((prev: any) => ({ ...prev, [name]: value }));
    setUserExist('');
    const updatedData = { ...formData, [name]: value };
    const { errors: err } = validateRegistration(updatedData, errors);
    err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
  };

  const { otpSent, requestingCode } = useSelector(s => s.auth);

  const userExistanceQuery = useMutation(checkUserExistance);

  async function onSubmitPhoneNumber() {
    const { countryCode, phoneNumber, email } = formData;

    const { isValid, errors: errs } = validateRegistration(formData, errors);
    dispatch(startUserRegistration());

    if (isValid) {
      setRegInProgress(true);

      const validityPayload = { phoneNumber, email, fromRegistration: true };
      const existRes = await userExistanceQuery.mutateAsync(validityPayload);
      if (existRes) {
        setUserExist(existRes);
        setRegInProgress(false);
      } else {
        const otpUsage = OTPUsage.REGISTRATION;
        const payload = { countryCode, phoneNumber, otpUsage };
        await onUserRegistration();
        await dispatch(signInWithPhoneNumber(payload));
        setRegInProgress(false);
      }
    } else {
      setErrors(errs);
      // console.log('Validation Failed');
    }
  }

  async function onSubmitVerificationCode() {
    setRegInProgress(true);
    if (code) {
      try {
        const { countryCode, phoneNumber } = formData;
        const payload = { countryCode, phoneNumber, otp: code };

        const response = await dispatch(confirmVerificationCode(payload));
        if (response.meta.requestStatus === 'fulfilled') {
          const otpVerified = await response.payload.verified;
          // console.log('otpVerified', otpVerified);

          if (otpVerified) {
            // await onUserRegistration();
            await updatePhoneVerification();
            await dispatch(refreshUserDetails());
            enqueueSnackbar(t('userRegistration.regMsg.success'), successToast);
            dispatch(endUserRegistration());
            navigate('/');
          }
        }
        if (response.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Invalid verification code', errorToast);
        }
      } catch (error) {
        // console.log('Invalid code:', error);
        enqueueSnackbar('Code verification error', errorToast);
      } finally {
        setRegInProgress(false);
      }
    }
  }

  const onUserRegistration = async () => {
    try {
      await userRegistrationAPI(formData);
      const { phoneNumber, password } = formData;
      const response = await dispatch(userLogin({ phoneNumber, password }));

      if (response.meta.requestStatus === 'fulfilled') {
        const locale = response.payload?.user?.userSetting?.locale || 'en';
        i18n.changeLanguage(locale);
        localStorage.setItem('language', JSON.stringify(locale));
      }
      // enqueueSnackbar(t('userRegistration.regMsg.success'), successToast);
    } catch (err) {
      // console.error('err:', err);
      enqueueSnackbar(t('userRegistration.regMsg.error'), errorToast);
    }
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const onPrivacyPolicy = () => {
    return 'https://karodapp.blogspot.com/2023/07/privacy-policy-for-karod-application.html';
  };

  const onTermsOfuse = () => {
    return 'https://karodapp.blogspot.com/2023/07/terms-of-use.html';
  };

  // console.log('formData', formData);
  // console.log('errors', errors);

  const renderUserRegistrationForm = () => {
    return (
      <>
        <Stack alignItems={'center'} direction={'row'} gap={2}>
          <Typography flex={2.5} sx={{ color: colors.theme1 }}>
            <Typography sx={{ fontSize: 16, color: colors.darker2 }}>
              KAROD - DIGITAL KHATA
            </Typography>
            <Typography
              lineHeight={1}
              sx={{ fontSize: 20, fontWeight: 'bold' }}>
              {t('userRegistration.text.title')}
            </Typography>
          </Typography>

          <Stack flex={1}>
            <SelectPicker
              size="small"
              isRequired={true}
              disableClearable={true}
              selectedValue={formData.locale}
              selectItems={[...localeOptions]}
              placeHolder={'भाषा छान्नुहोस्'}
              isInvalid={'locale' in errors}
              helpText={getHelpText('locale')}
              onValueChange={(v: string) => onFormChange('locale', v)}
            />
          </Stack>
        </Stack>

        <Divider />

        <Stack gap={2}>
          <Stack direction={'row'} gap={2}>
            {registrationInputFields1.map(({ required, fieldName }) => (
              <Box flex="1" key={fieldName}>
                <InputForm
                  key={fieldName}
                  isRequired={required}
                  defaultValue={formData[fieldName]}
                  label={t(`userRegistration.label.${fieldName}`)}
                  isInvalid={fieldName in errors}
                  helpText={getHelpText(fieldName)}
                  onChangeText={(v: string) => onFormChange(fieldName, v)}
                />
              </Box>
            ))}
          </Stack>

          <Stack direction={'row'} gap={1}>
            <Box flex={1.4}>
              <SelectPicker
                isRequired={true}
                isDisabled={true}
                disableClearable={true}
                selectItems={countryCodes}
                selectedValue={formData.countryCode}
                label={t('userRegistration.label.countryCode')}
                onValueChange={(v: any) => onFormChange('countryCode', v)}
              />
            </Box>
            {registrationInputFields2.map(({ required, fieldName }) => (
              <Box
                flex={fieldName === 'email' ? 4 : 2.5}
                key={fieldName}
                pl={fieldName === 'email' ? 1 : 0}>
                <InputForm
                  key={fieldName}
                  isNumberic={fieldName === 'email' ? false : true}
                  isFloatNo={false}
                  maxLength={fieldName === 'email' ? 128 : 10}
                  isRequired={required}
                  defaultValue={formData[fieldName]}
                  label={t(`userRegistration.label.${fieldName}`)}
                  isInvalid={fieldName in errors}
                  helpText={getHelpText(fieldName)}
                  onChangeText={(v: string) => onFormChange(fieldName, v)}
                />
              </Box>
            ))}
          </Stack>

          {registrationInputFields3.map(({ required, fieldName }) => (
            <InputForm
              key={fieldName}
              isRequired={required}
              defaultValue={formData[fieldName]}
              autoCapitalize={fieldName === 'email' ? 'none' : 'sentences'}
              label={t(`userRegistration.label.${fieldName}`)}
              placeholder={t(`userRegistration.placeholder.${fieldName}`)}
              isInvalid={fieldName in errors}
              onChangeText={(v: string) => onFormChange(fieldName, v)}
              helpText={getHelpText(fieldName)}
            />
          ))}

          <Stack gap={2}>
            {passwordResetFields.map(({ fieldName }) => (
              <Stack flex={1}>
                <Box py={0.8}>
                  <label>{t(`userRegistration.label.${fieldName}`)}</label>
                </Box>
                <TextField
                  required
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  placeholder={t(`userRegistration.label.${fieldName}`)}
                  onChange={(e: any) => onFormChange(fieldName, e.target.value)}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      'border-left-color': colors.red500,
                      'border-left-width': '2px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(prev => !prev)}
                          onMouseDown={() => setShowPassword(prev => !prev)}
                          edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                {fieldName in errors && (
                  <Typography pt={0.4} fontSize={14} color={colors.red500}>
                    {`${t('commonText.error')}: ${getHelpText(fieldName)}`}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>

          {userExist && (
            <Typography
              sx={{ color: colors.red500, fontWeight: 'bold', fontSize: 20 }}
              align="center">
              {`${t('userRegistration.text.exist1')} ${userExist} ${t(
                'userRegistration.text.exist2',
              )}`}
            </Typography>
          )}

          <Stack direction={'row'}>
            <Typography flexDirection={'row'} sx={{ fontSize: 15 }}>
              {t('userRegistration.accept.text1')}
              <span>&nbsp;</span>
              <Link href={onPrivacyPolicy()} target="_blank">
                {t('userRegistration.accept.text2')}
              </Link>
              <span>&nbsp;</span>
              {t('userRegistration.accept.text3')}
              <span>&nbsp;</span>
              <Link href={onTermsOfuse()} target="_blank">
                {t('userRegistration.accept.text4')}
              </Link>
              <span>&nbsp;</span>

              {(formData.locale === 'np' || lng === 'np') &&
                t('userRegistration.accept.text5')}
            </Typography>
          </Stack>

          {formData.phoneNumber && (
            <Stack bgcolor={colors.green100} p={0.4} borderRadius={1}>
              <Typography
                sx={{
                  px: 1,
                  fontSize: 16,
                  fontWeight: 'bold',
                  color: colors.green700,
                }}>
                You'll receive OTP code on {formData.phoneNumber} for
                verification!
              </Typography>
            </Stack>
          )}

          <LoadingButton
            size="large"
            onClick={onSubmitPhoneNumber}
            loading={requestingCode || regInProgress}
            // loading={true}
            variant="contained"
            sx={{ my: 1 }}>
            {t('userRegistration.text.register')}
          </LoadingButton>
        </Stack>

        <Stack alignItems={'center'}>
          <Typography>{t('userRegistration.text.already')}</Typography>
          <TextButton
            title="LOGIN HERE"
            color={colors.theme2}
            onClick={() => navigate('/login')}
          />
        </Stack>
      </>
    );
  };

  if (otpSent) {
    // if (true) {
    return (
      <Container sx={containerStyle} maxWidth={'xs'}>
        <Box sx={{ ...boxStyle, minWidth: 480, padding: 4 }}>
          <OTPCodeInput
            code={code}
            setCode={setCode}
            phoneNumber={formData.phoneNumber}
            isLoading={regInProgress}
            onSubmit={() => onSubmitVerificationCode()}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={containerStyle} maxWidth={'xs'}>
      <Box sx={{ ...boxStyle, minWidth: 680 }}>
        {renderUserRegistrationForm()}
      </Box>
    </Container>
  );
};

export default UserRegistration;

const containerStyle = {
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const boxStyle = {
  gap: 2,
  margin: 4,
  paddingY: 2,
  paddingX: 4,
  boxShadow: 8,
  minWidth: 480,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
};
