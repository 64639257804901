const FORBIDDEN_TERMINAL_CHARACTERS = [
  `!`,
  `#`,
  `$`,
  `%`,
  `&`,
  `'`,
  `*`,
  `+`,
  `-`,
  `/`,
  `=`,
  `?`,
  `^`,
  `_`,
  '`', // single quotes around tick
  `{`,
  `|`,
  `}`,
  `~`,
];

export const emailIsValid = (email: string) => {
  let syntaxGood = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  if (!syntaxGood) return false; // skip loop if we've already failed

  for (let badChar of FORBIDDEN_TERMINAL_CHARACTERS) {
    if (email.startsWith(badChar) || email.endsWith(badChar)) {
      return false; // terminate early
    }
  }

  return true;
};

export const phoneNoIsValid = (phoneNumber: string) => {
  if (phoneNumber.trim().length !== 10) {
    return false;
  }

  if (phoneNumber.trim().slice(0, 1) !== '9') {
    return false;
  }

  const numbers = phoneNumber.split('');
  for (const p of numbers) {
    if (isNaN(Number(p))) {
      return false;
    }
  }

  return true;
};
