import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import InputForm from '../../../../components/forms/InputForm';

import { useDispatch } from '../../../../store/reduxHooks';
import { validateChangePassword } from '../../../../validations/settings/validateLoginCode';
import { useMutation } from '@tanstack/react-query';
import { userPasswordChangeAPI } from '../../../../controllers/accounts/userAccountControllers';
import { refreshUserDetails } from '../../../../store/authSlice';
import { colors } from '../../../../assets/styles/colors';
import { LoadingButton } from '@mui/lab';
import {
  errorToast,
  successToast,
} from '../../../../constants/snackbar/snackbar';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

function PasswordChangeModal({ modalVisible, onModalClose }: any) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [changeError, setChangeError] = useState<any>('');

  const onFormChange = (name: string, value: any) => {
    setData((prev: any) => ({ ...prev, [name]: value }));
    changeError && setChangeError('');

    const updated = { ...formData, [name]: value };
    const { errs } = validateChangePassword(updated, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const { isLoading, mutateAsync } = useMutation(userPasswordChangeAPI);

  const onSubmit = async () => {
    const { isValid, errs } = validateChangePassword(formData, errors);
    if (isValid) {
      try {
        await mutateAsync(formData);
        await dispatch(refreshUserDetails());
        enqueueSnackbar('Password Changed !!', successToast);
        onModalClose();
      } catch (err) {
        let msg = 'Error Changing Password!';
        setChangeError(err?.response?.data.message || msg);
        enqueueSnackbar(msg, errorToast);
      }
    } else {
      setErrors(errs);
    }
  };
  // console.log('formData pass change:', formData);

  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>
          Change Password
        </Typography>
        <Divider />

        <Stack gap={2} py={2}>
          <InputForm
            maxLength={36}
            isPassword={true}
            isRequired={true}
            label={'Old Password'}
            isInvalid={'oldPassword' in errors}
            helpText={'oldPassword' in errors && errors.oldPassword}
            onChangeText={(v: string) => onFormChange('oldPassword', v)}
          />
          <InputForm
            maxLength={36}
            isPassword={true}
            isRequired={true}
            label={'New Password'}
            isInvalid={'newPassword' in errors}
            helpText={'newPassword' in errors && errors.newPassword}
            onChangeText={(v: string) => onFormChange('newPassword', v)}
          />
          <InputForm
            maxLength={36}
            isPassword={true}
            isRequired={true}
            label={'Confirm New Password'}
            isInvalid={'newPasswordTwo' in errors}
            helpText={'newPasswordTwo' in errors && errors.newPasswordTwo}
            onChangeText={(v: string) => onFormChange('newPasswordTwo', v)}
          />
        </Stack>

        {changeError && (
          <Typography sx={{ color: colors.red500, fontWeight: 'bold', mb: 2 }}>
            {changeError}
          </Typography>
        )}

        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onModalClose}>
            Close
          </Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            size="large"
            onClick={onSubmit}>
            Submit
          </LoadingButton>
        </Stack>
      </Box>
    </Modal>
  );
}

export default PasswordChangeModal;
