import React, { useState } from 'react';
import { Autocomplete, TextField, Box, Stack, Typography } from '@mui/material';

import { colors } from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { formatAmount } from '../../shared/utils/locale';

export default function SelectPicker({
  label,
  placeHolder,
  selectItems = [],
  selectedValue,
  onValueChange,
  helpText,
  isInvalid,
  setHandles,
  partnerBalance,
  productBalance,
  addItemText,
  defaultValue,
  isRequired = false,
  isDisabled = false,
  inLineLabel = false,
  size = 'medium',
  searchPlaceholder = 'Search...',
  disableClearable = false,
}: any) {
  const { t } = useTranslation();

  const findSelected = () => {
    if (selectedValue) {
      return selectItems.find(x => x.id === selectedValue);
    }
  };

  const getStyles = () => {
    let inputStyles: any = { color: 'red' };
    if (isDisabled) {
      inputStyles = { ...inputStyles, backgroundColor: colors.gray100 };
    }
    return inputStyles;
  };

  const balanceStyle = {
    fontSize: 20,
    fontWeight: 'bold',
    paddingRight: 8,
  };

  const styles = {
    green: { ...balanceStyle, color: colors.green700 },
    red: { ...balanceStyle, color: colors.theme2 },
  };

  // console.log('select picker:', selectedValue);
  // console.log('getSelectItems():', getSelectItems());

  return (
    <Stack>
      {label && (
        <Stack py={0.4} direction={'row'} justifyContent={'space-between'}>
          {label && !inLineLabel && (
            <Box py={0.4}>
              <label>{label}</label>
            </Box>
          )}
          {partnerBalance && (
            <Typography
              style={
                partnerBalance.type === 'CASH_IN' ? styles.green : styles.red
              }>
              {formatAmount(partnerBalance.balance)}
            </Typography>
          )}
          {productBalance && (
            <Typography
              style={
                productBalance.type === 'POSITIVE' ? styles.green : styles.red
              }
              sx={{ fontSize: '18px !important' }}>
              {productBalance.balance}
            </Typography>
          )}
        </Stack>
      )}
      <Autocomplete
        disablePortal
        size={size}
        id="select-picker"
        style={getStyles()}
        disabled={isDisabled}
        disableClearable={disableClearable}
        options={selectItems}
        getOptionLabel={(option: any) => option?.name}
        value={findSelected() || null} // to handle auto complete in update cases
        // defaultValue={findSelected()}
        onChange={(e, v: any) => onValueChange(v?.id)}
        isOptionEqualToValue={(option, v) => option?.id === v?.id}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: colors.darker3,
                // backgroundColor: colors.gray100,
              },
              '.MuiOutlinedInput-notchedOutline': isRequired
                ? {
                    'border-left-color': colors.red500,
                    'border-left-width': '2px',
                  }
                : {},
            }}
            error={isInvalid ? true : false}
            label={inLineLabel ? label : undefined}
            placeholder={placeHolder ? placeHolder : label}
          />
        )}
      />
      {isInvalid && (
        <Typography pt={0.4} fontSize={14} color={colors.red500}>
          {`${t('commonText.error')}: ${helpText}`}
        </Typography>
      )}

      {helpText && !isInvalid && (
        <Typography pt={0.4} fontSize={14}>
          {helpText}
        </Typography>
      )}
    </Stack>
  );
}
