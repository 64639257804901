export const validateLoginCode = (data, errors) => {
  let isValid = true;
  const regexp = /^-?\d+(\.\d{1,2})?$/;

  if (!data.newCode1) {
    errors = { ...errors, loginCode: 'Login Code is required' };
    isValid = false;
  } else if (!regexp.test(data.newCode1)) {
    errors = {
      ...errors,
      newCode1: 'Code should be positive integer number',
    };
    isValid = false;
  } else if (data.newCode1.length !== 4) {
    errors = { ...errors, newCode1: 'Login Code Should have 4 digits' };
    isValid = false;
  } else {
    delete errors.newCode1;
  }

  if (!data.newCode2) {
    errors = { ...errors, newCode2: 'Confirm Login Code is required' };
    isValid = false;
  } else if (!regexp.test(data.newCode2)) {
    errors = {
      ...errors,
      newCode2: 'Code should be positive integer number',
    };
    isValid = false;
  } else if (data.newCode1 !== data.newCode2) {
    errors = { ...errors, newCode2: "Login Code doesn't match" };
    isValid = false;
  } else if (data.newCode2.length !== 4) {
    errors = { ...errors, newCode2: 'Login Code Should have 4 digits' };
    isValid = false;
  } else {
    delete errors.newCode2;
  }

  if (data.loginCode && !data.oldCode) {
    errors = { ...errors, oldCode: 'Old login code is required' };
    isValid = false;
  } else if (data.loginCode && data.loginCode !== data.oldCode) {
    errors = { ...errors, oldCode: 'Invalid old login code' };
    isValid = false;
  } else if (data.oldCode && !regexp.test(data.oldCode)) {
    errors = {
      ...errors,
      oldCode: 'Code should be positive integer number',
    };
    isValid = false;
  } else {
    delete errors.oldCode;
  }

  return { isValid, errs: errors };
};

export const validateChangePassword = (data, errors) => {
  let isValid = true;

  if (!data.oldPassword) {
    errors = { ...errors, oldPassword: 'Old Password is required' };
    isValid = false;
  } else if (
    (data.oldPassword && data.oldPassword.length < 6) ||
    data.oldPassword.length > 36
  ) {
    errors = { ...errors, oldPassword: 'Inavlid Password Length' };
    isValid = false;
  } else {
    delete errors.oldPassword;
  }

  if (!data.newPassword) {
    errors = { ...errors, newPassword: 'New Password is required' };
    isValid = false;
  } else if (
    (data.newPassword && data.newPassword.length < 6) ||
    data.newPassword.length > 36
  ) {
    errors = { ...errors, newPassword: 'Inavlid Password Length' };
    isValid = false;
  } else {
    delete errors.newPassword;
  }

  if (!data.newPasswordTwo) {
    errors = { ...errors, newPasswordTwo: 'Confirm Password is required' };
    isValid = false;
  } else if (
    (data.newPasswordTwo && data.newPasswordTwo.length < 6) ||
    data.newPasswordTwo.length > 36
  ) {
    errors = { ...errors, newPasswordTwo: 'Inavlid Password Length' };
    isValid = false;
  } else if (data.newPassword !== data.newPasswordTwo) {
    errors = { ...errors, newPasswordTwo: 'New Passwords does not match' };
    isValid = false;
  } else {
    delete errors.newPasswordTwo;
  }

  return { isValid, errs: errors };
};
