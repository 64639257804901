import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Link,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { NavigateNextOutlined } from '@mui/icons-material';

import MainDrawer from './drawer/MainDrawer';
import Header from './header/Header';
import { colors } from '../assets/styles/colors';
import { drawerWidth } from './drawer/DrawerStyled';

const MainLayout = ({ children }: any) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const getBreadcrums = () => {
    const location = useLocation();
    const paths = location.pathname.split('/').filter(x => x);
    // console.log('paths:', paths);

    const breadcrumbs = [
      <Link
        key={'/'}
        underline="hover"
        color="inherit"
        onClick={() => navigate('/')}>
        <HomeIcon
          sx={{
            fontSize: 20,
            pb: 0.3,
            '&:hover': { cursor: 'pointer', color: colors.theme1 },
          }}
          fontSize="inherit"
        />
      </Link>,
    ];

    const urlPaths = [];
    let stringPath = '';
    for (const path of paths) {
      stringPath += `/${path}`;
      urlPaths.push({ path, stringPath });
    }

    const onNavigation = index => {
      if (urlPaths.length - 1 !== Number(index)) {
        navigate(urlPaths[index].stringPath);
      }
    };

    for (const index in urlPaths) {
      const pathElem = (
        <Typography
          key={index}
          sx={{
            fontSize: 14,
            fontWeight: 'bold',
            color: colors.darker3,
            '&:hover': { cursor: 'pointer', color: colors.theme1 },
          }}
          onClick={() => onNavigation(index)}>
          {urlPaths[index].path.toUpperCase()}
        </Typography>
      );

      breadcrumbs.push(pathElem);
    }

    if (breadcrumbs.length > 1) {
      return (
        <Breadcrumbs
          separator={<NavigateNextOutlined fontSize="small" />}
          aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <CssBaseline />
      <Header open={open} handleDrawerToggle={toggleDrawer} />
      <MainDrawer open={open} handleDrawerToggle={toggleDrawer} />

      <Box
        bgcolor={colors.gray100}
        flex="1"
        py={2}
        pt={getBreadcrums() ? 6 : 5}
        marginLeft={open ? 32.5 : 9}
        minHeight="99vh">
        {getBreadcrums() && (
          <Box
            py={0.5}
            px={2}
            position="fixed"
            width="100%"
            zIndex={10}
            bgcolor={colors.gray100}
            // bgcolor={colors.white}
            boxShadow={1}>
            {getBreadcrums()}
          </Box>
        )}
        <Box px={2} pt={getBreadcrums() ? 6 : 3}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default MainLayout;
