import React from 'react';
import Loading from '../feedbacks/Loading';
import ErrorMessage from '../feedbacks/ErrorMessage';
import EmptyScreen from '../feedbacks/EmptyScreen';

type PropTypes = {
  isSuccess?: Boolean;
  isLoading?: Boolean;
  isFetching?: Boolean;
  isError?: Boolean;
  isEmpty?: Boolean;
  children: JSX.Element;
};

/* actual value passed to isFetching is same as isLoading,
different name just to make it different than useMutation's isloading */
const ScreenWrapper = ({
  isSuccess,
  isLoading,
  isError,
  isEmpty,
  children,
  isFetching = false,
}: PropTypes): JSX.Element => {
  // console.log('isLoading', isLoading || isFetching);
  // console.log('isError', isError);
  // console.log('isEmpty', isEmpty);

  /* HANDLE THIS WRAPPER USING HIGHER ORDER COMPONENT CONCEPT */

  if (isLoading || isFetching) {
    return <Loading />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!isLoading && isEmpty) {
    return <EmptyScreen />;
  }

  if (isSuccess) {
    return <>{children}</>;
  }

  /* DO NOT ADD VIEW, MIGHT CHANGE BEHAVIOUR IN SOME SCREEN */
  return <>{children}</>;
  // return <SafeAreaView>{children}</SafeAreaView>;
};

export default ScreenWrapper;
