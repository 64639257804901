import NepaliDate from 'nepali-date-converter';
import { monthsName } from '../../components/calendar/NepaliCalendarData';
// import { monthsName } from './../../components/calendar/NepaliCalendarData';

export const getDaysFormatted = (firstdayIndex, totalDays) => {
  var dummyMonth: any = Array(6)
    .fill(0)
    .map(() => Array(7).fill(0));

  const daysFormatted = [];
  let counter = 0;
  for (const [index, row] of dummyMonth.entries()) {
    const newRow: any = [];
    for (const [ind] of row.entries()) {
      if (index === 0) {
        const previous: number = ind - 1 < 0 ? 0 : newRow[ind - 1];

        if (ind >= firstdayIndex) {
          newRow.push(previous + 1);
          counter += 1;
        } else {
          newRow.push(0);
        }
      } else {
        let forZero = daysFormatted[index - 1][6];
        const previous: number = ind === 0 ? forZero : newRow[ind - 1];

        if (counter >= totalDays) {
          newRow.push(0);
        } else {
          newRow.push(previous + 1);
          counter += 1;
        }
      }
    }
    daysFormatted.push(newRow);
  }
  // console.log('daysFormatted', daysFormatted);

  return daysFormatted;
};

export const getNepaliDate = (value: Date) => {
  const bsDate = new NepaliDate(new Date(value));
  // const nepaliDate = bsDate.format('YYYY-MM-DD');

  const textMonth = monthsName[bsDate.getMonth()];
  const finalBSDate = `${textMonth} ${bsDate.getDate()}, ${bsDate.getYear()}`;
  return finalBSDate;
};

export const getThisNepaliMonthsFirstDay = () => {
  const bsDate = new NepaliDate(new Date());

  const firstDay = new NepaliDate(bsDate.getYear(), bsDate.getMonth(), 1);
  return firstDay.toJsDate();
};

export const testFn = () => {};
