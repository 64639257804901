import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import {
  Box,
  CardContent,
  Chip,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getAccounts } from '../../../controllers/settings/accountControllers';
import InputForm from '../../../components/forms/InputForm';
import Button, { TextButton } from '../../../components/buttons/Button';
import ScreenWrapper from '../../../components/wrappers/ScreenWrapper';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellBody,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';

const AccountListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');

  const accFn = () => getAccounts({ withOpeningBalance: true });
  const { isLoading, isError, data = [] } = useQuery(['getAccounts'], accFn);

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = data.filter((account: any) => {
      const { name, code, accountType } = account;

      const hasName = name?.toLowerCase().includes(text);
      const hasCode = String(code)?.toLowerCase().includes(text);
      const hasAccType = String(accountType.code)?.toLowerCase().includes(text);

      if (hasName || hasCode || hasAccType) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let accountList = data;
  if (searchText) {
    accountList = filteredItems;
  }

  return (
    <Card sx={{ minWidth: 275, paddingX: 4, flex: 1 }}>
      <CardContent>
        <Stack pb={2} direction={'row'} alignItems={'center'} spacing={2}>
          <Box flex={1}>
            <Grid
              container
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              alignItems={'center'}>
              <Grid xs={8}>
                <Typography
                  sx={{ fontSize: 20 }}
                  color="text.primary"
                  gutterBottom>
                  {t('settingStack.accountList')} ({accountList.length})
                </Typography>
              </Grid>
              <Grid xs={4}>
                <InputForm
                  size="small"
                  inLineLabel={true}
                  label={'search account by name, code or type...'}
                  onChangeText={(v: string) => onSearch(String(v))}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Button
              title="Add New Account"
              onClick={() => navigate('/settings/accounts/create')}
            />
          </Box>
        </Stack>

        <Divider />

        <ScreenWrapper {...{ isLoading, isError, isEmpty: !data.length }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader sx={{ maxWidth: 20 }}>SN</TbCellHeader>
                  <TbCellHeader>Account Code</TbCellHeader>
                  <TbCellHeader>Account Name</TbCellHeader>
                  <TbCellHeader>Account Type</TbCellHeader>
                  <TbCellHeader align="right">Status</TbCellHeader>
                  <TbCellHeader />
                </TbRowHeaderStyled>
              </TableHead>
              <TableBody>
                {accountList.map((row, index) => (
                  <TableRowStyled key={row.id}>
                    <TbCellBody sx={{ maxWidth: 20 }}>{index + 1}</TbCellBody>
                    <TbCellBody>{row.code}</TbCellBody>
                    <TbCellBody>{row.name}</TbCellBody>
                    <TbCellBody>{row.accountType.name}</TbCellBody>
                    <TbCellBody align="right">
                      <Chip
                        size="small"
                        label={row.isActive ? 'ACTIVE' : 'IN-ACTIVE'}
                        color={row.isActive ? 'success' : 'error'}
                        variant="outlined"
                      />
                    </TbCellBody>
                    <TbCellBody align="right">
                      <TextButton
                        title="update"
                        size="sm"
                        onClick={() =>
                          navigate('/settings/accounts/update', { state: row })
                        }
                      />
                    </TbCellBody>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScreenWrapper>
      </CardContent>
    </Card>
  );
};

export default AccountListPage;
