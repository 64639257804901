import React, { useState } from 'react';
import { Button as MUIButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { colors } from '../../assets/styles/colors';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';

const getSize = size => {
  if (size === 'sm') {
    return 'small';
  } else if (size === 'md') {
    return 'medium';
  } else {
    return 'large';
  }
};

const Button = ({
  title,
  onClick,
  size,
  disabled = false, // need to fix the color issue when disabled, so disable not in implementation now
  loading = false,
  color = colors.theme1,
  variant = 'contained', // cannot work with outlined or text due to bg color
}: any): JSX.Element => (
  <LoadingButton
    disabled={disabled}
    loading={loading}
    variant={variant}
    size={getSize(size)}
    onClick={onClick}
    style={{ backgroundColor: !loading ? color : colors.bluex100 }}>
    <span>{title}</span>
  </LoadingButton>
);

export default Button;

export const IconButton = ({
  title,
  onClick,
  size,
  icon,
}: any): JSX.Element => (
  <LoadingButton
    variant="contained"
    size={getSize(size)}
    onClick={onClick}
    // startIcon={<Add />}
    style={{ backgroundColor: colors.theme1 }}>
    {title}
  </LoadingButton>
);

export const OutlinedButton = ({
  title,
  onClick,
  size,
  color = colors.theme1,
}: any): JSX.Element => (
  <MUIButton
    sx={{
      // paddingTop: 0.4,
      color: color,
      fontWeight: 'bold',
      borderColor: color,
      backgroundColor: colors.info50,
    }}
    variant={'outlined'}
    size={getSize(size)}
    onClick={onClick}>
    {title}
  </MUIButton>
);

export const TextButton = ({
  title,
  onClick,
  size,
  color = colors.theme1,
}: any): JSX.Element => (
  <MUIButton
    sx={{
      color: color,
      fontWeight: 'bold',
    }}
    size={getSize(size)}
    onClick={onClick}>
    {title}
  </MUIButton>
);
