import React from 'react';

import AuthRequired from './AuthRequired';
import SubscriptionPlans from '../pages/system/subscription/SubscriptionPlans';
import LearnToUsePage from '../pages/system/support/LearnToUse';
// import BillingInformation from '../pages/system/billing/BillingInfo';

const systemRoutes = [
  // {
  //   path: '/billing',
  //   element: (
  //     <AuthRequired>
  //       <BillingInformation />
  //     </AuthRequired>
  //   ),
  // },
  {
    path: '/subscription',
    element: (
      <AuthRequired>
        <SubscriptionPlans />
      </AuthRequired>
    ),
  },
  {
    path: '/learn-to-use',
    element: (
      <AuthRequired>
        <LearnToUsePage />
      </AuthRequired>
    ),
  },
];

export default systemRoutes;
