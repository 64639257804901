import React, { useEffect, useState } from 'react';
import { CardContent, IconButton, Button as MUIButton } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import NepaliDate from 'nepali-date-converter';
// import Icon from 'react-native-vector-icons/FontAwesome5';

import {
  adMonthsList,
  calendarData,
  monthsList,
  weekDays,
  yearsList,
} from './NepaliCalendarData';
import { colors } from '../../assets/styles/colors';
import { getDaysFormatted } from '../../shared/utils/nepaliDate';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import SelectPicker from '../forms/SelectPicker';
import Button from '../buttons/Button';
// import { Button } from '../buttons/Button';
// import { LinkButton } from '../buttons/LinkButton';
// import ModalPicker from '../modalPicker/ModalPicker';

const NepaliDatePicker = ({
  onModalClose,
  onDateChange,
  defaultValue,
  minDate,
}: any): JSX.Element => {
  const bsDateToday = new NepaliDate();
  const bsYear = bsDateToday.getYear();
  const bsMonth = bsDateToday.getMonth() + 1;
  const bsDay = bsDateToday.getDate();

  const [year, setYear] = useState(bsYear);
  const [month, setMonth] = useState(bsMonth);
  const [day, setDay] = useState<any>(bsDay);
  const [adInfo, setADInfo] = useState<any>({});

  const [formattedDays, setFormattedDays] = useState<any>([[]]);

  const firstday = new NepaliDate(year, month - 1, 1);
  const firstdayIndex = firstday.getDay();

  useEffect(() => {
    const bsDateTodayDefault = new NepaliDate(defaultValue || new Date());
    // console.log('bsDateTodayDefault', bsDateTodayDefault);

    const bsYearDefault = bsDateTodayDefault.getYear();
    const bsMonthDefault = bsDateTodayDefault.getMonth() + 1;
    const bsDayDefault = bsDateTodayDefault.getDate();

    setYear(bsYearDefault);
    setMonth(bsMonthDefault);
    setDay(bsDayDefault);
  }, [defaultValue]);

  useEffect(() => {
    const totalDays = calendarData[year][month - 1];
    // console.log('firstday...', firstday);
    // console.log('firstdayIndex...', firstdayIndex);

    const adDate = firstday.toJsDate();
    const adMonth = adDate.getMonth();
    const adYear = adDate.getFullYear();

    const nextMonth = adMonth + 2 <= 12 ? adMonth + 2 : 1;
    const adMonth1 = adMonthsList.find(x => x.id === adMonth + 1)?.name;
    const adMonth2 = adMonthsList.find(x => x.id === nextMonth)?.name;
    setADInfo({ adYear, adMonth1, adMonth2 });

    const formatted = getDaysFormatted(firstdayIndex, totalDays);
    setFormattedDays(formatted);
  }, [year, month, day, firstdayIndex]);

  // console.log('year...', year);
  // console.log('month...', month);
  // console.log('day...', day);
  // console.log('adInfo...', adInfo);
  // console.log('-----------');

  const onDateSelect = () => {
    if (day && month && year) {
      const bsDate = new NepaliDate(year, month - 1, day);
      onDateChange(bsDate.toJsDate());
    }
  };

  const isBelowMinDate = (dayValue: number) => {
    const npDate = new NepaliDate(year, month - 1, dayValue);
    const jsNpDate = npDate.toJsDate();

    if (jsNpDate < minDate) {
      return true;
    }
    return false;
  };

  const isToday = (dayValue: number) => {
    if (bsYear === year && bsMonth === month && bsDay === dayValue) {
      return true;
    }
    return false;
  };

  const isActive = (item: number) => {
    if (day === item) {
      return true;
    }
    return false;
  };

  const hasDayIn6thWeek = formattedDays[5]?.some(x => x);

  const getBGColor = (selDay, index, weekIndex) => {
    if (isActive(selDay)) {
      return colors.theme1;
    }
    if (isToday(selDay)) {
      return colors.theme2;
    }
    if (index === 6) {
      if (!hasDayIn6thWeek && weekIndex === 5) {
        return colors.white;
      }
      return colors.red100;
    }
  };

  const onLeftChevronClick = () => {
    if (month === 1) {
      if (year > 2070) {
        setYear(year - 1);
        setMonth(12);
      }
    } else {
      setMonth(month - 1);
    }
    setDay('');
  };

  const onRightChevronClick = () => {
    if (month === 12) {
      if (year < 2090) {
        setYear(year + 1);
        setMonth(1);
      }
    } else {
      setMonth(month + 1);
    }
    setDay('');
  };

  const getADDate = (bsDayValue: number) => {
    if (bsDayValue) {
      let date1 = new NepaliDate(year, month - 1, bsDayValue);
      const adDate = date1.toJsDate();
      return adDate.getDate();
    }
  };
  // let date1 = new NepaliDate(2080, month, bsDay);
  // const adDate = date1.toJsDate();
  // // console.log('adDate...', adDate);

  // console.log('formattedDays...', formattedDays);

  const getDayColor = (d: any, bs = true) => {
    if (isBelowMinDate(d)) {
      return colors.gray400;
    }
    if (isActive(d) || isToday(d)) {
      return colors.white;
    }
    return bs ? colors.darker : colors.blueGrey500;
  };

  return (
    <Box sx={{ minWidth: 420, padding: 1.2 }}>
      <Stack>
        <Stack
          flexDirection={'row'}
          // bgcolor={'red'}
          gap={1}
          alignItems={'center'}>
          <Stack flex={3}>
            <SelectPicker
              size="small"
              label={'Month'}
              disableClearable={true}
              inLineLabel={true}
              selectItems={monthsList}
              selectedValue={month}
              onValueChange={v => {
                setMonth(Number(v));
                setDay('');
              }}
            />
          </Stack>
          <Stack flex={3}>
            <SelectPicker
              size="small"
              label={'Year'}
              disableClearable={true}
              inLineLabel={true}
              selectItems={yearsList}
              selectedValue={year}
              onValueChange={v => {
                setYear(Number(v));
                setDay('');
              }}
            />
          </Stack>

          <Stack flex={1}>
            <IconButton onClick={onLeftChevronClick}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Stack>
          <Stack flex={1}>
            <IconButton onClick={onRightChevronClick}>
              <ChevronRightIcon />
            </IconButton>
          </Stack>
        </Stack>

        <Typography p={1} sx={{ fontSize: 14, color: colors.blueGrey700 }}>
          {`${adInfo.adMonth1}/${adInfo.adMonth2} - ${adInfo.adYear}`}
        </Typography>

        <Stack direction="row" gap={2}>
          {weekDays.map((dayx: any, index) => (
            <Typography key={index} flex={1} align="center">
              {dayx}
            </Typography>
          ))}
        </Stack>
      </Stack>

      <Box pt={0.4} justifyContent={'space-between'}>
        {formattedDays.map((row, index) => (
          <Stack
            key={index + 1}
            direction="row"
            borderBottom={1}
            borderColor={index === 5 ? colors.white : colors.gray200}>
            {row.map((day1: any, indx) => (
              <Box
                key={indx}
                sx={{
                  '&:hover': { cursor: 'pointer' },
                  backgroundColor: getBGColor(day1, indx, index),
                }}
                flex={1}
                onClick={() => {
                  if (day1 && !isBelowMinDate(day1)) {
                    setDay(day1);
                  }
                }}>
                <Typography
                  sx={{
                    paddingTop: 1.2,
                    fontSize: 17,
                    textAlign: 'center',
                    color: getDayColor(day1),
                    fontWeight:
                      isActive(day1) || isToday(day1) ? 'bold' : '400',
                  }}>
                  {day1 ? day1 : null}
                </Typography>

                <Typography
                  sx={{
                    paddingRight: 0.8,
                    paddingBottom: 0.6,
                    fontSize: 12,
                    textAlign: 'right',
                    color: getDayColor(day1, false),
                    fontWeight:
                      isActive(day1) || isToday(day1) ? 'bold' : '400',
                  }}>
                  {getADDate(day1)}
                </Typography>
              </Box>
            ))}
          </Stack>
        ))}
      </Box>

      <Stack
        direction={'row'}
        justifyContent={'flex-end'}
        gap={3}
        mt={hasDayIn6thWeek ? 2 : 0}>
        <MUIButton
          color="primary"
          variant="text"
          size="small"
          onClick={onModalClose}>
          Close
        </MUIButton>

        <MUIButton
          color="primary"
          variant="contained"
          size="small"
          disabled={year && month && day ? false : true}
          onClick={() => {
            onDateSelect();
            onModalClose();
          }}>
          Select
        </MUIButton>
      </Stack>
    </Box>
  );
};

export default NepaliDatePicker;
