import { store } from '../../store/store';
import {
  salaryPaymentUrl,
  paymentOnCashUrl,
  salesOnCashUrl,
} from '../endPoints';

const httpClient = () => {
  return store.getState().http.client;
};

export const paymentOnCash = async (data: any) => {
  const path = paymentOnCashUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const salesOnCash = async (data: any) => {
  const path = salesOnCashUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const salaryPayment = async (data: any) => {
  const path = salaryPaymentUrl();
  const response = await httpClient().post<any>(path, data);
  return response.data;
};
