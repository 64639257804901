export const registrationInputFields1 = [
  { fieldName: 'firstName', required: true },
  { fieldName: 'lastName', required: true },
];

export const registrationInputFields2 = [
  { fieldName: 'phoneNumber', required: true },
  { fieldName: 'email', required: true },
];

export const registrationInputFields3 = [
  { fieldName: 'companyName', required: true },
];

export const passwordResetFields = [
  { fieldName: 'password', required: true },
  { fieldName: 'password2', required: true },
];
