import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function VatExpense() {
  return (
    <Box maxWidth="sm" bgcolor={'red'}>
      <Typography>VatExpense page</Typography>
    </Box>
  );
}

export default VatExpense;
