import React from 'react';
import { Typography } from '@mui/material';

import { colors } from '../../../../assets/styles/colors';

const TransactionError = ({ error, align = 'right' }: any) => (
  <Typography align={align} sx={{ color: colors.theme2 }}>
    {error}
  </Typography>
);

export default TransactionError;
