import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Button,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { DashboardCardType } from '../../constants/defaultCodes/transactionCodes';
import { useSelector } from '../../store/reduxHooks';
import PeriodSelect, {
  LAST_7_DAYS,
  _7DaysAgo,
} from '../../components/forms/PeriodSelect';
import { getInvoicesToReceive } from '../../controllers/transactions/invoiceControllers';
import { getBillsToPay } from '../../controllers/transactions/billControllers';
import {
  getDashboardBankLoans,
  getDashboardBorrowedLoans,
  getDashboardLentLoans,
} from '../../controllers/transactions/loanTransactionControllers';
import {
  dateFileName,
  getSettingBasedDate,
} from '../../shared/utils/getSettingBasedDate';
import { colors } from '../../assets/styles/colors';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import InputForm from '../../components/forms/InputForm';
import { formatAmount } from '../../shared/utils/locale';
import OpenignBalanceAsOf from '../reports/components/OpeningBalnaceAsOf';
import PartnerTxnFilterModal from './components/PartnerTxnFilterModal';
import EmptyTable from '../../components/feedbacks/EmptyTable';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellBody,
  TbCellHeader,
  TbCellSummary,
} from '../../components/styled/TableCellStyled';
import DateRangeCard from '../../components/datePickers/DateRangeCard';
import { partnerStatDashTemplate } from '../../templates/partnerStatementDashTemplate';
import { createPDF } from '../../shared/utils/createPDF';
import { partnerNavigateTo } from '../../shared/utils/partnerUtils';

const { receivable, payable } = DashboardCardType;

const getEnableStatus = (state: any) => {
  const { code, partner, isNCLiability } = state;
  const isLoanPartner = partner.isLoanPartner;

  const isBankLoans = code === payable && isNCLiability && isLoanPartner;
  const isLentLoanItems = code === receivable && isLoanPartner;
  const isBorrowLoanItems = code === payable && isLoanPartner && !isBankLoans;

  let invoice = false;
  let bill = false;
  let lent = false;
  let borrow = false;
  let loan = false;
  if (code === receivable && partner.isCustomer) {
    invoice = true;
  } else if (code === payable && partner.isVendor) {
    bill = true;
  } else if (isLentLoanItems) {
    lent = true;
  } else if (isBorrowLoanItems) {
    borrow = true;
  } else if (isBankLoans) {
    loan = true;
  }
  return { invoice, bill, lent, borrow, loan };
};

function PartnerTransactions() {
  const navigate = useNavigate();
  const { state } = useLocation();
  if (!state) {
    navigate('/receivables');
    return null;
  }
  const { code, partner, isNCLiability } = state;

  const isLoanPartner = partner.isLoanPartner;
  const customer = partner.id;
  const vendor = partner.id;

  const queryClient = useQueryClient();
  const { user } = useSelector(s => s.auth.authUser);

  const init: any = { fromDate: _7DaysAgo, toDate: new Date() };
  const [filters, setFilters] = useState<any>({ ...init, vendor, customer });
  const [selectedPeriod, setSelectedPeriod] = useState<string>(LAST_7_DAYS);
  const [pdfSource, setPdfSource] = useState<any>({ uri: '' });

  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [showActionModal, setShowActionModal] = useState(false);
  const [applied, setApplied] = useState(false);

  const enableInvoice = { enabled: getEnableStatus(state).invoice };
  const func1 = () => getInvoicesToReceive(filters);
  const invoices = useQuery(['invoicesToReceive'], func1, enableInvoice);

  const enableBill = { enabled: getEnableStatus(state).bill };
  const func2 = () => getBillsToPay(filters);
  const bills = useQuery(['payBills'], func2, enableBill);

  const enableLent = { enabled: getEnableStatus(state).lent };
  const func3 = () => getDashboardLentLoans({ loanPartner: partner.id });
  const lentLoans = useQuery(['getDashLentLoans'], func3, enableLent);

  const enableBorrow = { enabled: getEnableStatus(state).borrow };
  const func4 = () => getDashboardBorrowedLoans({ loanPartner: partner.id });
  const borrowedLoans = useQuery(['getDashBorrowedLoans'], func4, enableBorrow);

  const enableLoan = { enabled: getEnableStatus(state).loan };
  const func5 = () => getDashboardBankLoans({ loanPartner: partner.id });
  const bankLoans = useQuery(['getDashBankLoans'], func5, enableLoan);

  const isBankLoans = code === payable && isNCLiability && isLoanPartner;
  const isLentLoanItems = code === receivable && isLoanPartner;
  const isBorrowLoanItems = code === payable && isLoanPartner && !isBankLoans;
  const isLoanItems = isLentLoanItems || isBorrowLoanItems || isBankLoans;

  const fetchTransactions = (afterUpdate = false) => {
    setApplied(true);
    if (afterUpdate) {
      queryClient.invalidateQueries(['partyDashledgers']);
    }

    if (code === receivable && partner.isCustomer) {
      invoices.refetch();
    } else if (code === payable && partner.isVendor) {
      bills.refetch();
    } else if (isLentLoanItems) {
      lentLoans.refetch();
    } else if (isBorrowLoanItems) {
      borrowedLoans.refetch();
    } else if (isBankLoans) {
      bankLoans.refetch();
    }
    setShowActionModal(false);
  };

  const onDateChange = (dateRange: any, period: string) => {
    const updatedData = { ...filters, ...dateRange };
    setFilters(updatedData);
    setSelectedPeriod(period);
  };

  // console.log('isNCLiability', isNCLiability);
  // console.log('filters', filters);
  // console.log('bankLoans', bankLoans.data);

  const getData = () => {
    const dataInv = invoices.data?.customerInvoices || [];
    const dataBill = bills.data?.vendorBills || [];
    const dataLents: any = lentLoans.data || {};
    const dataBorrows: any = borrowedLoans.data || {};
    const dataLoans: any = bankLoans.data || {};
    if (code === receivable && partner.isCustomer && dataInv.length) {
      const data = dataInv[0]; // 0 INDEX BECAUSE FETCHING FOR SINGLE CUSTOMER
      return {
        opening: data.customerOpeningBalance,
        closing: data.customerClosingBalance,
        statements: data.invoiceItems,
        debitSum: data.debitSum,
        creditSum: data.creditSum,
        partner: data.customer,
        length: data.invoiceItems.length > 0,
      };
    } else if (code === payable && partner.isVendor && dataBill.length) {
      const data = dataBill[0]; // 0 INDEX BECAUSE FETCHING FOR SINGLE VENDOR
      return {
        opening: data.vendorOpeningBalance,
        closing: data.vendorClosingBalance,
        statements: data.billItems,
        debitSum: data.debitSum,
        creditSum: data.creditSum,
        partner: data.vendor,
        length: data.billItems.length > 0,
      };
    } else if (isLentLoanItems && dataLents.lentLoans) {
      return {
        // opening: data.vendorOpeningBalance,
        debitSum: dataLents.debitSum,
        creditSum: dataLents.creditSum,
        closing: dataLents.closingBalance,
        statements: dataLents.lentLoans,
        partner: dataLents.loanPartner,
        length: dataLents.lentLoans?.length > 0,
      };
    } else if (isBorrowLoanItems && dataBorrows.borrowedLoans) {
      return {
        // opening: data.vendorOpeningBalance,
        debitSum: dataBorrows.debitSum,
        creditSum: dataBorrows.creditSum,
        closing: dataBorrows.closingBalance,
        statements: dataBorrows.borrowedLoans,
        partner: dataBorrows.loanPartner,
        length: dataBorrows.borrowedLoans?.length > 0,
      };
    } else if (isBankLoans && dataLoans.bankLoans) {
      return {
        // opening: data.vendorOpeningBalance,
        debitSum: dataLoans.debitSum,
        creditSum: dataLoans.creditSum,
        closing: dataLoans.closingBalance,
        statements: dataLoans.bankLoans,
        partner: dataLoans.loanPartner,
        length: dataLoans.bankLoans?.length > 0,
      };
    }
    return {};
  };

  const onSearch = (inputText: string) => {
    const text = inputText.toLowerCase();
    setSearchText(inputText);

    const filtered = getData()?.statements.filter((txn: any) => {
      const { aging, normalAmount, voucher } = txn;
      const voucherType = voucher?.voucherType.name;
      const referenceId = voucher?.referenceId;

      const hasType = voucherType?.toLowerCase().includes(text);
      const hasRefNo = String(referenceId)?.toLowerCase().includes(text);
      const hasAge = String(aging)?.toLowerCase().includes(text);
      const hasAmount = String(normalAmount)?.toLowerCase().includes(text);

      if (hasType || hasRefNo || hasAge || hasAmount) {
        return true;
      }
    });
    setFilteredItems(filtered);
  };

  let statements = getData()?.statements || [];
  if (searchText) {
    statements = filteredItems;
  }

  const getTheme: any = () => {
    // const closingText = styles.closingText;
    const closingText: any = {};
    if (code === 'receivable') {
      closingText.color = colors.green700;
      return closingText;
    } else if (code === 'payable') {
      closingText.color = colors.theme2;
      return closingText;
    }
  };

  // console.log('!getData().closing', !getData().closing);
  // console.log('data:', JSON.stringify(getData()));
  // console.log('statements:', statements);

  const onCreatePDF = async () => {
    const now = new Date();
    const createdDate = getSettingBasedDate(now, user);
    const createdTime = now.toLocaleTimeString();

    const dataX = {
      user,
      code,
      partnerStatments: getData(),
      fromDate: getSettingBasedDate(filters.fromDate, user),
      toDate: getSettingBasedDate(filters.toDate, user),
      createdAt: `${createdDate} ${createdTime}`,
    };

    const template = partnerStatDashTemplate(dataX);

    const fileName = `party_statement_${dateFileName(user)}`.toLowerCase();
    const source = await createPDF(template, fileName);
    setPdfSource(source);
  };

  const isFetching = invoices.isFetching || bills.isFetching;
  const isError = invoices.isError || bills.isError;

  // console.log('testestt:', getData().closing);

  return (
    <Card sx={{ paddingX: 4 }}>
      <CardContent sx={{ paddingY: 1 }}>
        <Stack pb={1} flexDirection={'row'} alignItems={'center'} gap={3}>
          <Stack>
            <IconButton
              onClick={() => navigate(partnerNavigateTo(code))}
              sx={{ backgroundColor: colors.info100, padding: 0.6 }}>
              <KeyboardArrowLeftIcon sx={{ fontSize: 32 }} />
            </IconButton>
          </Stack>

          <Stack flex={2}>
            <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
              <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>
                {partner.name}
              </Typography>

              {partner.phoneNumber && (
                <>
                  <Typography sx={{ fontSize: 18 }}>|</Typography>
                  <Typography sx={{ fontSize: 18 }}>
                    {partner.phoneNumber}
                  </Typography>
                </>
              )}
            </Stack>
            <Stack mt={0} alignItems={'flex-start'}>
              <DateRangeCard {...{ filters }} />
            </Stack>
          </Stack>

          <Stack pb={0.6} flex={1}>
            <InputForm
              size="small"
              inLineLabel={true}
              label={'Search by type, ref no, age or amount...'}
              onChangeText={(v: string) => onSearch(String(v))}
            />
          </Stack>

          <Stack direction="row" justifyContent={'flex-end'}>
            <Button
              variant="contained"
              onClick={() => {
                setShowActionModal(true);
                setApplied(false);
              }}>
              Other Filters
            </Button>
          </Stack>
          <IconButton
            sx={{ backgroundColor: colors.gray300 }}
            onClick={onCreatePDF}>
            <PictureAsPdfIcon sx={{ color: colors.theme1, fontSize: 28 }} />
          </IconButton>
        </Stack>

        <Divider />

        <ScreenWrapper
          isLoading={isFetching}
          isError={isError}
          isEmpty={!getData().closing}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TbRowHeaderStyled>
                  <TbCellHeader>SN</TbCellHeader>
                  <TbCellHeader>Txn Date</TbCellHeader>
                  <TbCellHeader>Txn Type</TbCellHeader>
                  <TbCellHeader>Ref No.</TbCellHeader>
                  <TbCellHeader>Age</TbCellHeader>
                  <TbCellHeader>Voucher No.</TbCellHeader>
                  <TbCellHeader align="right">Txn Amount</TbCellHeader>
                  <TbCellHeader align="right">Balance</TbCellHeader>
                  {/* <TbCellHeader /> */}
                </TbRowHeaderStyled>
              </TableHead>
              {!isLoanItems && (
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={8} sx={{ paddingY: 1 }}>
                      <OpenignBalanceAsOf
                        fromDate={filters.fromDate}
                        openingBalance={formatAmount(getData().opening)}
                      />
                    </TableCell>
                    {/* <TableCell /> */}
                  </TableRow>
                </TableHead>
              )}
              {getData().length ? (
                <TableBody>
                  {statements.map((row, inx) => (
                    <TableRowStyled key={inx}>
                      <TbCellBody>{inx + 1}</TbCellBody>
                      <TbCellBody>
                        {getSettingBasedDate(row.voucher.voucherDate, user)}
                      </TbCellBody>
                      <TbCellBody>
                        <Chip
                          size="small"
                          label={row.voucher.voucherType.code
                            .split('_')
                            .join(' ')}
                          color="default"
                        />
                      </TbCellBody>

                      <TbCellBody>
                        {row.voucher.referenceId || 'N/A'}{' '}
                      </TbCellBody>
                      <TbCellBody>
                        <Chip
                          size="small"
                          variant="outlined"
                          label={`${row.aging} ${
                            row.aging <= 1 ? 'day' : 'days'
                          }`}
                          color={row.aging <= 30 ? 'primary' : 'error'}
                          sx={{
                            backgroundColor:
                              row.aging <= 30 ? colors.info100 : colors.red100,
                          }}
                        />
                      </TbCellBody>
                      <TbCellBody>{row.voucher.voucherNumber}</TbCellBody>
                      <TbCellAmount sx={{ color: colors.darker3 }}>
                        {formatAmount(row.normalAmount)}
                      </TbCellAmount>
                      <TbCellAmount>{formatAmount(row.balance)}</TbCellAmount>
                      {/* <TbCellAmount>
                        <Tooltip title="Action Menu">
                          <IconButton
                            // ref={anchorRef}
                            id="action-button"
                            // onClick={handleMenuToggle}
                            size="small"
                            // aria-controls={openMenu ? 'action-menu' : undefined}
                            aria-haspopup="true"
                            // aria-expanded={openMenu ? 'true' : undefined}
                          >
                            <MoreVertRoundedIcon />
                          </IconButton>
                        </Tooltip>
                      </TbCellAmount> */}
                    </TableRowStyled>
                  ))}
                </TableBody>
              ) : (
                <EmptyTable message="No Transactions in Given Period !!" />
              )}

              <TableHead sx={{ backgroundColor: colors.gray200 }}>
                <TableRow>
                  <TbCellAmount colSpan={6} sx={{ fontWeight: 'bold' }}>
                    Closing Balance
                  </TbCellAmount>
                  <TbCellSummary>
                    {formatAmount(getData().closing)}
                  </TbCellSummary>
                  <TableCell />
                  {/* <TableCell /> */}
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </ScreenWrapper>

        {showActionModal && (
          <PartnerTxnFilterModal
            isLoading={false}
            onSubmit={fetchTransactions}
            filters={filters}
            selectedPeriod={selectedPeriod}
            onDateChange={onDateChange}
            modalVisible={showActionModal}
            partnerCreatedAt={partner.createdAt}
            onModalClose={() => setShowActionModal(false)}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default PartnerTransactions;
