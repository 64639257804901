import i18next from 'i18next';
import { emailIsValid, phoneNoIsValid } from '../common/commonValidations';

export const validateRegistration = (data: any, errors: any) => {
  let isValid = true;

  if (!data.firstName) {
    errors = {
      ...errors,
      firstName: i18next.t('userRegistration.validation.firstNameRequired'),
    };
    isValid = false;
  } else if (data.firstName.length > 64) {
    errors = {
      ...errors,
      firstName: i18next.t('userRegistration.validation.firstNameLength'),
    };
    isValid = false;
  } else {
    delete errors.firstName;
  }

  if (!data.lastName) {
    errors = {
      ...errors,
      lastName: i18next.t('userRegistration.validation.lastNameRequired'),
    };
    isValid = false;
  } else if (data.lastName.length > 64) {
    errors = {
      ...errors,
      lastName: i18next.t('userRegistration.validation.lastNameRequired'),
    };
    isValid = false;
  } else {
    delete errors.lastName;
  }

  if (data.companyName === undefined) {
    errors = {
      ...errors,
      companyName: i18next.t('userRegistration.validation.companyNameRequired'),
    };
    isValid = false;
  } else if (data.companyName.length < 3 || data.companyName.length > 128) {
    errors = {
      ...errors,
      companyName: i18next.t('userRegistration.validation.companyNameLength'),
    };
    isValid = false;
  } else {
    delete errors.companyName;
  }

  if (!data.countryCode) {
    errors = {
      ...errors,
      countryCode: i18next.t('userRegistration.validation.countryCodeReq'),
    };
    isValid = false;
  } else {
    delete errors.countryCode;
  }

  if (!data.locale) {
    errors = {
      ...errors,
      locale: i18next.t('userRegistration.validation.localeReq'),
    };
    isValid = false;
  } else {
    delete errors.locale;
  }

  if (!data.phoneNumber) {
    errors = {
      ...errors,
      phoneNumber: i18next.t('userRegistration.validation.phoneRequired'),
    };
    isValid = false;
  } else if (!phoneNoIsValid(data.phoneNumber)) {
    errors = {
      ...errors,
      phoneNumber: i18next.t('userLogin.validation.validPhoneNo'),
    };
    isValid = false;
  } else {
    delete errors.phoneNumber;
  }

  if (data.email === undefined) {
    errors = {
      ...errors,
      email: i18next.t('userRegistration.validation.emailRequired'),
    };
    isValid = false;
  } else if (!emailIsValid(data.email)) {
    errors = {
      ...errors,
      email: i18next.t('userRegistration.validation.validEmail'),
    };
    isValid = false;
  } else {
    delete errors.email;
  }

  if (data.password === undefined) {
    errors = {
      ...errors,
      password: i18next.t('userRegistration.validation.passwordRequired'),
    };
    isValid = false;
  } else if (data.password.length < 8 || data.password.length > 36) {
    errors = {
      ...errors,
      password: i18next.t('userRegistration.validation.passwordLength'),
    };
    isValid = false;
  } else if (data.password.indexOf(' ') >= 0) {
    errors = {
      ...errors,
      password: i18next.t('userLogin.validation.invalidPassword'),
    };
    isValid = false;
  } else {
    delete errors.password;
  }

  if (data.password2 === undefined) {
    errors = {
      ...errors,
      password2: i18next.t('userRegistration.validation.passwordRequired'),
    };
    isValid = false;
  } else if (data.password2.length < 8 || data.password2.length > 36) {
    errors = {
      ...errors,
      password2: i18next.t('userRegistration.validation.passwordLength'),
    };
    isValid = false;
  } else if (data.password !== data.password2) {
    errors = {
      ...errors,
      password2: i18next.t('userRegistration.validation.passwordDoesnotMatch'),
    };
    isValid = false;
  } else if (data.password2.indexOf(' ') >= 0) {
    errors = {
      ...errors,
      password2: i18next.t('userLogin.validation.invalidPassword'),
    };
    isValid = false;
  } else {
    delete errors.password2;
  }

  return { isValid, errors };
};

export const validatePasswordResetData = (data: any, errors: any) => {
  let isValid = true;

  if (data.password === undefined) {
    errors = {
      ...errors,
      password: i18next.t('userRegistration.validation.passwordRequired'),
    };
    isValid = false;
  } else if (data.password.length < 8 || data.password.length > 36) {
    errors = {
      ...errors,
      password: i18next.t('userRegistration.validation.passwordLength'),
    };
    isValid = false;
  } else {
    delete errors.password;
  }

  if (data.password2 === undefined) {
    errors = {
      ...errors,
      password2: i18next.t('userRegistration.validation.passwordRequired'),
    };
    isValid = false;
  } else if (data.password2.length < 8 || data.password2.length > 36) {
    errors = {
      ...errors,
      password2: i18next.t('userRegistration.validation.passwordLength'),
    };
    isValid = false;
  } else if (data.password !== data.password2) {
    errors = {
      ...errors,
      password2: i18next.t('userRegistration.validation.passwordDoesnotMatch'),
    };
    isValid = false;
  } else {
    delete errors.password2;
  }

  return { isValid, errors };
};
