import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSnackbar } from 'notistack';

import { validateLoginData } from '../../validations/auth/validateLogin';
import { useTranslation } from 'react-i18next';
import InputForm from '../../components/forms/InputForm';
import { useDispatch, useSelector } from '../../store/reduxHooks';
import {
  confirmVerificationCode,
  refreshUserDetails,
  signInWithPhoneNumber,
  userLogin,
} from '../../store/authSlice';
import {
  OTPUsage,
  RequestFrom,
} from '../../constants/defaultCodes/authAndAccounts';
import { updatePhoneVerification } from '../../controllers/settings/accountControllers';
import { NavLink, useNavigate } from 'react-router-dom';
import { Divider, IconButton, InputAdornment, Stack } from '@mui/material';
import { colors } from '../../assets/styles/colors';
import { TextButton } from '../../components/buttons/Button';
import OTPCodeInput from './components/OTPCodeInput';
import { errorToast } from '../../constants/snackbar/snackbar';
import { LoadingButton } from '@mui/lab';
import SelectPicker from '../../components/forms/SelectPicker';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function UserLogin() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setData] = useState<any>({});
  const [errors, setErrors] = useState<any>({});
  const [loginError, setLoginError] = useState<any>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [submittingCode, setSubmittingCode] = useState<boolean>(false);
  const [unverifiedPhone, setUnverifiedPhone] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');

  const authSlice = useSelector(state => state.auth);
  const { isAuthenticating, otpSent, requestingCode, authUser } = authSlice;

  const onFormChange = (name: string, value: any) => {
    setData(prev => ({ ...prev, [name]: value }));

    // const updatedData = { ...formData, [name]: value };
    // const { errors: err } = validateLoginData(updatedData, errors);
    // err[name] ? setErrors({ [name]: err[name] }) : setErrors({});
    setErrors({});
  };

  const onUserLogin = async () => {
    const { isValid, errors: errs } = validateLoginData(formData, errors);

    if (isValid) {
      const formD = { ...formData, requestFrom: RequestFrom.web };
      const response: any = await dispatch(userLogin(formD));
      // console.log('response.payload', response.payload);

      if (response.meta.requestStatus === 'fulfilled') {
        const locale = response.payload?.user?.userSetting?.locale || 'en';
        localStorage.setItem('language', JSON.stringify(locale));

        i18n.changeLanguage(locale);

        // console.log('phoneVerified', response.payload?.user.phoneVerified);
        const phoneVerified = response.payload?.user.phoneVerified;

        if (phoneVerified) {
          navigate('/', { replace: true });
        } else {
          await verifyPhoneNumber(response.payload.user);
          setUnverifiedPhone(true);
        }
        // toast.show({ title: t('account.createMsg.success') });
      } else if (response.meta.requestStatus === 'rejected') {
        // console.error('err @loginscreen', response.payload);
        // enqueueSnackbar('Login Error', errorToast);
        setLoginError(response.payload?.message);
        setErrors(response);
      }
    } else {
      setErrors(errs);
      // console.log('Validation Failed');
    }
  };

  async function verifyPhoneNumber(user) {
    const { countryCode, phoneNumber } = user;

    try {
      const otpUsage = OTPUsage.LOGIN_VERIFICATION;
      const payload = { countryCode, phoneNumber, otpUsage };
      await dispatch(signInWithPhoneNumber(payload));
    } catch (err) {
      // console.log('phone verification err', err);
    }
  }

  async function onSubmitVerificationCode() {
    setSubmittingCode(true);
    try {
      const { countryCode, phoneNumber } = authUser.user;

      // console.log('confirming verification code...', code);
      const payload = { countryCode, phoneNumber, otp: code };

      const response = await dispatch(confirmVerificationCode(payload));
      if (response.meta.requestStatus === 'fulfilled') {
        const otpVerified = await response.payload.verified;
        if (otpVerified) {
          // console.log('updating as verified...');
          await updatePhoneVerification();
          await dispatch(refreshUserDetails());
          navigate('/', { replace: true });
        }
      }
      if (response.meta.requestStatus === 'rejected') {
        // console.log('Invalid verification code');
        // enqueueSnackbar('Invalid verification code', errorToast);
      }
    } catch (error) {
      // console.log('verification error:', error);
      enqueueSnackbar('Code verification error', errorToast);
    } finally {
      setSubmittingCode(false);
    }
  }

  // console.log('formData', formData);
  // console.log('formErrors', errors);

  // console.log('otpSent', otpSent, unverifiedPhone);

  if (otpSent && unverifiedPhone) {
    // if (true) {
    return (
      <Container sx={containerStyle} maxWidth={'xs'}>
        <Box sx={boxStyle}>
          <OTPCodeInput
            code={code}
            setCode={setCode}
            phoneNumber={formData.phoneNumber}
            isLoading={submittingCode}
            onSubmit={onSubmitVerificationCode}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={containerStyle} maxWidth={'xs'}>
      <Box sx={boxStyle}>
        <Stack alignItems={'center'}>
          <Avatar sx={{ m: 1, bgcolor: colors.theme1 }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography sx={{ fontSize: 18 }}>KAROD - DIGITAL KHATA</Typography>
        </Stack>

        <Stack mb={2}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 20 }}>
            Welcome
          </Typography>
          <Typography sx={{ fontSize: 18, lineHeight: 0.6 }}>
            Log in to continue!
          </Typography>
        </Stack>

        <Stack>
          <Stack gap={1}>
            {/* <Stack direction={'row'}>
              <Stack flex={1}>
                <SelectPicker
                  isRequired={true}
                  isDisabled={true}
                  disableClearable={true}
                  // selectItems={countryCodes}
                  // selectedValue={formData.countryCode}
                  selectedValue={'+977'}
                  label={t('userRegistration.label.countryCode')}
                  onValueChange={(v: any) => onFormChange('countryCode', v)}
                />
              </Stack>
              <Stack flex={3}>
                <InputForm
                  id="phoneNumber"
                  autoComplete="phoneNumber"
                  maxLength={10}
                  isRequired={true}
                  isFloatNo={false}
                  autoCapitalize="none"
                  label={t('userLogin.label.phoneNumber')}
                  placeHolder={t('userLogin.placeholder.phoneNumber')}
                  isInvalid={'phoneNumber' in errors}
                  onChangeText={(v: any) => onFormChange('phoneNumber', v)}
                  helpText={'phoneNumber' in errors && errors.phoneNumber}
                />
              </Stack>
            </Stack> */}

            <Stack flex={1}>
              <Box py={0.8}>
                <label>{t('userLogin.label.phoneNumber')}</label>
              </Box>
              <TextField
                required
                id="phoneNumber"
                autoComplete="phoneNumber-password"
                placeholder={t('userLogin.placeholder.phoneNumber')}
                onChange={(e: any) =>
                  onFormChange('phoneNumber', e.target.value)
                }
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    'border-left-color': colors.red500,
                    'border-left-width': '2px',
                  },
                }}
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+977</InputAdornment>
                  ),
                }}
              />

              {'phoneNumber' in errors && (
                <Typography pt={0.4} fontSize={14} color={colors.red500}>
                  {`${t('commonText.error')}: ${errors['phoneNumber']}`}
                </Typography>
              )}
            </Stack>

            <Stack flex={1}>
              <Box py={0.8}>
                <label>{t('userLogin.label.password')}</label>
              </Box>
              <TextField
                required
                id="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="current-password"
                placeholder={t('userLogin.placeholder.password')}
                onChange={(e: any) => onFormChange('password', e.target.value)}
                sx={{
                  '.MuiOutlinedInput-notchedOutline': {
                    'border-left-color': colors.red500,
                    'border-left-width': '2px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prev => !prev)}
                        onMouseDown={() => setShowPassword(prev => !prev)}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {'password' in errors && (
                <Typography pt={0.4} fontSize={14} color={colors.red500}>
                  {`${t('commonText.error')}: ${errors['password']}`}
                </Typography>
              )}
            </Stack>
          </Stack>

          {loginError && (
            <Typography
              mt={2}
              sx={{ color: colors.red500, fontWeight: 'bold', fontSize: 17 }}
              align="center">
              {loginError}
            </Typography>
          )}

          <LoadingButton
            size="large"
            variant="contained"
            loading={isAuthenticating || requestingCode}
            // loading={true}
            onClick={onUserLogin}
            sx={{ my: 2 }}>
            Log In
          </LoadingButton>

          <Stack
            mt={-1}
            alignItems={'center'}
            justifyContent={'flex-end'}
            direction={'row'}>
            <NavLink to="/password-reset" style={{ fontSize: 15 }}>
              Forgot password ?
            </NavLink>
          </Stack>
        </Stack>

        <Divider />

        <Stack gap={1}>
          <Stack alignItems={'center'}>
            <Typography style={{ fontSize: 17 }}>
              Don't have an Account ?
            </Typography>
            <TextButton
              title="REGISTER NOW"
              color={colors.theme2}
              onClick={() => navigate('/signup')}
            />
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}

const containerStyle = {
  minHeight: '80vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const boxStyle = {
  gap: 2,
  margin: 2,
  padding: 4,
  boxShadow: 8,
  minWidth: 480,
  borderRadius: 2,
  display: 'flex',
  flexDirection: 'column',
};
