export const getSubscriptionDiscountedPrice = (
  originalPrice: number,
  discountPercent: number,
) => {
  if (!originalPrice && !discountPercent) {
    return 0;
  }
  const discountedAmount = (discountPercent / 100) * originalPrice;
  return Math.ceil(originalPrice - discountedAmount);
};

export const getSubscriptionTaxedPrice = (taxableAmount: number) => {
  return Math.ceil(1.13 * taxableAmount);
};
