const colors = {
  theme1: '#01579B',
  theme2: '#DC493A',
  green100: '#D5F5E3',
  green300: '#82E0AA',
  green500: '#2ECC71',
  green600: '#28B463',
  green700: '#239B56',
  green900: '#186A3B',
  red100: '#FADBD8',
  red300: '#F1948A',
  red500: '#E74C3C',
  red700: '#B03A2E',
  red900: '#78281F',
  lightBlue900: '#01579B',
  lightBlue800: '#3683B3',
  lightBlue400: '#29B6F6',
  bluex100: '#a9cce3',
  bluex200: '#7fb3d5',
  bluex300: '#85c1e9',
  bluex400: '#5dade2',
  bluex500: '#2980b9',
  bluex600: '#1a5276',
  blueGrey900: '#263238',
  blueGrey700: '#455A64',
  blueGrey500: '#607D8B',
  blueGrey300: '#90A4AE',
  blueGrey200: '#CFD8DC',
  red: '#b33636',
  orange: '#FF6600',
  white: '#FFFFFF',
  white2: '#F3F3F3',
  gray: '#CCCCCC',
  // gray100: '#D6DBDF',
  black: '#000',
  darker: '#222',
  darker2: '#333',
  darker3: '#444',
  darker4: '#555',
  darker5: '#666',
  text: '#333',

  // generated colors
  primary: '#4392F1',
  orange400: '#E37063',
  orange500: '#DC493A',
  // gray: '#ECE8EF',
  // lightBlue1: '#E3EBFF',
  // lightBlue2: '#E7F0FF',

  gray50: '#fafafa',
  gray100: '#f4f4f5',
  gray200: '#e4e4e7',
  gray300: '#d4d4d8',
  gray400: '#a1a1aa',
  gray500: '#71717a',

  info50: '#f0f9ff',
  info100: '#e0f2fe',
  info200: '#bae6fd',
  info300: '#7dd3fc',
  info400: '#38bdf8',
  info500: '#0ea5e9',
  info600: '#0284c7',

  blue400: '#549BF2',
  blue500: '#4392F1',
  blue600: '#1170E4',
  blue700: '#0E5DBE',
  blue800: '#093872',

  // orange500: '#f97316',

  whatsapp: '#075E54',
  viber: '#59267c',
};

export { colors };
