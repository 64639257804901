export const validateSubsOrder = (data, errors) => {
  let isValid = true;

  if (!data.orderedBy) {
    errors = { ...errors, orderedBy: 'Ordered by user is required' };
    isValid = false;
  } else {
    delete errors.orderedBy;
  }

  if (!data.product) {
    errors = { ...errors, product: 'Subscription product is required' };
    isValid = false;
  } else {
    delete errors.product;
  }

  if (!data.totalBusiness) {
    errors = {
      ...errors,
      totalBusiness: 'Total business subscribed is required',
    };
    isValid = false;
  } else {
    delete errors.totalBusiness;
  }

  if (!data.amount) {
    errors = {
      ...errors,
      amount: 'Total bill amount is required',
    };
    isValid = false;
  } else {
    delete errors.amount;
  }

  if (!data.mode) {
    errors = {
      ...errors,
      mode: 'Payment mode is required',
    };
    isValid = false;
  } else {
    delete errors.mode;
  }

  if (data.otherDiscount && data.otherDiscount >= data.totAmtAfterDis1) {
    errors = {
      ...errors,
      otherDiscount:
        "Other Discount can't be greater than Amount after Discount",
    };
    isValid = false;
  } else {
    delete errors.otherDiscount;
  }

  return { isValid, errors };
};
