export enum SubscriptionType {
  TRIAL = 'TRIAL',
  // Previous App Packages - NOT BEING USED NOW
  BASIC = 'BASIC',
  PREMIUM = 'PREMIUM',
  RELAXED = 'RELAXED',
  // NEW PACKAGES - INTRODUCED AFTER WEB RELEASE
  // Monthly Packages
  BASIC_MONTHLY = 'BASIC_MONTHLY',
  STANDARD_MONTHLY = 'STANDARD_MONTHLY',
  PREMIUM_MONTHLY = 'PREMIUM_MONTHLY',

  // Quarterly Packages - NOT BEING SHOWN TO USERS
  BASIC_QUARTERLY = 'BASIC_QUARTERLY',
  STANDARD_QUARTERLY = 'STANDARD_QUARTERLY',
  PREMIUM_QUARTERLY = 'PREMIUM_QUARTERLY',

  // Semi Annual Packages - NOT BEING SHOWN TO USERS
  BASIC_SEMI_YEARLY = 'BASIC_SEMI_YEARLY',
  STANDARD_SEMI_YEARLY = 'STANDARD_SEMI_YEARLY',
  PREMIUM_SEMI_YEARLY = 'PREMIUM_SEMI_YEARLY',

  // Yearly Packages
  BASIC_YEARLY = 'BASIC_YEARLY',
  STANDARD_YEARLY = 'STANDARD_YEARLY',
  PREMIUM_YEARLY = 'PREMIUM_YEARLY',
}
