export const validateVoucherEntriesFilters = (data: any, errors: any) => {
  let isValid = true;

  if (data.fromDate === undefined) {
    errors = { ...errors, fromDate: 'From date is required' };
    isValid = false;
  } else {
    delete errors.fromDate;
  }

  if (data.toDate === undefined) {
    errors = { ...errors, toDate: 'To Date is required' };
    isValid = false;
  } else {
    delete errors.toDate;
  }

  // if (!data.voucherType) {
  //   errors = { ...errors, voucherType: 'Voucher Type is required' };
  //   isValid = false;
  // } else {
  //   delete errors.voucherType;
  // }

  if (data.voucherNumber && data.voucherNumber.length > 20) {
    errors = {
      ...errors,
      voucherNumber: 'Voucher Number should be less than 20 characters',
    };
    isValid = false;
  } else {
    delete errors.voucherNumber;
  }

  return { isValid, errs: errors };
};
