import { store } from '../../store/store';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getAllParentCompany = async () => {
  const path = v1('/system-admin/all-parent-companies');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getAllBusinesses = async () => {
  const path = v1('/system-admin/all-businesses');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getActiveVerifiedUsers = async () => {
  const path = v1('/system-admin/active-verified-appusers');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const switchBusiness = async (businessId: number) => {
  const path = v1(`/system-admin/switch-business/${businessId}`);

  const response = await httpClient().post<any>(path);
  return response.data;
};

export const createSubsOrderBySysAdmin = async (data: any) => {
  const path = v1('/subscriptions/manual-subscription-order');
  const response = await httpClient().post<any>(path, data);
  return response.data;
};
