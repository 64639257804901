export const dateFilters = [
  { filter: 'fromDate', required: true },
  { filter: 'toDate', required: true },
];

export const periodsSelectOptions = [
  { name: 'Last 30 days', id: 'last30Days' },
  { name: 'Last 7 days', id: 'last7Days' },
  { name: 'Today', id: 'today' },
];
