import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button, Container, Divider, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import SelectPicker from '../../../components/forms/SelectPicker';
import { useDispatch, useSelector } from '../../../store/reduxHooks';

import {
  loginCodeUnlocked,
  refreshUserDetails,
} from '../../../store/authSlice';
import {
  loginCodeStatus,
  userLocaleAPI,
} from '../../../controllers/accounts/userAccountControllers';
import { localeOptions } from '../../../constants/defaultCodes/authAndAccounts';
import SwitchForm from '../../../components/forms/SwitchForm';
import SetLoginCodeModal from './components/SetLoginCodeModal';
import PasswordChangeModal from './components/PasswordChangeModal';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';

const boxStyle = {
  width: '100%',
  padding: 1,
  paddingY: 2.4,
  borderRadius: 1,
  borderBottomWidth: 1,
  // backgroundColor: '#DFDFDF',
  // '&:hover': {
  //   bgcolor: colors.gray100,
  //   cursor: 'pointer',
  // },
};

function UserSettingsPage() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const { authUser } = useSelector(s => s.auth);

  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showPasswordModal, setPasswordModal] = useState(false);
  const [enableLC, setEnableLC] = useState(false);
  const [userLocale, setUserLocale] = useState('en');

  const loginCode = authUser.user?.loginCode;
  const enableCode = authUser.user?.userSetting.enableLoginCode;
  const { locale } = authUser.user?.userSetting;

  const loginCdStatusQuery = useMutation(loginCodeStatus);
  const userLocaleQuery = useMutation(userLocaleAPI);

  useEffect(() => {
    setEnableLC(enableCode);
    setUserLocale(locale);
  }, [enableCode, locale]);

  const onCodeStatusChange = async (value: boolean) => {
    setEnableLC(value);
    try {
      dispatch(loginCodeUnlocked());
      await loginCdStatusQuery.mutateAsync({ enableCode: value });
      await dispatch(refreshUserDetails());
      dispatch(loginCodeUnlocked());
      if (value) {
        enqueueSnackbar('Login Code enabled !!', successToast);
      } else {
        enqueueSnackbar('Login Code disabled !!', successToast);
      }
    } catch (err) {
      setEnableLC(!value);
    }
  };

  const onLocaleChange = async (value: string) => {
    try {
      await userLocaleQuery.mutateAsync({ locale: value });
      await dispatch(refreshUserDetails());
      i18n.changeLanguage(value);
      localStorage.setItem('language', JSON.stringify(value));

      enqueueSnackbar('Language Change Successful !!', successToast);
    } catch (err) {
      enqueueSnackbar('Error, on Language Change !!', errorToast);
    }
  };

  // console.log('userLocale', userLocale);

  return (
    <Container>
      <Card>
        <CardContent>
          <Typography sx={{ fontSize: 20 }} color="text.primary" gutterBottom>
            {t('settingStack.userSettings')}
          </Typography>
          <Divider />

          <Stack divider={<Divider orientation="vertical" flexItem />}>
            <Stack
              sx={boxStyle}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography sx={{ fontSize: 17 }} color="text.primary">
                Language/भाषा
              </Typography>
              <Box minWidth={'20%'}>
                <SelectPicker
                  size="small"
                  disableClearable={true}
                  selectItems={localeOptions}
                  selectedValue={userLocale}
                  onValueChange={(v: string) => onLocaleChange(v)}
                />
              </Box>
            </Stack>
            <Stack
              sx={boxStyle}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography sx={{ fontSize: 17 }} color="text.primary">
                {t('userSetting.loginCodeLabel')}
              </Typography>
              <Box onClick={() => setShowCodeModal(true)}>
                <Button
                  variant="text"
                  sx={{ fontWeight: 'bold', fontSize: 15 }}>
                  {loginCode
                    ? t('userSetting.updateCode')
                    : t('userSetting.setCode')}
                </Button>
              </Box>
            </Stack>
            <Stack
              sx={boxStyle}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography sx={{ fontSize: 17 }} color="text.primary">
                {t('userSetting.enableCodeLabel')}
              </Typography>
              <SwitchForm
                isChecked={enableLC}
                onToggle={(e, v) => onCodeStatusChange(v)}
              />
            </Stack>
            <Stack
              sx={boxStyle}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}>
              <Typography sx={{ fontSize: 17 }} color="text.primary">
                {t('userSetting.passwordLabel')}
              </Typography>
              <Box onClick={() => setPasswordModal(true)}>
                <Button
                  variant="text"
                  sx={{ fontWeight: 'bold', fontSize: 15 }}>
                  {t('userSetting.changePassword')}
                </Button>
              </Box>
            </Stack>
          </Stack>

          {showCodeModal && (
            <SetLoginCodeModal
              loginCode={loginCode}
              modalVisible={showCodeModal}
              onModalClose={() => setShowCodeModal(false)}
            />
          )}

          {showPasswordModal && (
            <PasswordChangeModal
              modalVisible={showPasswordModal}
              onModalClose={() => setPasswordModal(false)}
            />
          )}
        </CardContent>
      </Card>
    </Container>
  );
}

export default UserSettingsPage;
