import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';

import { useSelector } from '../../../store/reduxHooks';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const TxnCancelConfirmModal = ({
  isLoading,
  showModal,
  setShowModal,
  onCancellationUpdate,
  voucher,
  userVoucherDate,
}: any): JSX.Element => {
  const { user } = useSelector(state => state.auth.authUser);
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={() => setShowModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={2}>
          <Typography
            sx={{ fontSize: 18, color: colors.red500, fontWeight: 'bold' }}>
            {voucher.isCancelled
              ? t('voucherCancelWarning.header.title2')
              : t('voucherCancelWarning.header.title1')}{' '}
          </Typography>
        </Stack>
        <Divider />

        <Stack py={1} gap={1}>
          <Typography
            sx={{ fontWeight: 'bold' }}
            color="text.primary"
            gutterBottom>
            {voucher.isCancelled
              ? t('voucherCancelWarning.body.title2')
              : t('voucherCancelWarning.body.title1')}
          </Typography>

          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={4}>Date</Grid>
            <Grid xs={8}>{userVoucherDate}</Grid>

            <Grid xs={4}>Bill/Ref No.</Grid>
            <Grid xs={8}>{voucher.referenceId}</Grid>

            <Grid xs={4}>Voucher No.</Grid>
            <Grid xs={8}>{voucher.voucherNumber}</Grid>

            <Grid xs={4}>Amount</Grid>
            <Grid xs={8}>
              {formatAmount(voucher.txnAmount || voucher.debitSum)}
            </Grid>
          </Grid>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={() => setShowModal(false)}>
            {t('voucherCancelWarning.button.close')}
          </Button>
          <Button
            color="error"
            variant="contained"
            size="large"
            onClick={onCancellationUpdate}>
            {voucher.isCancelled
              ? t('voucherCancelWarning.button.confirm2')
              : t('voucherCancelWarning.button.confirm1')}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default TxnCancelConfirmModal;
