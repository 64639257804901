import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Modal, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { colors } from '../../assets/styles/colors';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  paddingLeft: 4,
  paddingRight: 4,
};

const ProductItemDeleteWarning = ({
  isLoading,
  hasTxns,
  product,
  modalVisible,
  onModalClose,
  onConfirmDeletion,
}: any) => {
  const { t } = useTranslation();

  if (!modalVisible) return null;
  return (
    <Modal
      open={modalVisible}
      onClose={onModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Stack pb={1}>
          <Typography
            sx={{ fontSize: 20, color: colors.red500, fontWeight: 'bold' }}>
            {hasTxns
              ? t('productItem.deleteMsg.warningTitle2')
              : t('productItem.deleteMsg.warningTitle')}
          </Typography>
        </Stack>
        <Divider />

        <Stack py={1} gap={3}>
          <Typography sx={{ fontSize: 18 }}>
            {hasTxns
              ? t('productItem.deleteMsg.warningSubtitle2')
              : t('productItem.deleteMsg.warningSubtitle')}
          </Typography>

          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid xs={4} sx={{ fontWeight: 'bold' }}>
              Code
            </Grid>
            <Grid xs={8}>{product.code} </Grid>

            <Grid xs={4} sx={{ fontWeight: 'bold' }}>
              Product Name
            </Grid>
            <Grid xs={8}>{product.name}</Grid>

            <Grid xs={4} sx={{ fontWeight: 'bold' }}>
              Purchase Price
            </Grid>
            <Grid xs={8}>{product.purchasePrice}</Grid>

            <Grid xs={4} sx={{ fontWeight: 'bold' }}>
              Selling Price
            </Grid>
            <Grid xs={8}>{product.sellingPrice}</Grid>

            {product.category?.name && (
              <>
                <Grid xs={4} sx={{ fontWeight: 'bold' }}>
                  Product Category
                </Grid>
                <Grid xs={8}>{product.category?.name}</Grid>
              </>
            )}
          </Grid>
        </Stack>

        <Divider />

        <Stack direction="row" justifyContent={'flex-end'} spacing={4} pt={2}>
          <Button
            color="inherit"
            variant="contained"
            size="large"
            onClick={onModalClose}>
            {t('partner.deleteMsg.cancelBtn')}
          </Button>

          {!hasTxns && (
            <Button
              color="error"
              variant="contained"
              size="large"
              onClick={() => onConfirmDeletion(product)}>
              {t('partner.deleteMsg.confirmBtn')}
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default ProductItemDeleteWarning;
