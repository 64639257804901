import React, { useEffect } from 'react';
import { Container, Divider, Stack, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Unstable_Grid2';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import {
  AdjustmentType,
  StockType,
  adjustmentTypes,
  stockDecrementTypes,
  stockIncrementTypes,
} from '../../../constants/defaultCodes/stockType';
import voucherTypes from '../../../constants/defaultCodes/voucherTypes';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getVoucherNumber } from '../../../controllers/transactions/transactionControllers';
import { getProductItems } from '../../../controllers/settings/inventoryControllers';
import { getRefNoFromVoucherNo } from '../../../shared/utils/voucherUtils';
import { stockAdjustmentAPI } from '../../../controllers/reports/reportControllers';
import { validateStockAdjustment } from '../../../validations/settings/validateProductItem';
import { validateAEVoucherNo } from '../../../validations/transactions/validateAdjustingEntry';
import { ProductItemType } from '../../../constants/defaultCodes/producttemTypes';
import DatePicker from '../../../components/datePickers/DatePicker';
import InputForm from '../../../components/forms/InputForm';
import SelectPicker from '../../../components/forms/SelectPicker';
import Button from '../../../components/buttons/Button';
import { errorToast, successToast } from '../../../constants/snackbar/snackbar';
import HeaderSwitch from '../../../components/switch/HeaderSwitch';
import {
  TableRowStyled,
  TbRowHeaderStyled,
} from '../../../components/styled/TableRowStyled';
import {
  TbCellAmount,
  TbCellHeader,
} from '../../../components/styled/TableCellStyled';
import { colors } from '../../../assets/styles/colors';

const increment = StockType.increment;
const decrement = StockType.decrement;

const voucherType = voucherTypes.ADJUSTING_ENTRY;
const initial = { initialData: [] };

function StockAdjustment() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const init = { voucherDate: new Date(), isAddAdjustment: true };
  const init2 = { adjustmentType: AdjustmentType.quantityAdjustment };
  const [formData, setData] = React.useState<any>({ ...init, ...init2 });
  const [errors, setErrors] = React.useState<any>({});
  const { isAddAdjustment } = formData;

  const stockType = isAddAdjustment ? increment : decrement;

  const { data: voucherNumber } = useQuery(['getVoucherNo', voucherType], () =>
    getVoucherNumber(voucherType),
  );

  const prodItemFn = () => getProductItems({ isActive: true });
  const products = useQuery(['products'], prodItemFn, initial);

  useEffect(() => {
    if (voucherNumber) {
      const referenceId = getRefNoFromVoucherNo(voucherNumber);
      setData((prev: any) => ({ ...prev, voucherNumber, referenceId }));
    }
  }, [voucherNumber, isAddAdjustment]);

  const { isLoading, mutateAsync } = useMutation(stockAdjustmentAPI);

  const onSubmit = async () => {
    const { isValid, errs } = validateStockAdjustment(formData, errors);
    const otherValidity = await validateAEVoucherNo(formData, errs);

    if (isValid && otherValidity.isVoucherNoValid) {
      try {
        await mutateAsync(formData);
        queryClient.invalidateQueries(['getProductItems']);
        // queryClient.invalidateQueries(['stockMovement']);
        queryClient.invalidateQueries(['getDashboardReport']);

        navigate('/journals', { replace: true });
        enqueueSnackbar(t('stockAdjustment.createMsg.success'), successToast);
      } catch (err) {
        let title = t('stockAdjustment.createMsg.error');
        if (err.response?.data?.statusCode === 400) {
          title = err.response?.data?.message;
        }
        enqueueSnackbar(title, errorToast);
      }
    } else {
      setErrors(otherValidity.errss);
      if (!otherValidity.isVoucherNoValid) {
        queryClient.invalidateQueries(['getVoucherNo']);
      }
    }
  };

  const onFormChange = (name: string, value: any) => {
    if (name === 'isAddAdjustment' && value) {
      setData({ ...init, ...init2 });
    } else if (name === 'isAddAdjustment' && !value) {
      setData({ ...init, ...init2, [name]: value });
    } else {
      const isValueAdj = value === AdjustmentType.valueAdjustment;
      if (name === 'adjustmentType' && isValueAdj) {
        setData((prev: any) => ({ ...prev, [name]: value, quantity: '' }));
      } else if (name === 'quantity') {
        const adjustedValue =
          +value * getProduct()?.prodItem.purchasePrice || 0;
        setData((prev: any) => ({ ...prev, [name]: value, adjustedValue }));
      } else if (name === 'product') {
        const prod = products.data?.find(x => x.id === +value);
        const purchasePrice = prod?.purchasePrice || 0;
        const adjustedValue = +formData.quantity || 0 * purchasePrice;
        const otherData = { adjustedValue, purchasePrice };
        setData((prev: any) => ({ ...prev, [name]: value, ...otherData }));
      } else {
        setData((prev: any) => ({ ...prev, [name]: value }));
      }
    }

    const updatedData = { ...formData, [name]: value };
    const { errs } = validateStockAdjustment(updatedData, errors);
    errs[name] ? setErrors({ [name]: errs[name] }) : setErrors({});
  };

  const getHelpText = (fieldName: string) => {
    if (fieldName in errors) {
      return errors[fieldName];
    }
  };

  const getQuantityLabel = () => {
    const unitX = getProduct()?.prodItem.primaryUnit?.name;
    const unit = unitX ? `(${unitX})` : '';

    if (stockType === StockType.increment) {
      return t('stockAdjustment.label.quantity2', { unit });
    } else if (stockType === StockType.decrement) {
      return t('stockAdjustment.label.quantity1', { unit });
    }
  };

  const { adjustmentType } = formData;
  const isValueAdjustment = adjustmentType === AdjustmentType.valueAdjustment;
  const isQntyAdjustment = adjustmentType === AdjustmentType.quantityAdjustment;

  const getAdjustedValueLabel = () => {
    if (isQntyAdjustment) {
      return t('stockAdjustment.label.adjustedValue1');
    } else {
      return t('stockAdjustment.label.adjustedValue2');
    }
  };

  const getProduct = () => {
    const { product, adjustedValue, quantity } = formData;
    if (product) {
      const prodItem = products.data?.find(x => x.id === product);

      let qntyTitle = '';
      let qntyValue = `${quantity || 0}`;
      let valTitle = '';
      let valValue = `${adjustedValue}`;
      if (isAddAdjustment) {
        qntyTitle = `Quantity increasing by`;
        valTitle = `Inventory value increasing by`;
      } else {
        qntyTitle = `Quantity decreasing by`;
        valTitle = `Inventory value decreasing by`;
      }
      const message = { qntyTitle, qntyValue, valTitle, valValue };

      return { prodItem, message };
    }
  };

  const getStockAdjTypes = () => {
    if (isAddAdjustment) {
      return stockIncrementTypes;
    } else {
      return stockDecrementTypes;
    }
  };

  const prodCurrentStock = () => {
    if (formData.product) {
      const prodItem = products.data?.find(x => x.id === formData.product);
      const stock = +prodItem?.currentStock;
      const unit = prodItem?.primaryUnit?.name || '';

      const balance = `Current Stocks: ${stock} ${unit}`;

      if (stock <= 0) {
        return { type: 'NEGATIVE', balance };
      } else {
        return { type: 'POSITIVE', balance };
      }
    }
  };

  const getProducts = () => {
    return products.data?.filter(
      x => x.productType === ProductItemType.product,
    );
  };

  // console.log('formData 123:', formData);

  return (
    <Container>
      <Card sx={{ minWidth: 275, paddingX: 4 }}>
        <CardContent>
          <Stack pb={1} direction={'row'} justifyContent={'space-between'}>
            <Typography sx={{ fontSize: 20 }}>
              {isAddAdjustment
                ? t('transactionStack.addAdjustment')
                : t('transactionStack.subAdjustment')}
            </Typography>

            <HeaderSwitch
              leftEnabled={isAddAdjustment}
              leftText=" + ADD "
              rightText=" - SUB "
              onToggle={v => onFormChange('isAddAdjustment', v)}
            />
          </Stack>

          <Divider />

          <Stack py={2} spacing={4}>
            <Grid
              container
              rowSpacing={{ xs: 1, sm: 2, md: 3 }}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={4}>
                <DatePicker
                  entryMinDate={true}
                  label={t('adjustingEntry.label.voucherDate')}
                  value={formData.voucherDate}
                  onDateChange={(v: any) => onFormChange('voucherDate', v)}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  isDisabled={true}
                  isRequired={true}
                  defaultValue={formData.voucherNumber}
                  label={t('adjustingEntry.label.voucherNumber')}
                  isInvalid={'voucherNumber' in errors}
                  helpText={getHelpText('voucherNumber')}
                />
              </Grid>
              <Grid xs={4}>
                <InputForm
                  defaultValue={formData.referenceId}
                  label={t('adjustingEntry.label.referenceId')}
                  isInvalid={'referenceId' in errors}
                  helpText={getHelpText('referenceId')}
                  onChangeText={(v: string) => onFormChange('referenceId', v)}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  isRequired={true}
                  disableClearable={true}
                  label={t('stockAdjustment.label.adjustmentType')}
                  selectedValue={formData.adjustmentType}
                  selectItems={adjustmentTypes}
                  isInvalid={'adjustmentType' in errors}
                  helpText={getHelpText('adjustmentType')}
                  onValueChange={(v: any) => onFormChange('adjustmentType', v)}
                />
              </Grid>
              <Grid xs={6}>
                <SelectPicker
                  isRequired={true}
                  label={t('stockAdjustment.label.stockType')}
                  selectedValue={formData.stockType}
                  selectItems={getStockAdjTypes()}
                  isInvalid={'stockType' in errors}
                  helpText={getHelpText('stockType')}
                  onValueChange={(v: any) => onFormChange('stockType', v)}
                />
              </Grid>
              <Grid xs={12}>
                <SelectPicker
                  isRequired={true}
                  selectedValue={formData.product}
                  selectItems={getProducts()}
                  label={t('stockAdjustment.label.product')}
                  placeHolder={t('stockAdjustment.label.product')}
                  isInvalid={'product' in errors}
                  helpText={getHelpText('product')}
                  onValueChange={(v: string) => onFormChange('product', v)}
                  productBalance={prodCurrentStock() && prodCurrentStock()}
                />
              </Grid>

              <Grid xs={6}>
                <InputForm
                  isRequired={isQntyAdjustment ? true : false}
                  isNumberic={true}
                  maxLength={10}
                  isDisabled={isValueAdjustment ? true : false}
                  defaultValue={formData.quantity}
                  label={getQuantityLabel()}
                  placeHolder={t('stockAdjustment.label.quantity')}
                  isInvalid={'quantity' in errors}
                  helpText={getHelpText('quantity')}
                  onChangeText={(v: string) => onFormChange('quantity', v)}
                />
              </Grid>
              <Grid xs={6}>
                <InputForm
                  isNumberic={true}
                  isRequired={true}
                  isDisabled={isQntyAdjustment ? true : false}
                  defaultValue={formData.adjustedValue}
                  label={getAdjustedValueLabel()}
                  placeHolder={t('stockAdjustment.label.adjustedValue2')}
                  isInvalid={'adjustedValue' in errors}
                  helpText={getHelpText('adjustedValue')}
                  onChangeText={(v: string) => {
                    onFormChange('adjustedValue', v);
                  }}
                />
              </Grid>
            </Grid>

            {getProduct() && (
              <TableContainer component={Paper}>
                <Table aria-label="tbl">
                  <TbRowHeaderStyled>
                    <TbCellHeader sx={{ fontSize: 16, color: colors.darker2 }}>
                      {getProduct()?.prodItem.name}
                    </TbCellHeader>
                    <TbCellHeader />
                  </TbRowHeaderStyled>
                  <TableBody>
                    <TableRowStyled>
                      <TbCellHeader component="th" scope="row">
                        Purchase/Production Cost (per unit)
                      </TbCellHeader>
                      <TbCellAmount sx={{ fontSize: 18, py: 0.6 }}>
                        {getProduct()?.prodItem.purchasePrice}
                      </TbCellAmount>
                    </TableRowStyled>
                    <TableRowStyled>
                      <TbCellHeader>
                        {getProduct()?.message.qntyTitle}
                      </TbCellHeader>
                      <TbCellAmount sx={{ fontSize: 18, py: 0.6 }}>
                        {getProduct()?.message.qntyValue}
                      </TbCellAmount>
                    </TableRowStyled>
                    <TableRowStyled>
                      <TbCellHeader component="th" scope="row">
                        {getProduct()?.message.valTitle}
                      </TbCellHeader>
                      <TbCellAmount sx={{ fontSize: 18, py: 0.6 }}>
                        {getProduct()?.message.valValue}
                      </TbCellAmount>
                    </TableRowStyled>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </CardContent>
        <Divider />

        <Stack
          direction="row"
          justifyContent={'flex-end'}
          spacing={4}
          padding={2}>
          {/* <Button title="Save & Get PDF" onClick={onSubmit} /> */}
          <Button
            title="Save Transaction"
            onClick={onSubmit}
            loading={isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
}

export default StockAdjustment;
