import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Box, Chip, Stack, Typography } from '@mui/material';

import { useSelector } from '../../../store/reduxHooks';
import { itemsAmountSum } from '../../../shared/utils/amountSum';
import {
  getDiscountAmount,
  getDiscountPercent,
  getTaxAmount,
} from '../../../shared/utils/vatAndDiscount';
import InputForm from '../../../components/forms/InputForm';
import { colors } from '../../../assets/styles/colors';
import { FormError } from '../../../components/utility/FormError';
import SwitchForm from '../../../components/forms/SwitchForm';

const BillOrInvoiceDiscountAndTax = ({
  voucherItems,
  discountAmount,
  isTaxable,
  onFormDataChange,
  isReturn,
  isSales,
  errors,
  t,
}: any): JSX.Element => {
  const { user } = useSelector(state => state.auth.authUser);
  // console.log('user', user.business);

  const [discountPercent, setDiscountPercent] = useState(0);

  const amountSum = itemsAmountSum(voucherItems);
  // console.log('amountSum', amountSum);
  // console.log('fullPayment', fullPayment);

  const taxableItem = voucherItems.find(x => x.taxable);

  useEffect(() => {
    if (taxableItem) {
      onFormDataChange('isTaxable', false);
    }
  }, [taxableItem]);

  // CAUSING ISSUE ON UPDATE, REPLACES THE DISCOUNT FETCHED FROM SERVER
  useEffect(() => {
    if (discountPercent) {
      const discount = getDiscountAmount(discountPercent || 0, amountSum);
      onFormDataChange('discountAmount', discount);
    }
  }, [amountSum]);

  const onFormChange = (name: string, value: string) => {
    // console.log('okhrere...', name, value);

    switch (name) {
      case 'discountPercent':
        setDiscountPercent(Number(value) || 0);
        const discount = getDiscountAmount(Number(value) || 0, amountSum);
        onFormDataChange('discountAmount', Number(discount));
        break;

      case 'discountAmount':
        onFormDataChange('discountAmount', Number(value) || 0);
        break;
    }
  };

  const disper = getDiscountPercent(discountAmount, amountSum);
  return (
    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid xs={7} alignSelf={'center'}>
        <Typography mt={1}>{t('entriesCommonText.discount')}</Typography>
      </Grid>

      <Grid xs={1.9}>
        <Stack flexDirection={'row'}>
          <InputForm
            size="small"
            maxLength={6}
            isNumberic={true}
            // height={32}
            isRequired={false}
            isInvalid={false}
            defaultValue={disper}
            onChangeText={(v: string) => onFormChange('discountPercent', v)}
          />
          <Chip
            label="%"
            variant="outlined"
            sx={{
              mt: 0.8,
              borderRadius: 1,
              backgroundColor: colors.gray200,
              height: 40,
              borderLeftColor: colors.white,
            }}
          />
        </Stack>
      </Grid>

      <Grid xs={3.1}>
        <InputForm
          size="small"
          maxLength={12}
          height={32}
          isRequired={false}
          isNumberic={true}
          editable={discountPercent ? false : true}
          defaultValue={String(discountAmount || '')}
          placeHolder={'Discount Amt'}
          silentInvalid={true}
          isInvalid={'discountAmount' in errors}
          onChangeText={(v: string) => onFormChange('discountAmount', v)}
        />
      </Grid>

      {'discountAmount' in errors && (
        <FormError error={errors.discountAmount} align="right" />
      )}

      {(!isSales || (isSales && user.business.onVat)) && (
        <>
          <Grid xs={6} alignSelf={'center'}>
            <Typography mt={1}>{t('entriesCommonText.vat')}</Typography>
          </Grid>

          <Grid xs={1} alignSelf={'center'}>
            <SwitchForm
              // disabled={isReturn || taxableItem ? true : false}
              isChecked={isTaxable ? isTaxable : false}
              onToggle={(e, v: boolean) => onFormDataChange('isTaxable', v)}
            />
          </Grid>
          <Grid xs={1.9}>
            <Stack flexDirection={'row'}>
              <InputForm
                size="small"
                isDisabled={true}
                height={32}
                editable={false}
                maxLength={2}
                isNumberic={true}
                isRequired={false}
                defaultValue={isTaxable ? '13' : ''}
                isInvalid={false}
                onChangeText={(v: string) => onFormChange('vat', v)}
              />
              <Chip
                label="%"
                variant="outlined"
                sx={{
                  mt: 0.8,
                  borderRadius: 1,
                  backgroundColor: colors.gray200,
                  height: 40,
                  borderLeftColor: colors.white,
                }}
              />
            </Stack>
          </Grid>

          <Grid xs={3.1}>
            <InputForm
              size="small"
              height={32}
              isRequired={false}
              editable={false}
              defaultValue={
                getTaxAmount(isTaxable, discountAmount, amountSum, 13) || ''
              }
              placeHolder={'Tax amount'}
              isInvalid={false}
              isNumberic={true}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default BillOrInvoiceDiscountAndTax;
