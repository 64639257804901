export const formatDateTime = (dateString: string) => {
  const { year, month, day, hour, minute } = extractDateValues(dateString);

  return `${year}-${month}-${day}, ${hour}:${minute}`;
};

export const formatDate = (dateString: string) => {
  const { year, month, day } = extractDateValues(dateString);

  return `${year}-${month}-${day}`;
};

const extractDateValues = (dateString: string) => {
  const format = (value: number) => {
    return value.toString().length === 1 ? `0${value}` : value;
  };

  const UTCdate = new Date(dateString);
  var offSet = UTCdate.getTimezoneOffset();

  // research about offse, was supposed to add not subtract
  const date = new Date(UTCdate.getTime() + -offSet * 60000);

  const year = format(date.getFullYear());
  const month = format(date.getMonth() + 1);
  const day = format(date.getDate());
  const hour = format(date.getHours());
  const minute = format(date.getMinutes());

  return {
    year,
    month,
    day,
    hour,
    minute,
  };
};

export const formatToTextDate = (date: Date) => {
  let dateString = date.toDateString();
  let dateSplitted = dateString.split(' ');
  let dateJoined = `${dateSplitted[1]} ${dateSplitted[2]}, ${dateSplitted[3]}`;

  return dateJoined;
};

export const getAgingDate = (date: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const today: any = new Date();
  const oldDate: any = new Date(date);
  const todayStart = today.setHours(0, 0, 0, 0); // set to 12 AM
  const oldDateStart = oldDate.setHours(0, 0, 0, 0); // set to 12 AM

  const difference = oldDateStart - todayStart;
  return Math.round(difference / oneDay);
};
