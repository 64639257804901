// Creating while user creates account
export const accountingPeriodCode79 = {
  year: 79,
  quarter: 4,
  name: 'Fiscal Year 2079/80',
  startDate: new Date('2022-07-16T18:15:00.000Z'),
  endDate: new Date('2023-07-16T18:14:59.000Z'),
};

/* FOR CLOSING ACCOUNTS IN QUARTERLY BASIS FOR PERFORMANCE */
/* Quarterly Accounting Periods */

/* YEAR 070/80 */

export const accountingPeriod79Q3 = {
  year: 79,
  quarter: 3,
  name: 'Period 2079/80 Quarter 3',
  startDate: new Date('2023-01-14T18:15:00.000Z'),
  endDate: new Date('2023-04-13T18:14:59.000Z'),
};

export const accountingPeriod79Q4 = {
  year: 79,
  quarter: 4,
  name: 'Period 2079/80 Quarter 4',
  startDate: new Date('2023-04-13T18:15:00.000Z'),
  endDate: new Date('2023-07-16T18:14:59.000Z'),
};

/* YEAR 080/81 */

export const accountingPeriod80Q1 = {
  year: 80,
  quarter: 1,
  name: 'Period 2080/81 Quarter 1',
  startDate: new Date('2023-07-16T18:15:00.000Z'),
  endDate: new Date('2023-10-17T18:14:59.000Z'),
};

export const accountingPeriod80Q2 = {
  year: 80,
  quarter: 2,
  name: 'Period 2080/81 Quarter 2',
  startDate: new Date('2023-10-17T18:15:00.000Z'),
  endDate: new Date('2024-01-14T18:14:59.000Z'),
};

export const accountingPeriod80Q3 = {
  year: 80,
  quarter: 3,
  name: 'Period 2080/81 Quarter 3',
  startDate: new Date('2024-01-14T18:15:00.000Z'),
  endDate: new Date('2024-04-12T18:14:59.000Z'),
};

export const accountingPeriod80Q4 = {
  year: 80,
  quarter: 4,
  name: 'Period 2080/81 Quarter 4',
  startDate: new Date('2024-04-12T18:15:00.000Z'),
  endDate: new Date('2024-07-15T18:14:59.000Z'),
};

export const accountingPeriod81Q1 = {
  year: 81,
  quarter: 1,
  name: 'Period 2081/82 Quarter 1',
  startDate: new Date('2024-07-15T18:15:00.000Z'),
  endDate: new Date('2024-10-16T18:14:59.000Z'),
};

export const accountingPeriod81Q2 = {
  year: 81,
  quarter: 2,
  name: 'Period 2081/82 Quarter 2',
  startDate: new Date('2024-10-16T18:15:00.000Z'),
  endDate: new Date('2025-01-13T18:14:59.000Z'),
};

export const accountingPeriod81Q3 = {
  year: 81,
  quarter: 3,
  name: 'Period 2081/82 Quarter 3',
  startDate: new Date('2025-01-13T18:15:00.000Z'),
  endDate: new Date('2025-04-13T18:14:59.000Z'),
};

export const accountingPeriod81Q4 = {
  year: 81,
  quarter: 4,
  name: 'Period 2081/82 Quarter 4',
  startDate: new Date('2025-04-13T18:15:00.000Z'),
  endDate: new Date('2025-07-15T18:14:59.000Z'),
};

export const accountingPeriod82Q1 = {
  year: 82,
  quarter: 1,
  name: 'Period 2082/83 Quarter 1',
  startDate: new Date('2025-07-15T18:15:00.000Z'),
  endDate: new Date('2025-10-16T18:14:59.000Z'),
};

export const accountingPeriod82Q2 = {
  year: 82,
  quarter: 2,
  name: 'Period 2082/83 Quarter 2',
  startDate: new Date('2025-10-16T18:15:00.000Z'),
  endDate: new Date('2026-01-13T18:14:59.000Z'),
};

export const accountingPeriod82Q3 = {
  year: 82,
  quarter: 3,
  name: 'Period 2082/83 Quarter 3',
  startDate: new Date('2026-01-13T18:15:00.000Z'),
  endDate: new Date('2026-04-13T18:14:59.000Z'),
};

export const accountingPeriod82Q4 = {
  year: 82,
  quarter: 4,
  name: 'Period 2082/83 Quarter 4',
  startDate: new Date('2026-04-13T18:15:00.000Z'),
  endDate: new Date('2026-07-16T18:14:59.000Z'),
};

export const defaultAccPeriods = [
  accountingPeriod79Q4,
  accountingPeriod80Q1,
  accountingPeriod80Q2,
  accountingPeriod80Q3,
  accountingPeriod80Q4,
  accountingPeriod81Q1,
  accountingPeriod81Q2,
  accountingPeriod81Q3,
  accountingPeriod81Q4,
  accountingPeriod82Q1,
  accountingPeriod82Q2,
  accountingPeriod82Q3,
  accountingPeriod82Q4,
];

export const getStaticPeriodByDate = (date: Date) => {
  const jsd = new Date(date);
  const periods = defaultAccPeriods;
  return periods.find(x => jsd >= x.startDate && jsd < x.endDate);
};
