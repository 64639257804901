import {
  accountingPeriodsUrl,
  addOpeningBalanceUrl,
  companySetupStatusUrl,
  getOpeningBalanceUrl,
  subscriptionProductsUrl,
} from '../endPoints';

import { store } from '../../store/store';

const httpClient = () => {
  return store.getState().http.client;
};

const v1 = (path: string) => `/v1${path}`;

export const getAccountingPeriods = async () => {
  const path = accountingPeriodsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getCurrentAccountingPeriod = async () => {
  const path = v1('/reports/current-accounting-period');

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getCompanySetupStatus = async () => {
  const path = companySetupStatusUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getOpeningBalance = async () => {
  const path = getOpeningBalanceUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const addOpeningBalance = async (data: any) => {
  const path = addOpeningBalanceUrl();

  const response = await httpClient().post<any>(path, data);
  return response.data;
};

export const getSubscriptionProducts = async () => {
  const path = subscriptionProductsUrl();

  const response = await httpClient().get<any>(path);
  return response.data;
};

export const getSubscriptionOrders = async () => {
  const path = v1('/subscriptions/subscription-orders');

  const response = await httpClient().get<any>(path);
  return response.data;
};
