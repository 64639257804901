import React from 'react';
import { Navigate } from 'react-router-dom';

function AuthRequired({ children }: any) {
  // const { isAuthenticated } = useAuth(); // get from state later
  const isAuthenticated = true;

  return isAuthenticated === true ? children : <Navigate to="/login" replace />;
}

export default AuthRequired;
