export const getTotalProductPrice = (quantity: any, pricePerUnit: any) => {
  if (quantity) {
    const perUnit = +pricePerUnit || 0;
    const totalAmount = +quantity * perUnit;
    return +totalAmount.toFixed(2);
  }
  return 0;
};

export const getFinalProductPrice = (
  amount = 0,
  discount = 0,
  isTaxable = false,
) => {
  let finalAmount = +amount;
  if (!amount) {
    return 0;
  }
  if (discount) {
    finalAmount -= +discount;
  }

  if (isTaxable) {
    finalAmount += +(0.13 * finalAmount).toFixed(2);
    // finalAmount += 0.13 * finalAmount;
  }

  return +finalAmount.toFixed(2);
};

export const getProductPrice = (
  quantity: any,
  pricePerUnit: any,
  discount: number,
) => {
  if (quantity) {
    const perUnit = Number(pricePerUnit) || 0;
    const discountAmount = Number(discount) || 0;
    const totalAmount = Number(quantity) * perUnit - discountAmount;
    return String(totalAmount);
  }
  return 0;
};

export const getDiscountPercent = (
  discountAmount: number,
  amountSum: number,
) => {
  if (!amountSum || !discountAmount) {
    return '';
  }

  if (discountAmount) {
    const discount = (discountAmount * 100) / amountSum;
    return (+discount.toFixed(4)).toString();
  }
  return '';
};

export const getDiscountAmount = (
  discountPercent: number,
  amountSum: number,
) => {
  if (!amountSum) {
    return 0;
  }
  if (discountPercent) {
    const discount = (discountPercent / 100) * amountSum;
    return +discount.toFixed(2);
  }
  return 0;
};

export const getTaxAmount = (
  isTaxable = false,
  discountAmount = 0,
  amountSum = 0,
  vatPercent = 13,
) => {
  if (!amountSum) {
    return '';
  }
  const discountedAmount = amountSum - +discountAmount;

  if (isTaxable) {
    const vat = (vatPercent / 100) * discountedAmount;
    // return String(Math.ceil(vat));
    return vat.toFixed(2);
  }
  return '';
};

export const getFinalAmount = (
  isTaxable: boolean,
  discountAmount: number,
  fullPayment: boolean,
  amountSum: number,
) => {
  let discount = discountAmount || 0;
  let vat = getTaxAmount(isTaxable, discountAmount, amountSum, 13);
  // console.log('vat sad', vat);

  const discountedAndTaxedAmount = amountSum - Number(discount) + Number(vat);
  // console.log('- - - - - -');

  if (fullPayment) {
    return discountedAndTaxedAmount.toFixed(2);
  }
  return 0;
};
