import React from 'react';
import { Stack, Typography } from '@mui/material';

import { itemsAmountSum } from '../../../shared/utils/amountSum';
import {
  getFinalAmount,
  getTaxAmount,
} from '../../../shared/utils/vatAndDiscount';
import { colors } from '../../../assets/styles/colors';
import { formatAmount } from '../../../shared/utils/locale';

const BillOrInvoiceSummary = ({
  discountAmount,
  voucherItems,
  paidAmount,
  isTaxable,
  subTotalOnly,
  isFullPayment,
  isReturn,
  isInvoice,
  onCash,
  isPurchaseReturn,
  isSales,
  isPurchase,
  t,
}: any): JSX.Element => {
  // console.log('voucherItems =======', voucherItems);

  const amountSum = itemsAmountSum(voucherItems);
  const expectedPaidAmount = getFinalAmount(
    isTaxable,
    discountAmount,
    isFullPayment,
    amountSum,
  );

  const summaryData = [];
  let taxAmount = 0;
  let finalAmount = 0;
  if (subTotalOnly) {
    summaryData.push({
      label: t('entriesCommonText.subTotal'),
      amount: amountSum,
    });
  } else {
    taxAmount = +getTaxAmount(isTaxable, discountAmount, amountSum, 13);

    const finalRaw = amountSum - (+discountAmount || 0) + +(taxAmount || 0);
    finalAmount = +finalRaw.toFixed(2);

    if (discountAmount) {
      summaryData.push({
        label: t('entriesCommonText.discount'),
        amount: +(discountAmount || 0),
      });
    }
    if (taxAmount) {
      summaryData.push({ label: 'VAT', amount: taxAmount });
    }
    let totalTitle = t('entriesCommonText.totalWithDetail');
    if (isReturn || isSales || isPurchase) {
      totalTitle = t('entriesCommonText.total');
    }
    summaryData.push({ label: totalTitle, amount: finalAmount || 0 });

    // if (isReturn && dueAmount) {
    //   summaryData.push({ label: 'Due Amount', amount: dueAmount });
    // }

    if (!onCash && !isReturn) {
      summaryData.push({
        label:
          isInvoice || isPurchaseReturn
            ? t('entriesCommonText.received')
            : t('entriesCommonText.paid'),
        amount: +(expectedPaidAmount || paidAmount || 0),
      });
      summaryData.push({
        label:
          isInvoice || isPurchaseReturn
            ? t('entriesCommonText.remaining1')
            : t('entriesCommonText.remaining2'),
        amount: finalAmount - (expectedPaidAmount || paidAmount || 0),
      });
    }
  }

  return (
    <Stack bgcolor={colors.gray200} borderRadius={1}>
      {summaryData.map((item, x) => (
        <Stack
          key={x}
          p={0.5}
          px={2}
          borderRadius={1}
          direction={'row'}
          bgcolor={x === 5 ? colors.gray300 : colors.gray200}
          borderBottom={1}
          borderColor={colors.gray300}>
          <Typography align="right" flex={3}>
            {item.label}
          </Typography>

          <Typography ml={8} flex={1} fontWeight={'bold'}>
            {formatAmount(item.amount)}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default BillOrInvoiceSummary;
