import { DayBookFilters } from '../../shared/types/reportFilters/dayBook';

export const validateDayBookFilters = (data: DayBookFilters, errors: any) => {
  let isValid = true;

  if (!data.fromDate) {
    errors = { ...errors, fromDate: 'From date is required' };
    isValid = false;
  } else {
    delete errors.fromDate;
  }

  if (!data.toDate) {
    errors = { ...errors, toDate: 'To Date is required' };
    isValid = false;
  } else {
    delete errors.toDate;
  }

  return { isValid, errs: errors };
};
